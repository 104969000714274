import {OwnerPosts, Post} from "../objects/Post";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {VkApiError} from "../VkApiError";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import {TaskProgress} from "../../store/parsers/types";

export interface GetPostsParams {
    owner_id: number,
    filter?: "suggests"|"postponed"|"owner"|"others"|"all",
    extended?: 0|1,
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>,
    is_stop?: (post: Post) => boolean,
    is_skip?: (post: Post) => boolean,
    max_offset?: number,
}

const GetPosts = function* (params: GetPostsParams): Generator<any, OwnerPosts, any> {
    const access_token = params.access_token;

    let offset: number = 0;
    const count: number = 100;
    let total_posts: number = 0;

    const posts: Map<number, Post> = new Map<number, Post>();

    interface Response {
        count: number,
        items: Post[],
    }

    let is_stopped: boolean = false;

    const response_handler = (response: Response) => {
        total_posts = response.count;
        for (let post of response.items) {
            if (params.is_stop && params.is_stop(post)) {
                is_stopped = true;
            }
            if (params.is_skip && params.is_skip(post)) {
                continue;
            }
            posts.set(post.id, post);
        }
    };

    const error_handler = (error: VkApiError) => {
        is_stopped = true;
    };

    do {
        if (params.max_offset && offset >= params.max_offset) {
            break;
        }
        const method: IVkMethod = methods.wallGet({
            owner_id: params.owner_id,
            offset: offset,
            count: count,
            extended: params.extended,
            filter: params.filter,
        });
        yield SendMethodsDefault<Response>({
            methods_params: {
                methods: [method],
                access_token: access_token,
                progress_action: params.progress_action,
                add_api_errors_action: params.add_api_errors_action,
            },
            response_handler: response_handler,
            error_handler: error_handler,
            progress_text: 'Получаем посты',
        });
        offset += count;
    }
    while (offset < total_posts && !is_stopped);

    return {
        owner_id: params.owner_id,
        posts: Array.from(posts.values()),
    }
};

export default GetPosts;
