import isString from "lodash/isString";

const getLastPart = function (url: string): string|undefined {
    let id:string|undefined = url.split("/").pop();
    if (!id) {
        return undefined;
    }
    id = id.trim();
    if (id === '') {
        return undefined;
    }
    return id;
};

export const ConvertUrlToId = function (url: string): string|undefined {
    let id:string|undefined = getLastPart(url);
    if (!id) {
        return undefined;
    }
    const regex = /^(public|club|event)(\d+)$/g;
    const matches = regex.exec(id);
    if (matches && matches.length === 3) {
        return matches[2];
    }
    return id;
};

export function convert_urls_to_id(urls: string[]): string[] {
    const result: Set<string> = new Set<string>();
    for (let url of urls) {
        let converted = ConvertUrlToId(url);
        if (converted) {
            result.add(converted);
        }
    }
    return Array.from(result);
}

export interface TopicAddress {
    owner_id: number,
    topic_id?: number,
}

export interface PostAddress {
    owner_id: number,
    post_id: number,
}

export function convert_urls_to_posts(urls: string[]): PostAddress[] {
    const result: Map<string, PostAddress> = new Map<string, PostAddress>();
    const regex = /(-?\d+)_(\d+)/;
    urls.forEach(url => {
        url = url.trim();
        if (url === '') {
            return;
        }
        const m = regex.exec(url);
        if (m !== null) {
            const owner_id: number = parseInt(m[1]);
            const post_id: number = parseInt(m[2]);
            result.set(`${owner_id}_${post_id}`, {owner_id: owner_id, post_id: post_id});
        }
    });
    return Array.from(result.values());
}

export function convert_urls_to_topics(urls: string[]): TopicAddress[] {
    const result: Map<string, TopicAddress> = new Map<string, TopicAddress>();
    const regex = /topic(-?\d+)_(\d+)/;
    urls.forEach(url => {
        url = url.trim();
        if (url === '') {
            return;
        }
        const m = regex.exec(url);
        if (m !== null) {
            const owner_id: number = Math.abs(parseInt(m[1]));
            const topic_id: number = parseInt(m[2]);
            result.set(`${owner_id}_${topic_id}`, {owner_id: owner_id, topic_id: topic_id});
        }
    });
    return Array.from(result.values());
}

export function convert_urls_to_screen_names(urls: string[]): string[] {
    const result: Set<string> = new Set<string>();
    urls.map(url => getLastPart(url)).forEach(url => {
        if (isString(url)) {
            result.add(url);
        }
    });
    return Array.from(result.values());
}

export default ConvertUrlToId;
