import {createSelector} from "reselect";
import tokenize from "../../../../../helpers/tokenizer";
import {isTextContainsAnyWord} from "../../../../../helpers/words-checker";
import {Topic} from "../../../../../vkapi/objects/Topic";
import {RootState} from "../../../types";

const select_items = (state: RootState) => state.topicsParser.result.topics;
const min_messages = (state: RootState) => state.topicsParser.settings.min_messages;
const select_words_plus = (state: RootState) => state.topicsParser.settings.words_plus;
const select_words_minus = (state: RootState) => state.topicsParser.settings.words_minus;
const select_start_date = (state: RootState) => state.topicsParser.settings.start_date;
const select_end_date = (state: RootState) => state.topicsParser.settings.end_date;

const selectByMinMessages = createSelector(
    [select_items, min_messages],
    (items: Topic[], min: number) => {
        if (min === 0) {
            return items;
        }
        return items.filter(item => {
            return item.comments >= min;
        })
    }
);

const selectByWords = createSelector(
    [selectByMinMessages, select_words_plus, select_words_minus],
    (items: Topic[], words_plus: string, words_minus: string) => {
        const tokens_plus: string[] = tokenize(words_plus.replace(",", " "));
        const tokens_minus: string[] = tokenize(words_minus.replace(",", " "));
        if (tokens_plus.length === 0 && tokens_minus.length === 0) {
            return items;
        }

        return items.filter((item: Topic) => {
            if (tokens_minus.length > 0 && isTextContainsAnyWord(item.title, tokens_minus)) {
                return false;
            }
            if (tokens_plus.length > 0 && !isTextContainsAnyWord(item.title, tokens_plus)) {
                return false;
            }
            return true;
        });
    }
);

const selectFiltered = createSelector(
    [selectByWords, select_start_date, select_end_date],
    (items: Topic[], date_start: Date|null, date_end: Date|null) => {
        if (!date_start && !date_end) {
            return items;
        }

        return items.filter((item: Topic) => {
            if (date_start && item.updated * 1000 < date_start.getTime()) {
                return false;
            }
            if (date_end && item.updated * 1000 > date_end.getTime()) {
                return false;
            }
            return true;
        });
    }
);

export default selectFiltered;
