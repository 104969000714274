export const NAMESPACE = 'COMMUNITIES_FILTER/ACTIVITY/';

export const SET_SOURCE = NAMESPACE + 'SET_SOURCE';
export const SET_ONLY_MEMBERS = NAMESPACE + 'SET_ONLY_MEMBERS';
export const SET_ONLY_SELECTED_USERS = NAMESPACE + 'SET_ONLY_SELECTED_USERS';
export const SET_SELECTED_USERS_SOURCE = NAMESPACE + 'SET_SELECTED_USERS_SOURCE';
export const SET_START_DATE = NAMESPACE + 'SET_START_DATE';
export const SET_END_DATE = NAMESPACE + 'SET_END_DATE';

export const SET_SUBPROGRESS = NAMESPACE + 'SET_SUBPROGRESS';


