import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {Topic} from "../../../../vkapi/objects/Topic";
import GetGroups from "../../../../vkapi/tasks/GetGroups";
import {showAlert, showAlertError} from "../../../app/actions";
import {RootState, TaskStatus} from "../../types";
import {EMPTY_LINKS_LIST} from "../../validation-errors";
import * as action_types from './action-types';
import * as actions from "./actions";
import searchByGroups from "./sagas/search_by_groups";
import searchByLinks from "./sagas/search_by_links";
import searchTopicsOnly from "./sagas/search_topics_only";
import selectFiltered from "./selectors/TopicsSelector";
import {TopicsParserState, TopicsSearchType} from "./types";

const main = function* () {
    while (yield take(action_types.START)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        console.log(e);
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: TopicsParserState = yield select((state: RootState) => state.topicsParser);
    const access_token = yield select((state: RootState) => state.app.access_token);
    yield validate(state);

    if (state.settings.search_type === TopicsSearchType.BY_LINKS) {
        yield searchByLinks();
    }
    if (state.settings.search_type === TopicsSearchType.BY_GROUPS) {
        yield searchByGroups();
    }
    if (state.settings.search_type === TopicsSearchType.TOPICS_ONLY) {
        yield searchTopicsOnly();
    }

    const topics: Topic[] = yield select((state: RootState) => state.topicsParser.result.topics);
    const groups_ids: Set<number> = new Set<number>();
    topics.forEach(topic => {
        if (topic.owner_id) {
            groups_ids.add(topic.owner_id)
        }
    });

    const groups: GroupObject[] = yield GetGroups({
        access_token: access_token,
        group_ids: Array.from(groups_ids),
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });
    yield put(actions.setGroups(groups));

    let message: string = 'Готово.';
    const result = yield select((state: RootState) => state.topicsParser.result);
    if (state.settings.search_type === TopicsSearchType.BY_LINKS || state.settings.search_type === TopicsSearchType.BY_GROUPS) {
        message = 'Готово. Найдено: ' + result.user_ids.length;
    }
    if (state.settings.search_type === TopicsSearchType.TOPICS_ONLY) {

        const filtered: Topic[] = yield select(selectFiltered);
        message = 'Готово. Найдено: ' + filtered.length;

    }



    yield put(actions.setProgress({message: message, total: undefined, current: undefined}));
    yield put(showAlert({text: message, header: 'Парсинг завершен'}));
    yield put({ type: action_types.COMPLETED});
}

function* validate(state: TopicsParserState) {
    if (state.settings.search_type === TopicsSearchType.BY_LINKS) {
        if (state.settings.links_topics.trim() === '') {
            yield put(showAlertError(EMPTY_LINKS_LIST));
            yield put(actions.stop());
            return;
        }
    }
    if (state.settings.search_type === TopicsSearchType.BY_GROUPS || state.settings.search_type === TopicsSearchType.TOPICS_ONLY) {
        if (state.settings.links_groups.trim() === '') {
            yield put(showAlertError(EMPTY_LINKS_LIST));
            yield put(actions.stop());
            return;
        }
    }

    if (state.settings.start_date && state.settings.end_date && state.settings.start_date.getTime() > state.settings.end_date.getTime()) {
        let err: string = 'Начальная дата не может быть больше, чем конечная дата';
        yield put(showAlertError(err));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(action_types.STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
