import {IVkMethod} from "../IVkMethod";
import {DatabaseGetCitiesParams} from "./DatabaseGetCitiesParams";
import {FollowersGetParams} from "./FollowersGetParams";
import {GroupsGetMembersParams} from "./GroupsGetMembersParams";
import {GroupsGetByIdParams} from "./GroupsGetByIdParams";
import {ExecuteParams} from "./ExecuteParams";
import {GroupsGetParams} from "./GroupsGetParams";
import {NewsfeedSearchParams} from "./NewsfeedSearchParams";
import {UsersGetSubscriptionsParams} from "./UsersGetSubscriptionsParams";
import {FriendsGetParams} from "./FriendsGetParams";
import {UsersGetParams} from "./UsersGetParams";
import {UsersSearchParams} from "./UsersSearchParams";
import {UtilsGetLinkStatsParams} from "./UtilsGetLinkStatsParams";
import {WallGetParams} from "./WallGetParams";
import {LikesGetListParams} from "./LikesGetListParams";
import {WallGetCommentsParams} from "./WallGetCommentsParams";
import {BoardGetTopicsParams} from "./BoardGetTopicsParams";
import {BoardGetCommentsParams} from "./BoardGetCommentsParams";
import {UtilsResolveScreenNameParams} from "./UtilsResolveScreenNameParams";
import {GroupsSearchParams} from "./GroupsSearchParams";
import {WallGetByIdParams} from "./WallGetByIdParams";

export const friendsGet = (params: FriendsGetParams): IVkMethod => {
    return {
        method_name: 'friends.get',
        params: params,
    }
};

export const usersGetFollowers = (params: FollowersGetParams): IVkMethod => {
    return {
        method_name: 'users.getFollowers',
        params: params,
    }
};

export const usersSearch = (params: UsersSearchParams): IVkMethod => {
    return {
        method_name: 'users.search',
        params: params,
    }
};

export const groupsGetMembers = (params: GroupsGetMembersParams): IVkMethod => {
    return {
        method_name: 'groups.getMembers',
        params: params,
    }
};
export const groupsGetById = (params: GroupsGetByIdParams): IVkMethod => {
    return {
        method_name: 'groups.getById',
        params: params
    }
};

export const utilsGetLinkStats = (params: UtilsGetLinkStatsParams): IVkMethod => {
    return {
        method_name: 'utils.getLinkStats',
        params: params,
    }
};

export const groupsGet = (params: GroupsGetParams): IVkMethod => {
    return {
        method_name: 'groups.get',
        params: params
    }
};

export const groupsSearch = (params: GroupsSearchParams): IVkMethod => {
    return {
        method_name: 'groups.search',
        params: params
    }
};

export const usersGet = (params: UsersGetParams): IVkMethod => {
    return {
        method_name: 'users.get',
        params: params,
    }
};

export const usersGetSubscriptions = (params: UsersGetSubscriptionsParams): IVkMethod => {
    return {
        method_name: 'users.getSubscriptions',
        params: params
    }
};

export const wallGet = (params: WallGetParams): IVkMethod => {
    return {
        method_name: 'wall.get',
        params: params,
    }
};

export const wallGetById = (params: WallGetByIdParams): IVkMethod => {
    return {
        method_name: 'wall.getById',
        params: params,
    }
};

export const wallGetComments = (params: WallGetCommentsParams): IVkMethod => {
    return {
        method_name: 'wall.getComments',
        params: params,
    }
};

export const likesGetList = (params: LikesGetListParams): IVkMethod => {
    return {
        method_name: 'likes.getList',
        params: params,
    }
};

export const boardGetTopics = (params: BoardGetTopicsParams): IVkMethod => {
    return {
        method_name: 'board.getTopics',
        params: params,
    }
};

export const boardGetComments = (params: BoardGetCommentsParams): IVkMethod => {
    return {
        method_name: 'board.getComments',
        params: params,
    }
};

export const utilsResolveScreenName = (params: UtilsResolveScreenNameParams): IVkMethod => {
    return {
        method_name: 'utils.resolveScreenName',
        params: params,
    }
};

export const databaseGetCities = (params: DatabaseGetCitiesParams): IVkMethod => {
    return {
        method_name: 'database.getCities',
        params: params,
    }
};

export const execute = (params: ExecuteParams): IVkMethod => {
    return {
        method_name: 'execute',
        params: params,
    }
};

export const newsfeedSearch = (params: NewsfeedSearchParams): IVkMethod => {
    return {
        method_name: 'newsfeed.search',
        params: params,
    }
};
