export default function bool_to_int(val: boolean): number {
    return val ? 1 : 0;
}

export function bool_sum(vals: Array<boolean>): number {
    let sum = 0;
    vals.forEach(val => {
        sum += bool_to_int(val);
    });
    return sum;
}
