import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../../vkapi/objects/UserObject";
import {convert_urls_to_id} from "../../../../../vkapi/tasks/ConverUrlToId";
import GetGroups from "../../../../../vkapi/tasks/GetGroups";
import GroupsGetMembers from "../../../../../vkapi/tasks/GroupsGetMembers";
import ProgressGenerator from "../../../../helpers/ProgressGenerator";
import {blocked as actions} from "../actions";
import {RootState, TaskStatus} from "../../../types";
import {showAlertError, showAlertParsingCompleted} from "../../../../app/actions";
import selectFiltered from "../selectors/BlockedSelector";
import {BlockedFilterResult, CommunitiesFilterState} from "../types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: CommunitiesFilterState['blocked'] = yield select((state: RootState) => state.communitiesFilter.blocked);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_ids_string: string[] = convert_urls_to_id(state.settings.source.split("\n"));

    const groups: GroupObject[] = yield GetGroups({
        group_ids: group_ids_string,
        fields: 'members_count',
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });

    const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});

    const result: BlockedFilterResult[] = [];
    const skipped: GroupObject[] = [];

    for (const group of groups) {
        yield progress.next().value;

        if (group.deactivated || !group.members_count) {
            skipped.push(group);
            continue;
        }
        let users: UserObject[] = [];
        try {
            users = yield GroupsGetMembers<UserObject[]> ({
                group_id: group.id,
                members_count: group.members_count,
                fields: 'deactivated',
                access_token: access_token,
                progress_action: actions.setSubProgress
            });

        }
        catch (e) {
            skipped.push(group);
            continue;
        }
        if (users.length === 0) {
            skipped.push(group);
            continue;
        }
        const blocked_members_count: number = users.filter(user => user.deactivated).length;
        result.push({
            group: group,
            blocked_members_count: blocked_members_count,
            ratio: (blocked_members_count / group.members_count) * 100,
        })
    }

    yield put(actions.setResult(result));

    const filtered: GroupObject[] = yield select(selectFiltered);

    yield put(actions.setSkipped(skipped));
    yield put(actions.setProgress({message: `Завершено. Обработано групп: ${filtered.length}. Пропущенно: ${skipped.length}`, total: 100, current: 100}));
    yield put(actions.setSubProgress({message: ``}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${filtered.length}. Пропущенно: ${skipped.length}`));
    yield put(actions.setCompleted());
}

function* validate(state: CommunitiesFilterState['blocked']) {
    if (state.settings.source.trim() === '') {
        yield put(showAlertError('Вы не указали ссылки на сообщества'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;




