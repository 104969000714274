const NAMESPACE = 'TOPICS_PARSER/';

export const SET_SEARCH_TYPE = NAMESPACE + 'SET_SEARCH_TYPE';
export const SET_LINKS_TOPICS = NAMESPACE + 'SET_LINKS_TOPICS';
export const SET_LINKS_GROUPS = NAMESPACE + 'SET_LINKS_GROUPS';

export const SET_WORDS_PLUS = NAMESPACE + 'SET_WORDS_PLUS';
export const SET_WORDS_MINUS = NAMESPACE + 'SET_WORDS_MINUS';

export const SET_START_DATE = NAMESPACE + 'SET_START_DATE';
export const SET_END_DATE = NAMESPACE + 'SET_END_DATE';

export const SET_MIN_MESSAGES = NAMESPACE + 'SET_MIN_MESSAGES';

export const SET_IS_SKIP_MESSAGES_WITH_URLS = NAMESPACE + 'SET_IS_SKIP_MESSAGES_WITH_URLS';

export const SET_GROUPS = NAMESPACE + 'SET_GROUPS';

export const START = NAMESPACE + 'START';
export const STOP = NAMESPACE + 'STOP';
export const SET_TASK_STATUS = NAMESPACE + 'SET_TASK_STATUS';
export const SET_TASK_ERROR = NAMESPACE + 'SET_TASK_ERROR';
export const SET_PROGRESS = NAMESPACE + 'SET_PROGRESS';
export const SET_RESULT = NAMESPACE + 'SET_RESULT';

export const ADD_API_ERROR = NAMESPACE + 'ADD_API_ERROR';
export const RESET_API_ERRORS = NAMESPACE + 'RESET_API_ERRORS';

export const COMPLETED = NAMESPACE + 'COMPLETED';
