import {createAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {VkApiError} from "../../../../vkapi/VkApiError";
import {TaskProgress, TaskStatus} from "../../types";
import * as types from "./action-types";
import {TopicsSearchResult, TopicsSearchType} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSearchType = createAction(types.SET_SEARCH_TYPE, withPayloadType<TopicsSearchType>());
export const setLinksGroups = createAction(types.SET_LINKS_GROUPS, withPayloadType<string>());
export const setLinksTopics = createAction(types.SET_LINKS_TOPICS, withPayloadType<string>());
export const setWordsPlus = createAction(types.SET_WORDS_PLUS, withPayloadType<string>());
export const setWordsMinus = createAction(types.SET_WORDS_MINUS, withPayloadType<string>());
export const setStartDate = createAction(types.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.SET_END_DATE, withPayloadType<Date|null>());
export const setIsSkipMessagesWithUrls = createAction(types.SET_IS_SKIP_MESSAGES_WITH_URLS, withPayloadType<boolean>());
export const setMinMessages = createAction(types.SET_MIN_MESSAGES, withPayloadType<number>());
export const setGroups = createAction(types.SET_GROUPS, withPayloadType<GroupObject[]>());

export const start = createAction(types.START);
export const stop = createAction(types.STOP);
export const addApiError = createAction(types.ADD_API_ERROR, withPayloadType<VkApiError>());
export const resetApiErrors = createAction(types.RESET_API_ERRORS);

export const setTaskStatus = createAction(types.SET_TASK_STATUS, withPayloadType<TaskStatus>());
export const setTaskError = createAction(types.SET_TASK_ERROR, withPayloadType<string>());
export const setProgress = createAction(types.SET_PROGRESS, withPayloadType<TaskProgress>());
export const setResult = createAction(types.SET_RESULT, withPayloadType<TopicsSearchResult>());
