import React from "react";

export interface ButtonsContainerProps extends React.HTMLAttributes<HTMLDivElement> {
}

const ButtonsContainer = (props: React.PropsWithChildren<ButtonsContainerProps>) => {

    const classes = props.className || "tw-flex tw-flex-wrap tw-gap-x-2";

    return (
        <div className={classes}>
            {props.children}
        </div>
    )
};

export default ButtonsContainer;
