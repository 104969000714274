import {Chips} from "primereact/chips";
import React from "react";
import InputContainer from "../containers/InputContainer";

export interface WordsInputProps {
    label: string,
    value: string[],
    onChange: (e: {value: string[]}) => void,
}

const WordsInput = (props: WordsInputProps) => {
    return (
        <InputContainer label={props.label}>
            <Chips className="tw-flex-1 p-field" value={props.value} onChange={props.onChange} separator=","/>
        </InputContainer>
    )
};

export default WordsInput;
