import React, {useEffect, useState} from 'react';
import bridge from '@vkontakte/vk-bridge';
import _has from "lodash/has";

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './assets/css/primereact.css';
import './assets/css/App.css';
import './assets/css/tailwind.output.css';

import { Menubar } from 'primereact/menubar';
import ActivitiesCommunity from "./components/panels/parsers/communities-audience/ActivitiesCommunity";
import AdminContacts from "./components/panels/parsers/communities-audience/AdminContacts";
import PostsAudience from "./components/panels/parsers/communities-audience/PostsAudience";
import Promoposts from "./components/panels/parsers/communities-audience/Promoposts";
import StatsPostsCommunity from "./components/panels/parsers/communities-audience/StatsPostsCommunity";
import TopCommentatorsCommunity from "./components/panels/parsers/communities-audience/TopCommentatorsCommunity";
import TopicParserCommunity from "./components/panels/parsers/communities-audience/TopicsParser";
import TopReaders from "./components/panels/parsers/communities-audience/TopReaders";
import CommunitiesFilter from "./components/panels/parsers/communities/CommunitiesFilter";
import GroupStatuses from "./components/panels/parsers/communities/GroupStatuses";
import PostsSearch from "./components/panels/parsers/communities/PostsSearch";
import SearchCommunities from "./components/panels/parsers/communities/SearchCommunities";
import AbTesting from "./components/panels/parsers/filter-base/AbTesting";
import BaseAnalysis from "./components/panels/parsers/filter-base/BaseAnalysis";
import IdsConverter from "./components/panels/parsers/filter-base/IdsConverter";
import Intersections from "./components/panels/parsers/filter-base/Intersections";
import SearchBase from "./components/panels/parsers/filter-base/SearchBase";
import ActivitiesContest from "./components/panels/parsers/others/ActivitiesContest";
import BaseViewer from "./components/panels/parsers/others/BaseViewer";
import InvolvingPosts from "./components/panels/parsers/others/InvolvingPosts";
import NewsSearch from "./components/panels/parsers/others/NewsSearch";
import PopularPosts from "./components/panels/parsers/others/PopularPosts";
import ActivitiesInProfiles from "./components/panels/parsers/user-profiles/ActivitiesInProfiles";
import FriendsAndFollowers from "./components/panels/parsers/user-profiles/FriendsAndFollowers";
import Instagram from "./components/panels/parsers/user-profiles/Instagram";
import StatusLocator from "./components/panels/parsers/user-profiles/StatusLocator";
import UsersSearch from "./components/panels/parsers/user-profiles/UsersSearch";
import getMenuItems from "./components/ui/menu/Menu";
import AppPanelContainer from "./components/ui/panels/AppPanelContainer";
import TargetAudienceLocator from "./components/panels/parsers/audience-search/TargetAudienceLocator";
import SuperstarsLocator from "./components/panels/parsers/audience-search/SuperstarsLocator";
import AudienceAnalysis from "./components/panels/parsers/audience-search/AudienceAnalysis";
import {Dialog} from "primereact/dialog";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./store/parsers/types";
import {hideAlert, setAccessToken} from "./store/app/actions";
import {AlertProps} from "./store/app/types";
import GroupMembers from "./components/panels/parsers/communities-audience/GroupMembers";

interface GetAuthTokenParams {
    app_id: number,
    scope: string
}

function App() {

    const dispatch = useDispatch();

    const app_id: number = useSelector((state:RootState) => state.app.launch_params.vk_app_id);
    const alert_message: AlertProps|undefined = useSelector((state: RootState) => state.app.alert);
    const [activePanel, setActivePanel] = useState('audienceSearch_targetAudienceLocator');

    const onMenuClick = (menuId: string|undefined) => {
        if (menuId) {
            setActivePanel(menuId);
        }
    };
    const menuItems = getMenuItems(onMenuClick);

    useEffect(() => {
        async function fetchToken(app_id: number) {
            const params: GetAuthTokenParams = {
                app_id: app_id,
                scope: 'friends,photos,video,pages,wall,groups,market'
            };
            const info = await bridge.send('VKWebAppGetAuthToken', params);
            if(_has(info, 'access_token')) {
                dispatch(setAccessToken(info.access_token));
                return;
            }
            alert('No access token');
        }
        fetchToken(app_id);
    }, [app_id, dispatch]);

    return (
        <div className="App tw-px-4 tw-py-4">
            <Dialog header={alert_message?.header} visible={alert_message !== undefined} style={{ width: '50vw' }} onHide={() => dispatch(hideAlert())}>
                <p>{alert_message?.text}</p>
            </Dialog>
            <Menubar model={menuItems}/>
            <AppPanelContainer activePanel={activePanel}>
                <TargetAudienceLocator title={'Группы, где есть ЦА'} id={'audienceSearch_targetAudienceLocator'} />
                <SuperstarsLocator title={'Популярные люди'} id={'audienceSearch_superstarsLocator'} />
                <AudienceAnalysis title={'Анализ пользователей'} id={'audienceSearch_audienceAnalysis'} />

                <GroupMembers title={'Участники'} id={'communitiesAudience_members'} />
                <TopReaders title={'ТОП читатели'} id={'communitiesAudience_topReaders'}/>
                <ActivitiesCommunity title={'Активности'} id={'communitiesAudience_activities'}/>
                <TopCommentatorsCommunity title={'ТОП комментаторы'} id={'communitiesAudience_commentators'}/>
                <TopicParserCommunity title={'Обсуждения'} id={'communitiesAudience_topics'}/>
                <Promoposts title={'Промопосты'} id={'communitiesAudience_promoposts'}/>
                <PostsAudience title={'Аудитория постов'} id={'communitiesAudience_postsAudience'}/>
                <AdminContacts title={'Контакты (администраторы)'} id={'communitiesAudience_adminContacts'} />
                <StatsPostsCommunity title={'Статистика постов'} id={'communitiesAudience_statsPosts'}/>

                <SearchCommunities title={'Поиск сообществ'} id={'communities_searchCommunities'}/>
                <CommunitiesFilter title={'Фильтр сообществ'} id={'communities_communitiesFilter'}/>
                <PostsSearch title={'Поиск постов'} id={'communities_postsSearch'}/>
                <GroupStatuses title={'Статусы сообществ'} id={'communities_groupStatuses'}/>

                <FriendsAndFollowers title={'Друзья и подписчики'} id={'userProfiles_friendsAndFollowers'} />
                <ActivitiesInProfiles title={'Активность на странице'} id={'userProfiles_activitiesInProfiles'}/>
                <UsersSearch title={'Поиск людей'} id={'userProfiles_usersSearch'}/>
                <Instagram title={'Instagram и т. п.'} id={'userProfiles_instagram'}/>
                <StatusLocator title={'Поиск по статусам'} id={'userProfiles_statusLocator'}/>
                
                <Intersections title={'Пересечение аудитории'} id={'filterBase_intersections'}/>
                <SearchBase title={'Поиск по базе'} id={'filterBase_searchBase'}/>
                <AbTesting title={'A/B тесты'} id={'filterBase_abTesting'}/>
                <BaseAnalysis title={'Аналитика'} id={'filterBase_analysis'}/>
                <IdsConverter title={'Преобразование Id'} id={'filterBase_idsConverter'}/>

                <NewsSearch title={'Поиск по новостям'} id={'others_newsSearch'}/>
                <PopularPosts title={'Популярные посты'} id={'others_popularPosts'} />
                <BaseViewer title={'Просмотр базы'} id={'others_baseViewer'} />
                <InvolvingPosts title={'Вовлекающие посты'} id={'others_involvingPosts'} />
                <ActivitiesContest title={'Конкурс активностей'} id={'others_activitiesContest'}/>
            </AppPanelContainer>
        </div>
    );
}

export default App;
