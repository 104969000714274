import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Button} from "primereact/button";
import download_txt from "../../../../helpers/DownloadTxt";
import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {UsersSearchType} from "../../../../store/parsers/user-profiles/users-search/types";
import * as actions from "../../../../store/parsers/user-profiles/users-search/actions";
import useCitySingle from "../../../hooks/CitySingleHook";
import useCountry from "../../../hooks/CountryHook";
import useMinMax from "../../../hooks/MinMaxHook";
import useGender from "../../../hooks/UseGender";
import useSave from "../../../hooks/UseSave";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import MinMaxInput from "../../../ui/inputs/MinMaxInput";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingName from "../../../ui/inputs/ParsingName";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";

const UsersSearch = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.usersSearch);
    const progress: TaskProgress = useSelector((state: RootState) => state.usersSearch.progress);

    const [parsingName, setParsingName] = useState<string>('');

    const onSearchTypeChange = (e: RadioButtonChangeEvent) => dispatch(actions.setSearchType(e.value));
    const [onAgeMinChange, onAgeMaxChange] = useMinMax(actions.setAgeMin, actions.setAgeMax);
    const CountryComponent = useCountry(state.settings.selected_country, actions.setSelectedCountry);
    const CityComponent = useCitySingle(state.settings.selected_country, state.settings.selected_city, actions.setSelectedCity);
    const GenderComponent = useGender(state.settings.gender, actions.setGender);
    const {saveRef, setDownloadUrl, anchors} = useSave(parsingName);

    const save = () => {
        download_txt<number[]>({
            data: state.result,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: number[]) => data.map(item => item.toString()),
        });
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Найдите людей по работе, религии и другим критериям."
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/poisk_lyudey"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Искать по">
                    <BasicRadioButton name="usersSearchSearchType" label="по умолчанию"
                                      value={UsersSearchType.DEFAULT}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === UsersSearchType.DEFAULT}
                    />
                    <BasicRadioButton name="usersSearchSearchType" label="должность"
                                      value={UsersSearchType.JOB}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === UsersSearchType.JOB}
                    />
                    <BasicRadioButton name="usersSearchSearchType" label="место работы"
                                      value={UsersSearchType.WORK}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === UsersSearchType.WORK}
                    />
                    <BasicRadioButton name="usersSearchSearchType" label="религия"
                                      value={UsersSearchType.RELIGION}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === UsersSearchType.RELIGION}
                    />
                </InputContainer>

                <InputContainer label="Поисковый запрос">
                    <BasicTextInput placeholder="что искать?"
                                    value={state.settings.query}
                                    onChange={e => dispatch(actions.setQuery(e.currentTarget.value))}
                    />
                </InputContainer>

                <MinMaxInput label="Возраст"
                             min={state.settings.age_min} onMinChange={onAgeMinChange}
                             max={state.settings.age_max} onMaxChange={onAgeMaxChange}
                />

                {CountryComponent}

                {CityComponent}

                {GenderComponent}

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())}  className="p-field" />
                    {state.result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить" onClick={() => save()} />
                    </>
                    }
                    {anchors}
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

        </AppPanel>
    );
};

export default UsersSearch;
