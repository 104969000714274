import React, {useRef, useState} from "react";

function useSaveWithCsv(parsingName: string) {
    const saveCsvRef = useRef<HTMLAnchorElement>(null);
    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadCsvUrl, setDownloadCsvUrl] = useState<string>('');
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const anchors = (
        <div>
            <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Сохранить id</a>
            <a download={parsingName.trim() !== '' ? parsingName.trim() + '.csv' : 'result.csv'} ref={saveCsvRef} href={downloadCsvUrl} style={{display: 'none'}}>Сохранить в CSV</a>
        </div>
    );

    return {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors,
    }
}

export default useSaveWithCsv;
