const NAMESPACE = 'TOP_COMMENTATORS_COMMUNITY/';

export const SET_DATASOURCE = NAMESPACE + 'SET_DATASOURCE';
export const SET_FILTER_LIKES = NAMESPACE + 'SET_FILTER_LIKES';
export const SET_FILTER_LIKES_USERS = NAMESPACE + 'SET_FILTER_LIKES_USERS';
export const SET_START_DATE = NAMESPACE + 'SET_START_DATE';
export const SET_END_DATE = NAMESPACE + 'SET_END_DATE';
export const SET_IS_SKIP_FIRST_POST = NAMESPACE + 'SET_IS_SKIP_FIRST_POST';
export const SET_LIKES_MIN = NAMESPACE + 'SET_LIKES_MIN';
export const SET_LIKES_MAX = NAMESPACE + 'SET_LIKES_MAX';
export const SET_COMMENTS_MIN = NAMESPACE + 'SET_COMMENTS_MIN';
export const SET_COMMENTS_MAX = NAMESPACE + 'SET_COMMENTS_MAX';
export const SET_COMMENTS_LENGTH_MIN = NAMESPACE + 'SET_COMMENTS_LENGTH_MIN';
export const SET_COMMENTS_LENGTH_MAX = NAMESPACE + 'SET_COMMENTS_LENGTH_MAX';
export const SET_WORDS_PLUS = NAMESPACE + 'SET_WORDS_PLUS';
export const SET_WORDS_MINUS = NAMESPACE + 'SET_WORDS_MINUS';

export const START = NAMESPACE + 'START';
export const STOP = NAMESPACE + 'STOP';
export const SET_TASK_STATUS = NAMESPACE + 'SET_TASK_STATUS';
export const SET_TASK_ERROR = NAMESPACE + 'SET_TASK_ERROR';
export const SET_PROGRESS = NAMESPACE + 'SET_PROGRESS';
export const SET_SUBPROGRESS = NAMESPACE + 'SET_SUBPROGRESS';
export const SET_RESULT = NAMESPACE + 'SET_RESULT';

export const ADD_API_ERROR = NAMESPACE + 'ADD_API_ERROR';
export const RESET_API_ERRORS = NAMESPACE + 'RESET_API_ERRORS';

export const COMPLETED = NAMESPACE + 'COMPLETED';
