import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {StateWithErrors, StateWithProgress, TaskProgress} from "../../types";

export enum StatusLocatorSearchType {
    BY_GROUPS = "BY_GROUPS",
    BY_USERS = "BY_USERS",
}

export interface StatusLocatorResult {
    user: UserObject,
    status: string,
}


interface State {
    settings: {
        search_type: StatusLocatorSearchType,
        source_groups: string,
        source_users: string,
        words_plus: string,
        words_minus: string,
        strict_mode: boolean,
    },
    skipped: GroupObject[],
    sub_progress: TaskProgress,
    result: StatusLocatorResult[],
}

export interface StatusLocatorState extends State, StateWithErrors, StateWithProgress {}
