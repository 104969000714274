import {createAction} from "@reduxjs/toolkit";
import {Post} from "../../../../vkapi/objects/Post";
import {VkApiError} from "../../../../vkapi/VkApiError";
import {TaskProgress, TaskStatus} from "../../types";
import * as types from "./action-types";
import {ActivitiesFrom, PostsFilter} from "./types";


function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setDataSource = createAction(types.SET_DATASOURCE, withPayloadType<string>());
export const setSelectedUsers = createAction(types.SET_SELECTED_USERS, withPayloadType<string>());
export const setActivitiesFrom = createAction(types.SET_ACTIVITIES_FROM, withPayloadType<ActivitiesFrom>());
export const setPostsFilter = createAction(types.SET_POSTS_FILTER, withPayloadType<PostsFilter>());
export const setStartDate = createAction(types.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.SET_END_DATE, withPayloadType<Date|null>());

export const start = createAction(types.START);
export const stop = createAction(types.STOP);
export const addApiError = createAction(types.ADD_API_ERROR, withPayloadType<VkApiError>());
export const resetApiErrors = createAction(types.RESET_API_ERRORS);

export const setTaskStatus = createAction(types.SET_TASK_STATUS, withPayloadType<TaskStatus>());
export const setTaskError = createAction(types.SET_TASK_ERROR, withPayloadType<string>());
export const setProgress = createAction(types.SET_PROGRESS, withPayloadType<TaskProgress>());
export const setSubProgress = createAction(types.SET_SUBPROGRESS, withPayloadType<TaskProgress>());
export const setResult = createAction(types.SET_RESULT, withPayloadType<Post[]>());
