import {spawn} from 'redux-saga/effects'
import UsersSaga from "./sagas/saga-users";
import GroupsSaga from "./sagas/saga-groups";

const main = function* () {
    yield spawn(UsersSaga);
    yield spawn(GroupsSaga);
};

export default main;
