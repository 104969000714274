import {AlertProps, AppLaunchParams, VkAppState} from "./types";
import isApiDisabled from "../../helpers/EnvChecker";
import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import * as actions from './actions';

const initialState: VkAppState = {
    launch_params: getLaunchParams(),
    access_token: '',
    alert: undefined,
};

const VkAppReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setAccessToken, (state: VkAppState, action: PayloadAction<string>) => {
        state.access_token = action.payload;
    });
    builder.addCase(actions.showAlert, (state: VkAppState, action: PayloadAction<AlertProps>) => {
        state.alert = action.payload;
    });
    builder.addCase(actions.hideAlert, (state: VkAppState) => {
        state.alert = undefined;
    })
});

function getLaunchParams(): AppLaunchParams {
    if (isApiDisabled()) {
        return {
            vk_user_id: 1,
            vk_app_id: 1,
            vk_access_token_settings: 'none',
            vk_group_id: 1,
            vk_ref: '',
            vk_language: 'ru',
            vk_platform: '',
            sign: ''
        }
    }

    return {
        vk_user_id: getParamAsIntOrNull('vk_user_id') || 0,
        vk_app_id: getParamAsIntOrNull('vk_app_id') || 0,
        vk_access_token_settings: getParamsAsStringOrNull('vk_access_token_settings') || '',
        vk_group_id: getParamAsIntOrNull('vk_group_id') || 0,
        vk_ref: getParamsAsStringOrNull('vk_ref') || '',
        vk_language: getParamsAsStringOrNull('vk_language') || '',
        vk_platform: getParamsAsStringOrNull('vk_platform') || '',
        sign: getParamsAsStringOrNull('sign') || '',
    }
}

function getParamAsIntOrNull(param:string):number|null {
    const paramValue = getParamsAsStringOrNull(param);
    if (paramValue === null) {
        return null;
    }
    return parseInt(paramValue);
}

function getParamsAsStringOrNull(param:string):string|null {
    const url:string = window.location.href;
    const searchParams = new URLSearchParams(url);
    const value = searchParams.get(param);
    if(value === null) {
        return value;
    }
    return value.toString();
}

export default VkAppReducer;
