import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import * as actions from "./actions";
import {RootState, TaskStatus} from "../../types";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {AbTestingState} from "./types";
import chunk from "lodash/chunk";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: AbTestingState = yield select((state: RootState) => state.abTesting);
    yield validate(state);

    const lines: string[] = state.settings.source.split("\n");
    const chunk_size: number = Math.ceil(lines.length / state.settings.parts_count);
    const result: string[][] = chunk(lines, chunk_size);
    yield put(actions.setResult(result));

    yield put(showAlertParsingCompleted(`Готово.`));
    yield put(actions.setCompleted());
}

function* validate(state: AbTestingState) {
    if (state.settings.source.trim() === '') {
        yield put(showAlertError('Вы не загрузили файл или он пустой'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
