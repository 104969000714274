import {createSelector} from "reselect";
import {RootState} from "../../../types";
import {ShowType} from "../types";

const select_items = (state: RootState) => state.newsSearch.result;
const min_length = (state: RootState) => state.newsSearch.settings.min_text_length;
const show_type = (state: RootState) => state.newsSearch.settings.show_type;
const start_date = (state: RootState) => state.newsSearch.settings.start_date;
const end_date = (state: RootState) => state.newsSearch.settings.end_date;
const country = (state: RootState) => state.newsSearch.settings.selected_country;
const city = (state: RootState) => state.newsSearch.settings.selected_city;

const selectByShowType = createSelector(
    [select_items, show_type],
    (items, show_type) => {
        if (show_type === ShowType.ANY) {
            return items;
        }
        return items.filter(item => {
            if (item.owner_id < 0 && show_type === ShowType.USERS) {
                return false;
            }
            if (item.owner_id > 0 && show_type === ShowType.COMMUNITIES) {
                return false;
            }
            return true;
        })
    }
);

const selectByDate = createSelector(
    [selectByShowType, start_date, end_date],
    (items, start_date, end_date) => {
        if (!start_date && !end_date) {
            return items;
        }
        return items.filter(item => {
            if (start_date && item.date * 1000 < start_date.getTime()) {
                return false;
            }
            if (end_date && item.date * 1000 > end_date.getTime()) {
                return false;
            }
            return true;
        })
    }
);

const selectByGeo = createSelector(
    [selectByDate, country, city],
    (items, country, city) => {
        if (!city && !country) {
            return items;
        }
        return items.filter(item => {
            if (country) {
                if (item.owner.country?.id !== country.id) {
                    return false;
                }
            }
            if (city) {
                if (item.owner.city?.id !== city.id) {
                    return false;
                }
            }
            return true;
        })
    }
);

const selectFiltered = createSelector(
    [selectByGeo, min_length],
    (items, min_length) => {
        if (min_length === 0) {
            return items;
        }
        return items.filter(item => item.text.length <= min_length);
    }
);

export default selectFiltered;
