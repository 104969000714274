import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {incrementMap} from "../../../../helpers/IncrementMap";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {convert_urls_to_screen_names} from "../../../../vkapi/tasks/ConverUrlToId";
import GetFollowersAll from "../../../../vkapi/tasks/GetFollowersAll";
import GetFriendsAll from "../../../../vkapi/tasks/GetFriendsAll";
import GetUsers from "../../../../vkapi/tasks/GetUsers";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import {RootState, TaskStatus} from "../../types";
import * as actions from "./actions";
import {FriendsAndFollowersSearchType, FriendsAndFollowersState} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: FriendsAndFollowersState = yield select((state: RootState) => state.friendsAndFollowers);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const user_ids: string[] = convert_urls_to_screen_names(state.settings.source.split('\n'));
    const users: Map<number, UserObject> = yield GetUsers({
        user_ids: user_ids,
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
        filter_func: user => !user.deactivated,
    });

    const users_array: UserObject[] = Array.from(users.values());

    const progress = ProgressGenerator({text: 'Обрабатываем пользователей', total: users_array.length, action: actions.setProgress});

    const result_map: Map<number, number> = new Map<number, number>();

    for (const user of users_array) {
        yield progress.next().value;
        if (state.settings.type === FriendsAndFollowersSearchType.ANY || state.settings.type === FriendsAndFollowersSearchType.FRIENDS) {
            const friends: number[] = yield GetFriendsAll<number[]>({
                user_id: user.id,
                access_token: access_token,
                progress_action: actions.setSubProgress,
                add_api_errors_action: actions.addApiError,
            });
            friends.forEach(user_id => {
                incrementMap<number>(result_map, user_id);
            });
        }
        if (state.settings.type === FriendsAndFollowersSearchType.ANY || state.settings.type === FriendsAndFollowersSearchType.FOLLOWERS) {
            const followers: number[] = yield GetFollowersAll<number[]>({
                user_id: user.id,
                access_token: access_token,
                progress_action: actions.setSubProgress,
                add_api_errors_action: actions.addApiError,
            });
            followers.forEach(user_id => {
                incrementMap<number>(result_map, user_id);
            });
        }
    }

    let result: number[] = [];
    if (state.settings.min_count > 0) {
        result_map.forEach((count:number, user_id: number) => {
            if (count >= state.settings.min_count) {
                result.push(user_id);
            }
        });
    }
    else {
        result = Array.from(result_map.keys());
    }

    yield put(actions.setResult(result));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result.length}`, total: 100, current: 100}));
    yield put(actions.setSubProgress({message: ''}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${result.length}`));
    yield put(actions.setCompleted());
}

function* validate(state: FriendsAndFollowersState) {
    if (state.settings.source.trim() === '') {
        yield put(showAlertError('Вы не указали пользователей'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
