import {GroupCount} from "../types";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import _chunk from "lodash/chunk";
import ProgressGenerator from "../../../../helpers/ProgressGenerator";
import GetSubscriptionsOfEveryUserId from "../../../../../vkapi/tasks/GetSubscriptionsOfEveryUserId";
import {UserIdAnyIds} from "../../../../../vkapi/objects/UserIdAnyIds";
import {TaskProgress} from "../../../types";
import {VkApiError} from "../../../../../vkapi/VkApiError";

export interface GetSubscriptionCountsParams {
    user_ids: number[],
    access_token: string,
    top_min: number,
    top_max: number,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
}

const GetSubscriptionCounts = function* (params: GetSubscriptionCountsParams): Generator<any, Map<number, GroupCount>, any> {
    const group_counts: Map<number, GroupCount> = new Map<number, GroupCount>();

    const users_per_chunk: number = 150;
    const chunks:number[][] = _chunk(params.user_ids, users_per_chunk);

    const progress = ProgressGenerator({text: 'Получаем группы пользователей', total: chunks.length, action: params.progress_action});

    for (const chunk of chunks) {
        yield progress.next().value;

        let count: number = 200;
        if (params.top_max > 0) {
            count = Math.min(params.top_max, count);
        }
        const users_with_groups: UserIdAnyIds[] = yield GetSubscriptionsOfEveryUserId({user_ids: chunk, access_token: params.access_token, add_api_errors_action: params.add_api_errors_action, method_params: {count: count}});
        users_with_groups.forEach((user_with_groups: UserIdAnyIds) => {
            user_with_groups.ids.forEach((group_id: number, index: number) => {
                if (params.top_min > 0 && index + 1 < params.top_min) {
                    return;
                }
                if (params.top_max > 0 && index + 1 > params.top_max) {
                    return;
                }
                const group_count: GroupCount|undefined = group_counts.get(group_id);
                if (group_count) {
                    group_count.count++;
                    group_counts.set(group_id, group_count);
                    return;
                }
                group_counts.set(group_id, {group_id: group_id, count: 1});
            });
        });
    }
    return group_counts;
};

export default GetSubscriptionCounts;
