import {GroupsSearchType} from "../../vkapi/methods/GroupsSearchParams";
import {CommunityTypeFilter} from "../parsers/types";

export function CommunitiesSearchTypeToArray(type: CommunityTypeFilter): GroupsSearchType[] {
    if (type === CommunityTypeFilter.ALL) {
        return [GroupsSearchType.GROUP, GroupsSearchType.PAGE, GroupsSearchType.EVENT];
    }
    if (type === CommunityTypeFilter.GROUPS) {
        return [GroupsSearchType.GROUP];
    }
    if (type === CommunityTypeFilter.PUBLICS) {
        return [GroupsSearchType.PAGE];
    }
    if (type === CommunityTypeFilter.EVENTS) {
        return [GroupsSearchType.EVENT];
    }
    throw new Error('Передан неизвестный тип сообщества');
}
