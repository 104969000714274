import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {Button} from "primereact/button";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import download_txt from "../../../../helpers/DownloadTxt";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlert, showAlertError} from "../../../../store/app/actions";
import {PostsSearchAttachType} from "../../../../store/parsers/communities/posts-search/types";

import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import * as actions from "../../../../store/parsers/communities/posts-search/actions";
import {getPoll, Post} from "../../../../vkapi/objects/Post";
import {AttachPoll} from "../../../../vkapi/objects/PostAttach";
import useDateRange from "../../../hooks/DateRangeHook";
import BasicCheckbox from "../../../ui/inputs/BasicCheckbox";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import DateRangeInput from "../../../ui/inputs/DateRangeInput";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingName from "../../../ui/inputs/ParsingName";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";

const PostsSearch = (props: AppPanelProps) => {

    const moment = require("moment");

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.postsSearch);
    const progress: TaskProgress = useSelector((state: RootState) => state.postsSearch.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.postsSearch.sub_progress);

    const saveCsvRef = useRef<HTMLAnchorElement>(null);
    const [downloadCsvUrl, setDownloadCsvUrl] = useState<string>('');
    const saveGroupsUrlsRef = useRef<HTMLAnchorElement>(null);
    const [downloadGroupsUrlsUrl, setDownloadGroupsUrlsUrl] = useState<string>('');
    const savePostUrlsRef = useRef<HTMLAnchorElement>(null);
    const [downloadPostUrlsUrl, setDownloadPostUrlsUrl] = useState<string>('');
    const saveAuthorsIdsRef = useRef<HTMLAnchorElement>(null);
    const [downloadAuthorsIdsUrl, setDownloadAuthorsIdsUrl] = useState<string>('');

    const [parsingName, setParsingName] = useState<string>('');

    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);

    const attachOptions = [
        {label: 'не важно', value: PostsSearchAttachType.ANY},
        {label: 'любой опрос', value: PostsSearchAttachType.POLL_ANY},
        {label: 'открытый опрос', value: PostsSearchAttachType.POLL_OPEN},
    ];

    const saveCSV = () => {
        if (state.result.length === 0) {
            dispatch(showAlert({text: 'Нечего сохранять', header: 'Ошибка'}));
            return;
        }
        const headers: string[] = [
            '"Пост"',
            '"Ссылка"',
            '"Сообщество"',
            '"Лайки"',
            '"Репосты"',
            '"Комментарии"',
            '"Просмотры"',
            '"Соотношение"',
            '"Есть опрос"',
            '"Ответов в опросе"',
            '"Вовлеченность опроса: соотношение просмотров к количеству проголосовавших"',
            '"Анонимный"',
            '"Дата"',
        ];
        const generator = (item: Post) => {
            let text: string = strip_punctuation(item.text);
            if (text.trim() === '') {
                text = "Без текста";
            }
            const views: number = item.views?.count || 0;
            const likes: number = item.likes?.count || 0;
            const reposts: number = item.reposts?.count || 0;
            const comments: number = item.comments?.count || 0;

            const poll: AttachPoll|undefined = getPoll(item);
            let has_poll: 0|1 = 0;
            let poll_involve: number = 0;
            let poll_anonymous: number = 0;
            let poll_answers: number = 0;
            if (poll) {
                has_poll = 1;
                poll_anonymous = poll.poll.anonymous ? 1 : 0;
                poll.poll.answers.forEach(item => poll_answers + item.votes);
                if (poll_answers > 0) {
                    poll_involve = views / poll_answers;
                }
            }

            const line = [
                '"' + text + '"',
                `"https://vk.com/wall${item.owner_id}_${item.id}"`,
                `"https://vk.com/club${Math.abs(item.owner_id)}"`,
                likes,
                reposts,
                comments,
                views,
                views > 0 ? (likes + reposts + comments) / views : 0,
                has_poll,
                poll_answers,
                poll_involve,
                poll_anonymous,
                moment(item.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm'),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(state.result, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    const savePostsUrls = () => {
        if (state.result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        download_txt<Post[]>({
            data: state.result,
            ref: savePostUrlsRef,
            setDownloadUrl: setDownloadPostUrlsUrl,
            mapper: (data: Post[]) => data.map(item => `https://vk.com/wall${item.owner_id}_${item.id}`),
        });
    };

    const saveGroupsUrls = () => {
        if (state.result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        download_txt<Post[]>({
            data: state.result,
            ref: saveGroupsUrlsRef,
            setDownloadUrl: setDownloadGroupsUrlsUrl,
            mapper: (data: Post[]) => data.map(item => `https://vk.com/club${Math.abs(item.owner_id)}`),
        });
    };

    const saveAuthorsIds = () => {
        if (state.result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const data: number[] = state.result.filter(item => item.signer_id).map<number>(item => item.signer_id || 0);
        download_txt<number[]>({
            data: data,
            ref: saveAuthorsIdsRef,
            setDownloadUrl: setDownloadAuthorsIdsUrl,
            mapper: (data: number[]) => data.map(item => item.toString()),
        });
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Тут Вы можете вставить список групп, список фраз и найти посты где эти фразы встречаются."
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/poisk_postov"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Список групп (по одной на строке)'}
                                    placeholder={"https://vk.com/cerebro_vk"}
                                    value={state.settings.groups_source}
                                    onChange={(e) => dispatch(actions.setGroupsSource(e))}
                />

                <TextAreaWithButton label={'Список фраз (по одной на строке)'}
                                    placeholder={'Заказ роллов'}
                                    value={state.settings.queries_source}
                                    onChange={(e) => dispatch(actions.setQueriesSearch(e))}
                />

                <BasicCheckbox label="Искать только всё словосочетание"
                               checked={state.settings.is_strict}
                               onChange={e => dispatch(actions.setIsStrict(e.checked))}
                />

                <InputContainer label="Минус слова (через пробел)">
                    <BasicTextInput
                        value={state.settings.words_minus}
                        onChange={e => dispatch(actions.setWordsMinus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которых не должно быть в посте'}
                    />
                </InputContainer>

                <InputContainer label="Дата">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <InputContainer label="Прикрепления">
                    <Dropdown options={attachOptions} value={state.settings.attach_type}
                              className="p-field tw-w-full sm:tw-flex-1"
                              onChange={e => dispatch(actions.setAttachType(e.value))}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field" />
                    {state.result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить в CSV" onClick={() => saveCSV()} />
                        <SaveButton label="Сохранить ссылки" onClick={() => savePostsUrls()} />
                        <SaveButton label="Сохранить группы" onClick={() => saveGroupsUrls()} />
                        <SaveButton label="Сохранить авторов" onClick={() => saveAuthorsIds()} />

                        <a download={parsingName.trim() !== '' ? parsingName.trim() + '.csv' : 'result.csv'} ref={saveCsvRef} href={downloadCsvUrl} style={{display: 'none'}}>Сохранить в CSV</a>
                        <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={savePostUrlsRef} href={downloadPostUrlsUrl} style={{display: 'none'}}>Сохранить ссылки</a>
                        <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveGroupsUrlsRef} href={downloadGroupsUrlsUrl} style={{display: 'none'}}>Сохранить группы</a>
                        <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveAuthorsIdsRef} href={downloadAuthorsIdsUrl} style={{display: 'none'}}>Сохранить авторов</a>
                    </>
                    }
                </ButtonsContainer>
            </ParsingSettingsContainer>



            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>

            {state.status !== TaskStatus.RUNNING && state.result.length > 0 &&
            <div className="tw-mt-4">
                <DataTable value={state.result} autoLayout={true}
                           sortField={'date'} sortOrder={-1}
                           rowHover={true}
                           paginator={true}
                           rows={100}
                >
                    <Column header="Пост" field="text" body={(item: Post, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/wall${item.owner_id}_${item.id}`} target="_blank" rel="noopener noreferrer">{item.text.trim() === '' ? 'без текста' : item.text}</a></div>} />
                    <Column header="Лайки"  field="likes.count" sortable />
                    <Column header="Комментарии" field="comments.count" sortable />
                    <Column header="Дата" field="date" body={(item: Post) => moment(item.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm')} sortable />
                </DataTable>
            </div>
            }

        </AppPanel>
    );
};

export default PostsSearch;
