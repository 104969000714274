import {select} from "redux-saga/effects";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {convert_urls_to_id, TopicAddress} from "../../../../../vkapi/tasks/ConverUrlToId";
import GetGroups from "../../../../../vkapi/tasks/GetGroups";
import {RootState} from "../../../types";
import * as actions from "../actions";
import {TopicsParserState} from "../types";
import GetTopicCommentsSaga from "./get_topics_comments";

const main = function* () {
    const state: TopicsParserState = yield select((state: RootState) => state.topicsParser);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_urls: string[] = convert_urls_to_id(state.settings.links_groups.split('\n'));
    const groups: GroupObject[] = yield GetGroups({
        access_token: access_token,
        group_ids: group_urls,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    const topic_addresses: TopicAddress[] = groups.map((group: GroupObject) => {
        return {
            owner_id: group.id,
        }
    });

    yield GetTopicCommentsSaga(topic_addresses);
};

export default main;
