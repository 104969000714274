import {Button} from "primereact/button";
import React, {useCallback, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../store/app/actions";
import * as actions from "../../../../store/parsers/others/involving-posts/actions";
import selectFiltered from "../../../../store/parsers/others/involving-posts/selectors/InvolvingPostsSelector";
import {InvolvingPost, InvolvingPostsState} from "../../../../store/parsers/others/involving-posts/types";
import {RootState, SearchTypeListOrSearch, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import useDateRange from "../../../hooks/DateRangeHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import DateRangeInput from "../../../ui/inputs/DateRangeInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";

const InvolvingPosts = (props: AppPanelProps) => {

    const moment = require("moment");

    const dispatch = useDispatch();
    const state: InvolvingPostsState = useSelector((state: RootState) => state.involvingPosts);
    const progress: TaskProgress = useSelector((state: RootState) => state.involvingPosts.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.involvingPosts.sub_progress);

    const result: InvolvingPost[] = useSelector(selectFiltered);

    const [parsingName, setParsingName] = useState<string>('');

    const [downloadCsvURL, setDownloadCsvURL] = useState<string>('');
    const saveCsvRef = useRef<HTMLAnchorElement>(null);

    const onSearchTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setSearchType(e.value)), [dispatch]);
    const onGroupsSourceChange = useCallback((value: string) => dispatch(actions.setGroupsSource(value)), [dispatch]);
    const onSearchSourceChange = useCallback((value: string) => dispatch(actions.setSearchQueriesSource(value)), [dispatch]);
    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);

    const saveCsv = () => {
        if (result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = [
            '"Группа"',
            '"Ссылка на группу"',
            '"Ссылка на пост"',
            '"Лайки"',
            '"Репосты"',
            '"Комментарии"',
            '"Просмотры"',
            '"Всего активностей"',
            '"Соотношение лайков и репостов"',
            '"Соотношение активностей к просмотрам"',
            '"В среднем просмотров на пост"',
            '"Соотношение просмотров к среднему количеству просмоторов"',
            '"Дата"',
        ];
        const generator = (item: InvolvingPost) => {
            let group_name: string = strip_punctuation(item.group.name ?? '');
            const line = [
                `"${group_name}"`,
                `"https://vk.com/club${item.group.id}"`,
                `"https://vk.com/wall${item.post.owner_id}_${item.post.id}"`,
                item.post.likes.count,
                item.post.reposts.count,
                item.post.comments.count,
                item.post.views?.count || 0,
                item.total_activities,
                item.likes_to_reposts.toString().replace('.', ','),
                item.activities_to_views.toString().replace('.', ','),
                item.average_views_to_post.toString().replace('.', ','),
                item.views_to_average.toString().replace('.', ','),
                moment(item.post.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm'),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(result, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvURL(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>

            <ParsingHelp
                description="Поиск вовлекающих постов."
                url="http://blog.xn--90aha1bhc1b.xn--p1ai/vovlekayushchiye_posty"
                title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Как искать">
                    <BasicRadioButton id="searchTypeByGroup" name="searchType" label="По списку групп"
                                      value={SearchTypeListOrSearch.BY_GROUP_URLS}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === SearchTypeListOrSearch.BY_GROUP_URLS}
                    />
                    <BasicRadioButton id="searchTypeBySearch" name="searchType" label="Через поиск сообществ"
                                      value={SearchTypeListOrSearch.BY_GROUPS_SEARCH}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === SearchTypeListOrSearch.BY_GROUPS_SEARCH}
                    />
                </InputContainer>

                {state.settings.search_type === SearchTypeListOrSearch.BY_GROUP_URLS &&
                    <TextAreaWithButton label={'Список групп (по одной на строке)'}
                                        placeholder={"https://vk.com/cerebro_vk"}
                                        buttonId={'uploadButtonGroups'}
                                        value={state.settings.groups_source} onChange={onGroupsSourceChange}
                    />
                }
                {state.settings.search_type === SearchTypeListOrSearch.BY_GROUPS_SEARCH &&
                    <TextAreaWithButton label={'Поисковые запросы (по одному на строке)'}
                                        placeholder={'Кухни'}
                                        buttonId={'uploadButtonQueries'}
                                        value={state.settings.search_queries_source} onChange={onSearchSourceChange}
                    />
                }

                <InputContainer label="Дата">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)} />

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {result.length > 0 &&
                        <SaveButton label="Сохранить в CSV" onClick={() => saveCsv()} />
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.csv' : 'involving-posts.csv'} ref={saveCsvRef} href={downloadCsvURL} style={{display: 'none'}}>Сохранить в CSV</a>
                </ButtonsContainer>

            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>
            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

        </AppPanel>
    );
};

export default InvolvingPosts;

