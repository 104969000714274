import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {VkApiError} from "../VkApiError";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import {BoardCommentsSort} from "../methods/BoardGetCommentsParams";
import {TopicComment} from "../objects/TopicComment";
import {TaskProgress} from "../../store/parsers/types";

export interface GetTopicCommentsParams {
    topic_id: number,
    group_id: number,
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>,
    is_stop?: (post: TopicComment) => boolean,
    is_skip?: (post: TopicComment) => boolean,
}

const GetTopicComments = function* (params: GetTopicCommentsParams): Generator<any, TopicComment[], any> {
    const access_token = params.access_token;

    let offset: number = 0;
    const count: number = 100;
    let total_comments: number = 0;

    const comments: Map<number, TopicComment> = new Map<number, TopicComment>();

    interface Response {
        count: number,
        items: TopicComment[],
    }

    let is_stopped: boolean = false;

    const response_handler = (response: Response) => {
        total_comments = response.count;
        for (let comment of response.items) {
            if (params.is_stop && params.is_stop(comment)) {
                is_stopped = true;
            }
            if (params.is_skip && params.is_skip(comment)) {
                continue;
            }
            comments.set(comment.id, comment);
        }
    };

    const error_handler = (error: VkApiError) => {
        is_stopped = true;
    };

    do {
        const method: IVkMethod = methods.boardGetComments({
            topic_id: params.topic_id,
            group_id: params.group_id,
            offset: offset,
            count: count,
            sort: BoardCommentsSort.DESC,
            need_likes: 1,
        });
        yield SendMethodsDefault<Response>({
            methods_params: {
                methods: [method],
                access_token: access_token,
                progress_action: params.progress_action,
                add_api_errors_action: params.add_api_errors_action,
            },
            response_handler: response_handler,
            error_handler: error_handler,
        });
        offset += count;
    }
    while (offset < total_comments && !is_stopped);

    return Array.from(comments.values());
};

export default GetTopicComments;
