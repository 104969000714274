import {ResolveScreenNameResult} from "../objects/ResolveScreenNameResult";
import {IVkMethod} from "../IVkMethod";
import * as methods from "./../methods/VkMethods";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {WithDefaultActions} from "../../store/parsers/types";

export interface ResolveScreenNameParams extends WithDefaultActions {
    screen_name: string,
    access_token: string,
}

const ResolveScreenName = function* (params: ResolveScreenNameParams): Generator<any, ResolveScreenNameResult|null, any> {
    let result: ResolveScreenNameResult|null = null;
    const method: IVkMethod = methods.utilsResolveScreenName({screen_name: params.screen_name});

    yield SendMethodsDefault<ResolveScreenNameResult>({
        methods_params: {
            methods: [method],
            access_token: params.access_token,
            add_api_errors_action: params.add_api_errors_action,
            progress_action: params.progress_action,
        },
        progress_text: 'Получаем информацию о ссылке',
        response_handler: (response) => {
            result = response;
        },
        error_handler: error => {}
    });
    return result;
};

export default ResolveScreenName;
