import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {Button} from "primereact/button";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import download_txt from "../../../../helpers/DownloadTxt";
import {showAlert, showAlertError} from "../../../../store/app/actions";

import {ActivityType, RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {InProfileActivities} from "../../../../store/parsers/user-profiles/activities-in-profiles/types";
import * as actions from "../../../../store/parsers/user-profiles/activities-in-profiles/actions";
import useDateRange from "../../../hooks/DateRangeHook";
import useMinMax from "../../../hooks/MinMaxHook";
import useSaveWithCsv from "../../../hooks/SaveWithCsvHook";
import BasicCheckbox, {CheckBoxChangeEvent} from "../../../ui/inputs/BasicCheckbox";
import DateRangeInput from "../../../ui/inputs/DateRangeInput";
import MinMaxInput from "../../../ui/inputs/MinMaxInput";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingName from "../../../ui/inputs/ParsingName";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";

const ActivitiesInProfiles = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.activitiesInProfiles);
    const progress: TaskProgress = useSelector((state: RootState) => state.activitiesInProfiles.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.activitiesInProfiles.sub_progress);

    const [parsingName, setParsingName] = useState<string>('');
    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);

    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);
    const [onActivitiesMinChange, onActivitiesMaxChange] = useMinMax(actions.setActivitiesMin, actions.setActivitiesMax);
    const [onUsersMinChange, onUsersMaxChange] = useMinMax(actions.setUsersMin, actions.setUsersMax);
    const [onPostsMinChange, onPostsMaxChange] = useMinMax(actions.setPostsMin, actions.setPostsMax);

    const onActivityTypeChange = (e: CheckBoxChangeEvent, activity_type: ActivityType) => {
        if (!e.checked) {
            if (state.settings.activity_types.length === 1) {
                dispatch(showAlert({text: 'Нельзя снять все галочки', header: 'Ошибка'}));
                return;
            }
            dispatch(actions.removeActivityType(activity_type));
            return;
        }
        dispatch(actions.addActivityType(activity_type));
    };

    const save = () => {
        if (state.result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        download_txt<InProfileActivities[]>({
            data: state.result,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: InProfileActivities[]) => data.map(item => item.from_id.toString()),
        });
    };

    const saveCSV = () => {
        if (state.result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = ['"Id"', '"Ссылка"', '"Пользователи"', '"Посты"', '"Активности"',];
        const generator = (item: InProfileActivities) => {
            const item_url = 'https://vk.com/id' + item.from_id;
            const line = [
                item.from_id,
                '"' + item_url + '"',
                item.users,
                item.posts,
                item.activities,
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(state.result, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };


    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Соберите людей, которые проявляли активность (лайки, репосты, комментарии) у выбранных пользователей"
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/aktivnosti_na_stranitse_polzovatelya"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Ссылки на пользователей (по одной на строке)'}
                                    value={state.settings.data_source}
                                    onChange={(e) => dispatch(actions.setDataSource(e))}
                />

                <InputContainer label="Дата">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <MinMaxInput
                    label="Количество активностей (по умолчанию 1)"
                    min={state.settings.activities_min} onMinChange={onActivitiesMinChange}
                    max={state.settings.activities_max} onMaxChange={onActivitiesMaxChange}
                />

                <MinMaxInput
                    label="Проявили активности у N пользователей (по умолчанию 1)"
                    min={state.settings.users_min} onMinChange={onUsersMinChange}
                    max={state.settings.users_max} onMaxChange={onUsersMaxChange}
                    notice="НЕ РЕКОМЕНДУЕМ использовать этот параметр без необходимости. Этим вы слишком сузите ЦА и получите всего несколько десятков человек."
                />

                <MinMaxInput label="Количество постов с активностью (по умолчанию 1)" id="postsMinMax"
                             top={
                                 <BasicCheckbox label="У каждого пользователя"
                                                checked={state.settings.is_posts_every_user}
                                                onChange={(e) => dispatch(actions.setPostsEveryUser(e.checked))}
                                 />
                             }
                             min={state.settings.posts_min} onMinChange={onPostsMinChange}
                             max={state.settings.posts_max} onMaxChange={onPostsMaxChange}
                />

                <InputContainer label="Тип активности">
                    <BasicCheckbox id="activityLikes" label="Лайки"
                                   checked={state.settings.activity_types.includes(ActivityType.LIKES)}
                                   onChange={e => onActivityTypeChange(e, ActivityType.LIKES)}
                    />
                    <BasicCheckbox id="activityReposts" label="Репосты"
                                   checked={state.settings.activity_types.includes(ActivityType.REPOSTS)}
                                   onChange={e => onActivityTypeChange(e, ActivityType.REPOSTS)}
                    />
                    <BasicCheckbox id="activityComments" label="Комментарии"
                                   checked={state.settings.activity_types.includes(ActivityType.COMMENTS)}
                                   onChange={e => onActivityTypeChange(e, ActivityType.COMMENTS)}
                    />
                    <BasicCheckbox id="activityCommentLikes" label="Лайки комментариев"
                                   checked={state.settings.activity_types.includes(ActivityType.COMMENT_LIKES)}
                                   onChange={e => onActivityTypeChange(e, ActivityType.COMMENT_LIKES)}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {state.result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить id в txt файл" onClick={() => save()} />
                        <SaveButton label="Сохранить в CSV" onClick={() => saveCSV()} />
                    </>
                    }
                    {anchors}
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>

        </AppPanel>
    );
};

export default ActivitiesInProfiles;
