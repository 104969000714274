import React, {FormEvent, useCallback, useEffect, useRef, useState} from "react";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicFileUpload from "../../../ui/upload/BasicFileUpload";
import MinMaxInput from "../../../ui/inputs/MinMaxInput";
import InputContainer from "../../../ui/containers/InputContainer";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import BasicNumbersInput, {NumberChangeEvent} from "../../../ui/inputs/BasicNumbersInput";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import BasicCheckbox, {CheckBoxChangeEvent} from "../../../ui/inputs/BasicCheckbox";
import {Button} from "primereact/button";
import ParsingName from "../../../ui/inputs/ParsingName";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import {useDispatch, useSelector} from "react-redux";
import {DataSourceType, RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {
    AnalysisType,
    EventType,
    ShowType,
    TargetAudienceGroup,
    TargetAudienceLocatorState
} from "../../../../store/parsers/audience-search/target-audience-locator/types";

import selectFiltered
    from "../../../../store/parsers/audience-search/target-audience-locator/selectors/FilteredSelector";
import * as actions from "../../../../store/parsers/audience-search/target-audience-locator/actions";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {bool_sum} from "../../../../helpers/bool_to_int";
import {showAlert} from "../../../../store/app/actions";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import download_url from "../../../../helpers/download_url";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {csv_generator} from "../../../../helpers/csv_generator";

const TargetAudienceLocator = (props: AppPanelProps) => {

    const dispatch = useDispatch();
    const state: TargetAudienceLocatorState = useSelector((state: RootState) => state.targetAudienceLocator);
    const progress: TaskProgress = useSelector((state: RootState) => state.targetAudienceLocator.progress);
    const result: TargetAudienceGroup[] = useSelector(selectFiltered);

    const saveCsvRef = useRef<HTMLAnchorElement>(null);
    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadCsvUrl, setDownloadCsvUrl] = useState<string>('');
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const [parsingName, setParsingName] = useState<string>('');

    const onDatasourceTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setDatasourceType(e.value)), [dispatch]);
    const onUrlChange = useCallback((e: FormEvent<HTMLInputElement>) => dispatch(actions.setDatasourceGroupUrl(e.currentTarget.value)), [dispatch]);
    const onUpload = useCallback((lines: string[]) => dispatch(actions.setDatasourceUserIds(lines.join('\n'))), [dispatch]);
    const onMembersMinChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setMembersMin(parseInt(e.value))), [dispatch]);
    const onMembersMaxChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setMembersMax(parseInt(e.value))), [dispatch]);
    const onTruncateToCountChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setTruncateToCount(parseInt(e.value))), [dispatch]);
    const onFilterMembersMinChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setSelectorFilterMembersMin(parseInt(e.value))), [dispatch]);
    const onFilterMembersMaxChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setSelectorFilterMembersMax(parseInt(e.value))), [dispatch]);
    const onCommunityTypeChange = useCallback((e: CheckBoxChangeEvent, action: ActionCreatorWithPayload<boolean>) => {
        if (! e.checked) {
            const elements = [
                state.settings.selector_filter_community_type_event,
                state.settings.selector_filter_community_type_club,
                state.settings.selector_filter_community_type_public,
            ];
            const selected_elements = bool_sum(elements);
            if (selected_elements === 1) {
                dispatch(showAlert({text: 'Нельзя снять все галочки', header: 'Ошибка'}));
                return;
            }
        }
        dispatch(action(e.checked));
    }, [state, dispatch]);
    const onEventsTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setSelectorFilterEventType(e.value)), [dispatch]);
    const onGroupsTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setSelectorFilterShowType(e.value)), [dispatch]);
    const onAnalysisTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setAnalysisType(e.value)), [dispatch]);
    const onTopMinChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setTopMin(parseInt(e.value))), [dispatch]);
    const onTopMaxChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setTopMax(parseInt(e.value))), [dispatch]);

    const [selection, setSelection] = useState<TargetAudienceGroup[]>([]);

    useEffect(() => {
        setSelection(result);
    }, [result]);

    const save = () => {
        if (selection.length === 0) {
            dispatch(showAlert({text: 'Вы не выделили результаты, которые нужно сохранить', header: 'Ошибка'}));
            return;
        }
        const lines: string[] = [];
        selection.forEach((item) => {
            //lines.push('https://vk.com/club' + item.group.id);
            lines.push('https://vk.com/' + item.group.screen_name);
        });
        setDownloadUrl(download_url(lines.join('\r\n'), 'text/plain'));
        setTimeout(() => {
            if (saveRef && saveRef.current) {
                saveRef.current.click()
            }
        }, 1000);
    };

    const saveCSV = () => {
        if (selection.length === 0) {
            dispatch(showAlert({text: 'Вы не выделили результаты, которые нужно сохранить', header: 'Ошибка'}));
            return;
        }
        const headers: string[] = ['"Название"', '"Ссылка"', '"Подписчики"', '"Всего"', '"Соотношение"', '"Смарт"'];
        const generator = (item: TargetAudienceGroup) => {
            const line = [
                '"' + strip_punctuation(item.group.name ?? '') + '"',
                `"https://vk.com/club${item.group.id}"`,
                item.count,
                item.group.members_count,
                item.count_to_total.toFixed(2).replace('.', ','),
                item.smart.toFixed(3).replace('.', ','),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(selection, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>

            <ParsingHelp title={props.title} url="https://blog.xn--90aha1bhc1b.xn--p1ai/poisk_ca" description="Узнайте, в каких сообществах состоит аудитория. Для того, чтобы собрать активную аудиторию нужной тематики, найдите несколько небольших и активных сообществ, в которых состоит Ваша аудитория." />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Как искать">
                    <BasicRadioButton name="searchByRadio" label="По ссылке на группу" value={DataSourceType.URL} onChange={onDatasourceTypeChange} checked={state.settings.data_source_type === DataSourceType.URL} />
                    <BasicRadioButton name="searchByRadio" label="По файлу со списком id пользователей" value={DataSourceType.FILE} onChange={onDatasourceTypeChange} checked={state.settings.data_source_type === DataSourceType.FILE} />
                </InputContainer>

                {state.settings.data_source_type === DataSourceType.URL &&
                <InputContainer label="Ссылка на сообщество">
                    <BasicTextInput id="groupUrlInput" placeholder="https://vk.com/cerebro_vk" value={state.settings.data_source_url} onChange={onUrlChange}/>
                </InputContainer>
                }
                {state.settings.data_source_type === DataSourceType.FILE &&
                <InputContainer label="Загрузка из файла">
                    <BasicFileUpload id="upload" chooseLabel="Загрузить id из файла" onUpload={onUpload}/>
                </InputContainer>
                }

                <MinMaxInput
                    min={state.settings.members_min} onMinChange={onMembersMinChange}
                    max={state.settings.members_max} onMaxChange={onMembersMaxChange}
                    id="members" label="Количество подписчиков в сообществе"/>

                <InputContainer label="Сколько групп показывать">
                    <BasicNumbersInput id="groupsCountInput" value={state.settings.truncate_to_count} onNumberChange={onTruncateToCountChange} />
                </InputContainer>

                <MinMaxInput
                    min={state.settings.selector_filter_members_min} onMinChange={onFilterMembersMinChange}
                    max={state.settings.selector_filter_members_max} onMaxChange={onFilterMembersMaxChange}
                    id="filterByMembersCount" label="Отфильтровать список по количеству подписчиков"/>

                <InputContainer label="Фильтр по слову (через пробел)">
                    <BasicTextInput
                        value={state.settings.selector_filter_words_plus}
                        onChange={e => dispatch(actions.setSelectorFilterWordsPlus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которые должны быть в названии'}
                    />
                </InputContainer>

                <InputContainer label="Минус слова (через пробел)">
                    <BasicTextInput
                        value={state.settings.selector_filter_words_minus}
                        onChange={e => dispatch(actions.setSelectorFilterWordsMinus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которые должны быть в названии'}
                    />
                </InputContainer>

                <InputContainer label="Какие сообщества показывать">
                    <BasicCheckbox id="showPublics" label="Паблики" checked={state.settings.selector_filter_community_type_public} onChange={e => onCommunityTypeChange(e, actions.setSelectorFilterCommunityTypePublic)}/>
                    <BasicCheckbox id="showGroups" label="Группы" checked={state.settings.selector_filter_community_type_club} onChange={e => onCommunityTypeChange(e, actions.setSelectorFilterCommunityTypeClub)}/>
                    <BasicCheckbox id="showEvents" label="События" checked={state.settings.selector_filter_community_type_event} onChange={e => onCommunityTypeChange(e, actions.setSelectorFilterCommunityTypeEvent)}/>
                </InputContainer>

                {state.settings.selector_filter_community_type_event &&
                <InputContainer label="Какие встречи показывать">
                    <BasicRadioButton id="showAllEvents" name="eventsType" label="Все" value={EventType.ALL} checked={state.settings.selector_filter_event_type === EventType.ALL} onChange={onEventsTypeChange}/>
                    <BasicRadioButton id="showPastEvents" name="eventsType" label="Прошедшие" value={EventType.PAST} checked={state.settings.selector_filter_event_type === EventType.PAST} onChange={onEventsTypeChange}/>
                    <BasicRadioButton id="showFutureEvents" name="eventsType" label="Предстоящие" value={EventType.FUTURE} checked={state.settings.selector_filter_event_type === EventType.FUTURE} onChange={onEventsTypeChange}/>
                </InputContainer>
                }

                {state.settings.selector_filter_community_type_club &&
                <InputContainer label="Какие группы показывать">
                    <BasicRadioButton id="showAllGroups" name="groupsType" label="Все" value={ShowType.ALL} checked={state.settings.selector_filter_show_type === ShowType.ALL} onChange={onGroupsTypeChange}/>
                    <BasicRadioButton id="showOpenGroups" name="groupsType" label="Открытые" value={ShowType.OPEN} checked={state.settings.selector_filter_show_type === ShowType.OPEN} onChange={onGroupsTypeChange}/>
                    <BasicRadioButton id="showClosedGroups" name="groupsType" label="Закрытые" value={ShowType.CLOSED} checked={state.settings.selector_filter_show_type === ShowType.CLOSED} onChange={onGroupsTypeChange}/>
                </InputContainer>
                }

                <InputContainer label="Какие сообщества анализировать">
                    <BasicRadioButton id="showAll" name="showType" label="Все сообщества пользователя" value={AnalysisType.ANALISE_ALL} onChange={onAnalysisTypeChange} checked={state.settings.analysis_type === AnalysisType.ANALISE_ALL} />
                    <BasicRadioButton id="showTop" name="showType" label="Топ публичных страниц" value={AnalysisType.ANALISE_TOP} onChange={onAnalysisTypeChange} checked={state.settings.analysis_type === AnalysisType.ANALISE_TOP}/>
                </InputContainer>

                {state.settings.analysis_type === AnalysisType.ANALISE_TOP &&
                <MinMaxInput
                    min={state.settings.top_min} onMinChange={onTopMinChange}
                    max={state.settings.top_max} onMaxChange={onTopMaxChange}
                    id="topCount" label="На какой позиции должны находиться публичные страницы"/>
                }

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {result.length > 0 &&
                        <>
                            <SaveButton label="Сохранить в CSV" onClick={() => saveCSV()} />
                            <SaveButton label="Сохранить ссылки" onClick={() => save()} />
                        </>
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.csv' : 'result.csv'} ref={saveCsvRef} href={downloadCsvUrl} style={{display: 'none'}}>Скачать CSV</a>
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Скачать ссылки</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            {result.length > 0 &&
            <div className="tw-mt-4">
                <DataTable value={result} rowHover={true} paginator={true} rows={100} selection={selection} onSelectionChange={(e) => setSelection(e.value)}>
                    <Column selectionMode="multiple" style={{width:'20px'}}/>
                    <Column field="group.name" header="Имя" body={(item: TargetAudienceGroup, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/club${item.group.id}`} target="_blank" rel="noopener noreferrer">{item.group.name}</a></div>} sortable />
                    <Column field="count"  header="Подписчики" sortable />
                    <Column field="group.members_count"  header="Всего" sortable />
                    <Column field="count_to_total" header="Соотношение" body={(item: TargetAudienceGroup) => item.smart.toFixed(2)} sortable />
                    <Column field="smart" header="Смарт" body={(item: TargetAudienceGroup) => item.smart.toFixed(3)} sortable />
                </DataTable>
            </div>
            }

        </AppPanel>
    )
};

export default TargetAudienceLocator;

