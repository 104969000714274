import {createSelector} from "@reduxjs/toolkit";
import tokenize from "../../../../../helpers/tokenizer";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {RootState} from "../../../types";

const select_items = (state: RootState) => state.groupStatuses.result;
const words_minus = (state: RootState) => state.groupStatuses.settings.words_minus;
const words_plus = (state: RootState) => state.groupStatuses.settings.words_plus;

const selectFiltered = createSelector(
    [select_items, words_minus, words_plus],
    (items: GroupObject[], words_minus, words_plus) => {
        const words_plus_array: string[] = tokenize(words_plus.replace(",", " "));
        const words_minus_array: string[] = tokenize(words_minus.replace(",", " "));

        return items.filter(group => {
            if (!group.status) {
                return false;
            }
            if (words_plus_array.length === 0 && words_minus_array.length === 0) {
                return true;
            }
            const status: string = group.status.toLowerCase();
            if (words_plus_array.length > 0 && words_plus_array.filter(word => status.includes(word)).length === 0) {
                return false;
            }
            if (words_minus_array.length > 0 && words_minus_array.filter(word => status.includes(word)).length > 0) {
                return false;
            }
            return true;
        });
    }
);

export default selectFiltered;

