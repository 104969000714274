import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {getUserAge, getUserZodiacSign, UserObject} from "../../../../vkapi/objects/UserObject";
import {convert_urls_to_screen_names} from "../../../../vkapi/tasks/ConverUrlToId";
import GetUsers from "../../../../vkapi/tasks/GetUsers";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import date_to_timestamp from "../../../helpers/DateToTimestamp";
import {Gender, RootState, TaskStatus} from "../../types";
import * as actions from "./actions";
import {SearchBaseState, ZodiacSign} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: SearchBaseState = yield select((state: RootState) => state.searchBase);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    let users: UserObject[] = yield select((state: RootState) => state.searchBase.users);

    if (users.length === 0) {
        const user_ids: string[] = convert_urls_to_screen_names(state.settings.source_users);
        const users_map: Map<number, UserObject> = yield GetUsers({
            user_ids: user_ids,
            access_token: access_token,
            fields: "bdate,sex,city,country,relatives,relation,last_seen,has_photo,photo_50,verified,status",
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setProgress,
        });

        users = Array.from(users_map.values());
    }

    yield put(actions.setUsers(users));

    const settings = state.settings;

    let names: string[] = [];
    if (settings.source_names.trim().length > 0) {
        names = settings.source_names.trim().toLowerCase()
            .split(",")
            .map(item => item.trim())
            .filter(item => item !== '');
    }
    let statuses: string[] = [];
    if (settings.source_status.trim().length > 0) {
        statuses = settings.source_status.trim().toLowerCase()
            .split(" ")
            .map(item => item.trim())
            .filter(item => item !== "");
    }

    const result: UserObject[] = users.filter(user => {
        if (settings.remove_blocked && (user.deactivated || !user.has_photo)) {
            return false;
        }
        if (settings.keep_blocked && !(user.deactivated || !user.has_photo)) {
            return false;
        }
        if (settings.keep_verified && !user.verified) {
            return false;
        }
        if (settings.gender !== Gender.ANY) {
            if (!user.sex) {
                return false;
            }
            if (user.sex !== settings.gender) {
                return false;
            }
        }
        if (settings.only_hidden_age) {
            if (user.bdate && user.bdate.split(".").length === 3) {
                return false;
            }
        }
        if ((settings.age_from > 0 || settings.age_to > 0) && !settings.only_hidden_age) {
            const age: number|null = getUserAge(user);
            if (!age) {
                return false;
            }
            if (settings.age_from > 0 && age < settings.age_from) {
                return false;
            }
            if (settings.age_to > 0 && age > settings.age_to) {
                return false;
            }
        }
        if (settings.zodiac_sign !== ZodiacSign.ANY) {
            const sign: ZodiacSign|null = getUserZodiacSign(user);
            if (!sign) {
                return false;
            }
            if (sign !== settings.zodiac_sign) {
                return false;
            }
        }
        if (settings.cities.length > 0) {
            if (!user.city?.id) {
                return false;
            }
            const city_id: number = user.city.id;
            if (settings.cities.filter(city => city.id === city_id).length === 0) {
                return false;
            }
        }
        if (settings.selected_country) {
            if (!user.country?.id) {
                return false;
            }
            if (user.country.id !== settings.selected_country.id) {
                return false;
            }
        }

        if (settings.family.length > 0) {
            if (!user.relation) {
                return false;
            }
            if (!settings.family.includes(user.relation)) {
                return false;
            }
        }

        if (settings.has_children) {
            if (!user.relatives) {
                return false;
            }
            if (user.relatives.filter(item => item.type === "child").length === 0) {
                return false;
            }
        }

        if (settings.last_activity) {
            if (!user.last_seen) {
                return false;
            }
            const timestamp = date_to_timestamp(settings.last_activity);
            if (timestamp && user.last_seen.time < timestamp) {
                return false;
            }
        }
        if (names.length > 0) {
            if (!user.first_name) {
                return false;
            }
            if (!names.includes(user.first_name.toLowerCase())) {
                return false;
            }
        }
        if (statuses.length > 0) {
            if (!user.status) {
                return false;
            }
            if (statuses.filter(item => user.status?.toLowerCase().includes(item)).length === 0) {
                return false;
            }
        }
        return true;
    });

    yield put(actions.setResult(result));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result.length}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${result.length}`));
    yield put(actions.setCompleted());
}

function* validate(state: SearchBaseState) {
    if (state.settings.source_users.length === 0) {
        yield put(showAlertError('Вы не загрузили пользователей'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
