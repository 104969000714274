import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import AddDefaultCasesToReducer from "../../../../helpers/AddDefaultCasesToReducer";
import {DefaultProps} from "../../../types";
import {IdsConverterState} from "../types";
import {groups as actions} from "../actions";

const ReducerGroups = (initialState: IdsConverterState['groups']) => {
    return createReducer(initialState, builder => {
        builder.addCase(actions.setSource, (state, action: PayloadAction<string[]>) => {
            state.settings.source = action.payload
        });
        AddDefaultCasesToReducer<GroupObject[]>({
            actions: {
                setTaskStatus: actions.setTaskStatus,
                setProgress: actions.setProgress,
                setResult: actions.setResult,
                addApiError: actions.addApiError,
                resetApiErrors: actions.resetApiErrors
            }
        }, builder as unknown as ActionReducerMapBuilder<DefaultProps<GroupObject[]>>);
    });
};

export default ReducerGroups;
