import SendMethodsWithAutoRetry from "../../../../../vkapi/helpers/SendMethodsWithAutoRetry";
import {IVkMethod} from "../../../../../vkapi/IVkMethod";
import {UsersSearchParams} from "../../../../../vkapi/methods/UsersSearchParams";
import * as methods from "../../../../../vkapi/methods/VkMethods";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {Gender, WithDefaultActions} from "../../../types";
import {AudienceFilterResult} from "../types";

export interface GetStatsQuickParams extends WithDefaultActions {
    group: GroupObject,
    search: {
        age_min?: number,
        age_max?: number,
        gender?: Gender,
        country_id?: number,
        city_id?: number,
    },
    access_token: string,
}

const GetStatsQuick = function* (params: GetStatsQuickParams): Generator<any, AudienceFilterResult, any> {
    const method_params: UsersSearchParams = {
        group_id: params.group.id,
        offset: 0,
        count: 1,
    };
    if (params.search.age_min) {
        method_params.age_from = params.search.age_min;
    }
    if (params.search.age_max) {
        method_params.age_to = params.search.age_max;
    }
    if (params.search.gender) {
        method_params.sex = params.search.gender;
    }
    if (params.search.country_id) {
        method_params.country = params.search.country_id;
    }
    if (params.search.city_id) {
        method_params.city = params.search.city_id;
    }

    const method: IVkMethod = methods.usersSearch(method_params);

    let count: number = 0;

    yield SendMethodsWithAutoRetry<{count: number}>({
        methods: [method],
        access_token: params.access_token,
        onResponse: response => count = response.count,
        onError: () => {},
    });

    let ratio: number = 0;
    if (params.group.members_count) {
        ratio = (count / params.group.members_count || 1) * 100;
    }
    const result: AudienceFilterResult = {
        group: params.group,
        found: count,
        ratio: ratio,
    };

    return result;
};

export default GetStatsQuick;
