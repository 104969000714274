import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {City, Country} from "../../types";
import * as types from "./action-types";
import {LikesCount, MessageType, NewsAttachType, NewsSearchResult, ShowType} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setQueries = createAction(types.SET_QUERIES, withPayloadType<string>());
export const setWordsMinus = createAction(types.SET_WORDS_MINUS, withPayloadType<string>());
export const setMessageType = createAction(types.SET_MESSAGE_TYPE, withPayloadType<MessageType>());
export const setAttachType = createAction(types.SET_ATTACH_TYPE, withPayloadType<NewsAttachType>());
export const setLikesCount = createAction(types.SET_LIKES_COUNT, withPayloadType<LikesCount>());
export const setMinTextLength = createAction(types.SET_MIN_TEXT_LENGTH, withPayloadType<number>());
export const setSelectedCountry = createAction(types.SET_SELECTED_COUNTRY, withPayloadType<Country|null>());
export const setSelectedCity = createAction(types.SET_SELECTED_CITY, withPayloadType<City|null>());
export const setStartDate = createAction(types.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.SET_END_DATE, withPayloadType<Date|null>());
export const setShowType = createAction(types.SET_SHOW_TYPE, withPayloadType<ShowType>());


export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<NewsSearchResult[]>(types.NAMESPACE);
