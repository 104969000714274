import React, {FormEvent} from "react";
import InputContainer from "../containers/InputContainer";
import BasicTextInput from "./BasicTextInput";

export interface ParsingNameProps {
    value?: string,
    onChange?: (e: FormEvent<HTMLInputElement>) => void
}

const ParsingName = (props: ParsingNameProps) => {
    return (
        <InputContainer className="tw-w-full" label="Название парсинга">
            <BasicTextInput value={props.value} onChange={props.onChange} placeholder="Можете написать сюда название, чтобы не забыть что парсите" />
        </InputContainer>
    )
};

export default ParsingName;
