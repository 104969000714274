import {spawn} from 'redux-saga/effects'
import SubtractSaga from "./sagas/subtract";
import IntersectSaga from "./sagas/intersect";
import UniqueSaga from "./sagas/unique";

const main = function* () {
    yield spawn(SubtractSaga);
    yield spawn(IntersectSaga);
    yield spawn(UniqueSaga);
};

export default main;
