import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import GetGroups from "../../../../../vkapi/tasks/GetGroups";
import GroupsSearch, {TaskSearchType} from "../../../../../vkapi/tasks/GroupsSearch";
import {showAlertError, showAlertParsingCompleted} from "../../../../app/actions";
import {RootState, TaskStatus} from "../../../types";
import * as action_types from '../action-types';
import * as actions from "../actions";
import selectFiltered from "../selectors/FilteredSelector";
import {CommunitySearchItem, SearchCommunitiesState} from "../types";

const main = function* () {
    while (yield take(action_types.START)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {

    const state: SearchCommunitiesState = yield select((state: RootState) => state.searchCommunities);
    yield validate(state);
    yield put(actions.setIsLastPostDatesFound(false));
    const access_token = yield select((state: RootState) => state.app.access_token);

    const query: string = state.settings.search_query.trim().toLowerCase();
    const types: TaskSearchType[] = getTypes(state);

    const groups: Map<number, GroupObject> = yield GroupsSearch({
        query: query,
        countries: [state.settings.selected_country],
        cities: state.settings.cities,
        types: types,
        sort: state.settings.sort_type,
        access_token: access_token,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    const group_ids: number[] = Array.from(groups.values()).map(group => group.id);
    let groups_info: GroupObject[] = yield GetGroups({
        group_ids: group_ids,
        fields: 'members_count,verified,description,status',
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });
    groups_info = groups_info.filter((group: GroupObject) => {
        if (state.settings.is_strict && !group.name?.toLowerCase().includes(query)) {
            return false;
        }
        if (state.settings.only_official && !group.verified) {
            return false;
        }
        return true;
    });

    const result: CommunitySearchItem[] = groups_info.map(group => ({group: group, last_post: 0}));

    yield put(actions.setResult(result));

    const filtered: CommunitySearchItem[] = yield select(selectFiltered);

    yield put(actions.setProgress({message: `Завершено. Найдено: ${filtered.length}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${filtered.length}`));
    yield put({ type: action_types.COMPLETED});
}

function getTypes(state: SearchCommunitiesState): TaskSearchType[] {
    const types: TaskSearchType[] = [];
    if (state.settings.type_group) {
        types.push(TaskSearchType.GROUP);
    }
    if (state.settings.type_public) {
        types.push(TaskSearchType.PAGE);
    }
    if (state.settings.type_event) {
        types.push(TaskSearchType.EVENT);
    }
    if (state.settings.type_future_event) {
        types.push(TaskSearchType.FUTURE_EVENT);
    }
    return types;
}

function* validate(state: SearchCommunitiesState) {
    if (state.settings.search_query.trim() === '') {
        yield put(showAlertError('Не задан поисковый запрос'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(action_types.STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
