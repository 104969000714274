import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {date_in_range, is_post_before_start_date} from "../../../helpers/DateChecker";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import * as action_types from './action-types';
import * as actions from "./actions";
import {RootState, TaskStatus} from "../../types";
import {Promopost, PromopostsState} from "./types";
import {convert_urls_to_id} from "../../../../vkapi/tasks/ConverUrlToId";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import GetGroupsVkTask from "../../../../vkapi/tasks/GetGroups";
import {OwnerPosts, Post, post_has_button, post_has_pretty_card} from "../../../../vkapi/objects/Post";
import GetPosts, {GetPostsParams} from "../../../../vkapi/tasks/GetPosts";
import GetPostsById from "../../../../vkapi/tasks/GetPostsById";

const main = function* () {
    while (yield take(action_types.START)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: PromopostsState = yield select((state: RootState) => state.promoposts);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_urls: string[] = convert_urls_to_id(state.settings.groups_source.split("\n"));
    let groups: GroupObject[] = yield GetGroupsVkTask({
        access_token: access_token,
        group_ids: group_urls,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });
    groups = groups.filter(group => !(group.is_closed && group.is_closed === 1));

    const is_date_in_range = date_in_range(state.settings.start_date, state.settings.end_date);
    const is_posts_start_date_reached = is_post_before_start_date(state.settings.start_date);

    const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});

    const result: Promopost[] = [];

    for (const group of groups) {
        yield progress.next().value;
        if (group.deactivated) {
            continue;
        }
        const visible_post_dates: Set<number> = new Set<number>();
        yield put(actions.setSubProgress({message: 'Получаем посты'}));

        const get_posts_params: GetPostsParams = {
            owner_id: group.id * -1,
            extended: 0,
            is_skip: (post: Post) => !is_date_in_range(post.date),
            is_stop: (post: Post) => is_posts_start_date_reached(post),
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
        };
        if (!state.settings.start_date && !state.settings.end_date) {
            get_posts_params.max_offset = 100;
        }
        const posts: OwnerPosts = yield GetPosts(get_posts_params);
        posts.posts.forEach(post => visible_post_dates.add(post.date));
        const missing_ids: number[] = find_missing_post_ids(posts.posts);
        const post_ids: string[] = missing_ids.map((id: number) => `-${group.id}_${id}`);
        const hidden_posts: Post[] = yield GetPostsById({
            post_ids: post_ids,
            access_token: access_token,
            progress_action: actions.setSubProgress,
            add_api_errors_action: actions.addApiError,
        });
        const marked_as_ads: Post[] = hidden_posts.filter(post => {
            if (!is_date_in_range(post.date)) {
                return false;
            }
            if (post.post_type === "post_ads") {
                return true;
            }
            if (post.marked_as_ads && post.marked_as_ads === 1) {
                return true;
            }
            return false;
        });

        if (state.settings.posts_min > 0 && marked_as_ads.length < state.settings.posts_min) {
            continue;
        }
        if (state.settings.posts_max > 0 && marked_as_ads.length > state.settings.posts_max) {
            continue;
        }

        const total_likes: number = marked_as_ads.reduce<number>((current: number, post: Post) => current + post.likes?.count || 0, 0);
        const average_likes: number = marked_as_ads.length > 0 ? total_likes / marked_as_ads.length : 0;

        const promoposts: Promopost[] = marked_as_ads.map(post => {
            return {
                group: group,
                post: post,
                is_hidden: !visible_post_dates.has(post.date),
                type: getPromopostType(post),
                smart: average_likes > 0 ? (post.likes?.count || 0) / average_likes : 0,
            }
        });
        result.push(...promoposts);
        yield put(actions.setSubProgress({message: ''}));
    }

    yield put(actions.setSubProgress({message: ''}));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result.length}`, total: undefined, current: undefined}));
    if (result.length === 0) {
        yield put(showAlertParsingCompleted(`Парсинг завершен. Промопосты не найдены.`));
    }
    else {
        yield put(showAlertParsingCompleted(`Готово. Найдено: ${result.length}`));
    }

    yield put(actions.setResult(result));

    yield put({ type: action_types.COMPLETED});
}

function getPromopostType(post: Post): string {
    if (post_has_button(post)) {
        return 'с кнопкой';
    }
    if (post_has_pretty_card(post)) {
        return 'карусель';
    }
    return '';
}

function find_missing_post_ids(posts: Post[]): number[] {
    const missing_ids: number[] = [];
    const ids: number[] = set_first_and_last_post_id(posts.map(post => post.id));
    ids.sort((a, b) => a - b);
    const ids_count: number = ids.length;
    for(let i: number = 1; i < ids_count; i++) {
        let diff: number = ids[i] - ids[i-1];
        if (diff === 1) {
            continue;
        }
        let j: number = 1;
        while (j < diff) {
            missing_ids.push(ids[i-1] + j);
            j++;
        }
    }
    return missing_ids;
}
function set_first_and_last_post_id(ids: number[]): number[] {
    if (ids.length === 0) {
        return [0, 100];
    }
    ids.sort((a, b) => a - b);
    ids.unshift(Math.max(0, ids[0] - 50));
    ids.push(ids[ids.length - 1] + 50);
    return ids;
}

function* validate(state: PromopostsState) {
    if (state.settings.groups_source.trim() === '') {
        yield put(showAlertError('Не указаны ссылки'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(action_types.STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
