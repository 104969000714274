import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import tokenize from "../../../../helpers/tokenizer";
import SearchNewsFeed, {NewsSearchItem} from "../../../../vkapi/tasks/SearchNewsFeed";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import selectFiltered from "./selectors/NewsSearchSelector";
import {RootState, TaskStatus} from "../../types";
import * as actions from "./actions";
import {LikesCount, MessageType, NewsAttachType, NewsSearchResult, NewsSearchState} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: NewsSearchState = yield select((state: RootState) => state.newsSearch);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const queries: string[] = state.settings.queries.split("\n").filter(item => item.trim() !== '');
    const words_minus: string[] = tokenize(state.settings.words_minus.replace(",", " "));

    const result: Map<string, NewsSearchResult> = new Map<string, NewsSearchResult>();

    const progress = ProgressGenerator({text: 'Обрабатываем запросы', total: queries.length, action: actions.setProgress});
    for (const query of queries) {
        yield progress.next().value;
        const query_array: string[] = [query];
        if (words_minus.length > 0) {
            query_array.push(words_minus.map(word => "-" + word).join(" "));
        }
        if (state.settings.message_type !== MessageType.ANY) {
            query_array.push(state.settings.message_type);
        }
        if (state.settings.attach_type !== NewsAttachType.ANY) {
            query_array.push(state.settings.attach_type);
        }
        if (state.settings.likes_count !== LikesCount.ANY) {
            query_array.push(state.settings.likes_count);
        }
        const final_query: string = query_array.join(" ");
        const query_result: Map<string, NewsSearchItem> = yield SearchNewsFeed({
            query: final_query,
            fields: "city,country",
            access_token: access_token,
        });
        query_result.forEach((item: NewsSearchItem) => {
            const unique_id: string = `${item.from_id}_${item.post.id}`;
            if (!item.owner) {
                return;
            }
            result.set(unique_id, {
                owner: item.owner,
                date: item.post.date || 0,
                owner_id: item.from_id,
                post_id: item.post.id,
                text: item.post.text,
            });
        })
    }

    const result_array: NewsSearchResult[] = Array.from(result.values());

    yield put(actions.setResult(result_array));

    const filtered: NewsSearchResult[] = yield select(selectFiltered);

    yield put(actions.setProgress({message: `Завершено. Найдено: ${filtered.length}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${filtered.length}`));
    yield put(actions.setCompleted());
}

function* validate(state: NewsSearchState) {
    if (state.settings.queries.trim() === "") {
        yield put(showAlertError('Вы не указали поисковые запросы'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
