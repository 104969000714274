import {Button} from "primereact/button";
import React, {useCallback, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import download_txt from "../../../../helpers/DownloadTxt";
import {showAlert} from "../../../../store/app/actions";
import * as actions from "../../../../store/parsers/communities-audience/group-members/actions";
import {GroupMembersState} from "../../../../store/parsers/communities-audience/group-members/types";
import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import useSaveWithCsv from "../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import {NumberChangeEvent} from "../../../ui/inputs/BasicNumbersInput";
import MinMaxInput from "../../../ui/inputs/MinMaxInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";

const GroupMembers = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state: GroupMembersState = useSelector((state: RootState) => state.groupMembers);
    const progress: TaskProgress = useSelector((state: RootState) => state.groupMembers.progress);

    const [parsingName, setParsingName] = useState<string>('');
    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);
    const saveClosedGroupsRef = useRef<HTMLAnchorElement>(null);
    const [downloadClosedGroupsUrl, setDownloadClosedGroupsUrl] = useState<string>('');

    const onGroupsSourceChange = useCallback((value: string) => dispatch(actions.setGroupsSource(value)), [dispatch]);
    const onGroupsMinChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setGroupsMin(parseInt(e.value))), [dispatch]);
    const onGroupsMaxChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setGroupsMax(parseInt(e.value))), [dispatch]);

    const save = () => {
        if (state.result.length === 0) {
            dispatch(showAlert({text: 'Нечего сохранять', header: 'Ошибка'}));
            return;
        }
        download_txt<number[]>({
            data: state.result,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: number[]) => data.map(item => item.toString()),
        });
    };

    const saveCSV = () => {
        const headers = ['id пользователя', 'Количество групп'];
        const generator = (item: [number, number]) => {
            const line: string[] = [
                item[0].toString(),
                item[1].toString(),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(Array.from(state.user_counts.entries()), headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    const saveClosedGroups = () => {
        if (state.closed_groups.length === 0) {
            dispatch(showAlert({text:'Нечего сохранять', header: 'Ошибка'}));
            return;
        }
        const lines: string[] = [];
        state.closed_groups.forEach((item) => {
            lines.push(`https://vk.com/club${item.toString()}`);
        });
        setDownloadClosedGroupsUrl(download_url(lines.join('\r\n'), 'text/plain'));
        setTimeout(() => {
            if (saveClosedGroupsRef && saveClosedGroupsRef.current) {
                saveClosedGroupsRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>

            <ParsingHelp title={props.title} url="http://blog.xn--90aha1bhc1b.xn--p1ai/uchastnikisoobshchestva"
                         description="Соберите людей, которые состоят в одной или нескольких выбранных группах"/>

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>
                <TextAreaWithButton label={'Список групп (по одной на строке)'} value={state.settings.groups_source}
                                    onChange={onGroupsSourceChange} buttonId={'uploadButton'}/>

                <MinMaxInput
                    label="Минимальное количество групп (по умолчанию 1)" id="members"
                    min={state.settings.groups_min} onMinChange={onGroupsMinChange}
                    max={state.settings.groups_max} onMaxChange={onGroupsMaxChange}
                />

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field" />
                    {state.result.length > 0 &&
                        <>
                            <SaveButton label="Сохранить id" onClick={() => save()} />
                            <SaveButton label="Сохранить в CSV" onClick={() => saveCSV()} />
                        </>
                    }
                    {state.closed_groups.length > 0 &&
                        <SaveButton label="Сохранить ссылки на закрытые группы" onClick={() => saveClosedGroups()}/>
                    }
                    {anchors}
                    <a download={'closed_groups.txt'} ref={saveClosedGroupsRef} href={downloadClosedGroupsUrl} style={{display: 'none'}}>Сохранить ссылки на закрытые группы</a>
                </ButtonsContainer>

            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

        </AppPanel>
    );
};

export default GroupMembers;
