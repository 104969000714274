import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {GroupsSearchSort} from "../../../../vkapi/methods/GroupsSearchParams";
import {VkApiError} from "../../../../vkapi/VkApiError";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {City, Country} from "../../types";
import * as global_types from "../../types";
import * as actions from './actions';
import * as types from "./types";
import {CommunitySearchItem, GroupAccessType} from "./types";

const initialState: types.SearchCommunitiesState = {
    settings: {
        search_query: '',
        is_strict: false,
        only_official: false,
        type_group: true,
        type_public: true,
        type_event: true,
        type_future_event: true,
        group_access_type: GroupAccessType.ANY,
        selected_country: null,
        cities: [],
        sort_type: GroupsSearchSort.DEFAULT,
        members_min: 0,
        members_max: 0,
        title_length_min: 0,
        title_length_max: 0,
        min_date: null,
        words_plus: [],
        words_minus: [],
        is_last_post_dates_found: false,
    },
    ...DefaultStateProps<CommunitySearchItem[]>([])
};

const SearchCommunitiesReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSearchQuery, (state: types.SearchCommunitiesState, action: PayloadAction<string>) => {
        state.settings.search_query = action.payload;
    });
    builder.addCase(actions.setIsStrict, (state: types.SearchCommunitiesState, action: PayloadAction<boolean>) => {
        state.settings.is_strict = action.payload;
    });
    builder.addCase(actions.setOnlyOfficial, (state: types.SearchCommunitiesState, action: PayloadAction<boolean>) => {
        state.settings.only_official = action.payload;
    });
    builder.addCase(actions.setTypeGroup, (state: types.SearchCommunitiesState, action: PayloadAction<boolean>) => {
        state.settings.type_group = action.payload;
    });
    builder.addCase(actions.setTypePublic, (state: types.SearchCommunitiesState, action: PayloadAction<boolean>) => {
        state.settings.type_public = action.payload;
    });
    builder.addCase(actions.setTypeEvent, (state: types.SearchCommunitiesState, action: PayloadAction<boolean>) => {
        state.settings.type_event = action.payload;
    });
    builder.addCase(actions.setTypeFutureEvent, (state: types.SearchCommunitiesState, action: PayloadAction<boolean>) => {
        state.settings.type_future_event = action.payload;
    });
    builder.addCase(actions.setGroupAccessType, (state: types.SearchCommunitiesState, action: PayloadAction<GroupAccessType>) => {
        state.settings.group_access_type = action.payload;
    });
    builder.addCase(actions.setSelectedCountry, (state: types.SearchCommunitiesState, action: PayloadAction<Country|null>) => {
        state.settings.selected_country = action.payload;
    });
    builder.addCase(actions.setCities, (state: types.SearchCommunitiesState, action: PayloadAction<City[]>) => {
        state.settings.cities = action.payload;
    });
    builder.addCase(actions.setSortType, (state: types.SearchCommunitiesState, action: PayloadAction<GroupsSearchSort>) => {
        state.settings.sort_type = action.payload;
    });
    builder.addCase(actions.setMembersMin, (state: types.SearchCommunitiesState, action: PayloadAction<number>) => {
        state.settings.members_min = action.payload;
    });
    builder.addCase(actions.setMembersMax, (state: types.SearchCommunitiesState, action: PayloadAction<number>) => {
        state.settings.members_max = action.payload;
    });
    builder.addCase(actions.setTitleLengthMin, (state: types.SearchCommunitiesState, action: PayloadAction<number>) => {
        state.settings.title_length_min = action.payload;
    });
    builder.addCase(actions.setTitleLengthMax, (state: types.SearchCommunitiesState, action: PayloadAction<number>) => {
        state.settings.title_length_max = action.payload;
    });
    builder.addCase(actions.setMinDate, (state: types.SearchCommunitiesState, action: PayloadAction<Date|null>) => {
        state.settings.min_date = action.payload;
    });
    builder.addCase(actions.setWordsPlus, (state: types.SearchCommunitiesState, action: PayloadAction<string[]>) => {
        state.settings.words_plus = action.payload;
    });
    builder.addCase(actions.setWordsMinus, (state: types.SearchCommunitiesState, action: PayloadAction<string[]>) => {
        state.settings.words_minus = action.payload;
    });
    builder.addCase(actions.setIsLastPostDatesFound, (state: types.SearchCommunitiesState, action: PayloadAction<boolean>) => {
        state.settings.is_last_post_dates_found = action.payload;
    });

    builder.addCase(actions.setTaskStatus, (state: types.SearchCommunitiesState, action: PayloadAction<global_types.TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setProgress, (state: types.SearchCommunitiesState, action: PayloadAction<global_types.TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: types.SearchCommunitiesState, action: PayloadAction<CommunitySearchItem[]>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: types.SearchCommunitiesState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state: types.SearchCommunitiesState) => {
        state.api_errors = [];
    });
});

export default SearchCommunitiesReducer;
