import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useEffect,  useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator} from "../../../../../helpers/csv_generator";
import download_url from "../../../../../helpers/download_url";
import download_txt from "../../../../../helpers/DownloadTxt";
import strip_punctuation from "../../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../../store/app/actions";
import {lastPost as actions} from "../../../../../store/parsers/communities/communities-filter/actions";
import {LastPostFilterResult} from "../../../../../store/parsers/communities/communities-filter/types";
import {
    RootState,
    TaskProgress,
    TaskStatus,
} from "../../../../../store/parsers/types";
import useDateRange from "../../../../hooks/DateRangeHook";
import useSaveWithCsv from "../../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../../ui/info/AppProgressBar";
import BasicCheckbox from "../../../../ui/inputs/BasicCheckbox";
import DateRangeInput from "../../../../ui/inputs/DateRangeInput";
import ParsingName from "../../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../../ui/inputs/TextAreaWithButton";
import selectFiltered from "../../../../../store/parsers/communities/communities-filter/selectors/LastPostSelector";

const FilterByLastPost = () => {

    const moment = require('moment');

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.communitiesFilter.lastPost);
    const progress: TaskProgress = useSelector((state: RootState) => state.communitiesFilter.lastPost.progress);

    const result: LastPostFilterResult[] = useSelector(selectFiltered);

    const [parsingName, setParsingName] = useState<string>('');

    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);

    const [selection, setSelection] = useState<LastPostFilterResult[]>([]);
    useEffect(() => {
        setSelection(result);
    }, [result]);

    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);

    const save = () => {
        download_txt<LastPostFilterResult[]>({
            data: selection,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: LastPostFilterResult[]) => data.map(item => `https://vk.com/${item.group.screen_name}`),
        });
    };

    const saveCSV = () => {
        if (state.result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = ['"Название"', '"Ссылка"', '"Подписчики"', '"Дата поста"'];
        const generator = (item: LastPostFilterResult) => {
            const line: string[] = [
                '"' + strip_punctuation(item.group.name || '') + '"',
                `"https://vk.com/${item.group.screen_name}"`,
                item.group.members_count ? item.group.members_count.toString() : '',
                getLastPostDate(item),
            ];
            return line.join(';');
        };

        const csv: string|null = csv_generator(state.result, headers, generator);
        if (!csv) {
            return;
        }

        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    const getLastPostDate = (item: LastPostFilterResult): string => {
        if (!item.last_post_date) {
            return 'Не определена';
        }
        return moment(item.last_post_date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm');
    };

    return (
        <div>
            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>
                <TextAreaWithButton label="Список групп (по одной ссылке на строке)"
                                    value={state.settings.source}
                                    onChange={(value) => dispatch(actions.setSource(value))}
                />

                <BasicCheckbox label={"Учитывать только посты от сообщества"}
                               checked={state.settings.only_from_community}
                               onChange={(e) => dispatch(actions.setOnlyFromCommunities(e.checked))}
                />

                <InputContainer label="Дата последнего поста">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)} />

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {state.result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить ссылки" onClick={() => save()} />
                        <SaveButton label="Сохранить CSV" onClick={() => saveCSV()} />
                    </>
                    }
                    {anchors}
                </ButtonsContainer>

            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            {state.status !== TaskStatus.RUNNING && result.length > 0 &&
                <div className="tw-mt-4">
                    <DataTable
                        value={result} autoLayout={true} rowHover={true}
                        paginator={true} rows={100}
                        selection={selection} onSelectionChange={(e) => setSelection(e.value)}
                        sortField={"last_post_date"} sortOrder={-1}
                    >
                        <Column selectionMode="multiple" style={{width:'20px'}}/>
                        <Column header="Группа" field="group.id" body={(item: LastPostFilterResult, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/${item.group.screen_name}`} target="_blank" rel="noopener noreferrer">{item.group.name}</a></div>} sortable />
                        <Column header="Подписчики"  field="group.members_count" sortable/>
                        <Column header="Дата" field="last_post_date" body={(item: LastPostFilterResult) => getLastPostDate(item)} sortable />
                    </DataTable>
                </div>
            }
        </div>

    );
};

export default FilterByLastPost;
