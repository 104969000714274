import {createSelector} from "reselect";
import {RootState} from "../../../types";
import {BlockedFilterResult} from "../types";

const select_items = (state: RootState) => state.communitiesFilter.blocked.result;
const members_count_min = (state: RootState) => state.communitiesFilter.blocked.settings.members_count_min;
const members_count_max = (state: RootState) => state.communitiesFilter.blocked.settings.members_count_max;
const members_count_blocked_min = (state: RootState) => state.communitiesFilter.blocked.settings.members_count_blocked_min;
const members_count_blocked_max = (state: RootState) => state.communitiesFilter.blocked.settings.members_count_blocked_max;

const selectFiltered = createSelector(
    [select_items, members_count_min, members_count_max, members_count_blocked_min, members_count_blocked_max],
    (select_items,
     members_count_min,
     members_count_max,
     members_count_blocked_min,
     members_count_blocked_max) => {
        if (!members_count_min && !members_count_max && !members_count_blocked_min && !members_count_blocked_max) {
            return select_items;
        }
        return select_items.filter((item: BlockedFilterResult) => {
            if (!item.group.members_count) {
                return false;
            }
            const members_count: number = item.group.members_count;
            if (members_count_min && members_count < members_count_min) {
                return false;
            }
            if (members_count_max && members_count > members_count_max) {
                return false;
            }
            if (members_count_blocked_min && item.ratio < members_count_blocked_min) {
                return false;
            }
            if (members_count_blocked_max && item.ratio > members_count_blocked_max) {
                return false;
            }
            return true;
        })
    }
);

export default selectFiltered;
