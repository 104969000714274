import React from "react";
import {Checkbox} from "primereact/checkbox";
import { v4 as uuidv4 } from 'uuid';

export interface CheckBoxChangeEvent {
    originalEvent: Event,
    value: any,
    checked: boolean,
    target: {
        type: string,
        name: string,
        id: string,
        value: any,
        checked: boolean
    }
}

export interface BasicCheckboxProps {
    id?: string,
    label: string,
    value?: string,
    checked?: boolean,
    className?: string,
    onChange?: (e: CheckBoxChangeEvent) => void
}

const classNames = require('classnames');

const BasicCheckbox = (props: BasicCheckboxProps) => {

    const uniqueId = props.id ? props.id : uuidv4();

    return (
        <div className={classNames("p-field-checkbox", "p-field", "tw-items-start", props.className)}>
            <Checkbox inputId={uniqueId} value={props.value} onChange={props.onChange} checked={props.checked}/>
            <label htmlFor={uniqueId}>{props.label}</label>
        </div>
    )
};

export default BasicCheckbox;
