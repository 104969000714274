import React from "react";

export interface GroupLabelProps extends React.HTMLAttributes<HTMLHeadingElement> {
    label: string,
}

const GroupLabel = (props: GroupLabelProps) => {
    return (
        <h6 className={props.className}>{props.label}</h6>
    );
};

export default GroupLabel;
