import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import tokenize from "../../../../helpers/tokenizer";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {convert_urls_to_id, convert_urls_to_screen_names} from "../../../../vkapi/tasks/ConverUrlToId";
import GetGroups from "../../../../vkapi/tasks/GetGroups";
import GetUsers from "../../../../vkapi/tasks/GetUsers";
import GroupsGetMembers from "../../../../vkapi/tasks/GroupsGetMembers";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import {RootState, TaskStatus} from "../../types";
import * as actions from "./actions";
import {StatusLocatorResult, StatusLocatorSearchType, StatusLocatorState} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: StatusLocatorState = yield select((state: RootState) => state.statusLocator);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const result_map: Map<number, StatusLocatorResult> = new Map<number, StatusLocatorResult>();

    const words_plus: string[] = tokenize(state.settings.words_plus.replace(",", " "));
    const words_minus: string[] = tokenize(state.settings.words_minus.replace(",", " "));

    const filter_func = (user: UserObject) => {
        if (!user.status) {
            return false;
        }
        if (words_plus.length === 0 && words_minus.length === 0) {
            return true;
        }
        const status: string = user.status.toLowerCase();
        if (words_plus.length > 0) {
            const words_in_status = words_plus.filter(word => status.includes(word)).length;
            if (words_in_status === 0) {
                return false;
            }
            if (state.settings.strict_mode) {
                if (words_in_status < words_plus.length) {
                    return false;
                }
            }
        }
        if (words_minus.length > 0) {
            if (words_minus.filter(word => status.includes(word)).length > 0) {
                return false;
            }
        }
        return true;
    };

    const skipped: GroupObject[] = [];

    if (state.settings.search_type === StatusLocatorSearchType.BY_GROUPS) {
        const group_urls: string[] = convert_urls_to_id(state.settings.source_groups.split('\n'));
        const groups: GroupObject[] = yield GetGroups({
            access_token: access_token,
            fields: "members_count",
            group_ids: group_urls,
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setProgress,
        });

        const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});

        for (const group of groups) {
            yield progress.next().value;
            if (group.deactivated || !group.members_count) {
                skipped.push(group);
                continue;
            }
            let users: UserObject[] = [];
            try {
                users = yield GroupsGetMembers<UserObject[]> ({
                    group_id: group.id,
                    members_count: group.members_count,
                    fields: 'status',
                    access_token: access_token,
                    progress_action: actions.setSubProgress
                });
            }
            catch (e) {
                skipped.push(group);
                continue;
            }
            if (users.length === 0) {
                skipped.push(group);
                continue;
            }
            users.filter(filter_func).forEach(user => {
                if (user.status) {
                    result_map.set(user.id, {user: user, status: user.status})
                }
            });
        }
    }

    if (state.settings.search_type === StatusLocatorSearchType.BY_USERS) {

        const users: Map<number, UserObject> = yield GetUsers({
            user_ids: convert_urls_to_screen_names(state.settings.source_users.split("\n")),
            fields: "status",
            filter_func: filter_func,
            access_token: access_token,
            progress_action: actions.setProgress,
            add_api_errors_action: actions.addApiError,
        });
        users.forEach(user => {
            if (!user.status) {
                return;
            }
            result_map.set(user.id, {user: user, status: user.status});
        });
    }

    yield put(actions.setResult(Array.from(result_map.values())));
    yield put(actions.setSubProgress({message: ''}));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result_map.size}. Пропущенно групп с закрытым списком участников: ${skipped.length}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${result_map.size}. Пропущенно групп с закрытым списком участников: ${skipped.length}`));
    yield put(actions.setCompleted());
}

function* validate(state: StatusLocatorState) {
    if (state.settings.search_type === StatusLocatorSearchType.BY_GROUPS && state.settings.source_groups.trim() === '') {
        yield put(showAlertError('Вы не указали список групп'));
        yield put(actions.stop());
        return;
    }
    if (state.settings.search_type === StatusLocatorSearchType.BY_USERS && state.settings.source_users.trim() === '') {
        yield put(showAlertError('Вы не указали список пользователей'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
