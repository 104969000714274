import {PayloadAction} from "@reduxjs/toolkit";
import {Either, isLeft} from "fp-ts/Either";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {OwnerPosts, Post} from "../../../../vkapi/objects/Post";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import ConvertUrlToId from "../../../../vkapi/tasks/ConverUrlToId";
import GetActivitiesItems, {PostActivitiesItems} from "../../../../vkapi/tasks/GetActivitiesItems";
import {GetGroupError} from "../../../../vkapi/tasks/GetGroup";
import GetGroupByUrl from "../../../../vkapi/tasks/GetGroupByUrl";
import GetPosts from "../../../../vkapi/tasks/GetPosts";
import GetUserById from "../../../../vkapi/tasks/GetUserById";
import GetUsers from "../../../../vkapi/tasks/GetUsers";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {date_in_range, is_post_before_start_date} from "../../../helpers/DateChecker";
import {ActivityType, RootState, TaskStatus} from "../../types";
import * as actions from "./actions";
import CalculateScores from "./tasks/CalculateScores";
import {ActivitiesContestState, UserScore} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: ActivitiesContestState = yield select((state: RootState) => state.activitiesContest);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_result: Either<GetGroupError, GroupObject> = yield GetGroupByUrl({access_token: access_token, url: state.settings.source});
    if (isLeft(group_result)) {
        throw new Error(group_result.left.message);
    }
    const group: GroupObject = group_result.right;
    let owner: UserObject|null = null;
    if (state.settings.score_like_owner_enabled) {
        owner = yield getOwner(state.settings.owner_url, access_token);
    }
    const posts: OwnerPosts = yield getPosts(group, access_token, state);

    const activities: Map<number, PostActivitiesItems> = yield GetActivitiesItems({
        posts: posts.posts,
        comments_preview_length: 0,
        activity_types: getActivityTypes(state),
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });

    const scores: Map<number, UserScore> = CalculateScores(activities, owner, state);
    const user_ids: number[] = Array.from(scores.keys());
    const users: Map<number, UserObject> = yield GetUsers({
        user_ids: user_ids,
        access_token: access_token,
        fields: 'screen_name',
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });

    scores.forEach((score: UserScore, user_id: number) => {
        const user: UserObject|undefined = users.get(user_id);
        if (user) {
            score.user = user;
        }
    });

    const final_result: UserScore[] = Array.from(scores.values()).filter(item => item.score > 0);

    yield put(actions.setResult(final_result));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${final_result.length}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${final_result.length}`));
    yield put(actions.setCompleted());
}

function* getOwner(owner_url: string, access_token: string): Generator<any, UserObject|null, any> {
    const url: string|undefined = ConvertUrlToId(owner_url);
    if (!url) {
        return null;
    }
    const owner: UserObject = yield GetUserById({
        user_id: url,
        access_token: access_token,
        fields: 'screen_name',
    });
    return owner;
}

function* getPosts(group: GroupObject, access_token: string, state: ActivitiesContestState): Generator<any, OwnerPosts, any> {
    const is_date_in_range = date_in_range(state.settings.start_date, state.settings.end_date);
    const is_posts_start_date_reached = is_post_before_start_date(state.settings.start_date);

    const posts: OwnerPosts = yield GetPosts({
        owner_id: group.id * -1,
        is_skip: (post: Post) => !is_date_in_range(post.date),
        is_stop: (post: Post) => is_posts_start_date_reached(post),
        access_token: access_token,
    });
    return posts;
}

function getActivityTypes(state: ActivitiesContestState): Set<ActivityType> {
    const activity_types: Set<ActivityType> = new Set<ActivityType>();
    if (state.settings.score_like_enabled || state.settings.score_like_all_enabled) {
        activity_types.add(ActivityType.LIKES);
    }
    if (state.settings.score_like_from_author_enabled ||
        state.settings.score_like_owner_enabled ||
        state.settings.score_best_comment_enabled ||
        state.settings.score_comment_enabled ||
        state.settings.score_like_comment_enabled
    ) {
        activity_types.add(ActivityType.COMMENTS)
    }
    if (state.settings.score_like_from_author_enabled ||
        state.settings.score_like_owner_enabled ||
        state.settings.score_best_comment_enabled ||
        state.settings.score_like_comment_enabled
    ) {
        activity_types.add(ActivityType.COMMENT_LIKES)
    }
    return activity_types;
}

function* validate(state: ActivitiesContestState) {
    if (state.settings.source === '') {
        yield put(showAlertError('Вы не указали ссылку на группу'));
        yield put(actions.stop());
        return;
    }
    if (state.settings.score_like_owner_enabled && state.settings.owner_url === '') {
        yield put(showAlertError('Вы не указали ссылку на владельца группы'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
