import {spawn} from 'redux-saga/effects'
import AudienceAnalysis from "./store/parsers/audience-search/audience-analysis/saga";
import SuperstarsLocator from "./store/parsers/audience-search/superstars-locator/saga";
import ParserTargetAudienceLocator from "./store/parsers/audience-search/target-audience-locator/saga";
import ActivitiesCommunity from "./store/parsers/communities-audience/activities/saga";
import AdminContacts from "./store/parsers/communities-audience/admin-contacts/saga";
import GroupMembers from "./store/parsers/communities-audience/group-members/saga";
import PostsAudience from "./store/parsers/communities-audience/posts-audience/saga";
import Promoposts from "./store/parsers/communities-audience/promoposts/saga";
import TopCommentatorsCommunity from "./store/parsers/communities-audience/top-commentators/saga";
import TopReaders from "./store/parsers/communities-audience/top-readers/saga";
import TopicsParser from "./store/parsers/communities-audience/topics-parser/saga";
import StatsPostsCommunity from "./store/parsers/communities-audience/stats-posts-community/saga"
import CommunitiesFilter from "./store/parsers/communities/communities-filter/saga";
import GroupStatuses from "./store/parsers/communities/group-statuses/saga";
import PostsSearch from "./store/parsers/communities/posts-search/saga";
import GroupsSearch from "./store/parsers/communities/search-communities/saga";
import AbTesting from "./store/parsers/filter-base/ab-testing/saga";
import BaseAnalysis from "./store/parsers/filter-base/base-analysis/saga";
import IdsConverter from "./store/parsers/filter-base/ids-converter/saga";
import Intersections from "./store/parsers/filter-base/intersections/saga";
import SearchBase from "./store/parsers/filter-base/search-base/saga";
import ActivitiesContest from "./store/parsers/others/activities-contest/saga";
import InvolvingPosts from "./store/parsers/others/involving-posts/saga";
import PopularPosts from "./store/parsers/others/popular-posts/saga";
import NewsSearch from "./store/parsers/others/news-search/saga";
import BaseViewer from "./store/parsers/others/base-viewer/saga";
import ActivitiesInProfiles from "./store/parsers/user-profiles/activities-in-profiles/saga";
import FriendsAndFollowers from "./store/parsers/user-profiles/friends-and-followers/saga";
import Instagram from "./store/parsers/user-profiles/instagram/saga";
import StatusLocator from "./store/parsers/user-profiles/status-locator/saga";
import UsersSearch from "./store/parsers/user-profiles/users-search/saga";

function* rootSaga() {
    yield spawn(ParserTargetAudienceLocator);
    yield spawn(SuperstarsLocator);
    yield spawn(AudienceAnalysis);
    yield spawn(GroupMembers);
    yield spawn(ActivitiesCommunity);
    yield spawn(TopCommentatorsCommunity);
    yield spawn(TopicsParser);
    yield spawn(Intersections);
    yield spawn(PopularPosts);
    yield spawn(InvolvingPosts);
    yield spawn(Promoposts);
    yield spawn(GroupsSearch);
    yield spawn(IdsConverter);
    yield spawn(ActivitiesContest);
    yield spawn(AdminContacts);
    yield spawn(TopReaders);
    yield spawn(PostsAudience);
    yield spawn(PostsSearch);
    yield spawn(SearchBase);
    yield spawn(FriendsAndFollowers);
    yield spawn(CommunitiesFilter);
    yield spawn(UsersSearch);
    yield spawn(StatusLocator);
    yield spawn(BaseAnalysis);
    yield spawn(AbTesting);
    yield spawn(Instagram);
    yield spawn(GroupStatuses);
    yield spawn(ActivitiesInProfiles);
    yield spawn(NewsSearch);
    yield spawn(BaseViewer);
    yield spawn(StatsPostsCommunity);
}

export default rootSaga;
