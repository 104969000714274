import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlert} from "../../../../store/app/actions";
import * as actions from "../../../../store/parsers/communities-audience/stats-posts-community/actions";
import {
    PostsType,
    StatsPostsCommunityResult
} from "../../../../store/parsers/communities-audience/stats-posts-community/types";

import {DropdownValue, RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import useDateRange from "../../../hooks/DateRangeHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicCheckbox from "../../../ui/inputs/BasicCheckbox";
import DateRangeInput from "../../../ui/inputs/DateRangeInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";

import _truncate from "lodash/truncate";

const StatsPostsCommunity = (props: AppPanelProps) => {
    const moment = require('moment');

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.statsPostsCommunity);
    const progress: TaskProgress = useSelector((state: RootState) => state.statsPostsCommunity.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.statsPostsCommunity.sub_progress);

    const [parsingName, setParsingName] = useState<string>('');

    const result: StatsPostsCommunityResult[] = useSelector((state: RootState) => state.statsPostsCommunity.result);
    const [selection, setSelection] = useState<StatsPostsCommunityResult[]>([]);
    useEffect(() => {
        setSelection(result);
    }, [result]);

    const saveCsvAllRef = useRef<HTMLAnchorElement>(null);
    const saveCsvPromoRef = useRef<HTMLAnchorElement>(null);
    const [downloadCsvAllUrl, setDownloadCsvAllUrl] = useState<string>('');
    const [downloadCsvPromoUrl, setDownloadCsvPromoUrl] = useState<string>('');

    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);

    const post_types: DropdownValue<PostsType>[] = [
        {label: 'Не важно', value: PostsType.ANY},
        {label: 'Только обычные посты', value: PostsType.REGULAR},
        {label: 'Только промопосты', value: PostsType.PROMO},
    ];

    const ShortLinkBody = (item: StatsPostsCommunityResult) => {
        if (!item.short_link_code) {
            return (
                <div></div>
            );
        }
        return (
            <div>
                <a href={`https://vk.cc/${item.short_link_code}`} target="_blank" rel="noopener noreferrer">{item.short_link_code}</a>
            </div>
        );
    };
    const ShortLinkStatsBody = (item: StatsPostsCommunityResult) => {
        return (
            <div>
                <a href={`https://vk.cc?act=stats&url_id=${item.short_link_code}`} target="_blank" rel="noopener noreferrer">{item.short_link_views}</a>
            </div>
        );
    };

    const saveCSVAll = () => {
        if (selection.length === 0) {
            dispatch(showAlert({text: 'Вы не выделили результаты, которые нужно сохранить', header: 'Ошибка'}));
            return;
        }
        const headers: string[] = [
            '"Группа"',
            '"Ссылка на группу"',
            '"Тип поста"',
            '"Пост"',
            '"Ссылка на пост"',
            '"Просмотры поста"',
            '"Переходы по ссылке"',
            '"Соотношение переходов к просмотрам"',
            '"Короткая ссылка"',
            '"Статистика"',
            '"Подписчиков"',
            '"Дата"',
        ];
        const generator = (item: StatsPostsCommunityResult) => {
            const line = [
                '"' + strip_punctuation(item.group.name ?? '') + '"',
                `"https://vk.com/club${item.group.id}"`,
                `"${item.is_promo ? 'Промо' : 'Обычный'}"`,
                '"' + strip_punctuation(item.post.text ?? '') + '"',
                `"https://vk.com/wall${item.post.owner_id}_${item.post.id}"`,
                item.post.views?.count || 0,
                item.short_link_views,
                `"${(item.post.views?.count && item.post.views.count > 0) ? (item.short_link_views / item.post.views.count).toFixed(3).replace(".", ",") : 0}"`,
                `${item.short_link_code ? "https://vk.cc/"+item.short_link_code : ''}`,
                `"${item.short_link_code ? "https://vk.com/cc?act=stats&url_id="+item.short_link_code : ''}"`,
                item.group.members_count,
                moment(item.post.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm'),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(selection, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvAllUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvAllRef && saveCsvAllRef.current) {
                saveCsvAllRef.current.click()
            }
        }, 1000);
    };

    const saveCSVPromo = () => {
        if (selection.length === 0) {
            dispatch(showAlert({text: 'Вы не выделили результаты, которые нужно сохранить', header: 'Ошибка'}));
            return;
        }
        const headers: string[] = [
            '"Группа"',
            '"Ссылка на группу"',
            '"Тип поста"',
            '"Пост"',
            '"Ссылка на пост"',
            '"Просмотры поста"',
            '"Переходы по ссылке"',
            '"Соотношение переходов к просмотрам"',
            '"Короткая ссылка"',
            '"Статистика"',
            '"Подписчиков"',
            '"Дата"',
        ];
        const generator = (item: StatsPostsCommunityResult) => {
            const line = [
                '"' + strip_punctuation(item.group.name ?? '') + '"',
                `"https://vk.com/club${item.group.id}"`,
                `"${item.is_promo ? 'Промо' : 'Обычный'}"`,
                '"' + strip_punctuation(item.post.text ?? '') + '"',
                `"https://vk.com/wall${item.post.owner_id}_${item.post.id}"`,
                item.post.views?.count || 0,
                item.short_link_views,
                `"${(item.post.views?.count && item.post.views.count > 0) ? (item.short_link_views / item.post.views.count).toFixed(3).replace(".", ",") : 0}"`,
                `${item.short_link_code ? "https://vk.cc/"+item.short_link_code : ''}`,
                `"${item.short_link_code ? "https://vk.com/cc?act=stats&url_id="+item.short_link_code : ''}"`,
                item.group.members_count,
                moment(item.post.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm'),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(selection.filter(item => item.is_promo), headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvPromoUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvPromoRef && saveCsvPromoRef.current) {
                saveCsvPromoRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="В этой вкладке Вы можете найти посты, в которых есть короткая ссылка и посмотреть статистику переходов."
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/statistika_postov"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Список групп (по одной на строке)'}
                                    placeholder={"https://vk.com/cerebro_vk"}
                                    value={state.settings.source}
                                    onChange={(e) => dispatch(actions.setSource(e))}
                />

                <InputContainer label="Какие посты искать">
                    <Dropdown options={post_types} value={state.settings.post_type}
                              onChange={(e) => dispatch(actions.setPostType(e.value))}
                              className="p-field tw-w-full sm:tw-flex-1"
                    />
                </InputContainer>

                <BasicCheckbox label={"Только с короткими ссылками"} checked={state.settings.only_with_vk_cc}
                               onChange={(e) => dispatch(actions.setOnlyWithVkCc(e.checked))}
                />

                <InputContainer label="Дата (указывать не обязательно">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())}  className="p-field" />
                    {result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить CSV" onClick={() => saveCSVAll()} />
                        <SaveButton label="Сохранить в CSV только промо" onClick={() => saveCSVPromo()} />
                    </>
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.csv' : 'result.csv'} ref={saveCsvAllRef} href={downloadCsvAllUrl} style={{display: 'none'}}>Скачать CSV</a>
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.csv' : 'promo.csv'} ref={saveCsvPromoRef} href={downloadCsvPromoUrl} style={{display: 'none'}}>Скачать CSV только промо</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>

            {result.length > 0 &&
            <div className="tw-mt-4">
                <DataTable value={result} autoLayout={true}
                           paginator={true} rows={100} rowHover={true}
                           selection={selection} onSelectionChange={(e) => setSelection(e.value)}
                >
                    <Column selectionMode="multiple" style={{width:'20px'}}/>
                    <Column field="group.name" header="Группа" body={(item: StatsPostsCommunityResult, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/club${item.group.id}`} target="_blank" rel="noopener noreferrer">{_truncate(item.group.name, {length: 15})}</a></div>} />
                    <Column field="post.text" header="Пост" body={(item: StatsPostsCommunityResult) => <div><a href={`https://vk.com/wall${item.post.owner_id}_${item.post.id}`} target="_blank" rel="noopener noreferrer">{_truncate(item.post.text, {length: 64})}</a></div>} />
                    <Column field="is_promo" header="Промо" body={(item: StatsPostsCommunityResult) => <div>{item.is_promo ? 'Да' : 'Нет'}</div>} />
                    <Column field="post.views.count" header="Просмотры" sortable={true} />
                    <Column field="short_link_code" header="Ссылка" body={ShortLinkBody} sortable={true} />
                    <Column field="short_link_views" header="Переходы" body={ShortLinkStatsBody} sortable={true} />
                    <Column field="post.date" header="Дата" body={(item: StatsPostsCommunityResult) => moment(item.post.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm')} sortable />
                </DataTable>
            </div>
            }

        </AppPanel>
    );
};

export default StatsPostsCommunity;
