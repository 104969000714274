import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {ActivityType} from "../../types";
import * as types from "./action-types";
import {PostAudienceSearchType, PostsAudienceResult} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSource = createAction(types.SET_SOURCE, withPayloadType<string>());
export const setSearchType = createAction(types.SET_SEARCH_TYPE, withPayloadType<PostAudienceSearchType>());
export const addActivityType = createAction(types.ADD_ACTIVITY_TYPE, withPayloadType<ActivityType>());
export const removeActivityType = createAction(types.REMOVE_ACTIVITY_TYPE, withPayloadType<ActivityType>());
export const setPostsMin = createAction(types.SET_POSTS_MIN, withPayloadType<number>());
export const setPostsMax = createAction(types.SET_POSTS_MAX, withPayloadType<number>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<PostsAudienceResult[]>(types.NAMESPACE);
