import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {convert_urls_to_id} from "../../../../../vkapi/tasks/ConverUrlToId";
import GetGroups from "../../../../../vkapi/tasks/GetGroups";
import GetLastPostDates from "../../../../../vkapi/tasks/GetLastPostDates";
import selectFiltered from "../selectors/LastPostSelector";
import {lastPost as actions} from "../actions";
import {RootState, TaskStatus} from "../../../types";
import {showAlertError, showAlertParsingCompleted} from "../../../../app/actions";
import {CommunitiesFilterState, LastPostFilterResult} from "../types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: CommunitiesFilterState['lastPost'] = yield select((state: RootState) => state.communitiesFilter.lastPost);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_ids_string: string[] = convert_urls_to_id(state.settings.source.split("\n"));

    const groups: GroupObject[] = yield GetGroups({
        group_ids: group_ids_string,
        fields: 'members_count',
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });

    const group_ids_number: number[] = groups.map(item => item.id);
    let filter: "owner"|undefined = undefined;
    if (state.settings.only_from_community) {
        filter = "owner";
    }
    const last_post_dates: Map<number, number> = yield GetLastPostDates({
        group_ids: group_ids_number,
        filter: filter,
        access_token: access_token,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    const result: LastPostFilterResult[] = [];

    groups.forEach(group => {
        const last_post_date: number|undefined = last_post_dates.get(group.id);
        if (!last_post_date) {
            result.push({
                group
            });
            return;
        }
        result.push({
            group: group,
            last_post_date: last_post_date,
        })
    });

    yield put(actions.setResult(result));

    const filtered: LastPostFilterResult[] = yield select(selectFiltered);

    yield put(actions.setProgress({message: `Завершено. Найдено: ${filtered.length}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${filtered.length}`));
    yield put(actions.setCompleted());
}

function* validate(state: CommunitiesFilterState['lastPost']) {
    if (state.settings.source.trim() === '') {
        yield put(showAlertError('Вы не указали ссылки на сообщества'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;




