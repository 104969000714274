import {ActivityType, StateWithErrors, StateWithProgress, TaskProgress} from "../../types";

export interface AllActivities {
    from_id: number,
        groups: number,
        posts: number,
        activities: number,
}

export enum WhatToGet {
    USERS = 'users',
    COMMUNITIES = 'communities',
}

interface State {
    settings: {
        data_source: string,
        what_to_get: WhatToGet,
        start_date: Date|null,
        end_date: Date|null,
        activities_min: number,
        activities_max: number,
        groups_min: number,
        groups_max: number,
        posts_min: number,
        posts_max: number,
        is_posts_every_community: boolean,
        activity_types: ActivityType[],
    },
    sub_progress: TaskProgress,
    result: AllActivities[],
}

export interface ActivitiesCommunityState extends State, StateWithErrors, StateWithProgress {}
