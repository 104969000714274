import {createAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {TaskProgress} from "../../types";
import * as types from "./action-types";
import {StatusLocatorResult, StatusLocatorSearchType} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSearchType = createAction(types.SET_SEARCH_TYPE, withPayloadType<StatusLocatorSearchType>());
export const setSourceGroups = createAction(types.SET_SOURCE_GROUPS, withPayloadType<string>());
export const setSourceUsers = createAction(types.SET_SOURCE_USERS, withPayloadType<string>());
export const setWordsPlus = createAction(types.SET_WORDS_PLUS, withPayloadType<string>());
export const setWordsMinus = createAction(types.SET_WORDS_MINUS, withPayloadType<string>());
export const setStrictMode = createAction(types.SET_STRICT_MODE, withPayloadType<boolean>());
export const setSkipped = createAction(types.SET_SKIPPED, withPayloadType<GroupObject[]>());
export const setSubProgress = createAction(types.SET_SUBPROGRESS, withPayloadType<TaskProgress>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<StatusLocatorResult[]>(types.NAMESPACE);
