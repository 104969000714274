import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import download_txt from "../../../../helpers/DownloadTxt";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../store/app/actions";
import * as actions from "../../../../store/parsers/others/popular-posts/actions";
import {ActivitiesFrom, PopularPostsState, PostsFilter} from "../../../../store/parsers/others/popular-posts/types";
import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {Post} from "../../../../vkapi/objects/Post";
import useDateRange from "../../../hooks/DateRangeHook";
import useSaveWithCsv from "../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import DateRangeInput from "../../../ui/inputs/DateRangeInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import selectFiltered from "../../../../store/parsers/others/popular-posts/selectors/PopularPostsSelector";

const PopularPosts = (props: AppPanelProps) => {

    const moment = require("moment");

    const dispatch = useDispatch();
    const state: PopularPostsState = useSelector((state: RootState) => state.popularPosts);
    const progress: TaskProgress = useSelector((state: RootState) => state.popularPosts.progress);

    const [parsingName, setParsingName] = useState<string>('');

    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);

    const result: Post[] = useSelector(selectFiltered);
    const [selection, setSelection] = useState<Post[]>([]);
    useEffect(() => setSelection(result), [result]);

    const onDataSourceChange = useCallback((value: string) => dispatch(actions.setDataSource(value)), [dispatch]);
    const onActivitiesFromChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setActivitiesFrom(e.value)), [dispatch]);
    const onSelectedUsersChange = useCallback((value: string) => dispatch(actions.setSelectedUsers(value)), [dispatch]);
    const onPostsFilterChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setPostsFilter(e.value)), [dispatch]);
    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);

    const savePostsUrls = () => {
        if (selection.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        download_txt<Post[]>({
            data: selection,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: Post[]) => data.map(item => `https://vk.com/wall${item.owner_id}_${item.id}`),
        });
    };

    const saveComments = () => {
        if (selection.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = ['"Ссылка"', '"Пост"', '"Лайки"', '"Репосты"', '"Комментарии"', '"Дата"',];
        const generator = (item: Post) => {
            let text: string = strip_punctuation(item.text);
            if (text.trim() === '') {
                text = "Без текста";
            }
            const line = [
                `https://vk.com/wall${item.owner_id}_${item.id}`,
                '"' + text + '"',
                item.likes.count,
                item.reposts.count,
                item.comments.count,
                moment(item.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm'),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(selection, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>

            <ParsingHelp
                description="Тут Вы можете найти популярные посты в группах или на страницах пользователей."
                url="http://blog.xn--90aha1bhc1b.xn--p1ai/populyarnyye_posty"
                title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Ссылки на группы или страницы людей (по одной на строке)'}
                                    buttonId={'uploadButton'}
                                    value={state.settings.data_source} onChange={onDataSourceChange}
                />

                <InputContainer label="Учитывать активности">
                    <BasicRadioButton id="activitiesFromAll" name="activitiesFrom" label="всех пользователей"
                                      value={ActivitiesFrom.ALL}
                                      onChange={onActivitiesFromChange}
                                      checked={state.settings.activities_from === ActivitiesFrom.ALL}
                    />
                    <BasicRadioButton id="activitiesFromSelectedUsers" name="activitiesFrom" label="выбранных пользователей"
                                      value={ActivitiesFrom.SELECTED_USERS}
                                      onChange={onActivitiesFromChange}
                                      checked={state.settings.activities_from === ActivitiesFrom.SELECTED_USERS}
                    />
                </InputContainer>

                {state.settings.activities_from === ActivitiesFrom.SELECTED_USERS &&
                    <TextAreaWithButton label={'Ссылки на пользователей (по одной на строке)'}
                                        buttonId={'uploadButtonUsers'}
                                        value={state.settings.selected_users} onChange={onSelectedUsersChange}
                    />
                }

                <InputContainer label="Какие посты искать">
                    <BasicRadioButton id="postsFilterAll" name="postsFilter" label="все посты"
                                      value={PostsFilter.ALL}
                                      onChange={onPostsFilterChange}
                                      checked={state.settings.posts_filter === PostsFilter.ALL}
                    />
                    <BasicRadioButton id="postsFilterOwner" name="postsFilter" label="посты от владельца стены"
                                      value={PostsFilter.OWNER}
                                      onChange={onPostsFilterChange}
                                      checked={state.settings.posts_filter === PostsFilter.OWNER}
                    />
                    <BasicRadioButton id="postsFilterOthers" name="postsFilter" label="посты не от владельца стены"
                                      value={PostsFilter.OTHERS}
                                      onChange={onPostsFilterChange}
                                      checked={state.settings.posts_filter === PostsFilter.OTHERS}
                    />
                </InputContainer>

                <InputContainer label="Дата">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)} />

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field" />
                    {state.result.length > 0 &&
                        <>
                            <SaveButton label="Сохранить ссылки" onClick={() => savePostsUrls()} />
                            <SaveButton label="Сохранить в CSV" onClick={() => saveComments()} />
                        </>
                    }
                    {anchors}
                </ButtonsContainer>

            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message} />

            {state.status !== TaskStatus.RUNNING && state.result.length > 0 &&
                <div className="tw-mt-4">
                    <DataTable value={result}
                               sortField={'likes'}
                               rowHover={true}
                               paginator={true}
                               rows={100}
                               selection={selection} onSelectionChange={(e) => setSelection(e.value)}
                    >
                        <Column selectionMode="multiple" style={{width:'20px'}}/>
                        <Column header="Пост" field="text" body={(item: Post, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/wall${item.owner_id}_${item.id}`} target="_blank" rel="noopener noreferrer">{item.text.trim() === '' ? 'без текста' : item.text}</a></div>} />
                        <Column header="Лайки"  field="likes.count" sortable />
                        <Column header="Репосты" field="reposts.count" sortable />
                        <Column header="Комментарии" field="comments.count" sortable />
                        <Column header="Дата обновления" field="date" body={(item: Post) => moment(item.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm')} sortable />
                    </DataTable>
                </div>
            }

        </AppPanel>
    );
};

export default PopularPosts;
