import {WithDefaultActions} from "../../store/parsers/types";
import {CountersUser} from "../objects/UserObject";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import _chunk from "lodash/chunk";
import {UsersGetParams} from "../methods/UsersGetParams";
import {ProcessMethodsParams} from "../types";
import SendMethodsDefault from "../helpers/SendMethodsDefault";

export interface GetCountersUserParams extends WithDefaultActions {
    user_ids: number[],
    access_token: string,
}

const GetCountersUser = function* (params: GetCountersUserParams): Generator<any, Map<number, CountersUser>, any> {
    const executes:Array<IVkMethod> = prepare(params.user_ids);
    const executes_result: Map<number, CountersUser> = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(user_ids: number[]): IVkMethod[] {
    const requests_per_execute: number = 25;
    const chunks = _chunk(user_ids, requests_per_execute);
    return chunks.map((user_ids_chunk: number[]) => {
        const requests: string[] = user_ids_chunk.map((user_id: number) => {
            const params: UsersGetParams = {
                user_ids: [user_id],
                fields: 'counters',
            };
            const method: IVkMethod = methods.usersGet(params);
            return `{"user_id":${user_id},"result":API.${method.method_name}(${JSON.stringify(method.params)})[0].counters}`;
        });
        const code:string = `return [${requests.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, Map<number, CountersUser>, any> {
    interface IResponse {
        user_id: number,
        result: CountersUser|null,
    }
    const result: Map<number, CountersUser> = new Map<number, CountersUser>();

    const handler = (response: IResponse[]) => {
        response.forEach((response_item: IResponse) => {
            if (response_item.result) {
                result.set(response_item.user_id, response_item.result)
            }
        });
    };

    yield SendMethodsDefault<IResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем счетчики',
        chunk_size: 6
    });

    return result;
};

export default GetCountersUser;
