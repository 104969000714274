import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {VkApiError} from "../VkApiError";
import {UserFriends} from "../objects/UserFriends";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import {FriendsGetParams} from "../methods/FriendsGetParams";
import _chunk from "lodash/chunk";
import {UserIdAnyIds} from "../objects/UserIdAnyIds";
import {ProcessMethodsParams} from "../types";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {TaskProgress} from "../../store/parsers/types";


export interface GetFriendsParams {
    user_ids: number[],
    fields?: string,
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
}

const GetFriends = function* (params: GetFriendsParams): Generator<any, Map<number, UserFriends>, any> {
    const access_token = params.access_token;
    const executes:Array<IVkMethod> = prepare(params.user_ids);
    const executes_result: UserIdAnyIds[] = yield send({methods: executes, access_token: access_token, progress_action: params.progress_action, add_api_errors_action: params.add_api_errors_action});
    const result: Map<number, UserFriends> = new Map<number, UserFriends>();
    for (let item of executes_result) {
        result.set(item.user_id, {type: "regular", user_id: item.user_id, friend_ids: item.ids})
    }
    return result;
};

function prepare(user_ids: number[]): Array<IVkMethod> {
    const max_requests_per_execute: number = 10;
    const user_ids_chunks = _chunk(user_ids, max_requests_per_execute);
    return user_ids_chunks.map((user_ids_chunk: number[]) => {
        const requests: string[] = user_ids_chunk.map((user_id: number) => {
            let params: FriendsGetParams = {
                user_id: user_id,
            };
            const method: IVkMethod = methods.friendsGet(params);
            return `{"user_id":${user_id},"result":API.${method.method_name}(${JSON.stringify(method.params)})}`;
        });
        const code:string = `return [${requests.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, UserIdAnyIds[], any> {

    interface IResponse {
        user_id: number,
        result: {
            count: number,
            items: number[]
        }
    }

    const result: UserIdAnyIds[] = [];

    const handler = (response: IResponse[]) => {
        response.forEach((response_item: IResponse) => {
            const item: UserIdAnyIds = {
                user_id: response_item.user_id,
                ids: response_item.result ? response_item.result.items : []
            };
            result.push(item);
        });
    };

    yield SendMethodsDefault<IResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем друзей',
        chunk_size: 6,
    });

    return result;
};

export default GetFriends;
