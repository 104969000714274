import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import AddDefaultCasesToReducer from "../../../../helpers/AddDefaultCasesToReducer";
import {DefaultProps, TaskProgress} from "../../../types";
import {blocked as actions} from "../actions";
import {BlockedFilterResult, CommunitiesFilterState} from "../types";

const ReducerBlocked = (initialState: CommunitiesFilterState['blocked']) => {
    return createReducer(initialState, builder => {
        builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
            state.settings.source = action.payload;
        });
        builder.addCase(actions.setMembersCountMin, (state, action: PayloadAction<number>) => {
            state.settings.members_count_min = action.payload;
        });
        builder.addCase(actions.setMembersCountMax, (state, action: PayloadAction<number>) => {
            state.settings.members_count_max = action.payload;
        });
        builder.addCase(actions.setMembersCountBlockedMin, (state, action: PayloadAction<number>) => {
            state.settings.members_count_blocked_min = action.payload;
        });
        builder.addCase(actions.setMembersCountBlockedMax, (state, action: PayloadAction<number>) => {
            state.settings.members_count_blocked_max = action.payload;
        });
        builder.addCase(actions.setSkipped, (state, action: PayloadAction<GroupObject[]>) => {
            state.skipped = action.payload;
        });
        builder.addCase(actions.setSubProgress, (state, action: PayloadAction<TaskProgress>) => {
            state.sub_progress = action.payload;
        });

        AddDefaultCasesToReducer<BlockedFilterResult[]>({
            actions: {
                setTaskStatus: actions.setTaskStatus,
                setProgress: actions.setProgress,
                setResult: actions.setResult,
                addApiError: actions.addApiError,
                resetApiErrors: actions.resetApiErrors
            }
        }, builder as unknown as ActionReducerMapBuilder<DefaultProps<BlockedFilterResult[]>>);
    });
};

export default ReducerBlocked;
