import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import GetGroups from "../../../common-tasks/GetGroups";
import {CommunitiesSearchTypeToArray} from "../../../helpers/CommunitiesSearchTypeToArray";
import {RootState, SearchTypeListOrSearch, TaskStatus} from "../../types";
import * as actions from "./actions";
import {AdminContactsState} from "./types";
import GetGroupsVkTask from "../../../../vkapi/tasks/GetGroups";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: AdminContactsState = yield select((state: RootState) => state.adminContacts);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const groups: GroupObject[] = yield GetGroups({
        search_type: state.settings.search_type,
        group_urls_source: state.settings.groups_source,
        group_fields: 'contacts',
        search_queries_source: state.settings.search_queries_source,
        search_community_types: CommunitiesSearchTypeToArray(state.settings.search_filter_community),
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });

    const result: Set<number> = new Set<number>();

    let groups_with_contacts: GroupObject[] = [];

    if (state.settings.search_type === SearchTypeListOrSearch.BY_GROUP_URLS) {
        groups_with_contacts = groups;
    }

    if (state.settings.search_type === SearchTypeListOrSearch.BY_GROUPS_SEARCH) {
        const group_ids: number[] = groups.map(group => group.id);
        groups_with_contacts = yield GetGroupsVkTask({
            group_ids: group_ids,
            fields: 'contacts',
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setProgress,
        });
    }

    groups_with_contacts.forEach(group => {
        if (group.contacts) {
            group.contacts.forEach(item => {
                if (item.user_id) {
                    result.add(item.user_id);
                }
            })
        }
    });

    yield put(actions.setResult(Array.from(result.values())));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result.size}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${result.size}`));
    yield put(actions.setCompleted());
}

function* validate(state: AdminContactsState) {
    if (state.settings.search_type === SearchTypeListOrSearch.BY_GROUP_URLS && state.settings.groups_source.trim() === '') {
        yield put(showAlertError('Не указаны ссылки'));
        yield put(actions.stop());
        return;
    }
    if (state.settings.search_type === SearchTypeListOrSearch.BY_GROUPS_SEARCH && state.settings.search_queries_source.trim() === '') {
        yield put(showAlertError('Не указаны поисковые запросы'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
