import * as types from "./action-types";
import {createAction} from "@reduxjs/toolkit";
import {DataSourceType, TaskProgress, TaskStatus} from "../../types";
import {VkApiError} from "../../../../vkapi/VkApiError";
import {AnalysisType, EventType, ShowType, TargetAudienceGroup} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setDatasourceType = createAction(types.SET_DATASOURCE_TYPE, withPayloadType<DataSourceType.URL|DataSourceType.FILE>());
export const setDatasourceGroupUrl = createAction(types.SET_DATASOURCE_URL, withPayloadType<string>());
export const setDatasourceUserIds = createAction(types.SET_DATASOURCE_FILE, withPayloadType<string>());

export const setTruncateToCount = createAction(types.SET_TRUNCATE_TO_COUNT, withPayloadType<number>());
export const setAnalysisType = createAction(types.SET_ANALYSIS_TYPE, withPayloadType<AnalysisType>());
export const setMembersMin = createAction(types.SET_MEMBERS_MIN, withPayloadType<number>());
export const setMembersMax = createAction(types.SET_MEMBERS_MAX, withPayloadType<number>());
export const setTopMin = createAction(types.SET_TOP_MIN, withPayloadType<number>());
export const setTopMax = createAction(types.SET_TOP_MAX, withPayloadType<number>());
export const setSelectorFilterMembersMin = createAction(types.SET_SELECTOR_FILTER_MEMBERS_MIN, withPayloadType<number>());
export const setSelectorFilterMembersMax = createAction(types.SET_SELECTOR_FILTER_MEMBERS_MAX, withPayloadType<number>());
export const setSelectorFilterWordsPlus = createAction(types.SET_SELECTOR_FILTER_WORDS_PLUS, withPayloadType<string>());
export const setSelectorFilterWordsMinus = createAction(types.SET_SELECTOR_FILTER_WORDS_MINUS, withPayloadType<string>());
export const setSelectorFilterCommunityTypePublic = createAction(types.SET_SELECTOR_FILTER_COMMUNITY_TYPE_PUBLIC, withPayloadType<boolean>());
export const setSelectorFilterCommunityTypeClub = createAction(types.SET_SELECTOR_FILTER_COMMUNITY_TYPE_CLUB, withPayloadType<boolean>());
export const setSelectorFilterCommunityTypeEvent = createAction(types.SET_SELECTOR_FILTER_COMMUNITY_TYPE_EVENT, withPayloadType<boolean>());
export const setSelectorFilterEventType = createAction(types.SET_SELECTOR_FILTER_EVENT_TYPE, withPayloadType<EventType>());
export const setSelectorFilterShowType = createAction(types.SET_SELECTOR_FILTER_SHOW_TYPE, withPayloadType<ShowType>());

export const start = createAction(types.START);
export const stop = createAction(types.STOP);

export const addApiError = createAction(types.ADD_API_ERROR, withPayloadType<VkApiError>());
export const resetApiErrors = createAction(types.RESET_API_ERRORS);

export const setTaskStatus = createAction(types.SET_TASK_STATUS, withPayloadType<TaskStatus>());
export const setTaskError = createAction(types.SET_TASK_ERROR, withPayloadType<Error>());
export const setProgress = createAction(types.SET_PROGRESS, withPayloadType<TaskProgress>());
export const setResult = createAction(types.SET_RESULT, withPayloadType<TargetAudienceGroup[]>());
