import {Calendar} from "primereact/calendar";
import React from "react";
import InputContainer from "../containers/InputContainer";
import {CalendarChangeEvent} from "../ui-events/CalendarChangeEvent";

export interface DateInputProps {
    label: string,
    value: Date|null,
    onValueChange: (e: CalendarChangeEvent) => void,
    notice?: string,
    disabled?: boolean,
}

const ru = {
    firstDayOfWeek: 1,
    dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четерг", "Пятница", "Суббота"],
    dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб",],
    dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб",],
    monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    today: 'Сегодня',
    clear: 'Очистить',
    dateFormat: 'dd.mm.yy',
    weekHeader: 'Нд'
};

const DateInput = (props: DateInputProps) => {
    return (
        <InputContainer label={props.label}>
            <Calendar locale={ru} dateFormat="dd.mm.yy"
                      className="p-field"
                      value={props.value ? props.value : undefined}
                      onChange={props.onValueChange}
                      showButtonBar={true}
                      disabled={props.disabled}
            />
        </InputContainer>
    )
};

export default DateInput;
