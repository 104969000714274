import {Accordion, AccordionTab} from "primereact/accordion";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {FormEvent, useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {Button} from "primereact/button";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlert} from "../../../../store/app/actions";
import selectFiltered from "../../../../store/parsers/others/activities-contest/selectors/FilteredSelector";
import {UserScore} from "../../../../store/parsers/others/activities-contest/types";

import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import * as actions from "../../../../store/parsers/others/activities-contest/actions";
import useDateRange from "../../../hooks/DateRangeHook";
import BasicCheckbox from "../../../ui/inputs/BasicCheckbox";
import BasicNumbersInput from "../../../ui/inputs/BasicNumbersInput";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import DateRangeInput from "../../../ui/inputs/DateRangeInput";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingName from "../../../ui/inputs/ParsingName";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import ScoresDialog, {ScoresDialogData} from "./activities-contest/ScoresDialog";

const ActivitiesContest = (props: AppPanelProps) => {

    const moment = require("moment");

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.activitiesContest);
    const progress: TaskProgress = useSelector((state: RootState) => state.activitiesContest.progress);

    const saveCsvRef = useRef<HTMLAnchorElement>(null);
    const [downloadCsvUrl, setDownloadCsvUrl] = useState<string>('');

    const result: UserScore[] = useSelector(selectFiltered);
    const [selection, setSelection] = useState<UserScore[]>([]);
    useEffect(() => {
        setSelection(result);
    }, [result]);
    const [expandedRows, setExpandedRows] = useState<any[]>([]);

    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [dialogData, setDialogData] = useState<ScoresDialogData>({title: '', is_url: false, items: []});

    const [parsingName, setParsingName] = useState<string>('');

    const onSourceChange = useCallback((e: FormEvent<HTMLInputElement>) => dispatch(actions.setSource(e.currentTarget.value)), [dispatch]);
    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);

    const getScoreRow = (title: string, score: number, items: string[], is_url: boolean = true) => {
        const onClick = () => {
            setDialogData({
                title: `${title}: ${score}`,
                items: items,
                is_url: is_url,
            });
            setDialogVisible(true);
        };
        return (
            <div className="tw-flex tw-items-center tw-space-x-5">
                <div className="w-full">
                    <Button onClick={onClick} className="p-button-text">{title}</Button>
                </div>
                <div className="w-auto">
                    <span className="p-badge">{score}</span>
                </div>
            </div>
        )
    };

    function sortDates (a: string, b: string): number {
        return moment(a, "DD.MM.YYYY").unix() - moment(b, "DD.MM.YYYY").unix()
    }

    const ScoresExpandedComponent = (data) => {
        let scores: UserScore = JSON.parse(JSON.stringify(data as unknown as UserScore));
        return (
            <div>
                <div className="tw-border-b tw-border-gray-500 tw-py-4">
                    {state.settings.score_like_enabled &&
                        getScoreRow('Очки за лайки постов', scores.score_likes_post, scores.likes_post)
                    }
                    {state.settings.score_like_all_enabled &&
                        getScoreRow('Очки за лайки всех постов за день', scores.score_likes_to_all_posts, scores.likes_to_all_posts.sort(sortDates), false)
                    }
                    {state.settings.score_comment_enabled &&
                        getScoreRow('Очки за комментарии', scores.score_comments, scores.comments)
                    }
                    {state.settings.score_best_comment_enabled &&
                        getScoreRow('Очки за лучшие комментарии', scores.score_comments_best, scores.comments_best)
                    }
                    {state.settings.score_like_comment_enabled &&
                        getScoreRow('Очки за лайки на комментариях', scores.score_comments_liked, scores.comments_liked)
                    }
                    {state.settings.score_like_from_author_enabled &&
                        getScoreRow('Очки за лайки автора поста', scores.score_comments_likes_by_author, scores.comments_liked_by_author)
                    }
                    {state.settings.score_like_owner_enabled &&
                        getScoreRow('Очки за лайки владельца группы', scores.score_comments_liked_by_owner, scores.comments_liked_by_owner)
                    }
                </div>
            </div>
        )
    };

    const toggleRow = function (item: UserScore) {
        const index: number = expandedRows.findIndex((row: UserScore) => item.user.id === row.user.id);
        const expanded_rows: UserScore[] = [...expandedRows];
        if (index === -1) {
            expanded_rows.push(item);
        }
        else {
            expanded_rows.splice(index, 1);
        }
        setExpandedRows(expanded_rows);
    };

    const saveCSV = () => {
        if (selection.length === 0) {
            dispatch(showAlert({text: 'Вы не выделили результаты, которые нужно сохранить', header: 'Ошибка'}));
            return;
        }
        const headers: string[] = [
            '"id"',
            '"Имя"',
            '"Баллы"',
            '"Баллы за лайки"',
            '"Баллы за лайк всех постов"',
            '"Баллы за комментарии"',
            '"Баллы за лайки комментариев"',
            '"Баллы за лучшие комментарии"',
            '"Баллы за лайк от автора поста"',
            '"Баллы за лайк от владельца группы"',
        ];
        const generator = (item: UserScore) => {
            const line = [
                item.user.id,
                '"' + strip_punctuation(item.user.first_name + ' ' + item.user.last_name) + '"',
                item.score,
                item.score_likes_post,
                item.score_likes_to_all_posts,
                item.score_comments,
                item.score_comments_liked,
                item.score_comments_best,
                item.score_comments_likes_by_author,
                item.score_comments_liked_by_owner,
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(selection, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Подсчет баллов читателей сообщества, участвовавших в конкурсе"
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/konkurs_aktivnostei"
                    title={props.title}
            />

            <ScoresDialog title={dialogData.title} is_url={dialogData.is_url} items={dialogData.items}
                          visible={dialogVisible} onHide={() => setDialogVisible(false)}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Группа">
                    <BasicTextInput placeholder="https://vk.com/cerebro_vk" value={state.settings.source} onChange={onSourceChange}/>
                </InputContainer>

                <InputContainer label="Дата">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <Accordion>
                    <AccordionTab header={"Настройки"}>
                        <InputContainer label="Сколько очков давать за лайк поста" childrenContainerClassname={'tw-items-center'}>
                            <BasicCheckbox label={''} checked={state.settings.score_like_enabled}
                                           onChange={(e) => dispatch(actions.setScoreLikeEnabled(e.checked))}
                            />
                            <BasicNumbersInput value={state.settings.score_like} disabled={!state.settings.score_like_enabled}
                                               onNumberChange={(e) => dispatch(actions.setScoreLike(e.value))}
                            />
                        </InputContainer>
                        <InputContainer label="Сколько очков давать за лайк всех постов" childrenContainerClassname={'tw-items-center'}>
                            <BasicCheckbox label={''} checked={state.settings.score_like_all_enabled}
                                           onChange={(e) => dispatch(actions.setScoreLikeAllEnabled(e.checked))}
                            />
                            <BasicNumbersInput value={state.settings.score_like_all} disabled={!state.settings.score_like_all_enabled}
                                               onNumberChange={(e) => dispatch(actions.setScoreLikeAll(e.value))}
                            />
                        </InputContainer>
                        <InputContainer label="Сколько очков давать за комментарий" childrenContainerClassname={'tw-items-center'}>
                            <BasicCheckbox label={''} checked={state.settings.score_comment_enabled}
                                           onChange={(e) => dispatch(actions.setScoreCommentEnabled(e.checked))}
                            />
                            <BasicNumbersInput value={state.settings.score_comment} disabled={!state.settings.score_comment_enabled}
                                               onNumberChange={(e) => dispatch(actions.setScoreComment(e.value))}
                            />
                        </InputContainer>
                        {state.settings.score_comment_enabled &&
                            <InputContainer label="Минимальная длина комментария (0, если без ограничения)" childrenContainerClassname={'tw-items-center'}>
                                <BasicNumbersInput value={state.settings.comment_min_length}
                                                   onNumberChange={e => dispatch(actions.setCommentMinLength(e.value))}
                                />
                            </InputContainer>
                        }
                        <InputContainer label="Сколько очков давать за лайк на комментарий" childrenContainerClassname={'tw-items-center'}>
                            <BasicCheckbox label={''} checked={state.settings.score_like_comment_enabled}
                                           onChange={(e) => dispatch(actions.setScoreLikeCommentEnabled(e.checked))}
                            />
                            <BasicNumbersInput value={state.settings.score_like_comment} disabled={!state.settings.score_like_comment_enabled}
                                               onNumberChange={(e) => dispatch(actions.setScoreLikeComment(e.value))}
                            />
                        </InputContainer>
                        <InputContainer label="Сколько очков давать за лучший комментарий" childrenContainerClassname={'tw-items-center'}>
                            <BasicCheckbox label={''} checked={state.settings.score_best_comment_enabled}
                                           onChange={(e) => dispatch(actions.setScoreBestCommentEnabled(e.checked))}
                            />
                            <BasicNumbersInput value={state.settings.score_best_comment} disabled={!state.settings.score_best_comment_enabled}
                                               onNumberChange={(e) => dispatch(actions.setScoreBestComment(e.value))}
                            />
                        </InputContainer>
                        <InputContainer label="Сколько очков давать за лайк от автора поста" childrenContainerClassname={'tw-items-center'}>
                            <BasicCheckbox label={''} checked={state.settings.score_like_from_author_enabled}
                                           onChange={(e) => dispatch(actions.setScoreLikeFromAuthorEnabled(e.checked))}
                            />
                            <BasicNumbersInput value={state.settings.score_like_from_author} disabled={!state.settings.score_like_from_author_enabled}
                                               onNumberChange={(e) => dispatch(actions.setScoreLikeFromAuthor(e.value))}
                            />
                        </InputContainer>
                        <InputContainer label="Сколько очков давать за лайк владельца группы" childrenContainerClassname={'tw-items-center'}>
                            <BasicCheckbox label={''} checked={state.settings.score_like_owner_enabled}
                                           onChange={(e) => dispatch(actions.setScoreLikeOwnerEnabled(e.checked))}
                            />
                            <BasicTextInput value={state.settings.owner_url} placeholder={'Ссылка на владельца группы'}
                                            disabled={!state.settings.score_like_owner_enabled}
                                            onChange={e => dispatch(actions.setOwnerUrl(e.currentTarget.value))}
                            />
                            <BasicNumbersInput value={state.settings.score_like_owner} disabled={!state.settings.score_like_owner_enabled}
                                               onNumberChange={(e) => dispatch(actions.setScoreLikeOwner(e.value))}
                            />
                        </InputContainer>

                    </AccordionTab>
                </Accordion>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить в CSV" onClick={() => saveCSV()} />
                    </>
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.csv' : 'result.csv'} ref={saveCsvRef} href={downloadCsvUrl} style={{display: 'none'}}>Скачать CSV</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            {result.length > 0 &&
            <div className="tw-mt-4">
                <DataTable value={result} rowHover={true} paginator={true} rows={100}
                           selection={selection} onSelectionChange={(e) => setSelection(e.value)}
                           sortField="score" sortOrder={-1}
                           expandedRows={expandedRows}
                           autoLayout={true}
                           rowExpansionTemplate={ScoresExpandedComponent}
                >
                    <Column body={(item: UserScore) => <Button label={'Развернуть'} onClick={() => toggleRow(item)}/>} />
                    <Column field="user.first_name" header="Имя" body={(item: UserScore, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/id${item.user.id}`} target="_blank" rel="noopener noreferrer">{item.user?.first_name} {item.user?.last_name}</a></div>} sortable />
                    <Column field="score"  header="Очки" sortable />
                </DataTable>
            </div>
            }

        </AppPanel>
    );
};

export default ActivitiesContest;
