import {Dialog} from "primereact/dialog";
import React from "react";
import GroupLabel from "../../../../ui/labels/GroupLabel";

export interface ScoresDialogData {
    title: string,
    is_url: boolean,
    items: string[],
}

export interface ScoresDialogProps extends ScoresDialogData{
    visible: boolean,
    onHide: () => void,
}

const ScoresDialog = (props: ScoresDialogProps) => {

    return (
        <Dialog visible={props.visible} onHide={props.onHide}
                className="tw-w-4/5 md:tw-w-1/3"
                header={<GroupLabel label={props.title} className="tw-font-medium tw-mb-1" />}
        >
            <div className="tw-space-y-2">
            {props.items.map((item: string, index: number) => {
                if (props.is_url) {
                    return <div key={index}><a href={item} target={'_blank'} rel="noopener noreferrer">{item}</a></div>
                }
                return (
                    <div key={index}>{item}</div>
                );
            })}
            </div>
        </Dialog>
    )
};

export default ScoresDialog;
