import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import tokenize from "../../../../helpers/tokenizer";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {OwnerPosts, Post} from "../../../../vkapi/objects/Post";
import {PostAttach} from "../../../../vkapi/objects/PostAttach";
import {convert_urls_to_id} from "../../../../vkapi/tasks/ConverUrlToId";
import GetGroups from "../../../../vkapi/tasks/GetGroups";
import GetPosts from "../../../../vkapi/tasks/GetPosts";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {date_in_range, is_post_before_start_date} from "../../../helpers/DateChecker";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import {RootState, TaskStatus} from "../../types";
import {EMPTY_URL_GROUP_LIST} from "../../validation-errors";
import * as actions from "./actions";
import {PostsSearchAttachType, PostsSearchState} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: PostsSearchState = yield select((state: RootState) => state.postsSearch);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_urls: string[] = convert_urls_to_id(state.settings.groups_source.split('\n'));
    const groups: GroupObject[] = yield GetGroups({
        access_token: access_token,
        group_ids: group_urls,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    const is_date_in_range = date_in_range(state.settings.start_date, state.settings.end_date);
    const is_posts_start_date_reached = is_post_before_start_date(state.settings.start_date);

    const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});

    const result_map: Map<string, Post> = new Map<string, Post>();
    const posts_authors: Set<number> = new Set<number>();

    const words_minus: string[] = tokenize(state.settings.words_minus.replace(",", " "));

    for (const group of groups) {
        yield progress.next().value;
        const posts: OwnerPosts = yield GetPosts({
            owner_id: group.id * -1,
            is_skip: (post: Post) => !is_date_in_range(post.date),
            is_stop: (post: Post) => is_posts_start_date_reached(post),
            access_token: access_token,
            progress_action: actions.setSubProgress,
        });

        posts.posts.forEach(post => {
            if (state.settings.attach_type === PostsSearchAttachType.POLL_OPEN || state.settings.attach_type === PostsSearchAttachType.POLL_ANY) {
                if (!postHasPoll(post, state.settings.attach_type)) {
                    return;
                }
            }
            const text: string = extractTextFromPost(post).toLowerCase();
            const text_has_minus_words: boolean = words_minus.filter(word => text.includes(word.toLowerCase())).length > 0;
            if (text_has_minus_words) {
                return;
            }
            if (state.settings.queries_source.trim() !== '') {
                const text_has_plus_words: boolean = textHasPlusWords(text, state.settings.queries_source, state.settings.is_strict);
                if (!text_has_plus_words) {
                    return;
                }
            }
            const unique_id: string = `${post.owner_id}_${post.id}`;
            result_map.set(unique_id, post);
            if (post.signer_id) {
                posts_authors.add(post.signer_id);
            }
        });
    }

    yield put(actions.setResult(Array.from(result_map.values())));
    yield put(actions.setProgress({message: `Завершено. Найдено постов: ${result_map.size}. Найдено авторов: ${posts_authors.size}`, total: 100, current: 100}));
    yield put(actions.setSubProgress({message: ``, total: undefined, current: undefined}));
    yield put(showAlertParsingCompleted(`Готово. Найдено постов: ${result_map.size}. Найдено авторов: ${posts_authors.size}`));
    yield put(actions.setCompleted());
}

function textHasPlusWords(text: string, words_source: string, is_strict: boolean): boolean {
    words_source = words_source.toLowerCase();
    let words: string[] = words_source.split('\n').map(item => item.trim()).filter(item => item !== '');
    if (!is_strict) {
        words = words.join(' ').split(' ');
    }
    return words.filter(word => text.includes(word)).length > 0;
}

function postHasPoll(post: Post, type: PostsSearchAttachType.POLL_ANY|PostsSearchAttachType.POLL_OPEN): boolean {
    if (!post.attachments) {
        return false;
    }
    const filtered_attachments: PostAttach[] = post.attachments.filter(attach => attach.type === "poll");
    if (filtered_attachments.length === 0) {
        return false;
    }
    if (type === PostsSearchAttachType.POLL_ANY) {
        return true
    }
    for (let attach of filtered_attachments) {
        if (attach.type === "poll") {
            if (!attach.poll.anonymous) {
                return true;
            }
        }
    }
    return false;
}

function extractTextFromPost(post: Post): string {
    const all_texts: string[] = [];
    all_texts.push(post.text);
    if (post.copy_history && post.copy_history.length > 0 && post.copy_history[0]) {
        const repost: Post = post.copy_history[0];
        all_texts.push(repost.text);
    }
    if (post.attachments) {
        post.attachments.forEach(attach => {
            if (attach.type === "video") {
                all_texts.push(attach.video.title);
                all_texts.push(attach.video.description);
            }
            if (attach.type === "audio") {
                all_texts.push(attach.audio.artist);
                all_texts.push(attach.audio.title);
            }
            if (attach.type === "poll") {
                all_texts.push(attach.poll.question);
                attach.poll.answers.forEach(answer => {
                    all_texts.push(answer.text);
                })
            }
        });
    }
    return all_texts.join(' ');
}

function* validate(state: PostsSearchState) {
    if (state.settings.groups_source.trim() === '') {
        yield put(showAlertError(EMPTY_URL_GROUP_LIST));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
