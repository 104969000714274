import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../../helpers/DefaultActionsCreator";
import {City, Country, Gender} from "../../../types";
import * as types from "../action-types";
import {AudienceFilterResult, FilterSearchType} from "../types";

function withPayloadType<T>() {
    return (t: T) => ({payload: t})
}

export const setSource = createAction(types.audience.SET_SOURCE, withPayloadType<string>());
export const setAgeMin = createAction(types.audience.SET_AGE_MIN, withPayloadType<number>());
export const setAgeMax = createAction(types.audience.SET_AGE_MAX, withPayloadType<number>());
export const setGender = createAction(types.audience.SET_GENDER, withPayloadType<Gender>());
export const setSelectedCountry = createAction(types.audience.SET_SELECTED_COUNTRY, withPayloadType<Country|null>());
export const setSelectedCity = createAction(types.audience.SET_SELECTED_CITY, withPayloadType<City|null>());
export const setSearchType = createAction(types.audience.SET_SEARCH_TYPE, withPayloadType<FilterSearchType>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<AudienceFilterResult[]>(types.audience.NAMESPACE);
