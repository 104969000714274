import React from "react";
import {InputNumber} from "primereact/inputnumber";

import { v4 as uuidv4 } from 'uuid';

export interface NumberChangeEvent {
    originalEvent: Event,
    value: any
}

export interface BasicNumbersInputProps extends React.HTMLAttributes<HTMLDivElement> {
    id?: string,
    value?: number,
    label?: string,
    onNumberChange?: (e: NumberChangeEvent) => void,
    placeholder?: string,
    disabled?: boolean
}

const BasicNumbersInput = (props: BasicNumbersInputProps) => {

    const classes = props.className || "p-field tw-w-full sm:tw-flex-1";
    const uniqueId = props.id ? props.id : uuidv4();

    const onChange = (e: NumberChangeEvent) => {
        const i: number = parseInt(e.value);
        const value: number = isNaN(i) ? 0 : i;
        e.value = value;
        if (props.onNumberChange) {
            props.onNumberChange(e);
        }
    };

    return (
        <div className={classes}>
            {props.label &&
                <label htmlFor={uniqueId}>{props.label}</label>
            }
            <InputNumber className="tw-w-full"
                         id={uniqueId}
                         value={props.value}
                         onChange={onChange}
                         placeholder={props.placeholder}
                         disabled={props.disabled}
            />
        </div>
    )
};

export default BasicNumbersInput;
