import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../../helpers/AddDefaultCasesToReducer";
import {City, Country, DefaultProps, Gender} from "../../../types";
import {AudienceFilterResult, CommunitiesFilterState, FilterSearchType} from "../types";
import {audience as actions} from "../actions"

const ReducerAudience = (initialState: CommunitiesFilterState['audience']) => {
    return createReducer(initialState, builder => {
        builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
            state.settings.source = action.payload
        });
        builder.addCase(actions.setAgeMin, (state, action: PayloadAction<number>) => {
            state.settings.age_min = action.payload
        });
        builder.addCase(actions.setAgeMax, (state, action: PayloadAction<number>) => {
            state.settings.age_max = action.payload
        });
        builder.addCase(actions.setGender, (state, action: PayloadAction<Gender>) => {
            state.settings.gender = action.payload
        });
        builder.addCase(actions.setSelectedCountry, (state, action: PayloadAction<Country|null>) => {
            state.settings.selected_country = action.payload
        });
        builder.addCase(actions.setSelectedCity, (state, action: PayloadAction<City|null>) => {
            state.settings.selected_city = action.payload
        });
        builder.addCase(actions.setSearchType, (state, action: PayloadAction<FilterSearchType>) => {
            state.settings.search_type = action.payload
        });
        AddDefaultCasesToReducer<AudienceFilterResult[]>({
            actions: {
                setTaskStatus: actions.setTaskStatus,
                setProgress: actions.setProgress,
                setResult: actions.setResult,
                addApiError: actions.addApiError,
                resetApiErrors: actions.resetApiErrors
            }
        }, builder as unknown as ActionReducerMapBuilder<DefaultProps<AudienceFilterResult[]>>);
    });
};

export default ReducerAudience;
