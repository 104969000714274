import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import {DefaultProps, TaskProgress} from "../../types";
import * as types from "./types";
import * as actions from './actions';
import DefaultStateProps from "../../../helpers/DefaultStateProps";

const initialState: types.TopReadersState = {
    settings: {
        source: '',
        top_places: 30,
    },
    groups_with_hidden_members: [],
    not_publics: [],
    sub_progress: {message: ''},
    ...DefaultStateProps<number[]>([])
};

const TopReadersReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
        state.settings.source = action.payload;
    });
    builder.addCase(actions.setTopPlaces, (state, action: PayloadAction<number>) => {
        state.settings.top_places = action.payload;
    });
    builder.addCase(actions.setSubProgress, (state, action: PayloadAction<TaskProgress>) => {
        state.sub_progress = action.payload;
    });
    builder.addCase(actions.setNotPublics, (state, action: PayloadAction<GroupObject[]>) => {
        state.not_publics = action.payload;
    });
    builder.addCase(actions.setGroupsWithHiddenMembers, (state, action: PayloadAction<GroupObject[]>) => {
        state.groups_with_hidden_members = action.payload;
    });
    AddDefaultCasesToReducer<number[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<number[]>>);
});

export default TopReadersReducer;
