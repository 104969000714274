import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../../vkapi/objects/UserObject";
import {convert_urls_to_id, convert_urls_to_screen_names} from "../../../../../vkapi/tasks/ConverUrlToId";
import GetGroups from "../../../../../vkapi/tasks/GetGroups";
import GetUsers from "../../../../../vkapi/tasks/GetUsers";
import ProgressGenerator from "../../../../helpers/ProgressGenerator";
import {activity as actions} from "../actions";
import {RootState, TaskStatus} from "../../../types";
import {showAlertError, showAlertParsingCompleted} from "../../../../app/actions";
import GetActivityStatsQuick from "../tasks/GetActivityStatsQuick";
import GetActivityStatsSlow from "../tasks/GetActivityStatsSlow";
import {ActivityFilterResult, CommunitiesFilterState} from "../types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: CommunitiesFilterState['activity'] = yield select((state: RootState) => state.communitiesFilter.activity);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_ids_string: string[] = convert_urls_to_id(state.settings.source.split("\n"));
    const groups: GroupObject[] = yield GetGroups({
        group_ids: group_ids_string,
        fields: 'members_count',
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });

    yield put(actions.setProgress({message: 'Получаем пользователей', total: 0, current: 0}));

    let selected_users: Set<number> = new Set<number>();
    if (state.settings.only_selected_users) {
        const selected_users_map: Map<number, UserObject> = yield GetUsers({
            user_ids: convert_urls_to_screen_names(state.settings.selected_users_source.split("\n")),
            add_api_errors_action: actions.addApiError,
            filter_func: (user => !user.deactivated),
            access_token: access_token,
        });
        Array.from(selected_users_map.values()).forEach(user => selected_users.add(user.id));
    }

    const result: ActivityFilterResult[] = [];
    const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});
    for (const group of groups) {
        yield progress.next().value;
        if (state.settings.only_members || state.settings.only_selected_users) {
            try {
                const stats: ActivityFilterResult = yield GetActivityStatsSlow({
                    group: group,
                    only_members: state.settings.only_members,
                    only_selected_users: state.settings.only_selected_users,
                    selected_users: selected_users,
                    start_date: state.settings.start_date,
                    end_date: state.settings.end_date,
                    access_token: access_token,
                    add_api_errors_action: actions.addApiError,
                    progress_action: actions.setSubProgress,
                });
                if (stats.activity > 0) {
                    result.push(stats);
                }
                continue;
            }
            catch (e) {
                continue;
            }
        }
        const stats: ActivityFilterResult = yield GetActivityStatsQuick({
            group: group,
            start_date: state.settings.start_date,
            end_date: state.settings.end_date,
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setSubProgress,
        });
        if (stats.activity > 0) {
            result.push(stats);
        }

    }

    yield put(actions.setResult(result));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result.length}`, total: 100, current: 100}));
    yield put(actions.setSubProgress({message: ``}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${result.length}`));
    yield put(actions.setCompleted());
}

function* validate(state: CommunitiesFilterState['activity']) {
    if (state.settings.source.trim() === '') {
        yield put(showAlertError('Вы не указали ссылки на сообщества'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
