import {PostComments} from "../GetComments";
import {IVkMethod} from "../../IVkMethod";
import {LikesGetListParams} from "../../methods/LikesGetListParams";
import * as methods from "../../methods/VkMethods";
import _chunk from "lodash/chunk";
import {ProcessMethodsParams} from "../../types";
import SendMethodsDefault from "../../helpers/SendMethodsDefault";
import {WithDefaultActions} from "../../../store/parsers/types";

export interface GetCommentsLikesParams extends WithDefaultActions {
    comments: PostComments[],
    access_token: string,
}

export interface PostCommentsLikes {
    owner_id: number,
    post_id: number,
    comment_id: number,
    likes: number[],
}

const GetCommentsLikes = function* (params: GetCommentsLikesParams): Generator<any, PostCommentsLikes[], any> {
    const executes:Array<IVkMethod> = prepare(params.comments);
    const executes_result: PostCommentsLikes[] = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(post_comments: PostComments[]): IVkMethod[] {
    const methods_per_execute: number = 20;
    const count: number = 1000;
    const requests: string[] = [];

    for (let post of post_comments) {
        post.comments.forEach(comment => {
            if (!comment.likes || comment.likes.count === 0) {
                return;
            }
            for (let offset: number = 0; offset < comment.likes.count; offset += count) {
                const params: LikesGetListParams = {
                    type: "comment",
                    owner_id: post.owner_id,
                    item_id: comment.id,
                    offset: offset,
                    count: count,
                };
                const method: IVkMethod = methods.likesGetList(params);
                requests.push(`{owner_id: ${post.owner_id}, post_id: ${post.post_id}, comment_id: ${comment.id}, items: API.${method.method_name}(${JSON.stringify(method.params)}).items}`);
            }
        });
    }

    const requests_chunks: string[][] = _chunk(requests, methods_per_execute);
    return requests_chunks.map((chunk: string[]) => {
        const code:string = `return [${chunk.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, PostCommentsLikes[], any> {
    interface TResponse {
        owner_id: number,
        post_id: number,
        comment_id: number,
        items: number[],
    }
    const map: Map<string, PostCommentsLikes> = new Map<string, PostCommentsLikes>();
    const handler = (response: TResponse[]) => {
        response.forEach((response_item: TResponse) => {
            const unique_id: string = `${response_item.owner_id}_${response_item.post_id}_${response_item.comment_id}`;
            const comment_likes: PostCommentsLikes = map.get(unique_id) ||
                {
                    owner_id: response_item.owner_id,
                    post_id: response_item.post_id,
                    comment_id: response_item.comment_id,
                    likes: []
                };
            comment_likes.likes = comment_likes.likes.concat(response_item.items);
            map.set(unique_id, comment_likes);
        });
    };
    yield SendMethodsDefault<TResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем лайки комментариев',
        chunk_size: 6,
    });
    return Array.from(map.values());
};

export default GetCommentsLikes;
