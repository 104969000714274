import {IVkMethod} from "./IVkMethod";
import {Either} from "fp-ts/lib/Either";
import {VkApiError} from "./VkApiError";
import {VkApiResponse} from "./VkApiResponse";
import api from "./VkApi";

export interface VkApiBatchParams {
    methods: IVkMethod[],
    access_token: string,
}

const VkApiSendBatch = async (params: VkApiBatchParams): Promise<PromiseSettledResult<Either<VkApiError,VkApiResponse>>[]> => {
    const promises: Promise<Either<VkApiError,VkApiResponse>>[] = params.methods.map(execute => {
        return api(execute, params.access_token);
    });
    const promise_results: PromiseSettledResult<Either<VkApiError,VkApiResponse>>[] = await Promise.allSettled(promises);
    return promise_results;
};

export default VkApiSendBatch;
