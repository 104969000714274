import {createAction} from "@reduxjs/toolkit";
import {GroupsSearchSort} from "../../../../vkapi/methods/GroupsSearchParams";
import * as types from "./action-types";
import {City, Country, TaskProgress, TaskStatus} from "../../types";
import {VkApiError} from "../../../../vkapi/VkApiError";
import {CommunitySearchItem, GroupAccessType} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSearchQuery = createAction(types.SET_SEARCH_QUERY, withPayloadType<string>());
export const setIsStrict = createAction(types.SET_IS_STRICT, withPayloadType<boolean>());
export const setOnlyOfficial = createAction(types.SET_ONLY_OFFICIAL, withPayloadType<boolean>());

export const setTypeGroup = createAction(types.SET_TYPE_GROUP, withPayloadType<boolean>());
export const setTypePublic = createAction(types.SET_TYPE_PUBLIC, withPayloadType<boolean>());
export const setTypeEvent = createAction(types.SET_TYPE_EVENT, withPayloadType<boolean>());
export const setTypeFutureEvent = createAction(types.SET_TYPE_FUTURE_EVENT, withPayloadType<boolean>());

export const setGroupAccessType = createAction(types.SET_GROUP_ACCESS_TYPE, withPayloadType<GroupAccessType>());

export const setSelectedCountry = createAction(types.SET_SELECTED_COUNTRY, withPayloadType<Country|null>());
export const setCities = createAction(types.SET_CITIES, withPayloadType<City[]>());

export const setSortType = createAction(types.SET_SORT_TYPE, withPayloadType<GroupsSearchSort>());

export const setMembersMin = createAction(types.SET_MEMBERS_MIN, withPayloadType<number>());
export const setMembersMax = createAction(types.SET_MEMBERS_MAX, withPayloadType<number>());

export const setTitleLengthMin = createAction(types.SET_TITLE_LENGTH_MIN, withPayloadType<number>());
export const setTitleLengthMax = createAction(types.SET_TITLE_LENGTH_MAX, withPayloadType<number>());

export const setMinDate = createAction(types.SET_MIN_DATE, withPayloadType<Date|null>());

export const setWordsPlus = createAction(types.SET_WORDS_PLUS, withPayloadType<string[]>());
export const setWordsMinus = createAction(types.SET_WORDS_MINUS, withPayloadType<string[]>());

export const setIsLastPostDatesFound = createAction(types.SET_IS_LAST_POST_DATES_FOUND, withPayloadType<boolean>());

export const findLastPostDates = createAction(types.FIND_LAST_POST_DATES);

export const start = createAction(types.START);
export const stop = createAction(types.STOP);
export const addApiError = createAction(types.ADD_API_ERROR, withPayloadType<VkApiError>());
export const resetApiErrors = createAction(types.RESET_API_ERRORS);

export const setTaskStatus = createAction(types.SET_TASK_STATUS, withPayloadType<TaskStatus>());
export const setTaskError = createAction(types.SET_TASK_ERROR, withPayloadType<string>());
export const setProgress = createAction(types.SET_PROGRESS, withPayloadType<TaskProgress>());
export const setResult = createAction(types.SET_RESULT, withPayloadType<CommunitySearchItem[]>());
