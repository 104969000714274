import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import React, {useCallback, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import download_txt from "../../../../helpers/DownloadTxt";
import {showAlert} from "../../../../store/app/actions";
import selectFiltered from "../../../../store/parsers/filter-base/search-base/selectors/FilteredSelector";
import * as actions from "../../../../store/parsers/filter-base/search-base/actions";
import {ZodiacSign} from "../../../../store/parsers/filter-base/search-base/types";

import {DropdownValue, Gender, RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {Relation, UserObject} from "../../../../vkapi/objects/UserObject";
import useCity from "../../../hooks/CityHook";
import useCountry from "../../../hooks/CountryHook";
import useMinMax from "../../../hooks/MinMaxHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicCheckbox, {CheckBoxChangeEvent} from "../../../ui/inputs/BasicCheckbox";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import DateInput from "../../../ui/inputs/DateInput";
import MinMaxInput from "../../../ui/inputs/MinMaxInput";
import ParsingName from "../../../ui/inputs/ParsingName";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import {CalendarChangeEvent} from "../../../ui/ui-events/CalendarChangeEvent";
import BasicFileUpload from "../../../ui/upload/BasicFileUpload";

const SearchBase = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.searchBase);
    const progress: TaskProgress = useSelector((state: RootState) => state.searchBase.progress);

    const result: UserObject[] = useSelector(selectFiltered);

    const [parsingName, setParsingName] = useState<string>('');
    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const [onAgeMinChange, onAgeMaxChange] = useMinMax(actions.setAgeFrom, actions.setAgeTo);

    const CountryComponent = useCountry(state.settings.selected_country, actions.setSelectedCountry);
    const cities = useSelector((state: RootState) => state.searchBase.settings.cities);
    const CityComponent = useCity(state.settings.selected_country, cities, actions.setCities);

    const onFamilyChange = useCallback((e: CheckBoxChangeEvent, value: Relation) => {
        if (e.checked) {
            dispatch(actions.addFamily(value));
            return;
        }
        dispatch(actions.removeFamily(value));
    }, [dispatch]);

    const onLastActivityChange = useCallback((e: CalendarChangeEvent) => {
        let date: Date|null = null;
        if (e.value instanceof Date) {
            date = e.value;
        }
        dispatch(actions.setLastActivity(date));
    }, [dispatch]);

    const onUpload = useCallback((lines: string[]) => {
        dispatch(actions.setSourceUsers(lines));
        dispatch(showAlert({header: 'Файл загружен', text: `Количество строк: ${lines.length}`}));
    }, [dispatch])

    const zodiacs: DropdownValue<ZodiacSign>[] = [
        {label: "Не важно", value: ZodiacSign.ANY},
        {label: "Овен", value: ZodiacSign.OVEN},
        {label: "Телец", value: ZodiacSign.TELEC},
        {label: "Близнецы", value: ZodiacSign.BLIZNECI},
        {label: "Рак", value: ZodiacSign.RAK},
        {label: "Лев", value: ZodiacSign.LEV},
        {label: "Дева", value: ZodiacSign.DEVA},
        {label: "Весы", value: ZodiacSign.VESI},
        {label: "Скорпион", value: ZodiacSign.SCORPION},
        {label: "Стрелец", value: ZodiacSign.STRELEC},
        {label: "Козерог", value: ZodiacSign.KOZEROG},
        {label: "Водолей", value: ZodiacSign.VODOLEY},
        {label: "Рыбы", value: ZodiacSign.RIBI},
    ];

    const genders: DropdownValue<Gender>[] = [
        {label: "Любой", value: Gender.ANY},
        {label: "Мужской", value: Gender.MALE},
        {label: "Женский", value: Gender.FEMALE},
    ];

    const save = () => {
        download_txt<UserObject[]>({
            data: result,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: UserObject[]) => data.map(item => item.id.toString()),
        });
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Оставьте в базе только людей, удовлетворяющих критериям."
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/poisk_po_baze"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Имена (через запятые)">
                    <BasicTextInput value={state.settings.source_names}
                                    onChange={(e) => dispatch(actions.setSourceNames(e.currentTarget.value))}
                                    placeholder="Роман, Маша, Таня, Екатерина"
                    />
                </InputContainer>

                <MinMaxInput label="Возраст"
                             disabled={state.settings.only_hidden_age}
                             min={state.settings.age_from} onMinChange={onAgeMinChange}
                             max={state.settings.age_to} onMaxChange={onAgeMaxChange}
                />

                <BasicCheckbox label="оставить только тех, у кого возраст скрыт или не указан"
                               checked={state.settings.only_hidden_age}
                               onChange={(e) => dispatch(actions.setOnlyHiddenAge(e.checked))}
                />

                <InputContainer label="Знак зодиака">
                    <Dropdown options={zodiacs}
                              value={state.settings.zodiac_sign}
                              onChange={(e) => dispatch(actions.setZodiacSign(e.value))}
                              className="p-field tw-w-full sm:tw-flex-1"
                    />
                </InputContainer>

                <BasicCheckbox label="убрать заблокированных и без аватарки"
                               checked={state.settings.remove_blocked}
                               onChange={(e) => dispatch(actions.setRemoveBlocked(e.checked))}
                />
                <BasicCheckbox label="оставить только заблокированных и без аватарки"
                               checked={state.settings.keep_blocked}
                               onChange={(e) => dispatch(actions.setKeepBlocked(e.checked))}
                />
                <BasicCheckbox label="оставить только верифицированных (с галочкой) пользователей"
                               checked={state.settings.keep_verified}
                               onChange={(e) => dispatch(actions.setKeepVerified(e.checked))}
                />

                <InputContainer label="Пол">
                    <Dropdown options={genders}
                              value={state.settings.gender}
                              onChange={(e) => dispatch(actions.setGender(e.value))}
                              className="p-field tw-w-full sm:tw-flex-1"
                    />
                </InputContainer>

                {CountryComponent}

                {CityComponent}

                <InputContainer label="Семейное положение">
                    <BasicCheckbox label="всё сложно" value={Relation.COMPLICATED.toString()}
                                   checked={state.settings.family.includes(Relation.COMPLICATED)}
                                   onChange={e => onFamilyChange(e, Relation.COMPLICATED)}
                    />
                    <BasicCheckbox label="не женат/не замужем" value={Relation.SINGLE.toString()}
                                   checked={state.settings.family.includes(Relation.SINGLE)}
                                   onChange={e => onFamilyChange(e, Relation.SINGLE)}
                    />
                    <BasicCheckbox label="встречается" value={Relation.HAVE_PAIR.toString()}
                                   checked={state.settings.family.includes(Relation.HAVE_PAIR)}
                                   onChange={e => onFamilyChange(e, Relation.HAVE_PAIR)}
                    />
                    <BasicCheckbox label="женат/замужем" value={Relation.MARRIED.toString()}
                                   checked={state.settings.family.includes(Relation.MARRIED)}
                                   onChange={e => onFamilyChange(e, Relation.MARRIED)}
                    />
                    <BasicCheckbox label="в активном поиске" value={Relation.ACTIVE_SEARCH.toString()}
                                   checked={state.settings.family.includes(Relation.ACTIVE_SEARCH)}
                                   onChange={e => onFamilyChange(e, Relation.ACTIVE_SEARCH)}
                    />
                    <BasicCheckbox label="влюблен/влюблена" value={Relation.IN_LOVE.toString()}
                                   checked={state.settings.family.includes(Relation.IN_LOVE)}
                                   onChange={e => onFamilyChange(e, Relation.IN_LOVE)}
                    />
                    <BasicCheckbox label="помолвлен/помолвлена" value={Relation.IN_LOVE.toString()}
                                   checked={state.settings.family.includes(Relation.IN_LOVE)}
                                   onChange={e => onFamilyChange(e, Relation.IN_LOVE)}
                    />
                    <BasicCheckbox label="в гражданском браке" value={Relation.LIVE_TOGETHER.toString()}
                                   checked={state.settings.family.includes(Relation.LIVE_TOGETHER)}
                                   onChange={e => onFamilyChange(e, Relation.LIVE_TOGETHER)}
                    />
                </InputContainer>

                <BasicCheckbox label="есть дети"
                               checked={state.settings.has_children}
                               onChange={(e) => dispatch(actions.setHasChildren(e.checked))}
                />

                <DateInput label="Заходил после даты"
                           value={state.settings.last_activity} onValueChange={onLastActivityChange}
                />

                <InputContainer label="Слова в статусе (через пробел)">
                    <BasicTextInput value={state.settings.source_status}
                                    onChange={(e) => dispatch(actions.setSourceStatus(e.currentTarget.value))}
                                    placeholder="smm продвижение seo"
                    />
                </InputContainer>

                <BasicCheckbox label="в статусе должны быть все слова"
                               checked={state.settings.status_has_all_words}
                               onChange={(e) => dispatch(actions.setStatusHasAllWords(e.checked))}
                />

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    {state.settings.source_users.length > 0 &&
                        <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    }
                    <BasicFileUpload chooseLabel={'Загрузить id или ссылки на пользователей'} onUpload={onUpload} />
                    {result.length > 0 &&
                        <SaveButton label="Сохранить фильтрованные результаы" onClick={save} />
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Сохранить фильтрованные результаы</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

        </AppPanel>
    );
};

export default SearchBase;
