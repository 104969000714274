import {call, cancel, fork, put, race, select, take} from "redux-saga/effects";
import * as action_types from "../action-types";
import * as actions from "../actions";
import {RootState, TaskStatus} from "../../../types";
import {PayloadAction} from "@reduxjs/toolkit";
import {showAlert} from "../../../../app/actions";
import {AudienceAnalysisItem, AudienceAnalysisState, SelectedResults} from "../types";
import {id_to_life_main, id_to_people_main, id_to_political, id_to_relation} from "../helpers";
import {UserObject} from "../../../../../vkapi/objects/UserObject";

const main = function* () {
    while (yield take(action_types.START_USERS_SEARCH)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: AudienceAnalysisState = yield select((state: RootState) => state.audienceAnalysis);
    const profiles: Map<number, UserObject> = state.profiles;
    const selected_results: SelectedResults = state.selected_results;
    const ids: Set<number> = new Set<number>();

    profiles.forEach((profile: UserObject, user_id: number) => {
        if (selected_results.interests.length > 0 && profile.interests) {
            if (has(selected_results.interests, profile.interests)) {
                ids.add(user_id);
            }
        }
        if (selected_results.books.length > 0 && profile.books) {
            if (has(selected_results.books, profile.books)) {
                ids.add(user_id);
            }
        }
        if (selected_results.music.length > 0 && profile.music) {
            if (has(selected_results.music, profile.music)) {
                ids.add(user_id);
            }
        }
        if (selected_results.interests.length > 0 && profile.interests) {
            if (has(selected_results.interests, profile.interests)) {
                ids.add(user_id);
            }
        }
        if (selected_results.activities.length > 0 && profile.activities) {
            if (has(selected_results.activities, profile.activities)) {
                ids.add(user_id);
            }
        }
        if (selected_results.games.length > 0 && profile.games) {
            if (has(selected_results.games, profile.games)) {
                ids.add(user_id);
            }
        }
        if (selected_results.tv.length > 0 && profile.tv) {
            if (has(selected_results.tv, profile.tv)) {
                ids.add(user_id);
            }
        }
        if (selected_results.occupation.length > 0 && profile.occupation && profile.occupation.name && profile.occupation.type === "work") {
            if (has(selected_results.occupation, profile.occupation.name.trim().toLowerCase())) {
                ids.add(user_id);
            }
        }
        if (selected_results.universities.length > 0 && profile.universities) {
            if (profile.universities.filter(item => {
                if (!item.name) {
                    return false;
                }
                return has(selected_results.universities, item.name);
            }).length > 0) {
                ids.add(user_id);
            }
        }
        if (selected_results.faculties.length > 0 && profile.universities) {
            if (profile.universities.filter(item => {
                if (!item.faculty_name) {
                    return false;
                }
                return has(selected_results.faculties, item.faculty_name);
            }).length > 0) {
                ids.add(user_id);
            }
        }
        if (selected_results.relations.length > 0 && profile.relation) {
            if (has(selected_results.relations, id_to_relation(profile.relation))) {
                ids.add(user_id);
            }
        }
        if (selected_results.political.length > 0 && profile.personal?.political) {
            if (has(selected_results.political, id_to_political(profile.personal.political))) {
                ids.add(user_id);
            }
        }
        if (selected_results.life_main.length > 0 && profile.personal?.life_main) {
            if (has(selected_results.people_main, id_to_life_main(profile.personal.life_main))) {
                ids.add(user_id);
            }
        }
        if (selected_results.people_main.length > 0 && profile.personal?.people_main) {
            if (has(selected_results.people_main, id_to_people_main(profile.personal.people_main))) {
                ids.add(user_id);
            }
        }
    });

    yield put(actions.setFoundUsers(ids));

    yield put(showAlert({text: `Готово. Найдено: ${ids.size}`, header: 'Парсинг завершен'}));
    yield put({ type: action_types.COMPLETED});
}

function has(needles: AudienceAnalysisItem[], haystack: string): boolean {
    haystack = haystack.trim().toLowerCase();
    for (let needle of needles) {
        if (haystack.includes(needle.title)) {
            return true;
        }
    }
    return false;
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    yield put(actions.setProgress({message: `Ошибка: ${error.payload.message}`}));
    yield put(showAlert({text: error.payload.message, header: 'Ошибка'}));
    yield cancel(task);
}

export default main;
