import {createAction} from "@reduxjs/toolkit";
import * as types from "./action-types";
import {DataSourceType, TaskProgress, TaskStatus} from "../../types";
import {AudienceAnalysisItem, AudienceAnalysisResult} from "./types";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {VkApiError} from "../../../../vkapi/VkApiError";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setDatasourceType = createAction(types.SET_DATASOURCE_TYPE, withPayloadType<DataSourceType.URL|DataSourceType.FILE>());
export const setDatasourceGroupUrl = createAction(types.SET_DATASOURCE_GROUP_URL, withPayloadType<string>());
export const setDatasourceUserIds = createAction(types.SET_DATASOURCE_USER_IDS, withPayloadType<string>());

export const setProfiles = createAction(types.SET_PROFILES, withPayloadType<Map<number, UserObject>>());
export const setFoundUsers = createAction(types.SET_FOUND_USERS, withPayloadType<Set<number>>());
export const setSelectedCategory = createAction(types.SET_SELECTED_CATEGORY, withPayloadType<keyof AudienceAnalysisResult>());
export const addSelectedResults = createAction(types.ADD_SELECTED_RESULTS, withPayloadType<AudienceAnalysisItem[]>());
export const clearSelectedResults = createAction(types.CLEAR_SELECTED_RESULTS);

export const start_users_search = createAction(types.START_USERS_SEARCH);

export const start = createAction(types.START);
export const stop = createAction(types.STOP);
export const addApiError = createAction(types.ADD_API_ERROR, withPayloadType<VkApiError>());
export const resetApiErrors = createAction(types.RESET_API_ERRORS);

export const setTaskStatus = createAction(types.SET_TASK_STATUS, withPayloadType<TaskStatus>());
export const setTaskTitle = createAction(types.SET_TASK_TITLE, withPayloadType<string>());
export const setTaskError = createAction(types.SET_TASK_ERROR, withPayloadType<string>());
export const setProgress = createAction(types.SET_PROGRESS, withPayloadType<TaskProgress>());
export const setResult = createAction(types.SET_RESULT, withPayloadType<AudienceAnalysisResult>());
