import {spawn} from 'redux-saga/effects'
import MainSaga from './sagas/main';
import SearchLastPostDates from './sagas/find-last-post-dates';

const main = function* () {
    yield spawn(MainSaga);
    yield spawn(SearchLastPostDates);
};

export default main;
