import SendMethods, {SendMethodsParams} from "./SendMethods";
import {ERROR_CODE_TOO_MANY_REQUESTS} from "../VkApiError";
import RestoreMethod from "./RestoreMethod";
import {IVkMethod} from "../IVkMethod";

const SendMethodsWithAutoRetry = async <TResponse>(params: SendMethodsParams<TResponse>) => {
    const failed_methods: IVkMethod[] = [];
    const send_params: SendMethodsParams<TResponse> = {...params};
    send_params.onError = error => {
        params.onError(error);
        if (error.error_type === "client_error") {
            if (error.error_data.error_reason.error_code === ERROR_CODE_TOO_MANY_REQUESTS) {
                let restored_method = RestoreMethod(error.error_data.error_reason.request_params);
                if (restored_method) {
                    failed_methods.push(restored_method);
                }
            }
        }
    };

    await SendMethods<TResponse>(send_params);

    if (failed_methods.length > 0) {
        await new Promise(resolve => setTimeout(resolve, 1500));
        params.methods = failed_methods;
        await SendMethodsWithAutoRetry<TResponse>(params);
    }
};

export default SendMethodsWithAutoRetry;
