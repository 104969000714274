import {Button} from "primereact/button";
import React, {useState} from "react";
import {City, Country} from "../../../store/parsers/types";
import InputContainer from "../containers/InputContainer";
import SingleCityDialog from "../dialogs/SingleCityDialog";

export interface CitySingleInputProps {
    selectedCountry: Country|null,
    selectedCity: City|null,
    onSelect: (item: City) => void,
    onClear: () => void,
}

const CitySingleInput = (props: CitySingleInputProps) => {

    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const footer = () => {
        return (
            <div className="p-field">
                {props.selectedCity &&
                    <Button label={props.selectedCity?.title}
                            onClick={props.onClear}
                            className="p-button-rounded p-button-sm p-button-secondary"
                            icon="pi pi-times-circle"
                    />
                }
                {props.selectedCity === null &&
                    <span>Город не выбран</span>
                }
            </div>
        );
    };

    const onSelect = (item: City) => {
        props.onSelect(item);
        setDialogVisible(false);
    };

    return (
        <InputContainer label="Выбор города" bottom={footer()}>
            <Button label="Выбрать город" className="p-field" onClick={() => setDialogVisible(true)}/>
            <Button label="Сбросить"
                    className="p-field"
                    onClick={props.onClear}
            />
            <SingleCityDialog
                country_id={props.selectedCountry?.id}
                onSelect={onSelect}
                visible={dialogVisible}
                onHide={() => setDialogVisible(false)}
            />
        </InputContainer>
    )
};

export default CitySingleInput;
