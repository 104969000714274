import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import {useDispatch, useSelector} from "react-redux";
import {DataSourceType, RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {
    PopularityType, SuperstarItem,
    SearchType,
    SuperstarsLocatorState
} from "../../../../store/parsers/audience-search/superstars-locator/types";
import React, {FormEvent, useCallback, useEffect, useRef, useState} from "react";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import * as actions from "../../../../store/parsers/audience-search/superstars-locator/actions";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import BasicFileUpload from "../../../ui/upload/BasicFileUpload";
import MinMaxInput from "../../../ui/inputs/MinMaxInput";
import BasicNumbersInput, {NumberChangeEvent} from "../../../ui/inputs/BasicNumbersInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import {Button} from "primereact/button";
import selectFiltered
    from "../../../../store/parsers/audience-search/superstars-locator/selectors/FilteredSelector";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {csv_generator, prepare_string} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import {showAlert} from "../../../../store/app/actions";

const SuperstarsLocator = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state: SuperstarsLocatorState = useSelector((state: RootState) => state.superstarsLocator);
    const progress: TaskProgress = useSelector((state: RootState) => state.superstarsLocator.progress);
    const result: SuperstarItem[] = useSelector(selectFiltered);

    const saveCsvRef = useRef<HTMLAnchorElement>(null);
    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadCsvUrl, setDownloadCsvUrl] = useState<string>('');
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const [parsingName, setParsingName] = useState<string>('');
    const [selection, setSelection] = useState<SuperstarItem[]>([]);

    useEffect(() => {
        setSelection(result);
    }, [result]);

    const onDatasourceTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setDatasourceType(e.value)), [dispatch]);
    const onUrlChange = useCallback((e: FormEvent<HTMLInputElement>) => dispatch(actions.setDatasourceGroupUrl(e.currentTarget.value)), [dispatch]);
    const onUpload = useCallback((lines: string[]) => dispatch(actions.setDatasourceUserIds(lines.join('\n'))), [dispatch]);
    const onSearchTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setSearchType(e.value)), [dispatch]);
    const onPopularityTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setPopularityType(e.value)), [dispatch]);
    const onFriendsMinChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setMinFriends(parseInt(e.value))), [dispatch]);
    const onFriendsMaxChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setMaxFriends(parseInt(e.value))), [dispatch]);
    const onUsersToShowChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setUsersToShowCount(parseInt(e.value))), [dispatch]);



    const save = () => {
        if (selection.length === 0) {
            dispatch(showAlert({text: 'Вы не выделили результаты, которые нужно сохранить', header: 'Ошибка'}));
            return;
        }
        const lines: string[] = [];
        selection.forEach((item: SuperstarItem) => {
            lines.push(item.user_id.toString());
        });
        setDownloadUrl(download_url(lines.join('\r\n'), 'text/plain'));
        setTimeout(() => {
            if (saveRef && saveRef.current) {
                saveRef.current.click()
            }
        }, 1000);
    };

    const saveCSV = () => {
        if (selection.length === 0) {
            dispatch(showAlert({text: 'Вы не выделили результаты, которые нужно сохранить', header: 'Ошибка'}));
            return;
        }
        const headers = ['Имя', 'Ссылка', 'Подписчики', 'Всего', 'Соотношение', 'Smart'];
        const generator = (item: SuperstarItem) => {
            const name: string = item.profile.first_name + ' ' + item.profile.last_name;
            const url: string = 'https://vk.com/id' + item.user_id;
            const line: string[] = [
                prepare_string(name),
                url,
                item.counts.total.toFixed(2).replace('.', ','),
                item.total.toFixed(2).replace('.', ','),
                item.percent.toFixed(2).replace('.', ','),
                item.smart.toFixed(3).replace('.', ','),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(selection, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>

            <ParsingHelp title={props.title} url="http://blog.xn--90aha1bhc1b.xn--p1ai/populyarnyye_lyudi" description="Найдите людей, которые популярны среди Вашей аудитории." />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Как искать">
                    <BasicRadioButton id="searchByUrl" name="searchByRadio" label="По ссылке на группу" value={DataSourceType.URL} onChange={onDatasourceTypeChange} checked={state.settings.data_source_type === DataSourceType.URL} />
                    <BasicRadioButton id="searchByFile" name="searchByRadio" label="По файлу со списком id пользователей" value={DataSourceType.FILE} onChange={onDatasourceTypeChange} checked={state.settings.data_source_type === DataSourceType.FILE} />
                </InputContainer>

                {state.settings.data_source_type === DataSourceType.URL &&
                <InputContainer label="Ссылка на сообщество">
                    <BasicTextInput id="groupUrlInput" placeholder="https://vk.com/cerebro_vk" value={state.settings.data_source_url} onChange={onUrlChange}/>
                </InputContainer>
                }
                {state.settings.data_source_type === DataSourceType.FILE &&
                <InputContainer label="Загрузка из файла">
                    <BasicFileUpload id="upload" chooseLabel="Загрузить id из файла" onUpload={onUpload}/>
                </InputContainer>
                }

                <InputContainer label="Вид поиска">
                    <BasicRadioButton id="searchTypeInsideGroup" name="searchType" label="Искать людей только внутри группы" value={SearchType.SEARCH_INSIDE_GROUP} onChange={onSearchTypeChange} checked={state.settings.search_type === SearchType.SEARCH_INSIDE_GROUP} />
                    <BasicRadioButton id="searchEveryone" name="searchType" label="Искать любых людей" value={SearchType.SEARCH_EVERYONE} onChange={onSearchTypeChange} checked={state.settings.search_type === SearchType.SEARCH_EVERYONE} />
                </InputContainer>

                <InputContainer label="Тип популярности">
                    <BasicRadioButton id="popularityTypeFriends" name="popularityType" label="Популярные по количеству друзей" value={PopularityType.POPULAR_BY_FRIENDS} onChange={onPopularityTypeChange} checked={state.settings.popularity_type === PopularityType.POPULAR_BY_FRIENDS} />
                    <BasicRadioButton id="popularityTypeSubscribers" name="popularityType" label="Популярные по количеству подписчиков" value={PopularityType.POPULAR_BY_SUBSCRIBERS} onChange={onPopularityTypeChange} checked={state.settings.popularity_type === PopularityType.POPULAR_BY_SUBSCRIBERS} />
                    <BasicRadioButton id="popularityTypeAny" name="popularityType" label="Популярные по количеству подписчиков и друзей" value={PopularityType.POPULAR_BY_ANY} onChange={onPopularityTypeChange} checked={state.settings.popularity_type === PopularityType.POPULAR_BY_ANY} />
                </InputContainer>

                <MinMaxInput
                    min={state.settings.min_friends} onMinChange={onFriendsMinChange}
                    max={state.settings.max_friends} onMaxChange={onFriendsMaxChange}
                    id="friends" label="Отфильтровать список по количеству друзей/подписчиков" />

                <InputContainer label="Сколько людей показывать">
                    <BasicNumbersInput id="usersToShow" value={state.settings.users_to_show_count} onNumberChange={onUsersToShowChange} />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить в CSV" onClick={() => saveCSV()} />
                        <SaveButton label="Сохранить ссылки" onClick={() => save()} />
                    </>
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.csv' : 'result.csv'} ref={saveCsvRef} href={downloadCsvUrl} style={{display: 'none'}}>Скачать CSV</a>
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Скачать ссылки</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            {result.length > 0 &&
            <div className="tw-mt-4">
                <DataTable value={result} rowHover={true} paginator={true} rows={100} selection={selection} onSelectionChange={(e) => setSelection(e.value)}>
                    <Column selectionMode="multiple" style={{width:'20px'}}/>
                    <Column field="profile.id" header="Имя" body={(item: SuperstarItem, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/club${item.profile.id}`} target="_blank" rel="noopener noreferrer">{item.profile.first_name} {item.profile.last_name}</a></div>} />
                    <Column field="counts.total"  header="Пользователи" sortable />
                    <Column field="total"  header="Всего" sortable />
                    <Column field="percent" header="Соотношение" body={(item: SuperstarItem) => item.percent.toFixed(2)}  sortable />
                    <Column field="smart" header="Смарт" body={(item: SuperstarItem) => item.smart.toFixed(3)}  sortable />
                </DataTable>
            </div>
            }

        </AppPanel>
    );
};

export default SuperstarsLocator;
