import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import _chunk from "lodash/chunk";
import {GroupCount} from "../types";
import {TaskProgress} from "../../../types";
import GetGroupsOfEveryUserId from "../../../../../vkapi/tasks/GetGroupsOfEveryUserId";
import ProgressGenerator from "../../../../helpers/ProgressGenerator";
import {UserIdAnyIds} from "../../../../../vkapi/objects/UserIdAnyIds";
import {VkApiError} from "../../../../../vkapi/VkApiError";

const GetGroupCounts = function* (
    user_ids: number[],
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
): Generator<any, Map<number, GroupCount>, any> {
    const group_counts = new Map<number, GroupCount>();

    const users_per_chunk: number = 150;
    const chunks:number[][] = _chunk(user_ids, users_per_chunk);

    let progress = ProgressGenerator({text: 'Получаем группы пользователей', total: chunks.length, action: progress_action});

    for (const chunk of chunks) {

        yield progress.next().value;

        const users_with_groups: UserIdAnyIds[] = yield GetGroupsOfEveryUserId({user_ids: chunk, access_token: access_token, add_api_errors_action: add_api_errors_action});
        users_with_groups.forEach((item: UserIdAnyIds) => {
            item.ids.forEach((group_id: number) => {
                const group_count: GroupCount|undefined = group_counts.get(group_id);
                if (group_count) {
                    group_count.count++;
                    group_counts.set(group_id, group_count);
                    return;
                }
                group_counts.set(group_id, {group_id: group_id, count: 1});
            });
        });
    }
    return group_counts;
};

export default GetGroupCounts;
