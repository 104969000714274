import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {City, Country} from "../../store/parsers/types";
import CityInput from "../ui/inputs/CityInput";

function useCity(country: Country|null, cities: City[], setCities: ActionCreatorWithPayload<City[]>) {
    const dispatch = useDispatch();

    const setSelection = useCallback((cities: City[]) => {
        dispatch(setCities(cities));
    }, [dispatch, setCities]);

    const onRemoveCity = useCallback((city: City) => {
        const new_cities: City[] = cities.filter((item: City) => item.id !== city.id);
        dispatch(setCities(new_cities));
    }, [dispatch, cities, setCities]);

    const onClear = useCallback(() => {
        dispatch(setCities([]))
    }, [dispatch, setCities]);

    const component = (
        <CityInput
            selectedCountry={country}
            selectedCities={cities}
            setSelection={setSelection}
            onRemoveCity={onRemoveCity}
            onClear={onClear}
        />
    );

    return component;
}

export default useCity;
