import {PayloadAction} from "@reduxjs/toolkit";
import _cloneDeep from "lodash/cloneDeep";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import tokenize from "../../../../helpers/tokenizer";
import {isTextContainsAnyWord} from "../../../../helpers/words-checker";
import {Comment} from "../../../../vkapi/objects/Comment";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {OwnerPosts, Post} from "../../../../vkapi/objects/Post";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {PostCommentsLikes} from "../../../../vkapi/tasks/activities/GetCommentsLikes";
import {convert_urls_to_id} from "../../../../vkapi/tasks/ConverUrlToId";
import GetActivities from "../../../../vkapi/tasks/GetActivities";
import {PostComments} from "../../../../vkapi/tasks/GetComments";
import GetGroups from "../../../../vkapi/tasks/GetGroups";
import GetPosts from "../../../../vkapi/tasks/GetPosts";
import GetUsers from "../../../../vkapi/tasks/GetUsers";
import {showAlert, showAlertError} from "../../../app/actions";
import {date_in_range, is_post_before_start_date} from "../../../helpers/DateChecker";
import date_to_timestamp from "../../../helpers/DateToTimestamp";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import {ActivityType, RootState, TaskStatus, TopComment, UserComments} from "../../types";
import {EMPTY_URL_GROUP_LIST} from "../../validation-errors";
import * as action_types from './action-types';
import * as actions from "./actions";
import {FilterLikes, TopCommentatorsCommunityState} from "./types";

const main = function* () {
    while (yield take(action_types.START)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: TopCommentatorsCommunityState = yield select((state: RootState) => state.topCommentatorsCommunity);

    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_urls: string[] = convert_urls_to_id(state.settings.data_source.split('\n'));
    const groups: GroupObject[] = yield GetGroups({
        access_token: access_token,
        group_ids: group_urls,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    let filter_likes_users: Set<number> = new Set<number>();
    if (state.settings.filter_likes === FilterLikes.SELECTED_USERS) {
        filter_likes_users = yield getFilterLikesUsers(state.settings.filter_likes_users, access_token);
        if (filter_likes_users.size === 0) {
            throw new Error('Не найдены пользователи от которых учитывать лайки');
        }
    }

    const is_date_in_range = date_in_range(state.settings.start_date, state.settings.end_date);
    const is_posts_start_date_reached = is_post_before_start_date(state.settings.start_date);
    const start_timestamp: number|null = date_to_timestamp(state.settings.start_date);
    const end_timestamp: number|null = date_to_timestamp(state.settings.end_date);

    const tokens_plus: string[] = tokenize(state.settings.words_plus.replace(",", " "));
    const tokens_minus: string[] = tokenize(state.settings.words_minus.replace(",", " "));

    const user_comments: Map<number, UserComments> = new Map<number, UserComments>();

    const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});
    for (const group of groups) {
        yield progress.next().value;

        yield put(actions.setSubProgress({message: 'Получаем посты'}));

        const posts: OwnerPosts = yield GetPosts({
            owner_id: group.id * -1,
            is_skip: (post: Post) => !is_date_in_range(post.date),
            is_stop: (post: Post) => is_posts_start_date_reached(post),
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
        });
        yield put(actions.setSubProgress({message: 'Получаем комментарии'}));
        const group_comments: Map<number, Comment> = new Map<number, Comment>();

        const comments_handler = (post_comments: PostComments[]) => {
            let cloned_post_comments: PostComments[] = _cloneDeep(post_comments);
            cloned_post_comments.forEach((item: PostComments) => {
                if (state.settings.is_skip_first_post && item.comments.length > 0) {
                    item.comments = item.comments.sort((a, b) => a.date - b.date);
                    item.comments.shift();
                }
                item.comments.forEach((comment: Comment) => {
                    if (comment.deleted) {
                        return;
                    }
                    if (state.settings.comments_length_min > 0 && comment.text.length < state.settings.comments_length_min) {
                        return;
                    }
                    if (state.settings.comments_length_max > 0 && comment.text.length > state.settings.comments_length_max) {
                        return;
                    }
                    if (comment.from_id < 0) {
                        return;
                    }
                    if (start_timestamp && comment.date < start_timestamp) {
                        return;
                    }
                    if (end_timestamp && comment.date > end_timestamp) {
                        return;
                    }
                    if (tokens_plus.length > 0 && !isTextContainsAnyWord(comment.text, tokens_plus)) {
                        return;
                    }
                    if (tokens_minus.length > 0 && isTextContainsAnyWord(comment.text, tokens_minus)) {
                        return;
                    }
                    if (state.settings.filter_likes === FilterLikes.SELECTED_USERS) {
                        comment.likes = {
                            count: 0,
                        }
                    }
                    comment.owner_id = item.owner_id;
                    comment.post_id = item.post_id;
                    group_comments.set(comment.id, comment);
                });
            });
        };

        const comments_likes_handler = (post_comments_likes: PostCommentsLikes[]) => {
            post_comments_likes.forEach((item: PostCommentsLikes) => {
                let comment: Comment|undefined = group_comments.get(item.comment_id);
                if (comment) {
                    item.likes.forEach(user_id => {
                        if (filter_likes_users.has(user_id)) {
                            if (comment?.likes) {
                                comment.likes.count++;
                            }
                        }
                    });
                    group_comments.set(item.comment_id, comment);
                }
            });
        };

        const activity_types: Set<ActivityType> = new Set<ActivityType>();
        activity_types.add(ActivityType.COMMENTS);
        if (state.settings.filter_likes === FilterLikes.SELECTED_USERS) {
            activity_types.add(ActivityType.COMMENT_LIKES);
        }
        yield GetActivities({
            posts: posts.posts,
            activity_types: activity_types,
            comments_preview_length: 0,
            access_token: access_token,
            progress_action: actions.setSubProgress,
            add_api_errors_action: actions.addApiError,

            comments_handler: comments_handler,
            comments_likes_handler: comments_likes_handler,
        });

        group_comments.forEach((value: Comment) => {
            const user_comments_item: UserComments = user_comments.get(value.from_id) ||
                {
                    comments: [],
                };
            if (state.settings.filter_likes === FilterLikes.SELECTED_USERS && (value.likes?.count || 0) === 0) {
                return;
            }
            user_comments_item.comments?.push(value);
            user_comments.set(value.from_id, user_comments_item);
        });
    }

    let users: Map<number, UserObject> = yield GetUsers({
        user_ids: Array.from(user_comments.keys()),
        access_token: access_token,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    users.forEach((value: UserObject, user_id: number) => {
        const user_comments_item: UserComments|undefined = user_comments.get(user_id);
        if (user_comments_item) {
            user_comments_item.user = value;
        }
    });

    const top_comments: TopComment[] = calculate_result(user_comments);

    yield put(actions.setSubProgress({message: '', total: undefined, current: undefined}));
    yield put(actions.setProgress({message: `Завершено`, total: undefined, current: undefined}));
    yield put(showAlert({text: `Готово.`, header: 'Парсинг завершен'}));
    yield put(actions.setResult(top_comments));

    yield put({ type: action_types.COMPLETED});
}

function calculate_result(user_comments: Map<number, UserComments>): TopComment[] {
    let total_likes: number = 0;
    const top_comments: TopComment[] = [];
    user_comments.forEach((value: UserComments, user_id: number) => {
        if (!value.user || value.user.deactivated) {
            return;
        }
        const top_comment: TopComment = {
            user_comments: value,
            proportion: 0,
            smart: 0,
            total_comments: 0,
            total_likes: 0,
            user_id: user_id,
        };
        value.comments?.forEach((comment: Comment) => {
            total_likes += comment.likes?.count || 0;
            top_comment.total_likes += comment.likes?.count || 0;
        });
        top_comments.push(top_comment);
    });
    return top_comments.map((item: TopComment) => {
        const total_comments: number = item.user_comments.comments?.length || 0;
        if (total_comments > 0) {
            item.total_comments = total_comments;
            item.proportion = item.total_likes / total_comments;
        }
        if (total_likes > 0 && total_comments > 0) {
            item.smart = item.total_likes / total_likes * total_comments;
        }
        return item;
    }).sort((a, b) => b.total_likes - a.total_likes);
}

function* validate(state: TopCommentatorsCommunityState) {
    if (state.settings.data_source.trim() === '') {
        yield put(showAlertError(EMPTY_URL_GROUP_LIST));
        yield put(actions.stop());
        return;
    }
    if (state.settings.comments_length_min > state.settings.comments_length_max && state.settings.comments_length_max > 0) {
        let err: string = 'Минимальная длина комментария не может быть больше, чем максимальная';
        yield put(showAlertError(err));
        yield put(actions.stop());
        return;
    }
    if (state.settings.start_date && state.settings.end_date && state.settings.start_date.getTime() > state.settings.end_date.getTime()) {
        let err: string = 'Начальная дата не может быть больше, чем конечная дата';
        yield put(showAlertError(err));
        yield put(actions.stop());
        return;
    }
}

function* getFilterLikesUsers(source: string, access_token: string): Generator<any, Set<number>, any> {
    const user_urls: string[] = convert_urls_to_id(source.split('\n'));
    let get_users_result: Map<number, UserObject> = yield GetUsers({
        user_ids: user_urls,
        access_token: access_token,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });
    return new Set<number>(Array.from(get_users_result.keys()));
}

function* onStop(task) {
    yield take(action_types.STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    console.log(error);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
