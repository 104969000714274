import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useEffect,  useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator} from "../../../../../helpers/csv_generator";
import download_url from "../../../../../helpers/download_url";
import download_txt from "../../../../../helpers/DownloadTxt";
import strip_punctuation from "../../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../../store/app/actions";
import {activity as actions} from "../../../../../store/parsers/communities/communities-filter/actions";
import {
    ActivityFilterResult,
} from "../../../../../store/parsers/communities/communities-filter/types";
import {RootState, TaskProgress, TaskStatus} from "../../../../../store/parsers/types";
import useDateRange from "../../../../hooks/DateRangeHook";
import useSaveWithCsv from "../../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../../ui/info/AppProgressBar";
import BasicCheckbox from "../../../../ui/inputs/BasicCheckbox";
import DateRangeInput from "../../../../ui/inputs/DateRangeInput";
import ParsingName from "../../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../../ui/inputs/TextAreaWithButton";

const FilterByActivity = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.communitiesFilter.activity);
    const progress: TaskProgress = useSelector((state: RootState) => state.communitiesFilter.activity.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.communitiesFilter.activity.sub_progress);
    const result: ActivityFilterResult[] = useSelector((state: RootState) => state.communitiesFilter.activity.result);


    const [parsingName, setParsingName] = useState<string>('');

    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);

    const [selection, setSelection] = useState<ActivityFilterResult[]>([]);
    useEffect(() => {
        setSelection(result);
    }, [result]);

    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);

    const save = () => {
        download_txt<ActivityFilterResult[]>({
            data: selection,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: ActivityFilterResult[]) => data.map(item => `https://vk.com/${item.group.screen_name}`),
        });
    };

    const saveCSV = () => {

        if (result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = [
            '"Название"',
            '"Ссылка"',
            '"Подписчики"',
            '"Посты"',
            '"Лайки"',
            '"Репосты"',
            '"Комментарии"',
            '"Активность"',
            '"Вовлеченность"',
        ];

        const generator = (item: ActivityFilterResult) => {
            const line: string[] = [
                '"' + strip_punctuation(item.group.name || '') + '"',
                `"https://vk.com/${item.group.screen_name}"`,
                item.group.members_count ? item.group.members_count.toString() : "0",
                item.posts_count.toString(),
                item.likes.toString(),
                item.reposts.toString(),
                item.comments.toString(),
                item.activity.toFixed(2).replace(".", ","),
                item.engagement_rate.toFixed(2).replace(".", ","),
            ];
            return line.join(';');
        };

        const csv: string|null = csv_generator(result, headers, generator);
        if (!csv) {
            return;
        }

        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    return (
        <div>
            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>
                <TextAreaWithButton label="Список групп (по одной ссылке на строке)"
                                    value={state.settings.source}
                                    onChange={(value) => dispatch(actions.setSource(value))}
                />
                <BasicCheckbox label="Учитывать только активность подписчиков из сообщества"
                               checked={state.settings.only_members} onChange={(e) => dispatch(actions.setOnlyMembers(e.checked))}
                />
                <BasicCheckbox label="Учитывать только активность пользователей из файла"
                               checked={state.settings.only_selected_users} onChange={(e) => dispatch(actions.setOnlySelectedUsers(e.checked))}
                />
                {state.settings.only_selected_users &&
                    <TextAreaWithButton label="Список пользователей (по одной ссылке на строке)"
                                        value={state.settings.selected_users_source}
                                        onChange={(value) => dispatch(actions.setSelectedUsersSource(value))}
                    />
                }
                <InputContainer label="Дата (указывать не обязательно)">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>
                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)} />
                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить ссылки" onClick={() => save()} />
                        <SaveButton label="Сохранить CSV" onClick={() => saveCSV()} />
                    </>
                    }
                    {anchors}
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>

            {state.status !== TaskStatus.RUNNING && result.length > 0 &&
                <div className="tw-mt-4">
                    <DataTable value={state.result} autoLayout={true} rowHover={true} paginator={true} rows={100} selection={selection} onSelectionChange={(e) => setSelection(e.value)}>
                        <Column selectionMode="multiple" style={{width:'20px'}}/>
                        <Column header="Группа" field="group.id" body={(item: ActivityFilterResult, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/${item.group.screen_name}`} target="_blank" rel="noopener noreferrer">{item.group.name}</a></div>} sortable />
                        <Column header="Лайки"  field="likes" sortable/>
                        <Column header="Комментарии"  field="comments" sortable/>
                        <Column header="Репосты"  field="reposts" sortable/>
                        <Column header="Активность"  field="activity" body={(item: ActivityFilterResult) => item.activity.toFixed(2)} sortable/>
                        <Column header="Вовлеченность"  field="engagement_rate" body={(item: ActivityFilterResult) => item.engagement_rate.toFixed(2)} sortable/>
                    </DataTable>
                </div>
            }

        </div>
    )
};

export default FilterByActivity;
