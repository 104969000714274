import {Button} from "primereact/button";
import React from "react";

export interface SaveButtonProps {
    label: string,
    onClick: () => void,
    disabled?: boolean,
    className?: string,
}

const classNames = require('classnames');

const SaveButton = (props: SaveButtonProps) => {
    return (
        <Button label={props.label} onClick={props.onClick} disabled={props.disabled} className={classNames("p-button-success", "p-field", props.className)} icon="pi pi-download" iconPos="right"/>
    )
};

export default SaveButton;
