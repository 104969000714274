export default function get_lines_from_file(files: FileList, onUpload: (unique: string[]) => void) {
    const file = files[0];
    const file_reader: FileReader = new FileReader();
    file_reader.onload = (e:ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
            const contents: string = (e.target.result as string);
            const lines: string[] = contents
                .split('\n')
                .map(line => line.trim())
                .filter((line: string) => {
                    line = line.trim();
                    return line !== '';

                });
            const unique: string[] = Array.from(new Set(lines).values());
            onUpload(unique);
        }
    };
    file_reader.readAsText(file, "UTF-8");
}

export function get_lines_from_multiple_files(files: FileList, onUpload: (unique: string[]) => void) {
    const promises: Promise<string[]>[] = [];
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let promise: Promise<string[]> = new Promise<string[]>((resolve, reject) => {
            let file_reader: FileReader = new FileReader();
            file_reader.onload = (e:ProgressEvent<FileReader>) => {
                if (!e.target || !e.target.result) {
                    reject(new Error('failed to read file'));
                    return;
                }
                const contents: string = (e.target.result as string);
                const lines: string[] = contents
                    .split('\n')
                    .map(line => line.trim())
                    .filter((line: string) => {
                        line = line.trim();
                        return line !== '';

                    });
                const unique: string[] = Array.from(new Set(lines).values());
                resolve(unique);
            };
            file_reader.readAsText(file, "UTF-8");
        });
        promises.push(promise);
    }


    const unique_result_set: Set<string> = new Set<string>();
    Promise.all(promises).then((result: string[][]) => {
        result.forEach((item: string[]) => {
            item.forEach((str: string) => {
                unique_result_set.add(str);
            });
        });
        onUpload(Array.from(unique_result_set));
    });

}
