import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {put} from "redux-saga/effects";
import {TaskProgress} from "../parsers/types";

export interface ProgressGeneratorParams {
    total: number,
    text: string,
    action?: ActionCreatorWithPayload<TaskProgress>
}

const ProgressGenerator = function* (params: ProgressGeneratorParams) {
    for (let current = 0; current < params.total; current++) {
        const progress: string = (current / params.total * 100).toFixed(2);
        if (params.action) {
            let data = {message: `${params.text}: ${progress}%`, current: current, total: params.total};
            yield put(params.action(data));
        }
    }
    return;
};

export default ProgressGenerator;
