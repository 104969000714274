import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import download_txt from "../../../../helpers/DownloadTxt";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../store/app/actions";
import * as actions from "../../../../store/parsers/communities-audience/top-commentators/actions";
import {
    FilterLikes,
    TopCommentatorsCommunityState
} from "../../../../store/parsers/communities-audience/top-commentators/types";
import {RootState, TaskProgress, TaskStatus, TopComment} from "../../../../store/parsers/types";
import {Comment} from "../../../../vkapi/objects/Comment";
import useDateRange from "../../../hooks/DateRangeHook";
import useMinMax from "../../../hooks/MinMaxHook";
import useSaveWithCsv from "../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicCheckbox, {CheckBoxChangeEvent} from "../../../ui/inputs/BasicCheckbox";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import DateRangeInput from "../../../ui/inputs/DateRangeInput";
import MinMaxInput from "../../../ui/inputs/MinMaxInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import selectFiltered from "../../../../store/parsers/communities-audience/top-commentators/selectors/FilteredSelector";

const TopCommentatorsCommunity = (props: AppPanelProps) => {

    const moment = require('moment');

    const dispatch = useDispatch();
    const state: TopCommentatorsCommunityState = useSelector((state: RootState) => state.topCommentatorsCommunity);
    const progress: TaskProgress = useSelector((state: RootState) => state.topCommentatorsCommunity.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.topCommentatorsCommunity.sub_progress);

    const [parsingName, setParsingName] = useState<string>('');

    const result: TopComment[] = useSelector(selectFiltered);
    const [selection, setSelection] = useState<TopComment[]>([]);
    useEffect(() => {
        setSelection(result);
    }, [result]);

    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);

    const [expandedRows, setExpandedRows] = useState<any[]>([]);

    const onGroupsSourceChange = useCallback((value: string) => dispatch(actions.setDataSource(value)), [dispatch]);
    const onUsersSourceChange = useCallback((value: string) => dispatch(actions.setFilterLikesUsers(value)), [dispatch]);
    const onFilterLikesChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setFilterLikes(e.value)), [dispatch]);
    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);
    const onSkipFirstPostChange = useCallback((e: CheckBoxChangeEvent) => dispatch(actions.setIsSkipFirstPost(e.checked)), [dispatch])
    const [onLikesMinChange, onLikesMaxChange] = useMinMax(actions.setLikesMin, actions.setLikesMax);
    const [onCommentsMinChange, onCommentsMaxChange] = useMinMax(actions.setCommentsMin, actions.setCommentsMax);
    const [onCommentsLengthMinChange, onCommentsLengthMaxChange] = useMinMax(actions.setCommentsLengthMin, actions.setCommentsLengthMax);

    const save = () => {
        if (selection.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        download_txt<TopComment[]>({
            data: selection,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: TopComment[]) => data.map(item => item.user_id.toString()),
        });
    };

    const saveCSV = () => {
        if (state.result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = ['"Id"', '"Имя"', '"Количество комментариев"', '"Ссылка на комментарий"', '"Лайки"', '"Длина"', '"Текст"', '"Дата"'];
        const generator = (item: TopComment) => {
            let lines: string[] = [];
            if (item.user_comments.user && item.user_comments.comments) {
                item.user_comments.comments.forEach((comment: Comment) => {
                    const line = [
                        item.user_id,
                        '"' + item.user_comments.user?.first_name + ' ' +  item.user_comments.user?.last_name + '"',
                        item.total_comments,
                        `"https://vk.com/wall${comment.owner_id}_${comment.post_id}?reply=${comment.id}"`,
                        comment.likes?.count || 0,
                        comment.text.length,
                        '"' + strip_punctuation(comment.text) + '"',
                        moment(comment.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm'),
                    ];
                    lines.push(line.join(';'));
                });
            }
            return lines.join('\n');
        };
        const csv: string|null = csv_generator(state.result, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    const CommentsExpandedComponent = (data) => {
        let comments: Comment[] = [];
        if ((data as unknown as TopComment).user_comments.comments) {
            comments = JSON.parse(JSON.stringify((data as unknown as TopComment).user_comments.comments));
        }
        comments = comments.sort((a, b) => (b.likes?.count || 0) - (a.likes?.count || 0)).slice(0, 10);
        return (
            <div>
                    {comments.map((item: Comment) => {
                        return (
                            <div key={item.id} className="tw-border-b tw-border-gray-500 tw-py-4">
                                <div className="tw-flex tw-space-x-5" key={item.id}>
                                    <div className="w-full">
                                        <a href={'https://vk.com/wall' + item.owner_id + '_' + item.post_id + '?reply=' + item.id} target="_blank" rel="noopener noreferrer">{item.text}</a>
                                    </div>
                                    <div className="w-auto">
                                        <span className="p-badge">{item.likes?.count ?? 0}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
            </div>
        )
    };

    return (
        <AppPanel id={props.id} title={props.title}>

            <ParsingHelp
                description="Соберите самые популярные комментарии и их авторов в выбранных сообществах."
                url="http://blog.xn--90aha1bhc1b.xn--p1ai/top_kommentatory"
                title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Список групп (по одной на строке)'} value={state.settings.data_source}
                                    onChange={onGroupsSourceChange} buttonId={'uploadButton'}
                />

                <InputContainer label="Учитывать лайки">
                    <BasicRadioButton id="filterAllUsers" name="filterLikes" label="всех пользователей"
                                      value={FilterLikes.ALL_USERS}
                                      onChange={onFilterLikesChange}
                                      checked={state.settings.filter_likes === FilterLikes.ALL_USERS}
                    />
                    <BasicRadioButton id="filterSelectedUsers" name="filterLikes" label="выбранных пользователей"
                                      value={FilterLikes.SELECTED_USERS}
                                      onChange={onFilterLikesChange}
                                      checked={state.settings.filter_likes === FilterLikes.SELECTED_USERS}
                    />
                </InputContainer>
                {state.settings.filter_likes === FilterLikes.SELECTED_USERS &&
                    <TextAreaWithButton label={'Список пользователей (по одному на строке))'} value={state.settings.filter_likes_users}
                                        onChange={onUsersSourceChange} buttonId={'uploadUsersButton'}
                    />
                }

                <InputContainer label="Дата">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <InputContainer label="Пропускать первый комментарий к посту">
                    <BasicCheckbox id="skipFirstPost" label="Пропускать первый комментарий к посту"
                                   checked={state.settings.is_skip_first_post}
                                   onChange={onSkipFirstPostChange}
                    />
                </InputContainer>

                <MinMaxInput label="Количество лайков" id="likesMinMax"
                             min={state.settings.likes_min} onMinChange={onLikesMinChange}
                             max={state.settings.likes_max} onMaxChange={onLikesMaxChange}
                />

                <MinMaxInput label="Количество комментариев" id="commentsMinMax"
                             min={state.settings.comments_min} onMinChange={onCommentsMinChange}
                             max={state.settings.comments_max} onMaxChange={onCommentsMaxChange}
                />

                <MinMaxInput label="Длина комментариев" id="commentsLengthMinMax"
                             min={state.settings.comments_length_min} onMinChange={onCommentsLengthMinChange}
                             max={state.settings.comments_length_max} onMaxChange={onCommentsLengthMaxChange}
                />

                <InputContainer label="Фильтр по слову (через пробел)">
                    <BasicTextInput
                        value={state.settings.words_plus}
                        onChange={e => dispatch(actions.setWordsPlus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которые должны быть в комментарии'}
                    />
                </InputContainer>

                <InputContainer label="Минус слова (через пробел)">
                    <BasicTextInput
                        value={state.settings.words_minus}
                        onChange={e => dispatch(actions.setWordsMinus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которых не должно быть в комментарии'}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)} />

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field" />
                    {state.result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить id в txt файл" onClick={() => save()} />
                        <SaveButton label="Сохранить в CSV" onClick={() => saveCSV()} />
                    </>
                    }
                    {anchors}
                </ButtonsContainer>

            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>
            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            {state.result.length > 0 && state.status !== TaskStatus.RUNNING &&
                <p>Результатов: {result.length}</p>
            }

            {result.length > 0 &&
            <div className="tw-mt-4">
                <DataTable value={result} rowHover={true} paginator={true} rows={100} selection={selection}
                           onSelectionChange={(e) => setSelection(e.value)}
                           expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                           rowExpansionTemplate={CommentsExpandedComponent}
                >
                    <Column selectionMode="multiple" style={{width:'20px'}}/>
                    <Column expander style={{ width: '3em' }} />
                    <Column field="user_comments.user.first_name" header="Имя" body={(item: TopComment, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/club${item.user_id}`} target="_blank" rel="noopener noreferrer">{item.user_comments.user?.first_name} {item.user_comments.user?.last_name}</a></div>} sortable />
                    <Column field="total_likes"  header="Лайки" sortable />
                    <Column field="total_comments"  header="Комментарии" sortable />
                    <Column field="proportion" header="В среднем" body={(item: TopComment) => item.proportion.toFixed(2)} sortable />
                    <Column field="smart" header="Смарт" body={(item: TopComment) => item.smart.toFixed(3)} sortable />
                </DataTable>
            </div>
            }

        </AppPanel>
    );
};

export default TopCommentatorsCommunity;
