import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {Country} from "../../store/parsers/types";
import CountryInput from "../ui/inputs/CountryInput";

function useCountry(country: Country|null, setCountry: ActionCreatorWithPayload<Country|null>) {
    const dispatch = useDispatch();

    const onCountryClear = useCallback(() => {
        dispatch(setCountry(null));
    }, [setCountry, dispatch]);

    const onCountrySelect = useCallback((item: Country) => {
        dispatch(setCountry(item))
    }, [setCountry, dispatch]);

    const component = (
        <CountryInput
            selectedCountry={country}
            onClear={onCountryClear}
            onSelect={onCountrySelect}
        />
    );

    return component;
}

export default useCountry;
