import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import * as types from "./action-types";
import {UserScore} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSource = createAction(types.SET_SOURCE, withPayloadType<string>());
export const setStartDate = createAction(types.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.SET_END_DATE, withPayloadType<Date|null>());
export const setScoreLikeEnabled = createAction(types.SET_SCORE_LIKE_ENABLED, withPayloadType<boolean>());
export const setScoreLike = createAction(types.SET_SCORE_LIKE, withPayloadType<number>());
export const setScoreLikeAllEnabled = createAction(types.SET_SCORE_LIKE_ALL_ENABLED, withPayloadType<boolean>());
export const setScoreLikeAll = createAction(types.SET_SCORE_LIKE_ALL, withPayloadType<number>());
export const setScoreLikeFromAuthorEnabled = createAction(types.SET_SCORE_LIKE_FROM_AUTHOR_ENABLED, withPayloadType<boolean>());
export const setScoreLikeFromAuthor = createAction(types.SET_SCORE_LIKE_FROM_AUTHOR, withPayloadType<number>());
export const setScoreLikeCommentEnabled = createAction(types.SET_SCORE_LIKE_COMMENT_ENABLED, withPayloadType<boolean>());
export const setScoreLikeComment = createAction(types.SET_SCORE_LIKE_COMMENT, withPayloadType<number>());
export const setScoreCommentEnabled = createAction(types.SET_SCORE_COMMENT_ENABLED, withPayloadType<boolean>());
export const setScoreComment = createAction(types.SET_SCORE_COMMENT, withPayloadType<number>());
export const setCommentMinLength = createAction(types.SET_COMMENT_MIN_LENGTH, withPayloadType<number>());
export const setScoreBestCommentEnabled = createAction(types.SET_SCORE_BEST_COMMENT_ENABLED, withPayloadType<boolean>());
export const setScoreBestComment = createAction(types.SET_SCORE_BEST_COMMENT, withPayloadType<number>());
export const setScoreLikeOwnerEnabled = createAction(types.SET_SCORE_LIKE_OWNER_ENABLED, withPayloadType<boolean>());
export const setScoreLikeOwner = createAction(types.SET_SCORE_LIKE_OWNER, withPayloadType<number>());
export const setOwnerUrl = createAction(types.SET_OWNER_URL, withPayloadType<string>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<UserScore[]>(types.NAMESPACE);
