const NAMESPACE = 'TARGET_AUDIENCE_LOCATOR/';

export const SET_DATASOURCE_TYPE = NAMESPACE + 'SET_DATASOURCE_TYPE';
export const SET_DATASOURCE_URL = NAMESPACE + 'SET_DATASOURCE_URL';
export const SET_DATASOURCE_FILE = NAMESPACE + 'SET_DATASOURCE_FILE';
export const SET_MEMBERS_MIN = NAMESPACE + 'SET_MEMBERS_MIN';
export const SET_MEMBERS_MAX = NAMESPACE + 'SET_MEMBERS_MAX';
export const SET_TRUNCATE_TO_COUNT = NAMESPACE + 'SET_TRUNCATE_TO_COUNT';
export const SET_ANALYSIS_TYPE = NAMESPACE + 'SET_ANALYSIS_TYPE';
export const SET_TOP_MIN = NAMESPACE + 'SET_TOP_MIN';
export const SET_TOP_MAX = NAMESPACE + 'SET_TOP_MAX';
export const SET_SELECTOR_FILTER_MEMBERS_MIN = NAMESPACE + 'SET_SELECTOR_FILTER_MEMBERS_MIN';
export const SET_SELECTOR_FILTER_MEMBERS_MAX = NAMESPACE + 'SET_SELECTOR_FILTER_MEMBERS_MAX';
export const SET_SELECTOR_FILTER_WORDS_PLUS = NAMESPACE + 'SET_SELECTOR_FILTER_WORDS_PLUS';
export const SET_SELECTOR_FILTER_WORDS_MINUS = NAMESPACE + 'SET_SELECTOR_FILTER_WORDS_MINUS';
export const SET_SELECTOR_FILTER_COMMUNITY_TYPE_PUBLIC = NAMESPACE + 'SET_SELECTOR_FILTER_COMMUNITY_TYPE_PUBLIC';
export const SET_SELECTOR_FILTER_COMMUNITY_TYPE_CLUB = NAMESPACE + 'SET_SELECTOR_FILTER_COMMUNITY_TYPE_CLUB';
export const SET_SELECTOR_FILTER_COMMUNITY_TYPE_EVENT = NAMESPACE + 'SET_SELECTOR_FILTER_COMMUNITY_TYPE_EVENT';
export const SET_SELECTOR_FILTER_EVENT_TYPE = NAMESPACE + 'SET_SELECTOR_FILTER_EVENT_TYPE';
export const SET_SELECTOR_FILTER_SHOW_TYPE = NAMESPACE + 'SET_SELECTOR_FILTER_SHOW_TYPE';

export const START = NAMESPACE + 'START';
export const STOP = NAMESPACE + 'STOP';
export const SET_TASK_STATUS = NAMESPACE + 'SET_TASK_STATUS';
export const SET_TASK_ERROR = NAMESPACE + 'SET_TASK_ERROR';
export const SET_PROGRESS = NAMESPACE + 'SET_PROGRESS';
export const SET_RESULT = NAMESPACE + 'SET_RESULT';

export const ADD_API_ERROR = NAMESPACE + 'ADD_API_ERROR';
export const RESET_API_ERRORS = NAMESPACE + 'RESET_API_ERRORS';

export const COMPLETED = NAMESPACE + 'COMPLETED';
