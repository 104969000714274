export const NAMESPACE = 'SEARCH_BASE/';

export const SET_SOURCE_USERS = NAMESPACE + 'SET_SOURCE_USERS';
export const SET_SOURCE_NAMES = NAMESPACE + 'SET_SOURCE_NAMES';
export const SET_AGE_FROM = NAMESPACE + 'SET_AGE_FROM';
export const SET_AGE_TO = NAMESPACE + 'SET_AGE_TO';
export const SET_ONLY_HIDDEN_AGE = NAMESPACE + 'SET_ONLY_HIDDEN_AGE';
export const SET_ZODIAC_SIGN = NAMESPACE + 'SET_ZODIAC_SIGN';
export const SET_REMOVE_BLOCKED = NAMESPACE + 'SET_REMOVE_BLOCKED';
export const SET_KEEP_BLOCKED = NAMESPACE + 'SET_KEEP_BLOCKED';
export const SET_KEEP_VERIFIED = NAMESPACE + 'SET_KEEP_VERIFIED';
export const SET_GENDER = NAMESPACE + 'SET_GENDER';
export const SET_SELECTED_COUNTRY = NAMESPACE + 'SET_SELECTED_COUNTRY';
export const SET_CITIES = NAMESPACE + 'SET_CITIES';
export const ADD_FAMILY = NAMESPACE + 'ADD_FAMILY';
export const REMOVE_FAMILY = NAMESPACE + 'REMOVE_FAMILY';
export const SET_HAS_CHILDREN = NAMESPACE + 'SET_HAS_CHILDREN';
export const SET_LAST_ACTIVITY = NAMESPACE + 'SET_LAST_ACTIVITY';
export const SET_SOURCE_STATUS = NAMESPACE + 'SET_SOURCE_STATUS';
export const SET_STATUS_HAS_ALL_WORDS = NAMESPACE + 'SET_STATUS_HAS_ALL_WORDS';

export const SET_USERS = NAMESPACE + 'SET_USERS';
