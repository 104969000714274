import React, {ReactElement} from "react";
import {AppPanelProps} from "./AppPanel";

interface PanelContainerProps {
    activePanel: string,
    children: ReactElement<AppPanelProps>[]
}

const classNames = require("classnames");

function AppPanelContainer(props: PanelContainerProps): any {
    return (
        React.Children.map(props.children, (child: ReactElement<AppPanelProps>) => (
            <div className={classNames({'p-d-none': child.props.id !== props.activePanel})}>
                {child}
            </div>
        ))
    );
}

export default AppPanelContainer;
