import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import * as action_types from './action-types';
import * as actions from "./actions";
import {PopularityType, SuperstarItem, SuperstarsLocatorState, SearchType} from "./types";
import {DataSourceType, RootState, TaskStatus} from "../../types";
import {EMPTY_ID_FILE, EMPTY_URL_GROUP} from "../../validation-errors";
import GetFriendsUserCounts from "./tasks/GetFriendsUserCount";
import GetSubscriptionsUserCount from "./tasks/GetSubscriptionsUserCount";
import GetUsers from "../../../../vkapi/tasks/GetUsers";
import {CountersUser, UserObject} from "../../../../vkapi/objects/UserObject";
import GetCountersUser from "../../../../vkapi/tasks/GetCountersUser";
import {PayloadAction} from "@reduxjs/toolkit";
import {showAlert} from "../../../app/actions";
import GetUserIdsFromGroupOrFile from "../../../helpers/GetUserIdsFromGroupOrFile";

export interface UserFriendsCount {
    user_id: number,
    friends: number,
    followers: number,
    total: number,
}

const main = function* () {
    while (yield take(action_types.START)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {

    yield actions.resetApiErrors();

    const state: SuperstarsLocatorState = yield select((state: RootState) => state.superstarsLocator);
    yield validate(state);

    const access_token = yield select((state: RootState) => state.app.access_token);
    const search_type = state.settings.search_type;
    const popularity_type = state.settings.popularity_type;
    const users_to_show_count = state.settings.users_to_show_count;

    const users_array: number[] = yield yield GetUserIdsFromGroupOrFile(state.settings.data_source_type, state.settings.data_source_url, state.settings.data_source_file, access_token, actions.setProgress);
    const user_ids: Set<number> = new Set<number>(users_array);

    const filter_func = (user_id: number): boolean => {
        if (search_type === SearchType.SEARCH_INSIDE_GROUP) {
            if (!user_ids.has(user_id)) {
                return false;
            }
        }
        return true;
    };
    let user_counts: Map<number, UserFriendsCount> = new Map<number, UserFriendsCount>();
    if (popularity_type === PopularityType.POPULAR_BY_FRIENDS || popularity_type === PopularityType.POPULAR_BY_ANY) {
        user_counts = yield GetFriendsUserCounts({
            user_ids: users_array,
            counts: user_counts,
            filter_func: filter_func,
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setProgress,
        });
    }
    if (popularity_type === PopularityType.POPULAR_BY_SUBSCRIBERS || popularity_type === PopularityType.POPULAR_BY_ANY) {
        user_counts = yield GetSubscriptionsUserCount({
            user_ids: users_array,
            counts: user_counts,
            filter_func: filter_func,
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setProgress,
        });
    }

    user_counts = truncate(user_counts, users_to_show_count);

    const profiles: Map<number, UserObject> = yield GetUsers({
        user_ids: Array.from(user_counts.keys()),
        access_token: access_token,
        chunk_size: 100,
        fields: 'first_name,last_name,photo_max',
        filter_func: (user => !user.deactivated)
    });

    const counters: Map<number, CountersUser> = yield GetCountersUser({
        user_ids: Array.from(user_counts.keys()),
        access_token: access_token,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    const result: SuperstarItem[] = calculateResult(user_counts, profiles, counters, popularity_type);

    yield put(actions.setProgress({message: `Завершено`, total: undefined, current: undefined}));
    yield put(showAlert({text: `Готово`, header: 'Парсинг завершен'}));
    yield put(actions.setResult(result));

    yield put({ type: action_types.COMPLETED});
}

function truncate(user_counts: Map<number, UserFriendsCount>, limit: number): Map<number, UserFriendsCount> {
    const result: Map<number, UserFriendsCount> = new Map<number, UserFriendsCount>();
    Array.from(user_counts.values())
        .sort((a, b) => {
            return b.total - a.total;
        })
        .slice(0, limit)
        .forEach((item: UserFriendsCount) => {
            result.set(item.user_id, item);
        });
    return result;
}

function calculateResult(
    user_counts: Map<number, UserFriendsCount>,
    profiles: Map<number, UserObject>,
    counters: Map<number, CountersUser>,
    popularity_type: PopularityType
): SuperstarItem[] {

    const result: SuperstarItem[] = [];

    let total_smart: number = 0;
    for (let user_id of user_counts.keys()) {
        let profile = profiles.get(user_id);
        let counts = user_counts.get(user_id);
        let total_counters: CountersUser|undefined = counters.get(user_id);
        if (!profile || !counts || !total_counters) {
            continue;
        }
        let total = 0;
        if (popularity_type === PopularityType.POPULAR_BY_SUBSCRIBERS) {
            if (!total_counters.followers) {
                continue;
            }
            total = total_counters.followers || 0;
        }
        if (popularity_type === PopularityType.POPULAR_BY_FRIENDS) {
            if (!total_counters.friends) {
                continue;
            }
            total = total_counters.friends || 0;
        }
        if (popularity_type === PopularityType.POPULAR_BY_ANY) {
            total = (total_counters.followers || 0) + (total_counters.friends || 0)
        }
        let percent: number = 0;
        if (total > 0) {
            percent = counts.total / total * 100;
        }
        total_smart += counts.total * percent;

        const item: SuperstarItem = {
            user_id: user_id,
            counts: counts,
            profile: profile,
            total: total,
            percent: percent,
            smart: 0,
        };
        result.push(item);
    }

    return result.map((item: SuperstarItem) => {
        if (total_smart > 0) {
            item.smart = (item.percent * item.counts.total) * 10000 / total_smart;
        }
        return item;
    });
}

function* validate(state: SuperstarsLocatorState) {
    if (state.settings.data_source_type === DataSourceType.URL) {
        if (state.settings.data_source_url.trim() === '') {
            yield put(showAlert({text: EMPTY_URL_GROUP, header: 'Ошибка'}));
            yield put(actions.stop());
            return;
        }
    }
    if (state.settings.data_source_type === DataSourceType.FILE) {
        if (state.settings.data_source_file.trim() === '') {
            yield put(showAlert({text: EMPTY_ID_FILE, header: 'Ошибка'}));
            yield put(actions.stop());
            return;
        }
    }
}

function* onStop(task: any) {
    yield take(action_types.STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task: any) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    console.log(error);
    yield put(actions.setProgress({message: `Ошибка: ${error.payload.message}`}));
    yield put(showAlert({text: error.payload.message, header: 'Ошибка'}));
    yield cancel(task);
}

export default main;
