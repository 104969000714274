import {createAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import DefaultActionsCreator from "../../../../helpers/DefaultActionsCreator";
import {TaskProgress} from "../../../types";
import * as types from "../action-types";
import {BlockedFilterResult} from "../types";

function withPayloadType<T>() {
    return (t: T) => ({payload: t})
}

export const setSource = createAction(types.blocked.SET_SOURCE, withPayloadType<string>());
export const setMembersCountMin = createAction(types.blocked.SET_MEMBERS_COUNT_MIN, withPayloadType<number>());
export const setMembersCountMax = createAction(types.blocked.SET_MEMBERS_COUNT_MAX, withPayloadType<number>());
export const setMembersCountBlockedMin = createAction(types.blocked.SET_MEMBERS_COUNT_BLOCKED_MIN, withPayloadType<number>());
export const setMembersCountBlockedMax = createAction(types.blocked.SET_MEMBERS_COUNT_BLOCKED_MAX, withPayloadType<number>());

export const setSkipped = createAction(types.blocked.SET_SKIPPED, withPayloadType<GroupObject[]>());
export const setSubProgress = createAction(types.blocked.SET_SUBPROGRESS, withPayloadType<TaskProgress>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<BlockedFilterResult[]>(types.blocked.NAMESPACE);
