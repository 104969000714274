const NAMESPACE = 'GROUP_MEMBERS/';

export const SET_GROUPS_SOURCE = NAMESPACE + 'SET_GROUPS_SOURCE';

export const SET_GROUPS_MIN = NAMESPACE + 'SET_GROUPS_MIN';
export const SET_GROUPS_MAX = NAMESPACE + 'SET_GROUPS_MAX';

export const SET_USER_COUNTS = NAMESPACE + 'SET_USER_COUNTS';
export const SET_CLOSED_GROUPS = NAMESPACE + 'SET_CLOSED_GROUPS';

export const START = NAMESPACE + 'START';
export const STOP = NAMESPACE + 'STOP';
export const SET_TASK_STATUS = NAMESPACE + 'SET_TASK_STATUS';
export const SET_TASK_ERROR = NAMESPACE + 'SET_TASK_ERROR';
export const SET_PROGRESS = NAMESPACE + 'SET_PROGRESS';
export const SET_RESULT = NAMESPACE + 'SET_RESULT';

export const ADD_API_ERROR = NAMESPACE + 'ADD_API_ERROR';
export const RESET_API_ERRORS = NAMESPACE + 'RESET_API_ERRORS';

export const COMPLETED = NAMESPACE + 'COMPLETED';
