import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {DefaultProps, TaskProgress} from "../../types";
import * as actions from './actions';
import * as types from "./types";
import {PostsType, StatsPostsCommunityResult} from "./types";

const moment = require('moment');

const initialState: types.StatsPostsCommunityState = {
    settings: {
        source: '',
        post_type: PostsType.ANY,
        only_with_vk_cc: false,
        start_date: moment().subtract(7, "days").startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
    },
    sub_progress: {message: ''},
    ...DefaultStateProps<StatsPostsCommunityResult[]>([])
};

const StatsPostsCommunityReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
        state.settings.source = action.payload;
    });
    builder.addCase(actions.setPostType, (state, action: PayloadAction<PostsType>) => {
       state.settings.post_type = action.payload;
    });
    builder.addCase(actions.setOnlyWithVkCc, (state, action: PayloadAction<boolean>) => {
        state.settings.only_with_vk_cc = action.payload;
    });
    builder.addCase(actions.setStartDate, (state, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });
    builder.addCase(actions.setSubProgress, (state, action: PayloadAction<TaskProgress>) => {
        state.sub_progress = action.payload;
    });
    AddDefaultCasesToReducer<StatsPostsCommunityResult[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<StatsPostsCommunityResult[]>>);
});

export default StatsPostsCommunityReducer;
