import {Post} from "../objects/Post";
import {ActivityType, WithDefaultActions} from "../../store/parsers/types";
import GetLikes, {PostLikes} from "./activities/GetLikes";
import GetComments, {PostComments} from "./GetComments";
import GetCommentsLikes, {PostCommentsLikes} from "./activities/GetCommentsLikes";

export interface GetActivitiesParams extends WithDefaultActions {
    posts: Post[],
    activity_types: Set<ActivityType>,
    comments_preview_length?: number,
    access_token: string,

    likes_handler?: (items: PostLikes[]) => void,
    reposts_handler?: (items: PostLikes[]) => void,
    comments_handler?: (items: PostComments[]) => void,
    comments_likes_handler?: (items: PostCommentsLikes[]) => void,
}



const GetActivities = function* (params: GetActivitiesParams) {

    if (params.activity_types.has(ActivityType.LIKES)) {
        const likes: PostLikes[] = yield GetLikes({
            posts: params.posts,
            filter: "likes",
            access_token: params.access_token,
            add_api_errors_action: params.add_api_errors_action,
            progress_action: params.progress_action,
        });
        if (params.likes_handler) {
            params.likes_handler(likes);
        }
    }
    if (params.activity_types.has(ActivityType.REPOSTS)) {
        const reposts: PostLikes[] = yield GetLikes({
            posts: params.posts,
            filter: "copies",
            access_token: params.access_token,
            add_api_errors_action: params.add_api_errors_action,
            progress_action: params.progress_action,
        });
        if (params.reposts_handler) {
            params.reposts_handler(reposts);
        }
    }
    if (params.activity_types.has(ActivityType.COMMENTS) || params.activity_types.has(ActivityType.COMMENT_LIKES)) {
        const comments: PostComments[] = yield GetComments({
            posts: params.posts,
            access_token: params.access_token,
            preview_length: params.comments_preview_length ?? 0,
            add_api_errors_action: params.add_api_errors_action,
            progress_action: params.progress_action,
        });

        if (params.activity_types.has(ActivityType.COMMENTS)) {
            if (params.comments_handler) {
                params.comments_handler(comments);
            }
        }

        if (params.activity_types.has(ActivityType.COMMENT_LIKES)) {
            const comments_likes: PostCommentsLikes[] = yield GetCommentsLikes({
                comments: comments,
                access_token: params.access_token,
                add_api_errors_action: params.add_api_errors_action,
                progress_action: params.progress_action,
            });
            if (params.comments_likes_handler) {
                params.comments_likes_handler(comments_likes);
            }
        }
    }
};

export default GetActivities;
