import {ActivityType, StateWithErrors, StateWithProgress} from "../../types";

export enum PostAudienceSearchType {
    USERS = "users",
    GROUPS = "groups",
}

export interface PostsAudienceResult {
    from_id: number,
    likes: number,
    reposts: number,
    comments: number,
    comment_likes: number,
    posts: number,
}

interface State {
    settings: {
        source: string,
        search_type: PostAudienceSearchType,
        activity_types: ActivityType[],
        posts_min: number,
        posts_max: number,
    },
    result: PostsAudienceResult[],
}

export interface PostsAudienceState extends State, StateWithErrors, StateWithProgress {}
