import React from "react";
import RunningParsingOverlay from "../overlays/RunningParsingOverlay";

export interface ParsingSettingsContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    showOverlay?: boolean
}

const ParsingSettingsContainer = (props: React.PropsWithChildren<ParsingSettingsContainerProps>) => {
    return (
        <div className="tw-flex tw-flex-col tw-mt-4 tw-relative">
            {props.showOverlay &&
            <RunningParsingOverlay/>
            }
            {props.children}
        </div>
    )
};

export default ParsingSettingsContainer;
