import React from "react";
import {RadioButton} from "primereact/radiobutton";
import { v4 as uuidv4 } from 'uuid';

export interface RadioButtonChangeEvent {
    originalEvent: Event,
    value: any,
    checked: boolean,
    target: {
        type: string,
        name: string,
        id: string,
        value: any,
        checked: boolean
    }
}

export interface BasicRadioButtonProps {
    id?: string,
    name: string,
    label: string,
    className?: string,
    value?: string,
    onChange?: (e: RadioButtonChangeEvent) => void,
    checked?: boolean
}

const classNames = require('classnames');

const BasicRadioButton = (props: BasicRadioButtonProps) => {

    const uniqueId = props.id ? props.id : uuidv4();

    return (
        <div className={classNames("p-field-radiobutton", props.className)}>
            <RadioButton inputId={uniqueId} name={props.name} onChange={props.onChange} value={props.value} checked={props.checked} />
            <label htmlFor={uniqueId}>{props.label}</label>
        </div>
    )
};

export default BasicRadioButton;
