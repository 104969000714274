import {AudienceAnalysisResult} from "./types";

const relations: Map<number, string> = new Map<number, string>([
    [1, "не женат/не замужем"],
    [2, "есть друг/есть подруга"],
    [3, "помолвлен/помолвлена"],
    [4, "женат/замужем"],
    [5, "всё сложно"],
    [6, "в активном поиске"],
    [7, "влюблён/влюблена"],
    [8, "в гражданском браке"],
]);

const political: Map<number, string> = new Map<number, string>([
    [1, "коммунистические"],
    [2, "социалистические"],
    [3, "умеренные"],
    [4, "либеральные"],
    [5, "консервативные"],
    [6, "монархические"],
    [7, "ультраконсервативные"],
    [8, "индифферентные"],
    [9, "либертарианские"],
]);

const people_main: Map<number, string> = new Map<number, string>([
    [1, "ум и креативность"],
    [2, "доброта и честность"],
    [3, "красота и здоровье"],
    [4, "власть и богатство"],
    [5, "смелость и упорство"],
    [6, "юмор и жизнелюбие"],
]);

const life_main: Map<number, string> = new Map<number, string>([
    [1, "семья и дети"],
    [2, "карьера и деньги"],
    [3, "развлечения и отдых"],
    [4, "наука и исследования"],
    [5, "совершенствование мира"],
    [6, "саморазвитие"],
    [7, "красота и искусство"],
    [8, "слава и влияние"],
]);

const categories: Map<string, string> = new Map<string, string>([
    ['interests', 'интересы'],
    ['books', 'книги'],
    ['movies', 'кино'],
    ['music', 'музыка'],
    ['games', 'игры'],
    ['tv', 'шоу'],
    ['work', 'работа'],
    ['activities', 'занятость'],
    ['universities', 'ВУЗы'],
    ['faculties', 'факультеты'],
    ['relations', 'отношения'],
    ['political', 'политика'],
    ['people_main', 'главное в людях'],
    ['life_main', 'главное в жизни'],
]);

export function id_to_relation(id: number): string {
    return relations.get(id) || ''
}

export function id_to_political(id: number): string {
    return political.get(id) || ''
}

export function id_to_people_main(id: number): string {
    return people_main.get(id) || ''
}

export function id_to_life_main(id: number): string {
    return life_main.get(id) || ''
}

export function id_to_category_title(id: string): string {
    return categories.get(id) || '';
}

type TBlank = {
    [K in keyof AudienceAnalysisResult]: []
}

export function get_blank_audience_analysis(): TBlank {
    return {
        activities: [],
        books: [],
        faculties: [],
        games: [],
        interests: [],
        life_main: [],
        people_main: [],
        movies: [],
        music: [],
        occupation: [],
        political: [],
        relations: [],
        tv: [],
        universities: [],
        work: []
    }
}
