import {select} from 'redux-saga/effects'
import {convert_urls_to_topics, TopicAddress} from "../../../../../vkapi/tasks/ConverUrlToId";
import {TopicsParserState} from "../types";
import {RootState} from "../../../types";
import GetTopicCommentsSaga from "./get_topics_comments";

const main = function* () {
    const state: TopicsParserState = yield select((state: RootState) => state.topicsParser);
    const topic_addresses: TopicAddress[] = convert_urls_to_topics(state.settings.links_topics.split('\n'));

    yield GetTopicCommentsSaga(topic_addresses);

};

export default main;
