import {StateWithErrors, StateWithProgress, TaskProgress} from "../../types";

export enum FriendsAndFollowersSearchType {
    ANY = "any",
    FRIENDS = "friends",
    FOLLOWERS = "followers",
}

interface State {
    settings: {
        source: string,
        type: FriendsAndFollowersSearchType,
        min_count: number,
    },
    sub_progress: TaskProgress,
    result: number[],
}

export interface FriendsAndFollowersState extends State, StateWithErrors, StateWithProgress {}
