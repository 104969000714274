import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {OwnerPosts, Post} from "../../../../vkapi/objects/Post";
import {convert_urls_to_id} from "../../../../vkapi/tasks/ConverUrlToId";
import GetGroups from "../../../../vkapi/tasks/GetGroups";
import GetPosts, {GetPostsParams} from "../../../../vkapi/tasks/GetPosts";
import GetPostsById from "../../../../vkapi/tasks/GetPostsById";
import GetShortLinksStats, {LinkStats} from "../../../../vkapi/tasks/GetShortLinksStats";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {date_in_range, is_post_before_start_date} from "../../../helpers/DateChecker";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import {RootState, TaskStatus} from "../../types";
import * as actions from "./actions";
import {PostsType, StatsPostsCommunityResult, StatsPostsCommunityState} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: StatsPostsCommunityState = yield select((state: RootState) => state.statsPostsCommunity);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const result: StatsPostsCommunityResult[] = [];

    const group_urls: string[] = convert_urls_to_id(state.settings.source.split('\n'));
    const groups: GroupObject[] = yield GetGroups({
        access_token: access_token,
        group_ids: group_urls,
        fields: "members_count",
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    const is_date_in_range = date_in_range(state.settings.start_date, state.settings.end_date);
    const is_posts_start_date_reached = is_post_before_start_date(state.settings.start_date);

    const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});
    for (const group of groups) {
        yield progress.next().value;
        yield put(actions.setSubProgress({message: 'Получаем посты'}));

        const get_posts_params: GetPostsParams = {
            owner_id: group.id * -1,
            extended: 0,
            is_skip: (post: Post) => !is_date_in_range(post.date),
            is_stop: (post: Post) => is_posts_start_date_reached(post),
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
        };
        if (!state.settings.start_date && !state.settings.end_date) {
            get_posts_params.max_offset = 100;
        }
        const posts: OwnerPosts = yield GetPosts(get_posts_params);
        const all_codes: Set<string> = new Set<string>();
        const group_results: StatsPostsCommunityResult[] = [];
        if (state.settings.post_type === PostsType.REGULAR || state.settings.post_type === PostsType.ANY) {
            posts.posts.forEach(post => {
                const codes: string[] = getCodesFromText(post.text);
                const item: StatsPostsCommunityResult = {
                    group: group,
                    post: post,
                    is_promo: false,
                    short_link_views: 0,
                };
                if (state.settings.only_with_vk_cc && codes.length === 0) {
                    return;
                }
                if (codes.length > 0) {
                    item.short_link_code = codes[codes.length - 1];
                    codes.forEach(code => all_codes.add(code));
                }
                group_results.push(item);
            });
        }
        if (state.settings.post_type === PostsType.PROMO || state.settings.post_type === PostsType.ANY) {
            yield put(actions.setSubProgress({message: 'Получаем промопосты'}));
            const promo_posts: Post[] = yield getPromoPosts(group, posts, is_date_in_range, access_token);
            promo_posts.forEach(post => {
                const codes: string[] = getCodesFromText(post.text);
                const item: StatsPostsCommunityResult = {
                    group: group,
                    post: post,
                    is_promo: true,
                    short_link_views: 0,
                };
                if (state.settings.only_with_vk_cc && codes.length === 0) {
                    return;
                }
                if (codes.length > 0) {
                    item.short_link_code = codes[codes.length - 1];
                    codes.forEach(code => all_codes.add(code));
                }
                group_results.push(item);
            });
        }
        const codes_stats: Map<string, LinkStats> = yield GetShortLinksStats({
            codes: Array.from(all_codes),
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setSubProgress,
        });
        group_results.forEach(item => {
            if (item.short_link_code) {
                const stats: LinkStats|undefined = codes_stats.get(item.short_link_code);
                if (stats) {
                    item.short_link_views = stats.views;
                }
            }
            result.push(item);
        });
    }

    yield put(actions.setResult(result));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result.length}`, total: 100, current: 100}));
    yield put(actions.setSubProgress({message: ``}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${result.length}`));
    yield put(actions.setCompleted());
}

function* getPromoPosts(group: GroupObject, posts: OwnerPosts, is_date_in_range: (number) => boolean, access_token: string): Generator<any, Post[], any> {
    const visible_post_dates: Set<number> = new Set<number>();
    posts.posts.forEach(post => visible_post_dates.add(post.date));
    const missing_ids: number[] = find_missing_post_ids(posts.posts);
    const post_ids: string[] = missing_ids.map((id: number) => `-${group.id}_${id}`);
    const hidden_posts: Post[] = yield GetPostsById({
        post_ids: post_ids,
        access_token: access_token,
        progress_action: actions.setSubProgress,
        add_api_errors_action: actions.addApiError,
    });
    return hidden_posts.filter(post => is_date_in_range(post.date) && (post.marked_as_ads || post.post_type === "post_ads"));
}

function getCodesFromText(str: string): string[] {
    const matches: Set<string> = new Set<string>();
    const regex = /(vk\.cc\/([A-Za-z0-9]*)?)/gm;
    let m: RegExpExecArray|null;
    while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        if (m.length === 3) {
            matches.add(m[2]);
        }
    }
    return Array.from(matches);
}

function find_missing_post_ids(posts: Post[]): number[] {
    const missing_ids: number[] = [];
    const ids: number[] = set_first_and_last_post_id(posts.map(post => post.id));
    ids.sort((a, b) => a - b);
    const ids_count: number = ids.length;
    for(let i: number = 1; i < ids_count; i++) {
        let diff: number = ids[i] - ids[i-1];
        if (diff === 1) {
            continue;
        }
        let j: number = 1;
        while (j < diff) {
            missing_ids.push(ids[i-1] + j);
            j++;
        }
    }
    return missing_ids;
}
function set_first_and_last_post_id(ids: number[]): number[] {
    if (ids.length === 0) {
        return [0, 100];
    }
    ids.sort((a, b) => a - b);
    ids.unshift(Math.max(0, ids[0] - 50));
    ids.push(ids[ids.length - 1] + 50);
    return ids;
}

function* validate(state: StatsPostsCommunityState) {
    if (state.settings.source.trim() === '') {
        yield put(showAlertError('Вы не указали ссылки на группы'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
