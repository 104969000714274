import {createSelector} from "reselect";
import {isTextContainsAnyWord} from "../../../../../helpers/words-checker";
import {RootState} from "../../../types";
import {CommunitySearchItem, GroupAccessType} from "../types";

const select_items = (state: RootState) => state.searchCommunities.result;
const select_group_access_type = (state: RootState) => state.searchCommunities.settings.group_access_type;
const select_members_min = (state: RootState) => state.searchCommunities.settings.members_min;
const select_members_max = (state: RootState) => state.searchCommunities.settings.members_max;
const select_title_min = (state: RootState) => state.searchCommunities.settings.title_length_min;
const select_title_max = (state: RootState) => state.searchCommunities.settings.title_length_max;
const select_words_plus = (state: RootState) => state.searchCommunities.settings.words_plus;
const select_words_minus = (state: RootState) => state.searchCommunities.settings.words_minus;
const select_min_date = (state: RootState) => state.searchCommunities.settings.min_date;

const selectByPublicAccess = createSelector(
    [select_items, select_group_access_type],
    (items: CommunitySearchItem[], type: GroupAccessType) => {
        if (type === GroupAccessType.ANY) {
            return items;
        }
        return items.filter(item => {
            if (type === GroupAccessType.OPEN && item.group.is_closed && item.group.is_closed === 1) {
                return false;
            }
            if (type === GroupAccessType.CLOSED && item.group.is_closed !== undefined && item.group.is_closed === 0) {
                return false;
            }
            return true;
        });
    }
);

const selectByWords = createSelector(
    [selectByPublicAccess, select_words_plus, select_words_minus],
    (items: CommunitySearchItem[], words_plus: string[], words_minus: string[]) => {
        const tokens_plus: string[] = words_plus.map(word => word.trim().toLowerCase());
        const tokens_minus: string[] = words_minus.map(word => word.trim().toLowerCase());
        return items.filter((item: CommunitySearchItem) => {
            if (!item.group.name) {
                return false;
            }
            if (tokens_minus.length > 0 && isTextContainsAnyWord(item.group.name, tokens_minus)) {
                return false;
            }
            if (tokens_plus.length > 0 && !isTextContainsAnyWord(item.group.name, tokens_plus)) {
                return false;
            }
            return true;
        });
    }
);

const selectByMembers = createSelector(
    [selectByWords, select_members_min, select_members_max],
    (items, min, max) => {
        if (min === 0 && max === 0) {
            return items;
        }
        return items.filter((item) => {
            if (!item.group.members_count) {
                return false;
            }
            return (item.group.members_count >= min || min === 0) && (item.group.members_count <= max || max === 0)
        })
    }
);

const selectByTitleLength = createSelector(
    [selectByMembers, select_title_min, select_title_max],
    (items, min, max) => {
        if (min === 0 && max === 0) {
            return items;
        }
        return items.filter((item) => {
            if (!item.group.name) {
                return false;
            }
            return (item.group.name.length >= min || min === 0) && (item.group.name.length <= max || max === 0)
        })
    }
);

const selectByDate = createSelector(
    [selectByTitleLength, select_min_date],
    (items, min_date) => {
        if (!min_date) {
            return items;
        }
        return items.filter((item) => {
            if (!item.last_post) {
                return false;
            }
            return (item.last_post > min_date.getTime())
        })
    }
);

const selectFiltered = createSelector(
    [selectByDate],
    (items: CommunitySearchItem[]) => items
);

export default selectFiltered;
