import React, {ReactNode} from "react";
import GroupLabel from "../labels/GroupLabel";

export interface InputContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    label: string,
    notice?: string,
    top?: ReactNode,
    bottom?: ReactNode,
    childrenContainerClassname?: string,
}

const classNames = require('classnames');

const InputContainer = (props: React.PropsWithChildren<InputContainerProps>) => {
    return (
        <div className={props.className}>
            <GroupLabel label={props.label} className="tw-font-medium tw-mb-1" />
            {props.top}
            <div className={classNames("tw-flex", "tw-flex-wrap", "tw-gap-x-4", props.childrenContainerClassname)}>
                {props.children}
            </div>
            {props.bottom}
            {props.notice &&
                <div className="p-field"><i>{props.notice}</i></div>
            }
        </div>
    );
};

export default InputContainer;
