import {Button} from "primereact/button";
import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import download_txt from "../../../../helpers/DownloadTxt";
import {showAlert, showAlertError} from "../../../../store/app/actions";
import * as actions from "../../../../store/parsers/communities-audience/posts-audience/actions";
import {
    PostAudienceSearchType,
    PostsAudienceResult
} from "../../../../store/parsers/communities-audience/posts-audience/types";

import {ActivityType, RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import useMinMax from "../../../hooks/MinMaxHook";
import useSaveWithCsv from "../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicCheckbox, {CheckBoxChangeEvent} from "../../../ui/inputs/BasicCheckbox";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import MinMaxInput from "../../../ui/inputs/MinMaxInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import selectFiltered
    from "../../../../store/parsers/communities-audience/posts-audience/selectors/PostAudienceSelector";

const PostsAudience = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.postsAudience);
    const progress: TaskProgress = useSelector((state: RootState) => state.postsAudience.progress);
    const filtered: PostsAudienceResult[] = useSelector(selectFiltered);

    const [parsingName, setParsingName] = useState<string>('');
    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);

    const onSearchTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setSearchType(e.value)), [dispatch]);
    const onActivityTypeChange = (e: CheckBoxChangeEvent, activity_type: ActivityType) => {
        if (!e.checked) {
            if (state.settings.activity_types.length === 1) {
                dispatch(showAlert({text: 'Нельзя снять все галочки', header: 'Ошибка'}));
                return;
            }
            dispatch(actions.removeActivityType(activity_type));
            return;
        }
        dispatch(actions.addActivityType(activity_type));
    };
    const [onPostsMinChange, onPostsMaxChange] = useMinMax(actions.setPostsMin, actions.setPostsMax);

    const save = () => {
        if (filtered.length === 0) {
            dispatch(showAlertError('Нет результатов, подходящих под выбранные фильтры'));
            return;
        }
        download_txt<PostsAudienceResult[]>({
            data: filtered,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: PostsAudienceResult[]) => data.map(item => item.from_id.toString()),
        });
    };

    const saveCSV = () => {
        if (state.result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = ['"Id"', '"Ссылка"', '"Лайки"', '"Репосты"', '"Комментарии"', '"Лайки комментариев"', '"Посты"',];
        const generator = (item: PostsAudienceResult) => {
            let item_url = '';
            if (state.settings.search_type === PostAudienceSearchType.USERS) {
                item_url = 'https://vk.com/id' + item.from_id;
            }
            if (state.settings.search_type === PostAudienceSearchType.GROUPS) {
                item_url = 'https://vk.com/club' + item.from_id;
            }
            const line = [
                item.from_id,
                '"' + item_url + '"',
                item.likes,
                item.reposts,
                item.comments,
                item.comment_likes,
                item.posts,
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(state.result, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Соберите людей, которые лайкали, комментировали или репостили указанные посты"
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/auditoriya_postov"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Ссылки на посты (по одной на строке)'}
                                    placeholder={"https://vk.com/wall-73662138_11449"}
                                    value={state.settings.source}
                                    onChange={(e) => dispatch(actions.setSource(e))}
                />

                <InputContainer label="Кого собирать">
                    <BasicRadioButton name="searchType" label="пользователей"
                                      value={PostAudienceSearchType.USERS}
                                      checked={state.settings.search_type === PostAudienceSearchType.USERS}
                                      onChange={onSearchTypeChange} />
                    <BasicRadioButton name="searchType" label="сообщества"
                                      value={PostAudienceSearchType.GROUPS}
                                      checked={state.settings.search_type === PostAudienceSearchType.GROUPS}
                                      onChange={onSearchTypeChange} />
                </InputContainer>

                <InputContainer label="Тип активности">
                    <BasicCheckbox label="Лайки"
                                   checked={state.settings.activity_types.includes(ActivityType.LIKES)}
                                   onChange={e => onActivityTypeChange(e, ActivityType.LIKES)}
                    />
                    <BasicCheckbox label="Репосты"
                                   checked={state.settings.activity_types.includes(ActivityType.REPOSTS)}
                                   onChange={e => onActivityTypeChange(e, ActivityType.REPOSTS)}
                    />
                    <BasicCheckbox label="Комментарии"
                                   checked={state.settings.activity_types.includes(ActivityType.COMMENTS)}
                                   onChange={e => onActivityTypeChange(e, ActivityType.COMMENTS)}
                    />
                    <BasicCheckbox label="Лайки комментариев"
                                   checked={state.settings.activity_types.includes(ActivityType.COMMENT_LIKES)}
                                   onChange={e => onActivityTypeChange(e, ActivityType.COMMENT_LIKES)}
                    />
                </InputContainer>

                <MinMaxInput label="Количество постов с активностью (по умолчанию 1)"
                             min={state.settings.posts_min} onMinChange={onPostsMinChange}
                             max={state.settings.posts_max} onMaxChange={onPostsMaxChange}
                />

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {state.result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить id в txt файл" onClick={() => save()} />
                        <SaveButton label="Сохранить в CSV" onClick={() => saveCSV()} />
                    </>
                    }
                    {anchors}
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            {filtered.length > 0 &&
                <div>Отфильтрованных результатов: {filtered.length}</div>
            }
        </AppPanel>
    );
};

export default PostsAudience;
