import {StateWithErrors, StateWithProgress} from "../../types";
import {UserFriendsCount} from "./saga";
import {DataSourceType} from "../../types";
import {UserObject} from "../../../../vkapi/objects/UserObject";

export enum SearchType {
    SEARCH_INSIDE_GROUP = "search_inside_group",
    SEARCH_EVERYONE = "search_everyone",
}

export enum PopularityType {
    POPULAR_BY_FRIENDS = "popular_by_friends",
    POPULAR_BY_SUBSCRIBERS = "popular_by_subscribers",
    POPULAR_BY_ANY = "popular_by_any",
}

export interface UserCount {
    user_id: number,
    count: number,
}

export interface PopularPeopleSettings {
    data_source_type: DataSourceType.URL|DataSourceType.FILE,
    data_source_url: string,
    data_source_file: string,
    search_type: SearchType,
    popularity_type: PopularityType,
    min_friends: number,
    max_friends: number,
    users_to_show_count: number,
}

interface State {
    settings: PopularPeopleSettings,
    result: SuperstarItem[],
}

export interface SuperstarItem {
    user_id: number,
    counts: UserFriendsCount,
    profile: UserObject,
    total: number,
    percent: number,
    smart: number,
}

export interface SuperstarsLocatorState extends State, StateWithErrors, StateWithProgress {}
