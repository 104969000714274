import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {convert_urls_to_id} from "../../../../../vkapi/tasks/ConverUrlToId";
import GetGroups from "../../../../../vkapi/tasks/GetGroups";
import {groups as actions} from "../actions";
import {RootState, TaskStatus} from "../../../types";
import {showAlertError, showAlertParsingCompleted} from "../../../../app/actions";
import {BaseViewerState} from "../types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: BaseViewerState['groups'] = yield select((state: RootState) => state.baseViewer.groups);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_ids: string[] = convert_urls_to_id(state.settings.source.split("\n"));

    const groups: GroupObject[] = yield GetGroups({
        access_token: access_token,
        group_ids: group_ids,
        fields: "photo_50",
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    yield put(actions.setProgress({message: `Завершено. Найдено: ${groups.length}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${groups.length}`));
    yield put(actions.setResult(groups));
    yield put(actions.setCompleted());
}

function* validate(state: BaseViewerState['groups']) {
    if (state.settings.source.trim() === "") {
        yield put(showAlertError('Вы не загрузили файл или он пустой'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
