import {put} from "redux-saga/effects";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import GetUnsureEventMembers, {GetUnsureEventMembersParams} from "./GetUnsureEventsMembers";
import {GroupCount, TargetAudienceGroup} from "../types";
import {TaskProgress} from "../../../types";
import {VkApiError} from "../../../../../vkapi/VkApiError";
import GetGroups from "../../../../../vkapi/tasks/GetGroups";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";

export interface GetTargetAudienceGroupsParams {
    group_counts: Map<number, GroupCount>,
    truncate_to_count: number,
    target_group_id?: number,
    access_token: string,
    filter: (group: GroupObject) => boolean,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
}

const GetTargetAudienceGroups = function* (params: GetTargetAudienceGroupsParams): Generator<any,TargetAudienceGroup[], any> {
    const groups_per_request = 1000;
    let result: Map<number, TargetAudienceGroup> = new Map<number, TargetAudienceGroup>();
    const sorted_group_counts: GroupCount[] = sort_group_counts(params.group_counts);
    const generator: Generator<Map<number, GroupCount>, null, any> = generate(sorted_group_counts, groups_per_request);
    for (const chunk of generator) {
        if (!chunk || chunk.size === 0) {
            break;
        }
        if (params.progress_action) {
            const progress: string = (result.size / params.truncate_to_count * 100).toFixed(2);
            yield put(params.progress_action({
                message: `Получаем информацию о группах: ${progress}%`,
                current: result.size,
                total: params.truncate_to_count
            }));
        }
        const combined_groups_data: TargetAudienceGroup[] = yield get_combined_groups_data(chunk, params.filter, params.access_token, params.add_api_errors_action);
        combined_groups_data.forEach((data: TargetAudienceGroup) => {
            if (result.size < params.truncate_to_count) {
                result.set(data.group.id, data);
            }
        });
        if (result.size >= params.truncate_to_count) {
            break;
        }
    }

    if (params.progress_action) {
        yield put(params.progress_action({
            message: `Информация о группах получена`,
        }));
    }

    const event_ids: Set<number> = new Set<number>();
    for (let item of result.values()) {
        if (item.group.type === "event") {
            event_ids.add(item.group.id);
        }
    }

    const get_unsure_event_members_params: GetUnsureEventMembersParams = {
        event_ids: Array.from(event_ids.values()),
        access_token: params.access_token,
        add_api_errors_action: params.add_api_errors_action,
        progress_action: params.progress_action,
    };
    const unsure_members_count: Map<number, number> = yield GetUnsureEventMembers(get_unsure_event_members_params);
    for (let event_id of unsure_members_count.keys()) {
        let group: TargetAudienceGroup|undefined = result.get(event_id);
        const count: number|undefined = unsure_members_count.get(event_id);
        if (group && group.group.members_count && count) {
            group.group.members_count = group.group.members_count + count;
            result.set(event_id, group);
        }
    }

    if (params.target_group_id) {
        let target_group = result.get(params.target_group_id);
        if (target_group && target_group.group.members_count) {
            target_group.count = target_group.group.members_count;
            result.set(target_group.group.id, target_group);
        }
    }

    let groups_array = fill_smart(Array.from(result.values()));

    return groups_array.sort((a, b) => b.count - a.count);
};

function sort_group_counts(group_counts: Map<number, GroupCount>): GroupCount[] {
    return Array.from(group_counts.values()).sort((a, b) => b.count - a.count);
}

function* generate(group_counts: GroupCount[], groups_per_request: number): Generator<Map<number, GroupCount>, null, any> {
    for (let offset: number = 0; offset < group_counts.length; offset += groups_per_request) {
        let map: Map<number, GroupCount> = new Map<number, GroupCount>();
        const slice: GroupCount[] = group_counts.slice(offset, offset + groups_per_request);
        slice.forEach((group_count: GroupCount) => {
            map.set(group_count.group_id, group_count);
        });
        yield map;
    }
    return null;
}

function* get_combined_groups_data(
    groups_counts: Map<number, GroupCount>,
    filter: (group: GroupObject) => boolean,
    access_token: string,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
): Generator<any, TargetAudienceGroup[], any> {

    const result: TargetAudienceGroup[] = [];
    const group_ids = Array.from(groups_counts.values()).map(group_count => group_count.group_id);

    const groups: GroupObject[] = yield GetGroups({
        group_ids: group_ids,
        fields: 'members_count',
        access_token: access_token,
        add_api_errors_action: add_api_errors_action
    });

    groups.forEach((group: GroupObject) => {
        if (!filter(group)) {
            return;
        }
        const count: GroupCount|undefined = groups_counts.get(group.id);
        if (!count) {
            return;
        }
        const target_audience_group: TargetAudienceGroup = {
            group: group,
            count: count.count,
            count_to_total: 0,
            smart: 0,
        };
        result.push(target_audience_group);
    });
    return result;
}

function fill_smart(groups: TargetAudienceGroup[]): TargetAudienceGroup[] {
    let total_smart = 0;
    groups.forEach((group: TargetAudienceGroup) => {
        if (!group.group.members_count) {
            return;
        }
        const percent = (group.count / group.group.members_count) * 100;
        total_smart += group.count * percent;
    });
    return groups.map((group: TargetAudienceGroup) => {
        if (group.group.members_count) {
            group.count_to_total = (group.count / group.group.members_count) * 100;
            group.smart = (group.count_to_total * group.count * 10000) / total_smart;
        }
        return group;
    });
}

export default GetTargetAudienceGroups;
