const urlParams = new URLSearchParams(window.location.search);
const user_id = urlParams.get('vk_user_id');
const sign = urlParams.get('sign');


export function setWindowObject(data: string): boolean {
    type R = {
        user_id: number,
        timestamp: number|null,
    };
    const decoded: string = atob(data);
    const parsed: R = JSON.parse(decoded);
    return !!(parsed.timestamp && parsed.timestamp > 0);

}

export default function getLogger(): string {
    return btoa(`{"user_id":${user_id},"sign":"${sign}"}`);
}
