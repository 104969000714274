import _chunk from "lodash/chunk";
import {WithDefaultActions} from "../../store/parsers/types";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import {UserObject} from "../objects/UserObject";
import {GroupsGetMembersParams as MethodParams} from "../methods/GroupsGetMembersParams";
import {ProcessMethodsParams} from "../types";
import CheckHiddenMembers from "./CheckHiddenMembers";

export interface GroupsGetMembersParams extends WithDefaultActions {
    group_id: number,
    members_count: number,
    fields?: string,
    access_token: string,
}

const GroupsGetMembers = function* <TResult extends UserObject[]|number[]>(params: GroupsGetMembersParams): Generator<any, TResult, any> {

    const is_hidden: boolean = yield CheckHiddenMembers({
        group_id: params.group_id,
        access_token: params.access_token,
    });

    if (is_hidden) {
        throw new Error("Скрыт список участников группы: id " + params.group_id)
    }

    const executes:Array<IVkMethod> = prepare(params.group_id, params.members_count, params.fields);
    const executes_result: TResult = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    }, params.fields !== undefined);
    return executes_result;
};

function prepare(group_id: number, members_count: number, fields?: string): Array<IVkMethod> {
    const max_requests_per_execute: number = 20;
    const groupsGetMembersMethods: IVkMethod[] = makeGroupsGetMembersMethods(group_id, members_count, fields);
    const chunks:IVkMethod[][] = _chunk(groupsGetMembersMethods, max_requests_per_execute);
    return chunks.map((chunk) => {
        const requests:string[] = chunk.map((method) => {
            return "API." + method.method_name + "(" + JSON.stringify(method.params) + ")";
        });
        const code:string = "return [" + requests.join(",") + "];";
        return methods.execute({code: code});
    });
}

function makeGroupsGetMembersMethods(group_id: number, members_count: number, fields?: string): Array<IVkMethod> {
    const members_per_request: number = 250;
    let result:Array<IVkMethod> = [];
    for (let offset = 0; offset < members_count; offset += members_per_request) {
        let groupsGetMembersParams: MethodParams = {
            group_id: group_id,
            offset: offset,
            count: members_per_request
        };
        if (fields) {
            groupsGetMembersParams.fields = fields;
        }
        let method = methods.groupsGetMembers(groupsGetMembersParams);
        result.push(method);
    }
    return result;
}

const send = function* (params: ProcessMethodsParams, has_fields: boolean): Generator<any, UserObject[]|number[], any> {

    type TResponse = {
        count: number,
        items: number[],
    }|{
        count: number,
        items: UserObject[],
    }

    const result_users: Map<number, UserObject> = new Map<number, UserObject>();
    const result_ids: Set<number> = new Set<number>();

    const isUserObjects = (items: UserObject[]|number[]): items is UserObject[] => has_fields;

    const handler = (response: TResponse[]) => {
        response.forEach((response_item) => {
            const items = response_item.items;
            if (isUserObjects(items)) {
                items.forEach(item => {
                    result_users.set(item.id, item);
                })
            }
            else {
                items.forEach(item => result_ids.add(item));
            }
        });
    };
    yield SendMethodsDefault<TResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем подписчиков',
        chunk_size: 6,
    });
    if (has_fields) {
        return Array.from(result_users.values());
    }
    return Array.from(result_ids.values());
};

export default GroupsGetMembers;
