import {Calendar} from "primereact/calendar";
import React from "react";
import {CalendarChangeEvent} from "../ui-events/CalendarChangeEvent";

export interface DateRangeInputProps {
    minDateValue: Date|null,
    maxDateValue: Date|null,
    onMinDateChange: (e: CalendarChangeEvent) => void,
    onMaxDateChange: (e: CalendarChangeEvent) => void,
    notice?: string
}

const ru = {
    firstDayOfWeek: 1,
    dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четерг", "Пятница", "Суббота"],
    dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб",],
    dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб",],
    monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    today: 'Сегодня',
    clear: 'Очистить',
    dateFormat: 'dd.mm.yy',
    weekHeader: 'Нд'
};

const DateRangeInput = (props: DateRangeInputProps) => {
    return (
        <div>
            <div className="tw-flex tw-flex-wrap tw-gap-x-6">
                <div className="tw-flex tw-gap-x-2 tw-items-center">
                    от:
                    <Calendar locale={ru} dateFormat="dd.mm.yy" value={props.minDateValue ? props.minDateValue : undefined} onChange={props.onMinDateChange}
                              showButtonBar={true} />
                </div>
                <div className="tw-flex tw-gap-x-2 tw-items-center">
                    до:
                    <Calendar locale={ru} dateFormat="dd.mm.yy" value={props.maxDateValue ? props.maxDateValue : undefined} onChange={props.onMaxDateChange}
                              showButtonBar={true} />
                </div>
            </div>
            {props.notice &&
            <div className="p-field"><i>{props.notice}</i></div>
            }
        </div>
    )
};

export default DateRangeInput;
