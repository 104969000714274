import {Post} from "../../../../vkapi/objects/Post";
import {StateWithErrors, StateWithProgress, TaskProgress} from "../../types";

export enum PostsSearchAttachType {
    ANY = "any",
    POLL_ANY = "poll_any",
    POLL_OPEN = "poll_open",
}

interface State {
    settings: {
        groups_source: string,
        queries_source: string,
        is_strict: boolean,
        words_minus: string,
        start_date: Date|null,
        end_date: Date|null,
        attach_type: PostsSearchAttachType,
    },
    sub_progress: TaskProgress,
    result: Post[],
}

export interface PostsSearchState extends State, StateWithErrors, StateWithProgress {}
