import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {City, Country, DefaultProps} from "../../types";
import * as actions from './actions';
import {NewsSearchResult} from "./types";
import * as types from "./types";
import {LikesCount, MessageType, NewsAttachType, ShowType} from "./types";

const initialState: types.NewsSearchState = {
    settings: {
        queries: '',
        words_minus: '',
        selected_city: null,
        selected_country: null,
        attach_type: NewsAttachType.ANY,
        start_date: null,
        end_date: null,
        likes_count: LikesCount.ANY,
        message_type: MessageType.ANY,
        min_text_length: 0,
        show_type: ShowType.ANY,
    },
    ...DefaultStateProps<NewsSearchResult[]>([])
};

const NewsSearchReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setQueries, (state, action: PayloadAction<string>) => {
        state.settings.queries = action.payload;
    });
    builder.addCase(actions.setWordsMinus, (state, action: PayloadAction<string>) => {
        state.settings.words_minus = action.payload;
    });
    builder.addCase(actions.setStartDate, (state, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });
    builder.addCase(actions.setSelectedCountry, (state, action: PayloadAction<Country|null>) => {
        state.settings.selected_country = action.payload;
    });
    builder.addCase(actions.setSelectedCity, (state, action: PayloadAction<City|null>) => {
        state.settings.selected_city = action.payload;
    });
    builder.addCase(actions.setAttachType, (state, action: PayloadAction<NewsAttachType>) => {
        state.settings.attach_type = action.payload;
    });
    builder.addCase(actions.setMessageType, (state, action: PayloadAction<MessageType>) => {
        state.settings.message_type = action.payload;
    });
    builder.addCase(actions.setLikesCount, (state, action: PayloadAction<LikesCount>) => {
        state.settings.likes_count = action.payload;
    });
    builder.addCase(actions.setShowType, (state, action: PayloadAction<ShowType>) => {
        state.settings.show_type = action.payload;
    });
    builder.addCase(actions.setMinTextLength, (state, action: PayloadAction<number>) => {
        state.settings.min_text_length = action.payload;
    });


    AddDefaultCasesToReducer<NewsSearchResult[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<NewsSearchResult[]>>);
});

export default NewsSearchReducer;
