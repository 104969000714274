import {Button} from "primereact/button";
import React, {useState} from "react";
import {Country} from "../../../store/parsers/types";
import InputContainer from "../containers/InputContainer";
import CountriesDialog from "../dialogs/CountriesDialog";

export interface CountryInputProps {
    selectedCountry: Country|null,
    onClear: () => void,
    onSelect: (item: Country) => void,
}

const CountryInput = (props: CountryInputProps) => {

    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const footer = () => {
        return (
            <div className="p-field">
                {props.selectedCountry &&
                <Button label={props.selectedCountry?.title}
                        onClick={props.onClear}
                        className="p-button-rounded p-button-sm p-button-secondary"
                        icon="pi pi-times-circle"
                />
                }
                {props.selectedCountry === null &&
                <span>Страна не выбрана</span>
                }
            </div>
        );
    };

    const onSelect = (item: Country) => {
        props.onSelect(item);
        setDialogVisible(false);
    };

    return (
        <InputContainer label="Выбор страны" bottom={footer()}>
            <Button label="Выбрать страну" className="p-field" onClick={() => setDialogVisible(true)}/>
            <Button label="Сбросить"
                    className="p-field"
                    onClick={props.onClear}
            />
            <CountriesDialog visible={dialogVisible} onSelect={onSelect} onHide={() => setDialogVisible(false)} />
        </InputContainer>
    )
};

export default CountryInput;
