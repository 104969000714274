import {ZodiacSign} from "../../store/parsers/filter-base/search-base/types";
import {Gender} from "../../store/parsers/types";
import {City} from "./City";
import {Country} from "./Country";

export enum Relation {
    UNKNOWN = 0,
    SINGLE = 1,
    HAVE_PAIR = 2,
    ENGAGED = 3,
    MARRIED = 4,
    COMPLICATED = 5,
    ACTIVE_SEARCH = 6,
    IN_LOVE = 7,
    LIVE_TOGETHER = 8,
}

export interface UserCareer {
    group_id?: number,
    company?: string,
    country_id?: number,
    city_id?: number,
    city_name?: string,
    from?: number,
    until?: number,
    position?: string,
}

export interface Relative {
    id?: number,
    name?: string,
    type?: "child" | "sibling" | "parent" | "grandparent" | "grandchild",
}

export interface CountersUser {
    albums?: number,
    videos?: number,
    audios?: number,
    photos?: number,
    notes?: number,
    friends?: number,
    groups?: number,
    user_videos?: number,
    followers?: number,
    pages?: number,
}

export interface School {
    id?: number,
    country?: number,
    city?: number,
    name?: string,
    year_from?: number,
    year_to?: number,
    year_graduated?: number,
    class?: string,
    speciality?: string,
}

export interface University {
    id?: number,
    country?: number,
    city?: number,
    name?: string,
    faculty?: number,
    faculty_name?: string,
    chair?: number,
    chair_name?: string,
    graduation?: number,
    education_form?: string,
    education_status?: string,
}

export interface UserObject {
    id: number,
    first_name?: string,
    last_name?: string,
    deactivated?: string,
    is_closed?: number,
    about?: string,
    activities?: string,
    bdate?: string,
    books?: string,
    career?: UserCareer,
    city?: City,
    country?: Country,
    counters?: CountersUser,
    domain?: string,
    education?: {
        university?: number,
        university_name?: string,
        faculty?: number,
        faculty_name?: string,
        graduation?: number,
    },
    followers_count?: number,
    games?: string,
    has_photo?: number,
    interests?: string,
    last_seen?: {
        time: number,
        platform?: number,
    },
    military?: {
        unit?: string,
        unit_id?: number,
        country_id?: number,
        from?: number,
        until?: number,
    },
    movies?: string,
    music?: string,
    occupation?: {
        type?: "work" | "school" | "university",
        id?: number,
        name?: string,
    },
    online?: 0 | 1,
    personal?: {
        political?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
        langs?: Array<string>,
        religion?: string,
        inspired_by?: string,
        people_main?: 1 | 2 | 3 | 4 | 5 | 6,
        life_main?: 1 | 2 | 3 | 4 | 5 | 6 | 7| 8,
        smoking?: 1 | 2 | 3 | 4 | 5,
        alcohol?: 1 | 2 | 3 | 4 | 5,
    },
    photo_50?: string,
    photo_100?: string,
    photo_200_orig?: string,
    photo_200?: string,
    photo_400_orig?: string,
    photo_id?: string,
    photo_max?: string,
    photo_max_orig?: string,
    quotes?: string,
    relatives?: Array<Relative>
    relation?: Relation,
    schools?: Array<School>,
    screen_name?: string,
    sex?: Gender,
    site?: string,
    status?: string,
    trending?: 0 | 1,
    tv?: string,
    universities?: Array<University>,
    verified?: 1 | 0,
    instagram?: string,
    twitter?: string,
    facebook?: string,
}

export function getUserAge(user: UserObject): number|null {
    if (!user.bdate) {
        return null;
    }
    const dmy: number[] = user.bdate.split('.').map(item => parseInt(item));
    if (dmy.length !== 3) {
        return null;
    }
    const [day, month, year] = dmy;
    const birth_date: Date = new Date(year, month, day);
    const now: Date = new Date();
    let age: number = now.getFullYear() - birth_date.getFullYear();
    const m: number = now.getMonth() - birth_date.getMonth();
    if (m < 0 || (m === 0 && now.getDate() < birth_date.getDate())) {
        age--;
    }
    return age;
}

export function getUserZodiacSign(user: UserObject): ZodiacSign|null {
    if (!user.bdate) {
        return null;
    }
    const dmy: number[] = user.bdate.split('.').map(item => parseInt(item));
    if (dmy.length < 2) {
        return null;
    }
    const birth_day: number = dmy[0];
    const birth_month: number = dmy[1];
    switch (birth_month) {
        case 1:
            if (birth_day < 20) {
                return ZodiacSign.KOZEROG;
            }
            return ZodiacSign.VODOLEY;
        case 2:
            if (birth_day < 19) {
                return ZodiacSign.VODOLEY;
            }
            return ZodiacSign.RIBI;
        case 3:
            if (birth_day < 21) {
                return ZodiacSign.RIBI;
            }
            return ZodiacSign.OVEN;
        case 4:
            if (birth_day < 20) {
                return ZodiacSign.OVEN;
            }
            return ZodiacSign.TELEC;
        case 5:
            if (birth_day < 21) {
                return ZodiacSign.TELEC;
            }
            return ZodiacSign.BLIZNECI;
        case 6:
            if (birth_day < 21) {
                return ZodiacSign.BLIZNECI;
            }
            return ZodiacSign.RAK;
        case 7:
            if (birth_day < 23) {
                return ZodiacSign.RAK;
            }
            return ZodiacSign.LEV;
        case 8:
            if (birth_day < 23) {
                return ZodiacSign.LEV;
            }
            return ZodiacSign.DEVA;
        case 9:
            if (birth_day < 23) {
                return ZodiacSign.DEVA;
            }
            return ZodiacSign.VESI;
        case 10:
            if (birth_day < 23) {
                return ZodiacSign.VESI;
            }
            return ZodiacSign.SCORPION;
        case 11:
            if (birth_day < 22) {
                return ZodiacSign.SCORPION;
            }
            return ZodiacSign.STRELEC;
        case 12:
            if (birth_day < 22) {
                return ZodiacSign.STRELEC;
            }
            return ZodiacSign.KOZEROG;
    }
    throw new Error("Ошибка в проверке знака зодиака");
}
