import {DataSourceType, StateWithErrors, StateWithProgress} from "../../types";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";

interface State {
    settings: {
        data_source_type: DataSourceType.URL|DataSourceType.FILE,
        data_source_url: string,
        data_source_file: string,
        truncate_to_count: number,
        analysis_type: AnalysisType,
        members_min: number,
        members_max: number,
        top_min: number,
        top_max: number,
        selector_filter_members_min: number,
        selector_filter_members_max: number,
        selector_filter_words_plus: string,
        selector_filter_words_minus: string,
        selector_filter_community_type_public: boolean,
        selector_filter_community_type_club: boolean,
        selector_filter_community_type_event: boolean,
        selector_filter_event_type: EventType,
        selector_filter_show_type: ShowType,
    },
    result: TargetAudienceGroup[],
}

export interface TargetAudienceLocatorState extends State, StateWithErrors, StateWithProgress {}

export enum AnalysisType {
    ANALISE_ALL = "all",
    ANALISE_TOP = "top"
}

export enum EventType {
    PAST = "past",
    FUTURE = "future",
    ALL = "all",
}

export enum ShowType {
    OPEN = "open",
    CLOSED = "closed",
    ALL = "all",
}

export interface GroupCount {
    group_id: number,
    count: number,
}

export interface TargetAudienceGroup {
    group: GroupObject,
    count: number,
    count_to_total: number,
    smart: number,
}
