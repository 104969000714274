import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {City, Country, DefaultProps, Gender} from "../../types";
import * as actions from './actions';
import * as types from "./types";
import {UsersSearchType} from "./types";

const initialState: types.UsersSearchState = {
    settings: {
        search_type: UsersSearchType.DEFAULT,
        query: '',
        age_min: 0,
        age_max: 0,
        selected_country: null,
        selected_city: null,
        gender: Gender.ANY,
    },
    ...DefaultStateProps<number[]>([])
};

const UsersSearchReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSearchType, (state, action: PayloadAction<UsersSearchType>) => {
        state.settings.search_type = action.payload;
    });
    builder.addCase(actions.setQuery, (state, action: PayloadAction<string>) => {
        state.settings.query = action.payload;
    });
    builder.addCase(actions.setAgeMin, (state, action: PayloadAction<number>) => {
        state.settings.age_min = action.payload;
    });
    builder.addCase(actions.setAgeMax, (state, action: PayloadAction<number>) => {
        state.settings.age_max = action.payload;
    });
    builder.addCase(actions.setSelectedCountry, (state, action: PayloadAction<Country|null>) => {
        state.settings.selected_country = action.payload;
    });
    builder.addCase(actions.setSelectedCity, (state, action: PayloadAction<City|null>) => {
        state.settings.selected_city = action.payload;
    });
    builder.addCase(actions.setGender, (state, action: PayloadAction<Gender>) => {
        state.settings.gender = action.payload;
    });
    AddDefaultCasesToReducer<number[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<number[]>>);
});

export default UsersSearchReducer;
