import {TopicAddress} from "../ConverUrlToId";
import {IVkMethod} from "../../IVkMethod";
import * as methods from "../../methods/VkMethods";
import _chunk from "lodash/chunk";
import {ProcessMethodsParams} from "../../types";
import SendMethodsDefault from "../../helpers/SendMethodsDefault";
import {Topic} from "../../objects/Topic";
import {GroupTopics, WithDefaultActions} from "../../../store/parsers/types";
import {BoardGetTopicsParams} from "../../methods/BoardGetTopicsParams";

export interface GetTopicsByIdsParams extends WithDefaultActions {
    topic_addresses: Required<TopicAddress>[],
    access_token: string,
}

const GetTopicsByIds = function* (params: GetTopicsByIdsParams): Generator<any, GroupTopics[], any> {
    const executes:Array<IVkMethod> = prepare(params.topic_addresses);
    const executes_result: GroupTopics[] = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(topic_addresses: Required<TopicAddress>[]): IVkMethod[] {
    const methods_per_execute: number = 20;
    const groupped_addresses: Map<number, Required<TopicAddress>[]> = group_by_owner_id(topic_addresses);
    const requests: string[] = [];

    groupped_addresses.forEach((value: Required<TopicAddress>[], owner_id: number) => {
        const chunks: Required<TopicAddress>[][] = _chunk(value, 100);
        chunks.forEach((chunk: Required<TopicAddress>[]) => {
            const params: BoardGetTopicsParams = {
                group_id: owner_id,
                topic_ids: chunk.map(item => item.topic_id),
            };
            const method: IVkMethod = methods.boardGetTopics(params);
            requests.push(`{owner_id: ${owner_id}, items: API.${method.method_name}(${JSON.stringify(method.params)}).items}`);
        });
    });

    const requests_chunks: string[][] = _chunk(requests, methods_per_execute);
    return requests_chunks.map((chunk: string[]) => {
        const code:string = `return [${chunk.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, GroupTopics[], any> {
    interface TResponse {
        owner_id: number,
        items: Topic[]|null,
    }
    const map: Map<number, GroupTopics> = new Map<number, GroupTopics>();
    const handler = (response: TResponse[]) => {
        response.forEach((response_item: TResponse) => {
            if (!response_item.items) {
                return;
            }
            const owner_id: number = response_item.owner_id;
            const group_topics: GroupTopics = map.get(owner_id) ||
                {
                    group_id: owner_id,
                    topics: []
                };
            group_topics.topics = group_topics.topics.concat(response_item.items);
            map.set(owner_id, group_topics);
        });
    };
    yield SendMethodsDefault<TResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем темы',
        chunk_size: 6,
    });
    return Array.from(map.values());
};

function group_by_owner_id(addresses: Required<TopicAddress>[]): Map<number, Required<TopicAddress>[]> {
    const result: Map<number, Required<TopicAddress>[]> = new Map<number, Required<TopicAddress>[]>();
    addresses.forEach(item => {
        let items: Required<TopicAddress>[] = result.get(item.owner_id) || [];
        items.push(item);
        result.set(item.owner_id, items);
    });
    return result;
}

export default GetTopicsByIds;
