import {MenuItem} from "primereact/api";

function getMenuItems(setActivePanel: (item: string|undefined) => void): MenuItem[] {
    return [
        {
            label: 'Поиск аудитории',
            items: [
                {
                    label: 'Группы, где есть ЦА',
                    command: e => setActivePanel('audienceSearch_targetAudienceLocator'),
                },
                {
                    label: 'Популярные люди',
                    command: e => setActivePanel('audienceSearch_superstarsLocator'),
                },
                {
                    label: 'Анализ пользователей',
                    command: e => setActivePanel('audienceSearch_audienceAnalysis'),
                }
            ]
        },
        {
            label: 'Аудитория сообществ',
            items: [
                {
                    label: 'Участники',
                    command: e => setActivePanel('communitiesAudience_members'),
                },
                {
                    label: 'ТОП читатели',
                    command: e => setActivePanel('communitiesAudience_topReaders'),
                },
                {
                    label: 'Активности',
                    command: e => setActivePanel('communitiesAudience_activities'),
                },
                {
                    label: 'ТОП комментаторы',
                    command: e => setActivePanel('communitiesAudience_commentators'),
                },
                {
                    label: 'Обсуждения',
                    command: e => setActivePanel('communitiesAudience_topics'),
                },
                {
                    label: 'Промопосты',
                    command: e => setActivePanel('communitiesAudience_promoposts'),
                },
                {
                    label: 'Аудитория постов',
                    command: e => setActivePanel('communitiesAudience_postsAudience'),
                },
                {
                    label: 'Контакты (администраторы)',
                    command: e => setActivePanel('communitiesAudience_adminContacts'),
                },
                {
                    label: 'Статистика постов',
                    command: e => setActivePanel('communitiesAudience_statsPosts'),
                },
            ],
        },
        {
            label: 'Сообщества',
            items: [
                {
                    label: 'Поиск сообществ',
                    command: e => setActivePanel('communities_searchCommunities'),
                },
                {
                    label: 'Фильтр сообществ',
                    command: e => setActivePanel('communities_communitiesFilter'),
                },
                {
                    label: 'Поиск постов',
                    command: e => setActivePanel('communities_postsSearch'),
                },
                {
                    label: 'Статусы сообществ',
                    command: e => setActivePanel('communities_groupStatuses'),
                },
            ]
        },
        {
            label: 'Профили пользователей',
            items: [
                {
                    label: 'Друзья и подписчики',
                    command: e => setActivePanel('userProfiles_friendsAndFollowers'),
                },
                {
                    label: 'Активность на странице',
                    command: e => setActivePanel('userProfiles_activitiesInProfiles'),
                },
                {
                    label: 'Поиск людей',
                    command: e => setActivePanel('userProfiles_usersSearch'),
                },
                {
                    label: 'Instagram и т. п.',
                    command: e => setActivePanel('userProfiles_instagram'),
                },
                {
                    label: 'Поиск по статусам',
                    command: e => setActivePanel('userProfiles_statusLocator'),
                },
            ],
        },
        {
            label: 'Фильтр баз',
            items: [
                {
                    label: 'Пересечение аудитории',
                    command: e => setActivePanel('filterBase_intersections'),
                },
                {
                    label: 'Поиск по базе',
                    command: e => setActivePanel('filterBase_searchBase'),
                },
                {
                    label: 'A/B тесты',
                    command: e => setActivePanel('filterBase_abTesting'),
                },
                {
                    label: 'Аналитика',
                    command: e => setActivePanel('filterBase_analysis'),
                },
                {
                    label: 'Преобразование id',
                    command: e => setActivePanel('filterBase_idsConverter'),
                },
            ],
        },
        {
            label: 'Другое',
            items: [
                {
                    label: 'Поиск по новостям',
                    command: e => setActivePanel('others_newsSearch'),
                },
                {
                    label: 'Популярные посты',
                    command: e => setActivePanel('others_popularPosts'),
                },
                {
                    label: 'Просмотр базы',
                    command: e => setActivePanel('others_baseViewer'),
                },
                {
                    label: 'Вовлекающие посты',
                    command: e => setActivePanel('others_involvingPosts'),
                },
                {
                    label: 'Конкурс активностей',
                    command: e => setActivePanel('others_activitiesContest'),
                },
            ],
        }
    ]
}

export default getMenuItems;

