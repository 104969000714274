import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {City, Country, Gender, StateWithErrors, StateWithProgress, TaskProgress} from "../../types";

export enum CommunitiesFilterSearchType {
    AUDIENCE = "audience",
    LAST_POST = "last_post",
    BLOCKED = "blocked",
    ACTIVITY = "activity",
}

export enum FilterSearchType {
    QUICK = "quick",
    SLOW = "slow"
}

export interface AudienceFilterResult {
    group: GroupObject,
    found: number,
    ratio: number,
}

export interface LastPostFilterResult {
    group: GroupObject,
    last_post_date?: number,
}

export interface BlockedFilterResult {
    group: GroupObject,
    blocked_members_count: number,
    ratio: number,
}

export interface ActivityFilterResult {
    group: GroupObject,
    posts_count: number,
    likes: number,
    comments: number,
    reposts: number,
    activity: number,
    engagement_rate: number,
}

interface AudienceFilterState extends StateWithErrors, StateWithProgress {
    settings: {
        source: string,
        age_min: number,
        age_max: number,
        gender: Gender,
        selected_country: Country|null,
        selected_city: City|null,
        search_type: FilterSearchType,
    }
    result: AudienceFilterResult[]
}

interface LastPostFilterState extends StateWithErrors, StateWithProgress {
    settings: {
        source: string,
        only_from_community: boolean,
        start_date: Date|null,
        end_date: Date|null,
    }
    result: LastPostFilterResult[],
}

interface BlockedFilterState extends StateWithErrors, StateWithProgress {
    settings: {
        source: string,
        members_count_min: number,
        members_count_max: number,
        members_count_blocked_min: number,
        members_count_blocked_max: number,
    },
    skipped: GroupObject[],
    sub_progress: TaskProgress,
    result: BlockedFilterResult[],
}

interface ActivityFilterState extends StateWithErrors, StateWithProgress {
    settings: {
        source: string,
        only_members: boolean,
        only_selected_users: boolean,
        selected_users_source: string,
        start_date: Date|null,
        end_date: Date|null,
    }
    sub_progress: TaskProgress,
    result: ActivityFilterResult[],
}

interface State {
    settings: {
        search_type: CommunitiesFilterSearchType
    },
    audience: AudienceFilterState,
    lastPost: LastPostFilterState,
    blocked: BlockedFilterState,
    activity: ActivityFilterState,
}

export interface CommunitiesFilterState extends State {}
