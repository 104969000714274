import React from "react";
import {useSelector} from 'react-redux';

import {RootState, TaskProgress} from "../../../../store/parsers/types";
import * as actions from "../../../../store/parsers/filter-base/ids-converter/actions";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import IdsConverterInput from "./idsConverter/IdsConverterInput";

const IdsConverter = (props: AppPanelProps) => {

    const state = useSelector((state: RootState) => state.idsConverter);
    const progress_users: TaskProgress = useSelector((state: RootState) => state.idsConverter.users.progress);
    const progress_groups: TaskProgress = useSelector((state: RootState) => state.idsConverter.groups.progress);

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Вы можете преобразовать цифровые id в текстовые или в ссылки и наоборот."
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/preobrazovanie_id"
                    title={props.title}
            />

            <IdsConverterInput type={'user'} label="Преобразование id пользователей" uploadLabel="Загрузить пользователей"
                               source={state.users.settings.source}
                               status={state.users.status} progress={progress_users} result={state.users.result}
                               uploadAction={actions.users.setSource} startAction={actions.users.start}
            />

            <IdsConverterInput type={'group'} label="Преобразование id сообществ" uploadLabel="Загрузить сообщества"
                               source={state.groups.settings.source}
                               status={state.groups.status} progress={progress_groups} result={state.groups.result}
                               uploadAction={actions.groups.setSource} startAction={actions.groups.start}
            />

        </AppPanel>
    );
};

export default IdsConverter;
