import {createAction} from "@reduxjs/toolkit";
import {Relation, UserObject} from "../../../../vkapi/objects/UserObject";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {City, Country, Gender} from "../../types";
import * as types from "./action-types";
import {ZodiacSign} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSourceUsers = createAction(types.SET_SOURCE_USERS, withPayloadType<string[]>());
export const setSourceNames = createAction(types.SET_SOURCE_NAMES, withPayloadType<string>());
export const setAgeFrom = createAction(types.SET_AGE_FROM, withPayloadType<number>());
export const setAgeTo = createAction(types.SET_AGE_TO, withPayloadType<number>());
export const setOnlyHiddenAge = createAction(types.SET_ONLY_HIDDEN_AGE, withPayloadType<boolean>());
export const setZodiacSign = createAction(types.SET_ZODIAC_SIGN, withPayloadType<ZodiacSign>());
export const setRemoveBlocked = createAction(types.SET_REMOVE_BLOCKED, withPayloadType<boolean>());
export const setKeepBlocked = createAction(types.SET_KEEP_BLOCKED, withPayloadType<boolean>());
export const setKeepVerified = createAction(types.SET_KEEP_VERIFIED, withPayloadType<boolean>());
export const setGender = createAction(types.SET_GENDER, withPayloadType<Gender>());
export const setSelectedCountry = createAction(types.SET_SELECTED_COUNTRY, withPayloadType<Country|null>());
export const setCities = createAction(types.SET_CITIES, withPayloadType<City[]>());
export const addFamily = createAction(types.ADD_FAMILY, withPayloadType<Relation>());
export const removeFamily = createAction(types.REMOVE_FAMILY, withPayloadType<Relation>());
export const setHasChildren = createAction(types.SET_HAS_CHILDREN, withPayloadType<boolean>());
export const setLastActivity = createAction(types.SET_LAST_ACTIVITY, withPayloadType<Date|null>());
export const setSourceStatus = createAction(types.SET_SOURCE_STATUS, withPayloadType<string>());
export const setStatusHasAllWords = createAction(types.SET_STATUS_HAS_ALL_WORDS, withPayloadType<boolean>());
export const setUsers = createAction(types.SET_USERS, withPayloadType<UserObject[]>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<UserObject[]>(types.NAMESPACE);
