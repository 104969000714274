import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {GroupsSearchType} from "../../../../vkapi/methods/GroupsSearchParams";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {date_in_range, is_post_before_start_date} from "../../../helpers/DateChecker";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import * as action_types from './action-types';
import * as actions from "./actions";
import {RootState, SearchTypeListOrSearch, TaskStatus} from "../../types";
import {InvolvingPost, InvolvingPostsState} from "./types";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import GetGroups from "../../../common-tasks/GetGroups";
import {OwnerPosts, Post} from "../../../../vkapi/objects/Post";
import GetPosts from "../../../../vkapi/tasks/GetPosts";

const main = function* () {
    while (yield take(action_types.START)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: InvolvingPostsState = yield select((state: RootState) => state.involvingPosts);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);
    const groups: GroupObject[] = yield GetGroups({
        search_type: state.settings.search_type,
        group_urls_source: state.settings.groups_source,
        search_queries_source: state.settings.search_queries_source,
        search_community_types: [GroupsSearchType.GROUP, GroupsSearchType.PAGE],
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });

    const is_date_in_range = date_in_range(state.settings.start_date, state.settings.end_date);
    const is_posts_start_date_reached = is_post_before_start_date(state.settings.start_date);

    const involving_posts: InvolvingPost[] = [];

    const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});
    for (const group of groups) {
        yield progress.next().value;
        if (group.deactivated) {
            continue;
        }
        yield put(actions.setSubProgress({message: 'Получаем посты'}));

        const posts: OwnerPosts = yield GetPosts({
            owner_id: group.id * -1,
            is_skip: (post: Post) => !is_date_in_range(post.date),
            is_stop: (post: Post) => is_posts_start_date_reached(post),
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
        });

        involving_posts.push(...getInvolvingPosts(group, posts));

        yield put(actions.setSubProgress({message: ''}));
    }

    yield put(actions.setSubProgress({message: ''}));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${involving_posts.length}`, total: undefined, current: undefined}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${involving_posts.length}`));
    yield put(actions.setResult(involving_posts));

    yield put({ type: action_types.COMPLETED});
}

function getInvolvingPosts(group: GroupObject, posts: OwnerPosts): InvolvingPost[] {
    const total_views: number = posts.posts.reduce<number>((total_views: number, post: Post) => {
        if (post.views && post.views.count) {
            return total_views + post.views.count;
        }
        return total_views;
    }, 0);

    const average_views: number = posts.posts.length > 0 ? total_views / posts.posts.length : 0;

    const mapper = (post: Post): InvolvingPost => {
        if (!post.views) {
            post.views = {
                count: 0
            }
        }
        const total_activities: number = post.reposts.count + post.comments.count + post.likes.count;
        const likes_to_reposts: number = post.reposts.count > 0 ? post.likes.count / post.reposts.count : 0;
        const activities_to_views: number = post.views.count > 0 ? total_activities / post.views.count : 0;
        const views_to_average: number = average_views > 0 ? post.views.count / average_views : 0;

        return {
            group: group,
            post: post,
            total_activities: total_activities,
            likes_to_reposts: likes_to_reposts,
            activities_to_views: activities_to_views,
            average_views_to_post: average_views,
            views_to_average: views_to_average,
        }
    };

    return posts.posts.map(mapper);
}

function* validate(state: InvolvingPostsState) {
    if (state.settings.search_type === SearchTypeListOrSearch.BY_GROUP_URLS && state.settings.groups_source.trim() === '') {
        yield put(showAlertError('Не указаны ссылки'));
        yield put(actions.stop());
        return;
    }
    if (state.settings.search_type === SearchTypeListOrSearch.BY_GROUPS_SEARCH && state.settings.search_queries_source.trim() === '') {
        yield put(showAlertError('Не указаны поисковые запросы'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(action_types.STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
