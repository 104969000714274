import {WithDefaultActions} from "../../store/parsers/types";
import {UserObject} from "../objects/UserObject";
import GetUsers from "./GetUsers";

export interface GetUserByIdParams extends WithDefaultActions {
    user_id: number|string,
    access_token: string,
    fields?: string,
}

const GetUserById = function* (params: GetUserByIdParams): Generator<any, UserObject, any> {
    const result: Map<number, UserObject> = yield GetUsers({
        user_ids: [params.user_id],
        fields: params.fields,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action,
    });
    if (result.size > 0) {
        return Array.from(result.values())[0];
    }
    throw Error('Не удалось получить пользователя по id: ' + params.user_id);
};

export default GetUserById;
