import React, {FormEvent, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {Button} from "primereact/button";
import download_url from "../../../../helpers/download_url";
import {BaseAnalysisResultItem} from "../../../../store/parsers/filter-base/base-analysis/types";

import {DataSourceType, RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import * as actions from "../../../../store/parsers/filter-base/base-analysis/actions";
import useSave from "../../../hooks/UseSave";
import BasicRadioButton from "../../../ui/inputs/BasicRadioButton";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingName from "../../../ui/inputs/ParsingName";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicFileUpload from "../../../ui/upload/BasicFileUpload";

const BaseAnalysis = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.baseAnalysis);
    const progress: TaskProgress = useSelector((state: RootState) => state.baseAnalysis.progress);

    const [parsingName, setParsingName] = useState<string>('');

    const {saveRef, setDownloadUrl, anchors} = useSave(parsingName);

    const save = () => {

        const toLine = (item: BaseAnalysisResultItem) => `${item.label}: ${item.count} (${item.ratio.toFixed(2)})`;

        const lines: string[] = [];
        lines.push('Пол');
        state.result.genders.forEach(item => lines.push(toLine(item)));
        lines.push('Возраст');
        state.result.ages.forEach(item => lines.push(toLine(item)));
        lines.push('Город');
        state.result.cities.forEach(item => lines.push(toLine(item)));
        lines.push('Страна');
        state.result.countries.forEach(item => lines.push(toLine(item)));

        setDownloadUrl(download_url(lines.join('\r\n'), 'text/plain'));
        setTimeout(() => {
            if (saveRef && saveRef.current) {
                saveRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Проанализируйте соотношение соц-дем и гео характеристик участников группы."
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/analitika_auditorii"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Как искать">
                    <BasicRadioButton name="baseAnalysisSearchType" label="По ссылке на группу"
                                      value={DataSourceType.URL}
                                      onChange={e => dispatch(actions.setDatasourceType(e.value))}
                                      checked={state.settings.data_source_type === DataSourceType.URL}
                    />
                    <BasicRadioButton name="baseAnalysisSearchType" label="По файлу со списком id пользователей"
                                      value={DataSourceType.FILE}
                                      onChange={e => dispatch(actions.setDatasourceType(e.value))}
                                      checked={state.settings.data_source_type === DataSourceType.FILE}
                    />
                </InputContainer>

                {state.settings.data_source_type === DataSourceType.URL &&
                    <InputContainer label="Ссылка на сообщество">
                        <BasicTextInput placeholder="https://vk.com/cerebro_vk" value={state.settings.data_source_url}
                                        onChange={(e: FormEvent<HTMLInputElement>) => dispatch(actions.setDatasourceGroupUrl(e.currentTarget.value))}
                        />
                    </InputContainer>
                }
                {state.settings.data_source_type === DataSourceType.FILE &&
                    <InputContainer label="Загрузка из файла">
                        <BasicFileUpload chooseLabel="Загрузить id из файла"
                                         onUpload={(lines: string[]) => dispatch(actions.setDatasourceUserIds(lines.join('\n')))}
                        />
                    </InputContainer>
                }

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())}  className="p-field" />
                    {state.has_result &&
                        <SaveButton label="Сохранить" onClick={() => save()} />
                    }
                    {anchors}
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            {state.has_result &&
            <div style={{textAlign: "center"}}>
                <h4 className="tw-mt-4">Пол</h4>
                <ul>
                {state.result.genders.map(item => <li>{item.label}: {item.count} ({item.ratio})</li>)}
                </ul>

                <h4 className="tw-mt-4">Возраст</h4>
                <ul>
                    {state.result.ages.map(item => <li>{item.label}: {item.count} ({item.ratio})</li>)}
                </ul>

                <h4 className="tw-mt-4">Город</h4>
                <ul>
                    {state.result.cities.map(item => <li>{item.label}: {item.count} ({item.ratio})</li>)}
                </ul>

                <h4 className="tw-mt-4">Страна</h4>
                <ul>
                    {state.result.countries.map(item => <li>{item.label}: {item.count} {(item.ratio.toFixed(2) + '%')}</li>)}
                </ul>
            </div>
            }

        </AppPanel>
    );
};

export default BaseAnalysis;
