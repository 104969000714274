import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {DefaultProps, TaskProgress} from "../../types";
import * as actions from './actions';
import * as types from "./types";
import {FriendsAndFollowersSearchType} from "./types";

const initialState: types.FriendsAndFollowersState = {
    settings: {
        source: '',
        type: FriendsAndFollowersSearchType.ANY,
        min_count: 0,
    },
    sub_progress: {message: ''},
    ...DefaultStateProps<number[]>([])
};

const FriendsAndFollowersReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
        state.settings.source = action.payload;
    });
    builder.addCase(actions.setType, (state, action: PayloadAction<FriendsAndFollowersSearchType>) => {
        state.settings.type = action.payload;
    });
    builder.addCase(actions.setMinCount, (state, action: PayloadAction<number>) => {
        state.settings.min_count = action.payload;
    });
    builder.addCase(actions.setSubProgress, (state, action: PayloadAction<TaskProgress>) => {
        state.sub_progress = action.payload;
    });
    AddDefaultCasesToReducer<number[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<number[]>>);
});

export default FriendsAndFollowersReducer;
