
import {UserObject} from "../objects/UserObject";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import _chunk from "lodash/chunk";
import {UsersGetParams} from "../methods/UsersGetParams";
import {ProcessMethodsParams} from "../types";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {WithDefaultActions} from "../../store/parsers/types";

export interface GetUsersParams extends WithDefaultActions {
    user_ids: Array<number|string>,
    access_token: string,
    filter_func?: (user_id: UserObject) => boolean,
    fields?: string,
    chunk_size?: number,
}

const GetUsers = function* (params: GetUsersParams): Generator<any, Map<number, UserObject>, any> {
    const executes:Array<IVkMethod> = prepare(params.user_ids, params.chunk_size ?? 200, params.fields);
    const executes_result: Map<number, UserObject> = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    }, params.filter_func);
    return executes_result;
};

function prepare(user_ids: Array<number|string>, chunk_size: number, fields?: string): IVkMethod[] {
    const requests_per_execute: number = 25;
    const chunks: Array<number|string>[] = _chunk(user_ids, chunk_size);
    const users_chunks: Array<number|string>[][] = _chunk(chunks, requests_per_execute);

    return users_chunks.map((items: Array<number|string>[]) => {
        const requests: string[] = items.map((ids: Array<number|string>) => {
            const params: UsersGetParams = {
                user_ids: ids.join(','),
            };
            if (fields) {
                params.fields = fields;
            }
            const method: IVkMethod = methods.usersGet(params);
            return `API.users.get(${JSON.stringify(method.params)})`;
        });
        const code:string = `return [${requests.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams, filter_func?: (user_id: UserObject) => boolean): Generator<any, Map<number, UserObject>, any> {
    const result: Map<number, UserObject> = new Map<number, UserObject>();

    const handler = (response: UserObject[][]) => {
        response.forEach((response_item: UserObject[]) => {
            response_item.forEach((user: UserObject) => {
                if (!filter_func || filter_func(user)) {
                    result.set(user.id, user);
                    return;
                }
            })
        });
    };
    yield SendMethodsDefault<UserObject[][]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем пользователей',
        chunk_size: 6,
    });
    return result;
};

export default GetUsers;
