import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../../helpers/AddDefaultCasesToReducer";
import {DefaultProps} from "../../../types";
import {ActivityFilterResult, CommunitiesFilterState} from "../types";
import {activity as actions} from "../actions";

const ReducerActivity = (initialState: CommunitiesFilterState['activity']) => {
    return createReducer(initialState, builder => {
        builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
            state.settings.source = action.payload;
        });
        builder.addCase(actions.setOnlyMembers, (state, action: PayloadAction<boolean>) => {
            state.settings.only_members = action.payload;
        });
        builder.addCase(actions.setOnlySelectedUsers, (state, action: PayloadAction<boolean>) => {
            state.settings.only_selected_users = action.payload;
        });
        builder.addCase(actions.setSelectedUsersSource, (state, action: PayloadAction<string>) => {
            state.settings.selected_users_source = action.payload;
        });
        builder.addCase(actions.setStartDate, (state, action: PayloadAction<Date|null>) => {
            state.settings.start_date = action.payload;
        });
        builder.addCase(actions.setEndDate, (state, action: PayloadAction<Date|null>) => {
            state.settings.end_date = action.payload;
        });
        AddDefaultCasesToReducer<ActivityFilterResult[]>({
            actions: {
                setTaskStatus: actions.setTaskStatus,
                setProgress: actions.setProgress,
                setResult: actions.setResult,
                addApiError: actions.addApiError,
                resetApiErrors: actions.resetApiErrors
            }
        }, builder as unknown as ActionReducerMapBuilder<DefaultProps<ActivityFilterResult[]>>);
    });
};

export default ReducerActivity;
