import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {UserObject} from "../../../../../vkapi/objects/UserObject";
import {DefaultProps} from "../../../types";
import {BaseViewerState} from "../types";
import {users as actions} from "../actions";
import AddDefaultCasesToReducer from "../../../../helpers/AddDefaultCasesToReducer";

const ReducerUsers = (initialState: BaseViewerState['users']) => {
    return createReducer(initialState, builder => {
        builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
            state.settings.source = action.payload
        });
        builder.addCase(actions.setShowAvatar, (state, action: PayloadAction<boolean>) => {
            state.settings.show_avatar = action.payload
        });
        AddDefaultCasesToReducer<UserObject[]>({
            actions: {
                setTaskStatus: actions.setTaskStatus,
                setProgress: actions.setProgress,
                setResult: actions.setResult,
                addApiError: actions.addApiError,
                resetApiErrors: actions.resetApiErrors
            }
        }, builder as unknown as ActionReducerMapBuilder<DefaultProps<UserObject[]>>);
    });
};

export default ReducerUsers;
