import React, {useCallback, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {Button} from "primereact/button";
import download_txt from "../../../../helpers/DownloadTxt";

import {
    CommunityTypeFilter,
    RootState,
    SearchTypeListOrSearch,
    TaskProgress,
    TaskStatus
} from "../../../../store/parsers/types";
import * as actions from "../../../../store/parsers/communities-audience/admin-contacts/actions";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingName from "../../../ui/inputs/ParsingName";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";

const AdminContacts = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.adminContacts);
    const progress: TaskProgress = useSelector((state: RootState) => state.adminContacts.progress);

    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const [parsingName, setParsingName] = useState<string>('');

    const onSearchTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setSearchType(e.value)), [dispatch]);
    const onSearchFilterChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setSearchFilterCommunity(e.value)), [dispatch]);

    const save = () => {
        download_txt<number[]>({
            data: state.result,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: number[]) => data.map(item => item.toString()),
        });
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Соберите людей, которые указаны в сообществе в блоке контакты"
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/kontakty"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Как искать">
                    <BasicRadioButton name="searchType" label="По списку групп"
                                      value={SearchTypeListOrSearch.BY_GROUP_URLS}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === SearchTypeListOrSearch.BY_GROUP_URLS}
                    />
                    <BasicRadioButton name="searchType" label="Через поиск сообществ"
                                      value={SearchTypeListOrSearch.BY_GROUPS_SEARCH}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === SearchTypeListOrSearch.BY_GROUPS_SEARCH}
                    />
                </InputContainer>

                {state.settings.search_type === SearchTypeListOrSearch.BY_GROUP_URLS &&
                <TextAreaWithButton label={'Список групп (по одной на строке)'}
                                    placeholder={"https://vk.com/cerebro_vk"}
                                    value={state.settings.groups_source}
                                    onChange={(e) => dispatch(actions.setGroupsSource(e))}
                />
                }
                {state.settings.search_type === SearchTypeListOrSearch.BY_GROUPS_SEARCH &&
                    <div>
                        <TextAreaWithButton label={'Поисковые запросы (по одному на строке)'}
                                            placeholder={'Кухни'}
                                            value={state.settings.search_queries_source}
                                            onChange={(e) => dispatch(actions.setSearchQueriesSource(e))}
                        />
                        <InputContainer label="Как искать">
                            <BasicRadioButton name="searchFilter" label="всё"
                                              value={CommunityTypeFilter.ALL}
                                              onChange={onSearchFilterChange}
                                              checked={state.settings.search_filter_community === CommunityTypeFilter.ALL}
                            />
                            <BasicRadioButton name="searchFilter" label="группы"
                                              value={CommunityTypeFilter.GROUPS}
                                              onChange={onSearchFilterChange}
                                              checked={state.settings.search_filter_community === CommunityTypeFilter.GROUPS}
                            />
                            <BasicRadioButton name="searchFilter" label="паблики"
                                              value={CommunityTypeFilter.PUBLICS}
                                              onChange={onSearchFilterChange}
                                              checked={state.settings.search_filter_community === CommunityTypeFilter.PUBLICS}
                            />
                            <BasicRadioButton name="searchFilter" label="встречи"
                                              value={CommunityTypeFilter.EVENTS}
                                              onChange={onSearchFilterChange}
                                              checked={state.settings.search_filter_community === CommunityTypeFilter.EVENTS}
                            />
                        </InputContainer>
                    </div>
                }

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {state.result.length > 0 &&
                        <SaveButton label={'Сохранить id'} onClick={() => save()} />
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Скачать ссылки</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

        </AppPanel>
    );
};

export default AdminContacts;
