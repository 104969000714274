import {Relation, UserObject} from "../../../../vkapi/objects/UserObject";
import {City, Country, Gender, StateWithErrors, StateWithProgress} from "../../types";

export enum ZodiacSign {
    ANY = "any",
    OVEN = "oven",
    TELEC = "telec",
    BLIZNECI = "blizneci",
    RAK = "rak",
    LEV = "lev",
    DEVA = "deva",
    VESI = "vesi",
    SCORPION = "scorpion",
    STRELEC = "strelec",
    KOZEROG = "kozerog",
    VODOLEY = "vodoley",
    RIBI = "ribi",
}

interface State {
    settings: {
        source_users: string[],
        source_names: string,
        age_from: number,
        age_to: number,
        only_hidden_age: boolean,
        zodiac_sign: ZodiacSign,
        remove_blocked: boolean,
        keep_blocked: boolean,
        keep_verified: boolean,
        gender: Gender,
        selected_country: Country|null,
        cities: City[],
        family: Relation[],
        has_children: boolean,
        last_activity: Date|null,
        source_status: string,
        status_has_all_words: boolean,
    },
    users: UserObject[],
    result: UserObject[],
}

export interface SearchBaseState extends State, StateWithErrors, StateWithProgress {}
