import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import {DefaultProps} from "../../types";
import {UserScore} from "./types";
import * as types from "./types";
import * as actions from './actions';
import DefaultStateProps from "../../../helpers/DefaultStateProps";

const moment = require('moment');

const initialState: types.ActivitiesContestState = {
    settings: {
        source: '',
        start_date: moment().subtract(7, "days").startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
        score_like_enabled: true,
        score_like: 2,
        score_like_all_enabled: true,
        score_like_all: 20,
        score_comment_enabled: true,
        score_comment: 2,
        comment_min_length: 0,
        score_like_comment_enabled: true,
        score_like_comment: 4,
        score_best_comment_enabled: true,
        score_best_comment: 50,
        score_like_from_author_enabled: true,
        score_like_from_author: 20,
        score_like_owner_enabled: false,
        score_like_owner: 100,
        owner_url: '',
    },
    ...DefaultStateProps<types.UserScore[]>([])
};

const ActivitiesContestReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
        state.settings.source = action.payload;
    });
    builder.addCase(actions.setStartDate, (state, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });
    builder.addCase(actions.setScoreLikeEnabled, (state, action: PayloadAction<boolean>) => {
        state.settings.score_like_enabled = action.payload;
    });
    builder.addCase(actions.setScoreLike, (state, action: PayloadAction<number>) => {
        state.settings.score_like = action.payload;
    });
    builder.addCase(actions.setScoreLikeAllEnabled, (state, action: PayloadAction<boolean>) => {
        state.settings.score_like_all_enabled = action.payload;
    });
    builder.addCase(actions.setScoreLikeAll, (state, action: PayloadAction<number>) => {
        state.settings.score_like_all = action.payload;
    });
    builder.addCase(actions.setScoreCommentEnabled, (state, action: PayloadAction<boolean>) => {
        state.settings.score_comment_enabled = action.payload;
    });
    builder.addCase(actions.setScoreComment, (state, action: PayloadAction<number>) => {
        state.settings.score_comment = action.payload;
    });
    builder.addCase(actions.setCommentMinLength, (state, action: PayloadAction<number>) => {
        state.settings.comment_min_length = action.payload;
    });
    builder.addCase(actions.setScoreLikeCommentEnabled, (state, action: PayloadAction<boolean>) => {
        state.settings.score_like_comment_enabled = action.payload;
    });
    builder.addCase(actions.setScoreLikeComment, (state, action: PayloadAction<number>) => {
        state.settings.score_like_comment = action.payload;
    });
    builder.addCase(actions.setScoreBestCommentEnabled, (state, action: PayloadAction<boolean>) => {
        state.settings.score_best_comment_enabled = action.payload;
    });
    builder.addCase(actions.setScoreBestComment, (state, action: PayloadAction<number>) => {
        state.settings.score_best_comment = action.payload;
    });
    builder.addCase(actions.setScoreLikeFromAuthorEnabled, (state, action: PayloadAction<boolean>) => {
        state.settings.score_like_from_author_enabled = action.payload;
    });
    builder.addCase(actions.setScoreLikeFromAuthor, (state, action: PayloadAction<number>) => {
        state.settings.score_like_from_author = action.payload;
    });
    builder.addCase(actions.setScoreLikeOwnerEnabled, (state, action: PayloadAction<boolean>) => {
        state.settings.score_like_owner_enabled = action.payload;
    });
    builder.addCase(actions.setScoreLikeOwner, (state, action: PayloadAction<number>) => {
        state.settings.score_like_owner = action.payload;
    });
    builder.addCase(actions.setOwnerUrl, (state, action: PayloadAction<string>) => {
        state.settings.owner_url = action.payload;
    });

    AddDefaultCasesToReducer<UserScore[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<UserScore[]>>);
});

export default ActivitiesContestReducer;
