import {createAction} from "@reduxjs/toolkit";
import {VkApiError} from "../../../../vkapi/VkApiError";
import * as types from "./action-types";
import {ActivityType, TaskProgress, TaskStatus} from "../../types";
import {AllActivities, WhatToGet} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setDataSource = createAction(types.SET_DATASOURCE, withPayloadType<string>());
export const setWhatToGet = createAction(types.SET_WHAT_TO_GET, withPayloadType<WhatToGet>());
export const setStartDate = createAction(types.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.SET_END_DATE, withPayloadType<Date|null>());
export const setActivitiesMin = createAction(types.SET_ACTIVITIES_MIN, withPayloadType<number>());
export const setActivitiesMax = createAction(types.SET_ACTIVITIES_MAX, withPayloadType<number>());
export const setGroupsMin = createAction(types.SET_GROUPS_MIN, withPayloadType<number>());
export const setGroupsMax = createAction(types.SET_GROUPS_MAX, withPayloadType<number>());
export const setPostsMin = createAction(types.SET_POSTS_MIN, withPayloadType<number>());
export const setPostsMax = createAction(types.SET_POSTS_MAX, withPayloadType<number>());
export const setPostsEveryCommunity = createAction(types.SET_POSTS_EVERY_COMMUNITY, withPayloadType<boolean>());
export const addActivityType = createAction(types.ADD_ACTIVITY_TYPE, withPayloadType<ActivityType>());
export const removeActivityType = createAction(types.REMOVE_ACTIVITY_TYPE, withPayloadType<ActivityType>());

export const start = createAction(types.START);
export const stop = createAction(types.STOP);
export const addApiError = createAction(types.ADD_API_ERROR, withPayloadType<VkApiError>());
export const resetApiErrors = createAction(types.RESET_API_ERRORS);

export const setTaskStatus = createAction(types.SET_TASK_STATUS, withPayloadType<TaskStatus>());
export const setTaskError = createAction(types.SET_TASK_ERROR, withPayloadType<string>());
export const setProgress = createAction(types.SET_PROGRESS, withPayloadType<TaskProgress>());
export const setSubProgress = createAction(types.SET_SUBPROGRESS, withPayloadType<TaskProgress>());
export const setResult = createAction(types.SET_RESULT, withPayloadType<AllActivities[]>());
