import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import * as actions from "../../../../store/parsers/communities/communities-filter/actions";
import {CommunitiesFilterSearchType} from "../../../../store/parsers/communities/communities-filter/types";

import {RootState} from "../../../../store/parsers/types";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import FilterByActivity from "./communities-filter/FilterByActivity";
import FilterByAudience from "./communities-filter/FilterByAudience";
import FilterByBlocked from "./communities-filter/FilterByBlocked";
import FilterByLastPost from "./communities-filter/FilterByLastPost";

const CommunitiesFilter = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.communitiesFilter);

    const [description, setDescription] = useState<string>('Отфильтруйте только те группы, которые вам нужны.');
    const [helpUrl, setHelpUrl] = useState<string>('https://blog.xn--90aha1bhc1b.xn--p1ai/filtrsoobshchestv_filtratsiya_po_auditorii');

    const onTypeChange = (e: RadioButtonChangeEvent) => {
        dispatch(actions.setSearchType(e.value));
        if (e.value === CommunitiesFilterSearchType.AUDIENCE) {
            setDescription('Отфильтруйте только те группы, которые вам нужны.');
            setHelpUrl('https://blog.xn--90aha1bhc1b.xn--p1ai/filtrsoobshchestv_filtratsiya_po_auditorii')
        }
        if (e.value === CommunitiesFilterSearchType.LAST_POST) {
            setDescription('Отфильтруйте только те группы, которые вам нужны.');
            setHelpUrl('https://blog.xn--90aha1bhc1b.xn--p1ai/filtratsiya_po_date_poslednego_posta')
        }
        if (e.value === CommunitiesFilterSearchType.BLOCKED) {
            setDescription('Отфильтруйте только те группы, которые вам нужны.');
            setHelpUrl('https://blog.xn--90aha1bhc1b.xn--p1ai/filtr_soobshchestv_po_kolichestvu_zablokirovannykh')
        }
        if (e.value === CommunitiesFilterSearchType.ACTIVITY) {
            setDescription('Отфильтруйте только те группы, которые вам нужны.');
            setHelpUrl('https://blog.xn--90aha1bhc1b.xn--p1ai/filtratsiya_soobshchestv_po_aktivnosti')
        }
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description={description}
                    url={helpUrl}
                    title={props.title}
            />

            <ParsingSettingsContainer>
                <InputContainer label="Как фильтровать">
                    <BasicRadioButton name="communitiesFilterSearchType" label="по аудитории"
                                      value={CommunitiesFilterSearchType.AUDIENCE}
                                      onChange={onTypeChange}
                                      checked={state.settings.search_type === CommunitiesFilterSearchType.AUDIENCE}
                    />
                    <BasicRadioButton name="communitiesFilterSearchType" label="по дате последнего поста"
                                      value={CommunitiesFilterSearchType.LAST_POST}
                                      onChange={onTypeChange}
                                      checked={state.settings.search_type === CommunitiesFilterSearchType.LAST_POST}
                    />
                    <BasicRadioButton name="communitiesFilterSearchType" label="по количеству заблокированных"
                                      value={CommunitiesFilterSearchType.BLOCKED}
                                      onChange={onTypeChange}
                                      checked={state.settings.search_type === CommunitiesFilterSearchType.BLOCKED}
                    />
                    <BasicRadioButton name="communitiesFilterSearchType" label="по активности"
                                      value={CommunitiesFilterSearchType.ACTIVITY}
                                      onChange={onTypeChange}
                                      checked={state.settings.search_type === CommunitiesFilterSearchType.ACTIVITY}
                    />
                </InputContainer>
                {state.settings.search_type === CommunitiesFilterSearchType.AUDIENCE &&
                    <FilterByAudience />
                }
                {state.settings.search_type === CommunitiesFilterSearchType.LAST_POST &&
                    <FilterByLastPost/>
                }
                {state.settings.search_type === CommunitiesFilterSearchType.BLOCKED &&
                    <FilterByBlocked />
                }
                {state.settings.search_type === CommunitiesFilterSearchType.ACTIVITY &&
                    <FilterByActivity />
                }
            </ParsingSettingsContainer>


        </AppPanel>
    );
};

export default CommunitiesFilter;
