import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {ActivityType, TaskProgress} from "../../types";
import * as types from "./action-types";
import {InProfileActivities} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setDataSource = createAction(types.SET_DATASOURCE, withPayloadType<string>());
export const setStartDate = createAction(types.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.SET_END_DATE, withPayloadType<Date|null>());
export const setActivitiesMin = createAction(types.SET_ACTIVITIES_MIN, withPayloadType<number>());
export const setActivitiesMax = createAction(types.SET_ACTIVITIES_MAX, withPayloadType<number>());
export const setUsersMin = createAction(types.SET_USERS_MIN, withPayloadType<number>());
export const setUsersMax = createAction(types.SET_USERS_MAX, withPayloadType<number>());
export const setPostsMin = createAction(types.SET_POSTS_MIN, withPayloadType<number>());
export const setPostsMax = createAction(types.SET_POSTS_MAX, withPayloadType<number>());
export const setPostsEveryUser = createAction(types.SET_POSTS_EVERY_USER, withPayloadType<boolean>());
export const addActivityType = createAction(types.ADD_ACTIVITY_TYPE, withPayloadType<ActivityType>());
export const removeActivityType = createAction(types.REMOVE_ACTIVITY_TYPE, withPayloadType<ActivityType>());

export const setSubProgress = createAction(types.SET_SUBPROGRESS, withPayloadType<TaskProgress>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<InProfileActivities[]>(types.NAMESPACE);
