import {City, Country, Gender, StateWithErrors, StateWithProgress} from "../../types";

export enum UsersSearchType {
    DEFAULT = "DEFAULT",
    JOB = "JOB",
    WORK = "WORK",
    RELIGION = "RELIGION",
}

interface State {
    settings: {
        search_type: UsersSearchType,
        query: string,
        age_min: number,
        age_max: number,
        selected_country: Country|null,
        selected_city: City|null,
        gender: Gender,
    },
    result: number[],
}

export interface UsersSearchState extends State, StateWithErrors, StateWithProgress {}
