import {PopularityType, SuperstarItem, SuperstarsLocatorState, SearchType} from "./types";
import {DataSourceType, TaskProgress, TaskStatus} from "../../types";
import * as actions from './actions';
import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {VkApiError} from "../../../../vkapi/VkApiError";

const initialState: SuperstarsLocatorState = {
    settings: {
        data_source_type: DataSourceType.URL,
        data_source_url: '',
        data_source_file: '',
        search_type: SearchType.SEARCH_INSIDE_GROUP,
        popularity_type: PopularityType.POPULAR_BY_FRIENDS,
        min_friends: 0,
        max_friends: 0,
        users_to_show_count: 1000,
    },
    ...DefaultStateProps<SuperstarItem[]>([])
};


const PopularPeopleReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setDatasourceType, (state: SuperstarsLocatorState, action: PayloadAction<DataSourceType.FILE|DataSourceType.URL>) => {
        state.settings.data_source_type = action.payload;
    });
    builder.addCase(actions.setDatasourceGroupUrl, (state: SuperstarsLocatorState, action: PayloadAction<string>) => {
        state.settings.data_source_url = action.payload;
    });
    builder.addCase(actions.setDatasourceUserIds, (state:SuperstarsLocatorState, action: PayloadAction<string>) => {
        state.settings.data_source_file = action.payload;
    });
    builder.addCase(actions.setSearchType, (state: SuperstarsLocatorState, action: PayloadAction<SearchType>) => {
        state.settings.search_type = action.payload;
    });
    builder.addCase(actions.setPopularityType, (state: SuperstarsLocatorState, action: PayloadAction<PopularityType>) => {
        state.settings.popularity_type = action.payload;
    });
    builder.addCase(actions.setMinFriends, (state: SuperstarsLocatorState, action: PayloadAction<number>) => {
        state.settings.min_friends = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setMaxFriends, (state: SuperstarsLocatorState, action: PayloadAction<number>) => {
        state.settings.max_friends = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setUsersToShowCount, (state: SuperstarsLocatorState, action: PayloadAction<number>) => {
        state.settings.users_to_show_count = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setTaskStatus, (state: SuperstarsLocatorState, action: PayloadAction<TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setProgress, (state: SuperstarsLocatorState, action: PayloadAction<TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: SuperstarsLocatorState, action: PayloadAction<SuperstarItem[]>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: SuperstarsLocatorState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state: SuperstarsLocatorState) => {
        state.api_errors = [];
    });
});

export default PopularPeopleReducer;
