export const ERROR_CODE_RATE_LIMIT = 29;
export const ERROR_CODE_HIDDEN_MEMBERS = 15;
export const ERROR_CODE_TOO_MANY_REQUESTS = 6;

export interface VkApiError {
    error_type: 'client_error' | 'api_error' | 'auth_error',
    error_data: {
        error_code: number,
        error_reason: {
            error_code: number,
            error_msg: string,
            request_params: {
                key: string,
                value: string
            }[]
        }
    }
}

export interface VkApiErrorExecute {
    method: string,
    error_code: number,
    error_msg: string,
}

export function convert_execute_error_to_api_error(error: VkApiErrorExecute): VkApiError {
    return {
        error_type: "client_error",
        error_data: {
            error_reason: {
                error_msg: error.error_msg,
                error_code: error.error_code,
                request_params: [
                    {key: 'method', value: error.method}
                ]
            },
            error_code: error.error_code
        }
    }
}
