import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {OwnerPosts, Post} from "../../../../vkapi/objects/Post";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {convert_urls_to_id} from "../../../../vkapi/tasks/ConverUrlToId";
import GetActivitiesIds, {PostActivitiesIds} from "../../../../vkapi/tasks/GetActivitiesIds";
import GetPosts from "../../../../vkapi/tasks/GetPosts";
import GetUsers from "../../../../vkapi/tasks/GetUsers";
import {date_in_range, is_post_before_start_date} from "../../../helpers/DateChecker";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import {EMPTY_URL_GROUP_LIST} from "../../validation-errors";
import * as actions from "./actions";
import {ActivityType, RootState, TaskStatus} from "../../types";
import {showAlert, showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {ActivitiesInProfilesState, InProfileActivities} from "./types";

interface ProfileActivities {
    from_id,
    posts: number,
    activities: number,
}

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        console.log(e);
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: ActivitiesInProfilesState = yield select((state: RootState) => state.activitiesInProfiles);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const user_urls: string[] = convert_urls_to_id(state.settings.data_source.split('\n'));
    const users_map: Map<number, UserObject> = yield GetUsers({
        user_ids: user_urls,
        filter_func: (user) => !user.deactivated,
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });
    const users: UserObject[] = Array.from(users_map.values());

    const is_date_in_range = date_in_range(state.settings.start_date, state.settings.end_date);
    const is_posts_start_date_reached = is_post_before_start_date(state.settings.start_date);

    const all_activities: Map<number, InProfileActivities> = new Map<number, InProfileActivities>();
    const progress = ProgressGenerator({text: 'Обрабатываем пользователей', total: users.length, action: actions.setProgress});
    for (const user of users) {
        yield progress.next().value;
        const posts: OwnerPosts = yield GetPosts({
            owner_id: user.id,
            is_skip: (post: Post) => !is_date_in_range(post.date),
            is_stop: (post: Post) => is_posts_start_date_reached(post),
            access_token: access_token,
        });

        const activities: Map<number, PostActivitiesIds> = yield GetActivitiesIds({
            posts: posts.posts,
            activity_types: new Set<ActivityType>(state.settings.activity_types),
            access_token: access_token,
            progress_action: actions.setSubProgress,
            add_api_errors_action: actions.addApiError,
        });

        const profile_activities: Map<number, ProfileActivities> = summarize_profile_activities(Array.from(activities.values()));
        profile_activities.forEach((value: ProfileActivities, from_id: number) => {
            if (state.settings.is_posts_every_user) {
                if (state.settings.posts_min > 0 && value.posts < state.settings.posts_min) {
                    return;
                }
                if (state.settings.posts_max > 0 && value.posts > state.settings.posts_max) {
                    return;
                }
            }
            const item: InProfileActivities = all_activities.get(from_id) || {
                from_id: from_id,
                activities: 0,
                users: 0,
                posts: 0,
            };
            item.activities += value.activities;
            item.posts += value.posts;
            item.users++;
            all_activities.set(from_id, item);
        });
    }

    const result: InProfileActivities[] = Array.from(all_activities.values()).filter((item: InProfileActivities) => {
        if (item.from_id < 0) {
            return false;
        }
        if (state.settings.users_min > 0 && item.users < state.settings.users_min) {
            return false;
        }
        if (state.settings.users_max > 0 && item.users > state.settings.users_max) {
            return false;
        }
        if (state.settings.posts_min > 0 && item.posts < state.settings.posts_min) {
            return false;
        }
        if (state.settings.posts_max > 0 && item.posts > state.settings.posts_max) {
            return false;
        }
        if (state.settings.activities_min > 0 && item.activities < state.settings.activities_min) {
            return false;
        }
        if (state.settings.activities_max > 0 && item.activities > state.settings.activities_max) {
            return false;
        }
        return true;
    });

    yield put(actions.setResult(result));
    yield put(actions.setSubProgress({message: '', total: undefined, current: undefined}));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result.length}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${result.length}`));
    yield put(actions.setCompleted());
}

function summarize_profile_activities(post_activities: PostActivitiesIds[]): Map<number, ProfileActivities> {
    const result: Map<number, ProfileActivities> = new Map<number, ProfileActivities>();
    post_activities.forEach((item: PostActivitiesIds) => {
        const post_activities: Set<number> = new Set<number>();
        item.likes.concat(item.reposts, item.comments, item.comments_likes).forEach((from_id: number) => {
            const result_item: ProfileActivities = result.get(from_id) ||
                {
                    from_id: from_id,
                    activities: 0,
                    posts: 0,
                };
            result_item.activities++;
            if (!post_activities.has(from_id)) {
                result_item.posts++;
                post_activities.add(from_id);
            }
            result.set(from_id, result_item);
        });
    });
    return result;
}

function* validate(state: ActivitiesInProfilesState) {
    if (state.settings.data_source.trim() === '') {
        yield put(showAlertError(EMPTY_URL_GROUP_LIST));
        yield put(actions.stop());
        return;
    }
    if (state.settings.activities_min > state.settings.activities_max && state.settings.activities_max > 0) {
        yield put(showAlert({text: 'Минимальное количество активностей не может быть больше, чем максимальное', header: 'Ошибка'}));
        yield put(actions.stop());
        return;
    }
    if (state.settings.users_min > state.settings.users_max && state.settings.users_max > 0) {
        yield put(showAlert({text: 'Минимальное количество групп не может быть больше, чем максимальное', header: 'Ошибка'}));
        yield put(actions.stop());
        return;
    }
    if (state.settings.posts_min > state.settings.posts_max && state.settings.posts_max > 0) {
        yield put(showAlert({text: 'Минимальное количество постов не может быть больше, чем максимальное', header: 'Ошибка'}));
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
