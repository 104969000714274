import {SearchTypeListOrSearch, WithDefaultActions} from "../parsers/types";
import {GroupObject} from "../../vkapi/objects/GroupObject";
import {convert_urls_to_id} from "../../vkapi/tasks/ConverUrlToId";
import * as actions from "../parsers/others/involving-posts/actions";
import GetGroupsVkTask from "../../vkapi/tasks/GetGroups";
import GroupsSearchQuick from "../../vkapi/tasks/GroupsSearchQuick";
import {GroupsSearchType} from "../../vkapi/methods/GroupsSearchParams";

export interface GetGroupsParams extends WithDefaultActions {
    search_type: SearchTypeListOrSearch,
    search_community_types: GroupsSearchType[],
    group_urls_source: string,
    search_queries_source: string,
    access_token: string,
    group_fields?: string,
}

const GetGroups = function* (params: GetGroupsParams): Generator<any, GroupObject[], any> {
    if (params.search_type === SearchTypeListOrSearch.BY_GROUP_URLS) {
        const group_urls: string[] = convert_urls_to_id(params.group_urls_source.split('\n'));
        const groups: GroupObject[] = yield GetGroupsVkTask({
            access_token: params.access_token,
            group_ids: group_urls,
            fields: params.group_fields,
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setProgress,
        });
        return groups;
    }
    if (params.search_type === SearchTypeListOrSearch.BY_GROUPS_SEARCH) {
        const queries: string[] = params.search_queries_source
            .split('\n')
            .map(str => str.trim()).filter(str => str !== '');
        const groups: GroupObject[] = yield GroupsSearchQuick({
            queries: queries,
            types: params.search_community_types,
            access_token: params.access_token,
            progress_action: params.progress_action,
            add_api_errors_action: params.add_api_errors_action,
        });
        return groups;
    }
    throw new Error('invalid type')
};

export default GetGroups
