import React, {useCallback, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {Button} from "primereact/button";
import download_txt from "../../../../helpers/DownloadTxt";

import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import * as actions from "../../../../store/parsers/communities-audience/top-readers/actions";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingName from "../../../ui/inputs/ParsingName";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";

const TopReaders = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.topReaders);
    const progress: TaskProgress = useSelector((state: RootState) => state.topReaders.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.topReaders.sub_progress);

    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const [parsingName, setParsingName] = useState<string>('');

    const onTopPlacesChange = useCallback((e: RadioButtonChangeEvent) => {
        dispatch(actions.setTopPlaces(parseInt(e.value)));
    }, [dispatch]);

    const save = () => {
        download_txt<number[]>({
            data: state.result,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: number[]) => data.map(item => item.toString()),
        });
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Узнайте, у каких пользователей ВКонтакте нужные Вам публичные страницы находятся в ТОПе."
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/top_chitateli"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Список групп (по одной на строке)'}
                                    placeholder={"https://vk.com/cerebro_vk"}
                                    value={state.settings.source}
                                    onChange={(e) => dispatch(actions.setSource(e))}
                />

                <InputContainer label="Топ">
                    <BasicRadioButton name="topPlaces" label="30"
                                      value={"30"}
                                      onChange={onTopPlacesChange}
                                      checked={state.settings.top_places === 30}
                    />
                    <BasicRadioButton name="topPlaces" label="20"
                                      value={"20"}
                                      onChange={onTopPlacesChange}
                                      checked={state.settings.top_places === 20}
                    />
                    <BasicRadioButton name="topPlaces" label="10"
                                      value={"10"}
                                      onChange={onTopPlacesChange}
                                      checked={state.settings.top_places === 10}
                    />
                    <BasicRadioButton name="topPlaces" label="5"
                                      value={"5"}
                                      onChange={onTopPlacesChange}
                                      checked={state.settings.top_places === 5}
                    />
                    <BasicRadioButton name="topPlaces" label="3"
                                      value={"3"}
                                      onChange={onTopPlacesChange}
                                      checked={state.settings.top_places === 3}
                    />
                    <BasicRadioButton name="topPlaces" label="1"
                                      value={"1"}
                                      onChange={onTopPlacesChange}
                                      checked={state.settings.top_places === 1}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {state.result.length > 0 &&
                    <SaveButton label={'Сохранить id'} onClick={() => save()} />
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Скачать ссылки</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>

        </AppPanel>
    );
};

export default TopReaders;
