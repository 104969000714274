import * as types from "./action-types";
import {createAction} from "@reduxjs/toolkit";
import {AlertProps} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setAccessToken = createAction(types.SET_ACCESS_TOKEN, withPayloadType<string>());
export const showAlert = createAction(types.SHOW_ALERT, withPayloadType<AlertProps>());
export const hideAlert = createAction(types.HIDE_ALERT);

export const showAlertError = (text: string) => showAlert({text: text, header: 'Ошибка'});
export const showAlertParsingCompleted = (text: string) => showAlert({text: text, header: 'Парсинг завершен'});
