import {createAction} from "@reduxjs/toolkit";
import * as types from "./action-types";
import {TaskProgress, TaskStatus} from "../../types";
import {VkApiError} from "../../../../vkapi/VkApiError";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setGroupsSource = createAction(types.SET_GROUPS_SOURCE, withPayloadType<string>());

export const setGroupsMin = createAction(types.SET_GROUPS_MIN, withPayloadType<number>());
export const setGroupsMax = createAction(types.SET_GROUPS_MAX, withPayloadType<number>());

export const setUserCounts = createAction(types.SET_USER_COUNTS, withPayloadType<Map<number, number>>());
export const setClosedGroups = createAction(types.SET_CLOSED_GROUPS, withPayloadType<number[]>());

export const start = createAction(types.START);
export const stop = createAction(types.STOP);
export const addApiError = createAction(types.ADD_API_ERROR, withPayloadType<VkApiError>());
export const resetApiErrors = createAction(types.RESET_API_ERRORS);

export const setTaskStatus = createAction(types.SET_TASK_STATUS, withPayloadType<TaskStatus>());
export const setTaskError = createAction(types.SET_TASK_ERROR, withPayloadType<string>());
export const setProgress = createAction(types.SET_PROGRESS, withPayloadType<TaskProgress>());
export const setResult = createAction(types.SET_RESULT, withPayloadType<number[]>());
