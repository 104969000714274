export const NAMESPACE = 'COMMUNITIES_FILTER/BLOCKED/';

export const SET_SOURCE = NAMESPACE + 'SET_SOURCE';
export const SET_MEMBERS_COUNT_MIN = NAMESPACE + 'SET_MEMBERS_COUNT_MIN';
export const SET_MEMBERS_COUNT_MAX = NAMESPACE + 'SET_MEMBERS_COUNT_MAX';
export const SET_MEMBERS_COUNT_BLOCKED_MIN = NAMESPACE + 'SET_MEMBERS_COUNT_BLOCKED_MIN';
export const SET_MEMBERS_COUNT_BLOCKED_MAX = NAMESPACE + 'SET_MEMBERS_COUNT_BLOCKED_MAX';

export const SET_SKIPPED = NAMESPACE + 'SET_SKIPPED';
export const SET_SUBPROGRESS = NAMESPACE + 'SET_SUBPROGRESS';
