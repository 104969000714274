import {createAction} from "@reduxjs/toolkit";
import {UserObject} from "../../../../../vkapi/objects/UserObject";
import DefaultActionsCreator from "../../../../helpers/DefaultActionsCreator";
import * as types from "../action-types";

function withPayloadType<T>() {
    return (t: T) => ({payload: t})
}

export const setSource = createAction(types.users.SET_SOURCE, withPayloadType<string>());
export const setShowAvatar = createAction(types.groups.SET_SHOW_AVATAR, withPayloadType<boolean>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<UserObject[]>(types.users.NAMESPACE);
