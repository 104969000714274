import {Either, isLeft} from "fp-ts/Either";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {UserIdAnyIds} from "../../../../vkapi/objects/UserIdAnyIds";
import CheckHiddenMembers from "../../../../vkapi/tasks/CheckHiddenMembers";
import {convert_urls_to_id} from "../../../../vkapi/tasks/ConverUrlToId";
import GetAllGroupMemberIds, {GetAllGroupMemberIdsError} from "../../../../vkapi/tasks/GetAllGroupMemberIds";
import GetGroups from "../../../../vkapi/tasks/GetGroups";
import GetSubscriptionsOfEveryUserId from "../../../../vkapi/tasks/GetSubscriptionsOfEveryUserId";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import * as actions from "./actions";
import {RootState, TaskStatus} from "../../types";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {TopReadersState} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: TopReadersState = yield select((state: RootState) => state.topReaders);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_urls: string[] = convert_urls_to_id(state.settings.source.split('\n'));
    const groups: GroupObject[] = yield GetGroups({
        access_token: access_token,
        group_ids: group_urls,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    const result: Set<number> = new Set<number>();
    const groups_with_hidden_members: GroupObject[] = [];
    const not_publics: GroupObject[] = [];
    const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});
    for (const group of groups) {
        yield progress.next().value;
        if (group.type !== "page") {
            not_publics.push(group);
            continue;
        }
        const is_closed: boolean = yield CheckHiddenMembers({group_id: group.id, access_token: access_token});
        if (is_closed) {
            groups_with_hidden_members.push(group);
            continue;
        }
        const group_members_ids: number[] = yield getGroupMembersIds(group.id, access_token);
        if (group_members_ids.length === 0) {
            continue;
        }
        const users_with_subscriptions: UserIdAnyIds[] = yield GetSubscriptionsOfEveryUserId({
            user_ids: group_members_ids,
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setSubProgress,
            method_params: {count: state.settings.top_places}
        });
        users_with_subscriptions.forEach(value => {
            value.ids.forEach((group_id: number, index: number) => {
                if (index + 1 > state.settings.top_places) {
                    return;
                }
                if (group_id === group.id) {
                    result.add(value.user_id);
                    return;
                }
            });
        });
    }

    yield put(actions.setResult(Array.from(result.values())));
    yield put(actions.setSubProgress({message: ``, total: undefined, current: undefined}));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result.size}. Пропущенно групп в закрытым списком участников ${groups_with_hidden_members.length}. Пропущено не пабликов: ${not_publics.length}.`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${result.size}. Пропущенно групп в закрытым списком участников ${groups_with_hidden_members.length}. Пропущено не пабликов: ${not_publics.length}.`));
    yield put(actions.setCompleted());
}

function* getGroupMembersIds(group_id: number, access_token: string): Generator<any, number[], any> {
    const group_members_ids_either: Either<GetAllGroupMemberIdsError, number[]> = yield GetAllGroupMemberIds({
        group_id: group_id,
        access_token: access_token,
        progressAction: actions.setSubProgress,
    });
    if (isLeft(group_members_ids_either)) {
        return [];
    }
    return group_members_ids_either.right;
}

function* validate(state: TopReadersState) {
    if (state.settings.source.trim() === '') {
        yield put(showAlertError('Не указаны ссылки'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
