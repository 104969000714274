import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {bool_sum} from "../../../../helpers/bool_to_int";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlert, showAlertError} from "../../../../store/app/actions";
import * as actions from "../../../../store/parsers/communities/search-communities/actions";
import selectFiltered from "../../../../store/parsers/communities/search-communities/selectors/FilteredSelector";
import {CommunitySearchItem, GroupAccessType} from "../../../../store/parsers/communities/search-communities/types";
import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {GroupsSearchSort} from "../../../../vkapi/methods/GroupsSearchParams";
import useCity from "../../../hooks/CityHook";
import useCountry from "../../../hooks/CountryHook";
import useMinMax from "../../../hooks/MinMaxHook";
import useSaveWithCsv from "../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicCheckbox, {CheckBoxChangeEvent} from "../../../ui/inputs/BasicCheckbox";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import DateInput from "../../../ui/inputs/DateInput";
import MinMaxInput from "../../../ui/inputs/MinMaxInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import WordsInput from "../../../ui/inputs/WordsInput";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import {CalendarChangeEvent} from "../../../ui/ui-events/CalendarChangeEvent";
import DropdownChangeEvent from "../../../ui/ui-events/DropdownChangeEvent";

const SearchCommunities = (props: AppPanelProps) => {

    const moment = require('moment');

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.searchCommunities);
    const cities = useSelector((state: RootState) => state.searchCommunities.settings.cities);
    const progress: TaskProgress = useSelector((state: RootState) => state.searchCommunities.progress);
    const result: CommunitySearchItem[] = useSelector(selectFiltered);

    const [selection, setSelection] = useState<CommunitySearchItem[]>([]);
    useEffect(() => {
        setSelection(result);
    }, [result]);

    const [parsingName, setParsingName] = useState<string>('');

    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);

    const CountryComponent = useCountry(state.settings.selected_country, actions.setSelectedCountry);
    const CityComponent = useCity(state.settings.selected_country, cities, actions.setCities);

    const onSearchQueryChange = useCallback((e: FormEvent<HTMLInputElement>) => dispatch(actions.setSearchQuery(e.currentTarget.value)), [dispatch]);
    const onStrictChange = useCallback((e: CheckBoxChangeEvent) => dispatch(actions.setIsStrict(e.checked)), [dispatch]);
    const onOnlyOfficialChange = useCallback((e: CheckBoxChangeEvent) => dispatch(actions.setOnlyOfficial(e.checked)), [dispatch]);
    const onCommunityTypeChange = useCallback((e: CheckBoxChangeEvent, action: ActionCreatorWithPayload<boolean>) => {
        if (! e.checked) {
            const elements = [
                state.settings.type_group,
                state.settings.type_public,
                state.settings.type_event,
                state.settings.type_future_event,
            ];
            const selected_elements = bool_sum(elements);
            if (selected_elements === 1) {
                dispatch(showAlertError('Нельзя снять все галочки'));
                return;
            }
        }
        dispatch(action(e.checked));
    }, [state, dispatch]);
    const onGroupAccessTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setGroupAccessType(e.value)), [dispatch]);
    const onSortChange = useCallback((e: DropdownChangeEvent<GroupsSearchSort>) => dispatch(actions.setSortType(e.value)), [dispatch]);

    const sortOptions = [
        {label: 'По количеству пользователй', value: GroupsSearchSort.DEFAULT},
        {label: 'По скорости роста', value: GroupsSearchSort.BY_GROWTH_SPEED},
        {label: 'По отношению дневной посещаемости к количеству пользователей', value: GroupsSearchSort.BY_DAILY_VISITORS},
        {label: 'По отношению количества лайков к количеству пользователей', value: GroupsSearchSort.BY_LIKES},
        {label: 'По отношению количества комментариев к количеству пользователей', value: GroupsSearchSort.BY_COMMENTS},
        {label: 'По отношению количества записей к количеству пользователей', value: GroupsSearchSort.BY_TOPICS},
    ];
    const [onMembersMinChange, onMembersMaxChange] = useMinMax(actions.setMembersMin, actions.setMembersMax);
    const [onTitleLengthMinChange, onTitleLengthMaxChange] = useMinMax(actions.setTitleLengthMin, actions.setTitleLengthMax);
    const onWordsPlusChange = useCallback((e: {value: string[]}) => dispatch(actions.setWordsPlus(e.value)), [dispatch]);
    const onWordsMinusChange = useCallback((e: {value: string[]}) => dispatch(actions.setWordsPlus(e.value)), [dispatch]);
    const onMinDateChange = useCallback((e: CalendarChangeEvent) => {
        let date: Date|null = null;
        if (e.value instanceof Date) {
            date = e.value;
        }
        dispatch(actions.setMinDate(date));
    }, [dispatch]);

    const save = () => {
        if (selection.length === 0) {
            dispatch(showAlert({text: 'Вы не выделили результаты, которые нужно сохранить', header: 'Ошибка'}));
            return;
        }
        const lines: string[] = [];
        selection.forEach((item) => {
            lines.push('https://vk.com/' + item.group.screen_name);
        });
        setDownloadUrl(download_url(lines.join('\r\n'), 'text/plain'));
        setTimeout(() => {
            if (saveRef && saveRef.current) {
                saveRef.current.click()
            }
        }, 1000);
    };

    const saveCSV = () => {
        if (selection.length === 0) {
            dispatch(showAlert({text: 'Вы не выделили результаты, которые нужно сохранить', header: 'Ошибка'}));
            return;
        }
        const headers: string[] = ['"Название"', '"Ссылка"', '"Подписчики"', '"Последний пост"'];
        const generator = (item: CommunitySearchItem) => {
            const line = [
                '"' + strip_punctuation(item.group.name ?? '') + '"',
                `"https://vk.com/club${item.group.id}"`,
                item.group.members_count,
                moment(item.last_post * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm'),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(selection, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Найдите группы и паблики по ключевому слову в названии. Для групп (НЕ ДЛЯ ПАБЛИКОВ) возможен поиск по географическому положению. Сортировку нужно выбирать ДО поиска."
                    url="http://blog.xn--90aha1bhc1b.xn--p1ai/poisk_soobshchestv"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Поисковый запрос">
                    <BasicTextInput id="searchQueryInput" placeholder="запрос для поиска" value={state.settings.search_query} onChange={onSearchQueryChange}/>
                </InputContainer>

                <BasicCheckbox
                    id="strictCheckbox" label="В названии должно быть только точное вхождение поисковой фразы"
                    checked={state.settings.is_strict === true}
                    onChange={onStrictChange}
                />

                <BasicCheckbox id="onlyOfficialCheckbox" label="Искать только в официальных сообществах"
                               checked={state.settings.is_strict}
                               onChange={onOnlyOfficialChange}
                />

                <InputContainer label="Тип сообщества">
                    <BasicCheckbox id="typeGroup" label="группа" checked={state.settings.type_group} onChange={e => onCommunityTypeChange(e, actions.setTypeGroup)}/>
                    <BasicCheckbox id="typePublic" label="паблик" checked={state.settings.type_public} onChange={e => onCommunityTypeChange(e, actions.setTypePublic)}/>
                    <BasicCheckbox id="typeEvent" label="встреча" checked={state.settings.type_event} onChange={e => onCommunityTypeChange(e, actions.setTypeEvent)}/>
                    <BasicCheckbox id="typeFutureEvent" label="будущая встреча" checked={state.settings.type_future_event} onChange={e => onCommunityTypeChange(e, actions.setTypeFutureEvent)}/>
                </InputContainer>

                <InputContainer label="Открытые или закрытые сообщества">
                    <BasicRadioButton id="groupAccessTypeAny" name="groupAccessType" label="любые"
                                      value={GroupAccessType.ANY}
                                      onChange={onGroupAccessTypeChange}
                                      checked={state.settings.group_access_type === GroupAccessType.ANY}
                    />
                    <BasicRadioButton id="groupAccessTypeOpen" name="groupAccessType" label="открытые"
                                      value={GroupAccessType.OPEN}
                                      onChange={onGroupAccessTypeChange}
                                      checked={state.settings.group_access_type === GroupAccessType.OPEN}
                    />
                    <BasicRadioButton id="groupAccessTypeClosed" name="groupAccessType" label="закрытые"
                                      value={GroupAccessType.CLOSED}
                                      onChange={onGroupAccessTypeChange}
                                      checked={state.settings.group_access_type === GroupAccessType.CLOSED}
                    />
                </InputContainer>

                {CountryComponent}

                {CityComponent}

                <InputContainer label="Сортировка">
                    <Dropdown options={sortOptions} value={state.settings.sort_type} onChange={onSortChange} />
                </InputContainer>

                <MinMaxInput label="Отфильтровать список по количеству подписчиков"
                             min={state.settings.members_min} onMinChange={onMembersMinChange}
                             max={state.settings.members_max} onMaxChange={onMembersMaxChange}
                />

                <MinMaxInput label="Отфильтровать список по количеству букв в названии"
                             min={state.settings.title_length_min} onMinChange={onTitleLengthMinChange}
                             max={state.settings.title_length_max} onMaxChange={onTitleLengthMaxChange}
                />

                <WordsInput label="Плюс слова" value={state.settings.words_plus} onChange={onWordsPlusChange}/>
                <WordsInput label="Минус слова" value={state.settings.words_minus} onChange={onWordsMinusChange}/>

                <DateInput label="Последний пост не позднее"
                           value={state.settings.min_date}
                           onValueChange={onMinDateChange}
                />

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {result.length > 0 && !state.settings.is_last_post_dates_found &&
                        <Button label="Найти даты последнего поста" onClick={() => dispatch(actions.findLastPostDates())} className="p-field"/>
                    }

                    {result.length > 0 &&
                        <>
                            <SaveButton label="Сохранить ссылки" onClick={save}/>
                            <SaveButton label="Сохранить CSV" onClick={saveCSV}/>

                        </>
                    }
                    {anchors}
                </ButtonsContainer>

            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            {result.length > 0 &&
                <div className="tw-mt-4">
                    <DataTable value={result} autoLayout={true}
                               sortField="group.members_count" sortOrder={-1}
                               paginator={true} rows={100} rowHover={true}
                               selection={selection} onSelectionChange={(e) => setSelection(e.value)}
                    >
                        <Column selectionMode="multiple" style={{width:'20px'}}/>
                        <Column field="group.name" header="Имя" body={(item: CommunitySearchItem, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/club${item.group.id}`} target="_blank" rel="noopener noreferrer">{item.group.name}</a></div>} sortable />
                        <Column field="group.members_count"  header="Подписчики" sortable />
                        <Column field="last_post"  header="Последний пост" body={(item: CommunitySearchItem) => item.last_post ? moment(item.last_post * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm') : 'не определен'} sortable />
                    </DataTable>
                </div>
            }

        </AppPanel>
    );
};

export default SearchCommunities;
