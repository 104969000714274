import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import * as types from "./types";
import * as global_types from "../../types";
import * as actions from './actions';
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {VkApiError} from "../../../../vkapi/VkApiError";

const initialState: types.GroupMembersState = {
    settings: {
        groups_source: '',
        groups_min: 0,
        groups_max: 0,
    },
    user_counts: new Map<number, number>(),
    closed_groups: [],
    ...DefaultStateProps<number[]>([])
};

const GroupMembersReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setGroupsSource, (state: types.GroupMembersState, action: PayloadAction<string>) => {
        state.settings.groups_source = action.payload;
    });
    builder.addCase(actions.setGroupsMin, (state: types.GroupMembersState, action: PayloadAction<number>) => {
        state.settings.groups_min = action.payload;
    });
    builder.addCase(actions.setGroupsMax, (state: types.GroupMembersState, action: PayloadAction<number>) => {
        state.settings.groups_max = action.payload;
    });
    builder.addCase(actions.setUserCounts, (state: types.GroupMembersState, action: PayloadAction<Map<number, number>>) => {
        state.user_counts = action.payload;
    });
    builder.addCase(actions.setClosedGroups, (state: types.GroupMembersState, action: PayloadAction<number[]>) => {
        state.closed_groups = action.payload;
    });

    builder.addCase(actions.setTaskStatus, (state: types.GroupMembersState, action: PayloadAction<global_types.TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setProgress, (state: types.GroupMembersState, action: PayloadAction<global_types.TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: types.GroupMembersState, action: PayloadAction<number[]>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: types.GroupMembersState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state: types.GroupMembersState) => {
        state.api_errors = [];
    });
});

export default GroupMembersReducer;
