import React from "react";

export interface AppPanelProps {
    id: string,
    title: string,
}

function AppPanel(props: React.PropsWithChildren<AppPanelProps>) {
    return (
        <div className="tw-mb-16">
            { props.children }
        </div>
    );
}

export default AppPanel;
