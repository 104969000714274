import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import {DataSourceType, DefaultProps} from "../../types";
import {BaseAnalysisResult} from "./types";
import * as types from "./types";
import * as actions from './actions';
import DefaultStateProps from "../../../helpers/DefaultStateProps";

const initialState: types.BaseAnalysisState = {
    settings: {
        data_source_type: DataSourceType.URL,
        data_source_file: '',
        data_source_url: '',
    },
    has_result: false,
    ...DefaultStateProps<BaseAnalysisResult>({
        genders: [],
        ages: [],
        cities: [],
        countries: [],
    })
};

const BaseAnalysisReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setDatasourceType, (state, action: PayloadAction<DataSourceType.URL|DataSourceType.FILE>) => {
        state.settings.data_source_type = action.payload;
    });
    builder.addCase(actions.setDatasourceGroupUrl, (state, action: PayloadAction<string>) => {
        state.settings.data_source_url = action.payload;
    });
    builder.addCase(actions.setDatasourceUserIds, (state, action: PayloadAction<string>) => {
        state.settings.data_source_file = action.payload;
    });
    builder.addCase(actions.setHasResult, (state, action: PayloadAction<boolean>) => {
        state.has_result = action.payload;
    });

    AddDefaultCasesToReducer<BaseAnalysisResult>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<BaseAnalysisResult>>);
});

export default BaseAnalysisReducer;
