const NAMESPACE = 'SEARCH_COMMUNITIES/';

export const SET_SEARCH_QUERY = NAMESPACE + 'SET_SEARCH_QUERY';
export const SET_IS_STRICT = NAMESPACE + 'SET_IS_STRICT';
export const SET_ONLY_OFFICIAL = NAMESPACE + 'SET_ONLY_OFFICIAL';

export const SET_TYPE_GROUP = NAMESPACE + 'SET_TYPE_GROUP';
export const SET_TYPE_PUBLIC = NAMESPACE + 'SET_TYPE_PUBLIC';
export const SET_TYPE_EVENT = NAMESPACE + 'SET_TYPE_EVENT';
export const SET_TYPE_FUTURE_EVENT = NAMESPACE + 'SET_TYPE_FUTURE_EVENT';

export const SET_GROUP_ACCESS_TYPE = NAMESPACE + 'SET_GROUP_ACCESS_TYPE';

export const SET_SELECTED_COUNTRY = NAMESPACE + 'SET_SELECTED_COUNTRY';
export const SET_CITIES = NAMESPACE + 'SET_CITIES';

export const SET_SORT_TYPE = NAMESPACE + 'SET_SORT_TYPE';

export const SET_MEMBERS_MIN = NAMESPACE + 'SET_MEMBERS_MIN';
export const SET_MEMBERS_MAX = NAMESPACE + 'SET_MEMBERS_MAX';

export const SET_TITLE_LENGTH_MIN = NAMESPACE + 'SET_TITLE_LENGTH_MIN';
export const SET_TITLE_LENGTH_MAX = NAMESPACE + 'SET_TITLE_LENGTH_MAX';

export const SET_WORDS_MINUS = NAMESPACE + 'SET_WORDS_MINUS';
export const SET_WORDS_PLUS = NAMESPACE + 'SET_WORDS_PLUS';

export const SET_MIN_DATE = NAMESPACE + 'SET_MIN_DATE';

export const SET_IS_LAST_POST_DATES_FOUND = NAMESPACE + 'SET_IS_LAST_POST_DATES_FOUND';

export const FIND_LAST_POST_DATES = NAMESPACE + 'FIND_LAST_POST_DATES';

export const START = NAMESPACE + 'START';
export const STOP = NAMESPACE + 'STOP';
export const SET_TASK_STATUS = NAMESPACE + 'SET_TASK_STATUS';
export const SET_TASK_ERROR = NAMESPACE + 'SET_TASK_ERROR';
export const SET_PROGRESS = NAMESPACE + 'SET_PROGRESS';
export const SET_RESULT = NAMESPACE + 'SET_RESULT';

export const ADD_API_ERROR = NAMESPACE + 'ADD_API_ERROR';
export const RESET_API_ERRORS = NAMESPACE + 'RESET_API_ERRORS';

export const COMPLETED = NAMESPACE + 'COMPLETED';
