import {put, select, take} from "redux-saga/effects";
import {showAlertParsingCompleted} from "../../../../app/actions";
import {RootState} from "../../../types";
import * as actions from "../actions";
import * as action_types from "../action-types"

const main = function* () {
    while (yield take(action_types.UNIQUE_START)) {
        const data_1: string[] = yield select((state: RootState) => state.intersections.settings.unique.data1);
        const data_2: string[] = yield select((state: RootState) => state.intersections.settings.unique.data2);
        const remove_items: Set<string> = new Set<string>(data_2);
        const not_unique_items: string[] = data_1.filter(item => {
            return remove_items.has(item);
        });
        const not_unique_set: Set<string> = new Set<string>(not_unique_items);
        const filter_func = (item) => !not_unique_set.has(item);
        const unique_items: string[] = data_1.filter(filter_func).concat(data_2.filter(filter_func));

        yield put(actions.unique_set_result(unique_items));
        yield put(showAlertParsingCompleted(`Готово. Результат: ${unique_items.length}`));
    }
};

export default main;
