import {WithDefaultActions} from "../../store/parsers/types";
import SendMethodsWithAutoRetry from "../helpers/SendMethodsWithAutoRetry";
import {IVkMethod} from "../IVkMethod";
import {NewsfeedSearchParams} from "../methods/NewsfeedSearchParams";
import * as methods from "../methods/VkMethods";
import {GroupObject} from "../objects/GroupObject";
import {Post} from "../objects/Post";
import {UserObject} from "../objects/UserObject";

export interface SearchNewsFeedParams extends WithDefaultActions {
    query: string,
    fields?: string,
    access_token: string,
}

export interface NewsSearchItem {
    from_id: number,
    owner: UserObject|GroupObject|undefined,
    post: Post,
}

const SearchNewsFeed = function* (params: SearchNewsFeedParams): Generator<any, Map<string, NewsSearchItem>, any> {

    interface TResponse {
        items: Post[],
        profiles: UserObject[],
        groups: GroupObject[],
        next_from?: string,
    }

    const method_params: NewsfeedSearchParams = {
        q: params.query,
        count: 100,
        extended: 1
    };
    if (params.fields) {
        method_params.fields = params.fields;
    }
    let method: IVkMethod = methods.newsfeedSearch(method_params);
    const result: Map<string, NewsSearchItem> = new Map<string, NewsSearchItem>();
    let next_from: string|undefined = undefined;

    const response_handler = (response: TResponse) => {
        const groups: Map<number, GroupObject> = new Map<number, GroupObject>();
        const profiles: Map<number, UserObject> = new Map<number, UserObject>();
        response.groups.forEach(group => groups.set(group.id, group));
        response.profiles.forEach(profile => profiles.set(profile.id, profile));
        response.items.forEach(post => {
            const unique_id: string = `${post.owner_id}_${post.id}`;
            let owner: UserObject|GroupObject|undefined;
            if (post.from_id < 0) {
                owner = groups.get(Math.abs(post.from_id));
            }
            if (post.from_id > 0) {
                owner = profiles.get(Math.abs(post.from_id));
            }
            if (!owner) {
                return;
            }
            result.set(unique_id, {
                from_id: post.from_id,
                post: post,
                owner: owner,
            })
        });
        next_from = response.next_from;
    };

    while (true) {
        let stop: boolean = false;
        yield SendMethodsWithAutoRetry<TResponse>({
            methods: [method],
            access_token: params.access_token,
            onResponse: response_handler,
            onError: error => {
                stop = true;
            },
        });
        if (!next_from) {
            stop = true;
        }
        else {
            method_params.start_from = next_from;
            method = methods.newsfeedSearch(method_params);
        }
        if (stop) {
            break;
        }
    }
    return result;
};


export default SearchNewsFeed;
