import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import * as actions from './actions';
import getBlankResult from "./helpers/blank_result";
import * as types from "./types";
import {IntersectionsResult} from "./types";

const initialState: types.IntersectionsState = {
    settings: {
        subtract: {
            data1: [],
            data2: [],
        },
        intersect: {
            data1: [],
            data2: [],
        },
        unique: {
            data1: [],
            data2: [],
        }
    },
    ...DefaultStateProps<IntersectionsResult>(getBlankResult())
};

const IntersectionsReducer = createReducer(initialState, builder => {
    builder.addCase(actions.subtract_set_data_1, (state: types.IntersectionsState, action: PayloadAction<string[]>) => {
        state.settings.subtract.data1 = action.payload;
    });
    builder.addCase(actions.subtract_set_data_2, (state: types.IntersectionsState, action: PayloadAction<string[]>) => {
        state.settings.subtract.data2 = action.payload;
    });
    builder.addCase(actions.subtract_set_result, (state: types.IntersectionsState, action: PayloadAction<string[]>) => {
        state.result.subtract = action.payload;
    });

    builder.addCase(actions.intersect_set_data_1, (state: types.IntersectionsState, action: PayloadAction<string[]>) => {
        state.settings.intersect.data1 = action.payload;
    });
    builder.addCase(actions.intersect_set_data_2, (state: types.IntersectionsState, action: PayloadAction<string[]>) => {
        state.settings.intersect.data2 = action.payload;
    });
    builder.addCase(actions.intersect_set_result, (state: types.IntersectionsState, action: PayloadAction<string[]>) => {
        state.result.intersect = action.payload;
    });

    builder.addCase(actions.unique_set_data_1, (state: types.IntersectionsState, action: PayloadAction<string[]>) => {
        state.settings.unique.data1 = action.payload;
    });
    builder.addCase(actions.unique_set_data_2, (state: types.IntersectionsState, action: PayloadAction<string[]>) => {
        state.settings.unique.data2 = action.payload;
    });
    builder.addCase(actions.unique_set_result, (state: types.IntersectionsState, action: PayloadAction<string[]>) => {
        state.result.unique = action.payload;
    });

    builder.addCase(actions.union_set_result, (state: types.IntersectionsState, action: PayloadAction<string[]>) => {
        state.result.union = action.payload;
    });
});

export default IntersectionsReducer;
