import {Comment} from "../../../../../vkapi/objects/Comment";
import {UserObject} from "../../../../../vkapi/objects/UserObject";
import {PostCommentsLikes} from "../../../../../vkapi/tasks/activities/GetCommentsLikes";
import {PostActivitiesItems} from "../../../../../vkapi/tasks/GetActivitiesItems";
import {date_in_range} from "../../../../helpers/DateChecker";
import {ActivitiesContestState, UserScore} from "../types";
import groupBy from "lodash/groupBy";

const get_blank = (user_id: number): UserScore => {
    return {
        comments: [],
        comments_best: [],
        comments_liked: [],
        comments_liked_by_author: [],
        comments_liked_by_owner: [],
        likes_post: [],
        likes_to_all_posts: [],

        score: 0,
        score_comments: 0,
        score_comments_best: 0,
        score_comments_liked: 0,
        score_comments_liked_by_owner: 0,
        score_comments_likes_by_author: 0,
        score_likes_post: 0,
        score_likes_to_all_posts: 0,
        user: {
            id: user_id,
        }
    }
};

export default function CalculateScores(
    activities: Map<number, PostActivitiesItems>,
    owner: UserObject|null,
    state: ActivitiesContestState
    ): Map<number, UserScore> {

    const filled_scores: Map<number, UserScore> = fillScoreItems(activities, owner, state);
    const calculated: Map<number, UserScore> = calculate(filled_scores, state);
    return calculated;

}

function fillScoreItems(activities: Map<number, PostActivitiesItems>,
                        owner: UserObject|null,
                        state: ActivitiesContestState)
    : Map<number, UserScore> {
    const is_date_in_range = date_in_range(state.settings.start_date, state.settings.end_date);

    const scores_map: Map<number, UserScore> = new Map<number, UserScore>();

    const get_score = (user_id: number): UserScore => {
        let score: UserScore|undefined = scores_map.get(user_id);
        if (!score) {
            const blank: UserScore = get_blank(user_id);
            scores_map.set(user_id, blank);
            return blank;
        }
        return score;
    };

    activities.forEach((value: PostActivitiesItems) => {
        // POST LIKES
        const post_url: string = `https://vk.com/wall${value.post.owner_id}_${value.post.id}`;
        value.likes.forEach(user_id => {
            let score = get_score(user_id);
            score.likes_post.push(post_url);
        });

        // COMMENTS
        const comments_map: Map<number, Comment> = new Map<number, Comment>();
        value.comments.forEach((comment: Comment) => {
            if (comment.deleted) {
                return;
            }
            if (!is_date_in_range(comment.date)) {
                return;
            }
            if (state.settings.comment_min_length > 0 && comment.text.length < state.settings.comment_min_length) {
                return;
            }
            const comment_url: string = `https://vk.com/wall${value.post.owner_id}_${value.post.id}?reply=${comment.id}`;
            if (comment.from_id < 0) {
                return;
            }
            let user_id = comment.from_id;
            comments_map.set(comment.id, comment);
            let score = get_score(user_id);
            score.comments.push(comment_url);
        });

        // BEST COMMENTS
        let best_comments: Comment[] = getBestComments(comments_map);
        best_comments.forEach(comment => {
            const comment_url: string = `https://vk.com/wall${comment.owner_id}_${comment.post_id}?reply=${comment.id}`;
            const user_id = comment.from_id;
            let score = get_score(user_id);
            score.comments_best.push(comment_url);
        });

        // COMMENT LIKES
        value.comments_likes.forEach((comment_like: PostCommentsLikes) => {
            const comment_url: string = `https://vk.com/wall${comment_like.owner_id}_${comment_like.post_id}?reply=${comment_like.comment_id}`;
            const comment: Comment|undefined = comments_map.get(comment_like.comment_id);
            if (!comment) {
                return;
            }
            const comment_owner: number = comment.from_id;
            let score = get_score(comment_owner);
            comment_like.likes.forEach((like_from: number) => {
                score.comments_liked.push(comment_url);
                if (like_from === value.post.signer_id) {
                    score.comments_liked_by_author.push(comment_url);
                }
                if (owner && like_from === owner.id) {
                    score.comments_liked_by_owner.push(comment_url);
                }
            });
        });
    });

    const activities_grouped_by_date: {[key: string]: PostActivitiesItems[]} = groupBy<PostActivitiesItems>(Array.from(activities.values()), (item: PostActivitiesItems) => {
        return new Date(item.post.date * 1000).toLocaleDateString("ru");
    });
    for (const [date, activities] of Object.entries(activities_grouped_by_date)) {
        const user_likes_counter: Map<number, number> = new Map<number, number>();
        activities.forEach(item => {
            item.likes.forEach(user_id => {
                let counter: number|undefined = user_likes_counter.get(user_id);
                if (!counter) {
                    counter = 0;
                }
                counter++;
                user_likes_counter.set(user_id, counter);
            });
        });
        user_likes_counter.forEach((counter: number, user_id: number) => {
            if (counter === activities.length) {
                let score = get_score(user_id);
                score.likes_to_all_posts.push(date);
            }
        });
    }

    return scores_map;
}

function calculate(scores: Map<number, UserScore>, state: ActivitiesContestState): Map<number, UserScore> {
    const settings = state.settings;
    scores.forEach((score: UserScore, user_id: number) => {
        if (settings.score_like_enabled) {
            score.score_likes_post = score.likes_post.length * settings.score_like;
            score.score += score.score_likes_post;
        }
        if (settings.score_like_comment_enabled) {
            score.score_comments_liked = score.comments_liked.length * settings.score_like_comment;
            score.score += score.score_comments_liked;
        }
        if (settings.score_comment_enabled) {
            score.score_comments = score.comments.length * settings.score_comment;
            score.score += score.score_comments;
        }
        if (settings.score_best_comment_enabled) {
            score.score_comments_best = score.comments_best.length * settings.score_best_comment;
            score.score += score.score_comments_best;
        }
        if (settings.score_like_owner_enabled) {
            score.score_comments_liked_by_owner = score.comments_liked_by_owner.length * settings.score_like_owner;
            score.score += score.score_comments_liked_by_owner;
        }
        if (settings.score_like_from_author_enabled) {
            score.score_comments_likes_by_author = score.comments_liked_by_author.length * settings.score_like_from_author;
            score.score += score.score_comments_likes_by_author;
        }
        if (settings.score_like_all_enabled) {
            score.score_likes_to_all_posts = score.likes_to_all_posts.length * settings.score_like_all;
            score.score += score.score_likes_to_all_posts;
        }
    });

    return scores;
}

function getBestComments(comments: Map<number, Comment>): Comment[] {
    let best_comments: Comment[] = [];
    let best_comment_likes: number = 0;
    comments.forEach((comment: Comment) => {
        if (!comment.likes?.count) {
            return;
        }
        if (comment.likes.count === best_comment_likes) {
            best_comments.push(comment);
        }
        if (comment.likes.count > best_comment_likes) {
            best_comments = [comment];
            best_comment_likes = comment.likes.count;
        }
    });
    return best_comments;
}

