import React from "react";
import {ProgressBar} from "primereact/progressbar";

export interface AppProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
    current?: number
    total?: number,
    message: string,
}

const classNames = require('classnames');

const AppProgressBar = (props: AppProgressBarProps) => {
    let percent = 0;
    if (props.total && props.current) {
        percent = Math.round(props.current / props.total * 100);
    }

    return (
        <div className={classNames(props.className, {'tw-hidden': !props.current && !props.total})}>
            <ProgressBar value={percent} />
            <div>{props.message}</div>
        </div>
    )
};

export default AppProgressBar;
