import strip_punctuation from "./strip-punctuation";

export function csv_generator<TItem>(items: TItem[], headers: string[], line_generator: (item: TItem) => string): string|null {
    if (items.length === 0) {
        return null;
    }
    const lines: string[] = [];
    items.forEach((item: TItem) => {
        lines.push(line_generator(item))
    });
    return "\ufeff" + headers.join(";") + "\n" + lines.join("\n");;
}

export function prepare_string(s: string): string {
    return '"' + strip_punctuation(s) + '"';
}
