import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {CalendarChangeEvent} from "../ui/ui-events/CalendarChangeEvent";

function useDateRange(setMin: ActionCreatorWithPayload<Date|null>, setMax: ActionCreatorWithPayload<Date|null>) {
    const dispatch = useDispatch();
    const onMinChange = useCallback((e: CalendarChangeEvent) => {
        let date: Date|null = null;
        if (e.value instanceof Date) {
            date = e.value;
        }
        dispatch(setMin(date));
    }, [dispatch, setMin]);

    const onMaxChange = useCallback((e: CalendarChangeEvent) => {
        let date: Date|null = null;
        if (e.value instanceof Date) {
            date = e.value;
        }
        dispatch(setMax(date));
    }, [dispatch, setMax]);

    return [onMinChange, onMaxChange];
}

export default useDateRange;
