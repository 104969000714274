import {StateWithErrors, StateWithProgress, TaskProgress, TopComment} from "../../types";

export enum FilterLikes {
    ALL_USERS = "all_users",
    SELECTED_USERS = "selected_users",
}

interface State {
    settings: {
        data_source: string,
        filter_likes: FilterLikes,
        filter_likes_users: string,
        start_date: Date|null,
        end_date: Date|null,
        is_skip_first_post: boolean,
        likes_min: number,
        likes_max: number,
        comments_min: number,
        comments_max: number,
        comments_length_min: number,
        comments_length_max: number,
        words_plus: string,
        words_minus: string,
    },
    sub_progress: TaskProgress,
    result: TopComment[],
}

export interface TopCommentatorsCommunityState extends State, StateWithErrors, StateWithProgress {}
