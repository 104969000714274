import {put, select, take} from "redux-saga/effects";
import {showAlertParsingCompleted} from "../../../../app/actions";
import {RootState} from "../../../types";
import * as actions from "../actions";
import * as action_types from "../action-types"

const main = function* () {
    while (yield take(action_types.SUBTRACT_START)) {
        const data_1: string[] = yield select((state: RootState) => state.intersections.settings.subtract.data1);
        const data_2: string[] = yield select((state: RootState) => state.intersections.settings.subtract.data2);
        const remove_items: Set<string> = new Set<string>(data_2);
        const filtered: string[] = data_1.filter(item => {
            return !remove_items.has(item);
        });
        yield put(actions.subtract_set_result(filtered));
        yield put(showAlertParsingCompleted(`Готово. Результат: ${filtered.length}`));
    }
};

export default main;
