import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import download_txt from "../../../../helpers/DownloadTxt";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../store/app/actions";
import * as actions from "../../../../store/parsers/communities-audience/promoposts/actions";
import selectFiltered from "../../../../store/parsers/communities-audience/promoposts/selectors/PromopostsSelector";
import {Promopost, PromopostsState} from "../../../../store/parsers/communities-audience/promoposts/types";
import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import useDateRange from "../../../hooks/DateRangeHook";
import useMinMax from "../../../hooks/MinMaxHook";
import useSaveWithCsv from "../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import DateRangeInput from "../../../ui/inputs/DateRangeInput";
import MinMaxInput from "../../../ui/inputs/MinMaxInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";

const Promoposts = (props: AppPanelProps) => {

    const moment = require('moment');

    const dispatch = useDispatch();
    const state: PromopostsState = useSelector((state: RootState) => state.promoposts);
    const progress: TaskProgress = useSelector((state: RootState) => state.promoposts.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.promoposts.sub_progress);

    const result: Promopost[] = useSelector(selectFiltered);

    const [selection, setSelection] = useState<Promopost[]>([]);

    useEffect(() => {
        setSelection(result);
    }, [result]);

    const [parsingName, setParsingName] = useState<string>('');

    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);
    const saveGroupsRef = useRef<HTMLAnchorElement>(null);
    const [downloadGroupsUrl, setDownloadGroupsUrl] = useState<string>('');

    const onGroupsSourceChange = useCallback((value: string) => dispatch(actions.setGroupsSource(value)), [dispatch]);
    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);
    const [onPostsMinChange, onPostsMaxChange] = useMinMax(actions.setPostsMin, actions.setPostsMax);

    const saveLinks = () => {
        if (selection.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        download_txt<Promopost[]>({
            data: selection,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: Promopost[]) => data.map(item => `https://vk.com/wall${item.post.owner_id}_${item.post.id}`),
        });
    };

    const saveCsv = () => {
        if (selection.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = ['"Ссылка"', '"Группа"', '"Ссылка на группу"', '"Пост"', '"Тип"', '"Лайки"', '"Репосты"', '"Smart"', '"Дата"',];
        const generator = (item: Promopost) => {
            let text: string = strip_punctuation(item.post.text);
            if (text.trim() === '') {
                text = "Без текста";
            }
            const post_type: string = (item.is_hidden ? 'Скрытый' : 'Открытый') + (item.type !== '' ? ' ' + item.type : '');
            const line = [
                `"https://vk.com/wall${item.post.owner_id}_${item.post.id}"`,
                `"${item.group.name}"`,
                `"https://vk.com/club${item.group.id}"`,
                `"${text}"`,
                `"${post_type}"`,
                item.post.likes.count,
                item.post.reposts.count,
                item.smart.toFixed(3).replace('.', ','),
                moment(item.post.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm'),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(selection, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    const saveGroups = () => {
        if (selection.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const lines: string[] = [];
        selection.forEach((item: Promopost) => {
            lines.push(`https://vk.com/club${item.group.id}`);
        });
        setDownloadGroupsUrl(download_url(lines.join('\r\n'), 'text/plain'));
        setTimeout(() => {
            if (saveGroupsRef && saveGroupsRef.current) {
                saveGroupsRef.current.click()
            }
        }, 1000);
    };


    return (
        <AppPanel id={props.id} title={props.title}>

            <ParsingHelp
                description="В этой вкладке Вы можете найти промо посты, которые размещались в группах."
                url="http://blog.xn--90aha1bhc1b.xn--p1ai/promoposty"
                title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Список групп (по одной на строке)'} value={state.settings.groups_source}
                                    placeholder={"https://vk.com/cerebro_vk"}
                                    onChange={onGroupsSourceChange} buttonId={'uploadButton'}
                />

                <InputContainer label="Дата (указывать не обязательно">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <MinMaxInput label="Минимальное и максимальное количество промопостов" id="postsMinMax"
                             min={state.settings.posts_min} onMinChange={onPostsMinChange}
                             max={state.settings.posts_max} onMaxChange={onPostsMaxChange}
                />

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)} />

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {result.length > 0 &&
                        <>
                            <SaveButton label="Сохранить ссылки" onClick={() => saveLinks()} />
                            <SaveButton label="Сохранить CSV" onClick={() => saveCsv()} />
                            <SaveButton label="Сохранить ссылки на группы" onClick={() => saveGroups()} />
                        </>
                    }
                    {anchors}
                    <a download={'groups.txt'} ref={saveGroupsRef} href={downloadGroupsUrl} style={{display: 'none'}}>Сохранить темы</a>
                </ButtonsContainer>

            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>
            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            {state.status !== TaskStatus.RUNNING && result.length > 0 &&
                <div className="tw-mt-4">
                    <DataTable value={result} autoLayout={true} rowHover={true} paginator={true} rows={100} selection={selection} onSelectionChange={(e) => setSelection(e.value)}>
                        <Column selectionMode="multiple" style={{width:'20px'}}/>
                        <Column header="Группа" field="group.id" body={(item: Promopost, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/${item.group.screen_name}`} target="_blank" rel="noopener noreferrer">{item.group.name}</a></div>} sortable />
                        <Column header="Пост"  field="post.text" body={(item: Promopost, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/wall${item.post.owner_id}_${item.post.id}`} target="_blank" rel="noopener noreferrer">{item.post.text.trim() === '' ? 'без текста' : item.post.text}</a></div>}/>
                        <Column header="Тип" field="is_hidden" body={(item: Promopost, row: any) => (item.is_hidden ? 'Скрытый' : 'Открытый') + (item.type !== '' ? ` (${item.type})` : '')} sortable />
                        <Column header="Лайки" field="post.likes.count" sortable />
                        <Column header="Репосты" field="post.reposts.count" sortable />
                        <Column header="Smart" field="smart" body={(item: Promopost) => item.smart.toFixed(3)} sortable />
                        <Column header="Дата" field="post.date" body={(item: Promopost) => moment(item.post.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm')} sortable />
                    </DataTable>
                </div>
            }

        </AppPanel>
    );
};

export default Promoposts;
