import {createAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import * as types from "./action-types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSource = createAction(types.SET_SOURCE, withPayloadType<string>());
export const setWordsPlus = createAction(types.SET_WORDS_PLUS, withPayloadType<string>());
export const setWordsMinus = createAction(types.SET_WORDS_MINUS, withPayloadType<string>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<GroupObject[]>(types.NAMESPACE);
