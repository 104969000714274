import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../../helpers/AddDefaultCasesToReducer";
import {DefaultProps} from "../../../types";
import {CommunitiesFilterState, LastPostFilterResult} from "../types";
import {lastPost as actions} from "../actions"

const ReducerLastPost = (initialState: CommunitiesFilterState['lastPost']) => {
    return createReducer(initialState, builder => {
        builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
            state.settings.source = action.payload;
        });
        builder.addCase(actions.setOnlyFromCommunities, (state, action: PayloadAction<boolean>) => {
            state.settings.only_from_community = action.payload;
        });
        builder.addCase(actions.setStartDate, (state, action: PayloadAction<Date|null>) => {
            state.settings.start_date = action.payload;
        });
        builder.addCase(actions.setEndDate, (state, action: PayloadAction<Date|null>) => {
            state.settings.end_date = action.payload;
        });
        AddDefaultCasesToReducer<LastPostFilterResult[]>({
            actions: {
                setTaskStatus: actions.setTaskStatus,
                setProgress: actions.setProgress,
                setResult: actions.setResult,
                addApiError: actions.addApiError,
                resetApiErrors: actions.resetApiErrors
            }
        }, builder as unknown as ActionReducerMapBuilder<DefaultProps<LastPostFilterResult[]>>);
    });
};

export default ReducerLastPost;
