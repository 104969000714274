import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {Button} from "primereact/button";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import download_txt from "../../../../helpers/DownloadTxt";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../store/app/actions";
import selectFiltered
    from "../../../../store/parsers/communities/group-statuses/selectors/FilteredSelector";

import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import * as actions from "../../../../store/parsers/communities/group-statuses/actions";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import useSaveWithCsv from "../../../hooks/SaveWithCsvHook";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingName from "../../../ui/inputs/ParsingName";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";

const GroupStatuses = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.groupStatuses);
    const progress: TaskProgress = useSelector((state: RootState) => state.groupStatuses.progress);
    const result: GroupObject[] = useSelector(selectFiltered);

    const [parsingName, setParsingName] = useState<string>('');

    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);

    const [selection, setSelection] = useState<GroupObject[]>([]);
    useEffect(() => {
        setSelection(result);
    }, [result]);

    const save = () => {
        download_txt<GroupObject[]>({
            data: selection,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: GroupObject[]) => data.map(item => `https://vk.com/${item.screen_name}`),
        });
    };

    const saveCSV = () => {

        if (result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = [
            '"Id"',
            '"Название"',
            '"Ссылка"',
            '"Статус"',
            '"Подписчики"',
        ];

        const generator = (item: GroupObject) => {
            const line: string[] = [
                item.id.toString(),
                '"' + strip_punctuation(item.name || '') + '"',
                `"https://vk.com/club${item.id}"`,
                '"' + strip_punctuation(item.status || '') + '"',
            ];
            return line.join(';');
        };

        const csv: string|null = csv_generator(result, headers, generator);
        if (!csv) {
            return;
        }

        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };


    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Поиск сообществ с определенными словами в статусе"
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/statusy_soobshchestva"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Ссылки на группы (по одной на строке)'}
                                    placeholder={"https://vk.com/cerebro_vk"}
                                    value={state.settings.source}
                                    onChange={(e) => dispatch(actions.setSource(e))}
                />

                <InputContainer label="Слова в статусе (через пробел)" notice={'Оставьте поле пустым, если хотите найти все статусы'}>
                    <BasicTextInput
                        value={state.settings.words_plus}
                        onChange={e => dispatch(actions.setWordsPlus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которые должны быть в статусе'}
                    />
                </InputContainer>

                <InputContainer label="Минус слова (через пробел)">
                    <BasicTextInput
                        value={state.settings.words_minus}
                        onChange={e => dispatch(actions.setWordsMinus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которых не должно быть в статусе'}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())}  className="p-field" />
                    {result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить ссылки" onClick={() => save()} />
                        <SaveButton label="Сохранить в CSV" onClick={() => saveCSV()} />
                    </>
                    }
                    {anchors}
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            {state.status !== TaskStatus.RUNNING && result.length > 0 &&
            <div className="tw-mt-4">
                <DataTable value={result}
                           autoLayout={true} rowHover={true} paginator={true} rows={100}
                           selection={selection}
                           onSelectionChange={(e) => setSelection(e.value)}>
                    <Column selectionMode="multiple" style={{width:'20px'}}/>
                    <Column header="Название" field="name" body={(item: GroupObject, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/club${item.screen_name}`} target="_blank" rel="noopener noreferrer">{item.name}</a></div>} sortable />
                    <Column header="Статус"  field="status" sortable/>
                    <Column header="Подписчики"  field="members_count" sortable/>
                </DataTable>
            </div>
            }

        </AppPanel>
    );
};

export default GroupStatuses;
