import {createSelector} from "reselect";
import {RootState} from "../../../types";
import {UserScore} from "../types";

const select_items = (state: RootState) => state.activitiesContest.result;

const selectFiltered = createSelector(
    [select_items],
    (items: UserScore[]) => items.filter(item => item.score > 0)
);

export default selectFiltered;
