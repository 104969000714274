import _chunk from "lodash/chunk";
import {WithDefaultActions} from "../../store/parsers/types";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import {WallGetParams} from "../methods/WallGetParams";
import {Post} from "../objects/Post";
import {ProcessMethodsParams} from "../types";
import maxBy from "lodash/maxBy";

export interface GetLastPostDatesParams extends WithDefaultActions {
    group_ids: number[],
    filter?: "suggests"|"postponed"|"owner"|"others"|"all",
    access_token: string,
}

const GetLastPostDates = function* (params: GetLastPostDatesParams): Generator<any, Map<number, number>, any> {
    const executes:Array<IVkMethod> = prepare(params.group_ids, params.filter);
    const executes_result: Map<number, number> = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(group_ids: number[], filter?: "suggests"|"postponed"|"owner"|"others"|"all"): IVkMethod[] {
    const requests_per_execute: number = 25;
    const chunks = _chunk(group_ids, requests_per_execute);
    return chunks.map((group_ids_chunk: number[]) => {
        const requests: string[] = group_ids_chunk.map((group_id: number) => {
            const params: WallGetParams = {
                owner_id: Math.abs(group_id) * -1,
                offset: 0,
                count: 2,
                extended: 0,
            };
            if (filter) {
                params.filter = filter;
            }
            const method: IVkMethod = methods.wallGet(params);
            return `{"group_id":${group_id},"result":API.${method.method_name}(${JSON.stringify(method.params)})}`;
        });
        const code:string = `return [${requests.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, Map<number, number>, any> {
    interface IResponse {
        group_id: number,
        result: {
            count: number,
            items: Post[],
        }|false,
    }
    const result: Map<number, number> = new Map<number, number>();

    const handler = (response: IResponse[]) => {
        response.forEach((response_item: IResponse) => {
            if (!response_item.result || !response_item.result.items || response_item.result.items.length === 0) {
                result.set(response_item.group_id, 0);
                return;
            }
            const last_post_date: number = maxBy(response_item.result.items, function (item: Post) {
                return item.date;
            })?.date || 0;
            result.set(response_item.group_id, last_post_date)
        });
    };

    yield SendMethodsDefault<IResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем даты последнего поста',
        chunk_size: 6
    });

    return result;
};

export default GetLastPostDates;
