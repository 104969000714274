import {createAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import DefaultActionsCreator from "../../../../helpers/DefaultActionsCreator";
import * as types from "../action-types";

function withPayloadType<T>() {
    return (t: T) => ({payload: t})
}

export const setSource = createAction(types.groups.SET_SOURCE, withPayloadType<string>());
export const setShowAvatar = createAction(types.groups.SET_SHOW_AVATAR, withPayloadType<boolean>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<GroupObject[]>(types.groups.NAMESPACE);
