import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import download_txt from "../../../../helpers/DownloadTxt";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../store/app/actions";
import * as actions from "../../../../store/parsers/communities-audience/topics-parser/actions";
import selectFiltered from "../../../../store/parsers/communities-audience/topics-parser/selectors/TopicsSelector";
import {TopicsParserState, TopicsSearchType} from "../../../../store/parsers/communities-audience/topics-parser/types";
import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {Topic} from "../../../../vkapi/objects/Topic";
import {TopicComment} from "../../../../vkapi/objects/TopicComment";
import useDateRange from "../../../hooks/DateRangeHook";
import useSaveWithCsv from "../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicCheckbox, {CheckBoxChangeEvent} from "../../../ui/inputs/BasicCheckbox";
import BasicNumbersInput, {NumberChangeEvent} from "../../../ui/inputs/BasicNumbersInput";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import DateRangeInput from "../../../ui/inputs/DateRangeInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";

const TopicParserCommunity = (props: AppPanelProps) => {

    const moment = require('moment');
    const dispatch = useDispatch();
    const state: TopicsParserState = useSelector((state: RootState) => state.topicsParser);
    const progress: TaskProgress = useSelector((state: RootState) => state.topicsParser.progress);

    const [parsingName, setParsingName] = useState<string>('');
    const result: Topic[] = useSelector(selectFiltered);
    const [selection, setSelection] = useState<Topic[]>([]);

    useEffect(() => {
        setSelection(result);
    }, [result]);

    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);
    const saveTopicsRef = useRef<HTMLAnchorElement>(null);
    const [downloadTopicsUrl, setDownloadTopicsUrl] = useState<string>('');

    const onSearchTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setSearchType(e.value)), [dispatch]);
    const onLinksTopicsChange = useCallback((value: string) => dispatch(actions.setLinksTopics(value)), [dispatch]);
    const onLinksGroupsChange = useCallback((value: string) => dispatch(actions.setLinksGroups(value)), [dispatch]);
    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);
    const onMinMessagesChange = useCallback((e: NumberChangeEvent) => dispatch(actions.setMinMessages(parseInt(e.value))), [dispatch]);
    const onSkipLinksChange = useCallback((e: CheckBoxChangeEvent) => dispatch(actions.setIsSkipMessagesWithUrls(e.checked)), [dispatch]);

    const groups = useMemo(() => {
        let groups_map: Map<number, GroupObject> = new Map<number, GroupObject>();
        state.groups.forEach(group => {
            groups_map.set(group.id, group);
        });
        return groups_map;
    }, [state.groups]);

    const save = () => {
        if (state.result.user_ids.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        download_txt<number[]>({
            data: state.result.user_ids,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: number[]) => data.map(item => item.toString()),
        });
    };

    const saveComments = () => {
        if (state.result.comments.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = ['"Ссылка"', '"Пользователь"', '"Количество лайков"', '"Текст"', '"Длина"', '"Дата"',];
        const generator = (item: TopicComment) => {
            const line = [
                `https://vk.com/topic-${item.owner_id}_${item.topic_id}?post=${item.id}}`,
                `https://vk.com/id${item.from_id}`,
                item.likes.count,
                '"' + strip_punctuation(item.text) + '"',
                item.text.length,
                moment(item.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm'),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(state.result.comments, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    const saveTopics = () => {
        if (state.result.topics.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        let topics: Topic[];
        if (state.settings.search_type === TopicsSearchType.TOPICS_ONLY) {
            topics = selection;
            if (topics.length === 0) {
                dispatch(showAlertError('Вы не выделили результаты, которые нужно сохранить'));
                return;
            }
        }
        else {
            topics = result;
        }
        const headers: string[] = ['"Тема"', '"Ответы"', '"Ссылка"', '"Название группы"', '"Ссылка на группу"', '"Обновлено"',];
        const generator = (item: Topic) => {
            const line = [
                '"' + strip_punctuation(item.title) + '"',
                item.comments,
                `"https://vk.com/topic-${item.owner_id}_${item.id}"`,
                '"' + strip_punctuation(get_group_name(item.owner_id)) + '"',
                `"https://vk.com/club${item.owner_id}"`,
                moment(item.updated * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm'),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(topics, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadTopicsUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveTopicsRef && saveTopicsRef.current) {
                saveTopicsRef.current.click()
            }
        }, 1000);
    };



    function get_group_name(group_id: number|undefined): string {
        if (!group_id) {
            return '';
        }
        const group: GroupObject|undefined = groups.get(group_id);
        if (group && group.name) {
            return group.name;
        }
        return '';
    }

    return (
        <AppPanel id={props.id} title={props.title}>

            <ParsingHelp
                description="Соберите людей, которые участвовали в обсуждениях в выбранных группах."
                url="http://blog.xn--90aha1bhc1b.xn--p1ai/obsuzhdeniya"
                title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Как искать">
                    <BasicRadioButton id="searchTypeByLinks" name="searchType" label="по ссылкам на обсуждения"
                                      value={TopicsSearchType.BY_LINKS}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === TopicsSearchType.BY_LINKS}
                    />
                    <BasicRadioButton id="searchTypeByGroups" name="searchType" label="по всем обсуждениям в группе"
                                      value={TopicsSearchType.BY_GROUPS}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === TopicsSearchType.BY_GROUPS}
                    />
                    <BasicRadioButton id="searchTypeByTopics" name="searchType" label="найти только обсуждения"
                                      value={TopicsSearchType.TOPICS_ONLY}
                                      onChange={onSearchTypeChange}
                                      checked={state.settings.search_type === TopicsSearchType.TOPICS_ONLY}
                    />
                </InputContainer>

                {state.settings.search_type === TopicsSearchType.BY_LINKS &&
                    <TextAreaWithButton label={'Ссылки на обсуждения (по одному на строке)'}
                                        placeholder={'https://vk.com/topic-73662138_31498998'}
                                        value={state.settings.links_topics}
                                        onChange={onLinksTopicsChange} buttonId={'uploadButtonLinks'}
                    />
                }

                {(state.settings.search_type === TopicsSearchType.BY_GROUPS || state.settings.search_type === TopicsSearchType.TOPICS_ONLY) &&
                    <TextAreaWithButton label={'Ссылки на группы (по одной на строке)'}
                                        placeholder={'https://vk.com/cerebro_vk'}
                                        value={state.settings.links_groups}
                                        onChange={onLinksGroupsChange} buttonId={'uploadButtonGroups'}
                    />
                }

                <InputContainer label="Оставить только темы со словами (через пробел)">
                    <BasicTextInput
                        value={state.settings.words_plus}
                        onChange={e => dispatch(actions.setWordsPlus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которые должны быть в теме'}
                    />
                </InputContainer>

                <InputContainer label="Пропускать темы со словами (через пробел)">
                    <BasicTextInput
                        value={state.settings.words_minus}
                        onChange={e => dispatch(actions.setWordsMinus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которых не должно быть в теме'}
                    />
                </InputContainer>

                <InputContainer label="Дата (указывать не обязательно">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <InputContainer label="Каким должно быть минимальное количество сообщений в теме">
                    <BasicNumbersInput id="minMessagesInput"
                                       value={state.settings.min_messages}
                                       onNumberChange={onMinMessagesChange}
                    />
                </InputContainer>

                <InputContainer label="Пропускать сообщения с ссылками">
                    <BasicCheckbox id="skipLinks" label="Пропускать сообщения с ссылками"
                                   checked={state.settings.is_skip_messages_with_urls}
                                   onChange={onSkipLinksChange}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())}  className="p-field"/>
                    {state.result.user_ids.length > 0 && state.settings.search_type !== TopicsSearchType.TOPICS_ONLY &&
                        <SaveButton label="Сохранить пользователей" onClick={() => save()} />
                    }
                    {state.result.comments.length > 0 && state.settings.search_type !== TopicsSearchType.TOPICS_ONLY &&
                        <SaveButton label="Сохранить посты с лайками" onClick={() => saveComments()} />
                    }
                    {state.status !== TaskStatus.RUNNING && state.result.topics.length > 0 &&
                        <SaveButton label="Сохранить темы" onClick={() => saveTopics()} />
                    }
                    {anchors}
                    <a download={'topics.txt'} ref={saveTopicsRef} href={downloadTopicsUrl} style={{display: 'none'}}>Сохранить темы</a>
                </ButtonsContainer>

            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            {(state.settings.search_type === TopicsSearchType.BY_LINKS || state.settings.search_type === TopicsSearchType.BY_GROUPS) && state.status !== TaskStatus.RUNNING &&
                <p>Найдено: {state.result.user_ids.length}</p>
            }
            {state.settings.search_type === TopicsSearchType.TOPICS_ONLY && state.status !== TaskStatus.RUNNING && state.result.topics.length > 0 &&
                <div className="tw-mt-4">
                    <DataTable value={result} rowHover={true} paginator={true} rows={100} selection={selection} onSelectionChange={(e) => setSelection(e.value)}>
                        <Column selectionMode="multiple" style={{width:'20px'}}/>
                        <Column field="title" header="Имя" body={(item: Topic, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/topic-${item.owner_id}_${item.id}`} target="_blank" rel="noopener noreferrer">{item.title}</a></div>} sortable />
                        <Column field="comments" header="Комментарии" sortable />
                        <Column field="updated"  header="Дата обновления" body={(item: Topic) => moment(item.updated * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm')} sortable />
                    </DataTable>
                </div>
            }

        </AppPanel>
    );
};

export default TopicParserCommunity;
