import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {DefaultProps, TaskProgress} from "../../types";
import * as actions from './actions';
import * as types from "./types";
import {StatusLocatorResult, StatusLocatorSearchType} from "./types";

const initialState: types.StatusLocatorState = {
    settings: {
        search_type: StatusLocatorSearchType.BY_GROUPS,
        source_groups: '',
        source_users: '',
        words_plus: '',
        words_minus: '',
        strict_mode: false,
    },
    skipped: [],
    sub_progress: {message: ''},
    ...DefaultStateProps<StatusLocatorResult[]>([])
};

const StatusLocatorReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSearchType, (state, action: PayloadAction<StatusLocatorSearchType>) => {
        state.settings.search_type = action.payload;
    });
    builder.addCase(actions.setSourceGroups, (state, action: PayloadAction<string>) => {
        state.settings.source_groups = action.payload;
    });
    builder.addCase(actions.setSourceUsers, (state, action: PayloadAction<string>) => {
        state.settings.source_users = action.payload;
    });
    builder.addCase(actions.setWordsPlus, (state, action: PayloadAction<string>) => {
        state.settings.words_plus = action.payload;
    });
    builder.addCase(actions.setWordsMinus, (state, action: PayloadAction<string>) => {
        state.settings.words_minus = action.payload;
    });
    builder.addCase(actions.setStrictMode, (state, action: PayloadAction<boolean>) => {
        state.settings.strict_mode = action.payload;
    });
    builder.addCase(actions.setSkipped, (state, action: PayloadAction<GroupObject[]>) => {
        state.skipped = action.payload;
    });
    builder.addCase(actions.setSubProgress, (state, action: PayloadAction<TaskProgress>) => {
        state.sub_progress = action.payload;
    });

    AddDefaultCasesToReducer<StatusLocatorResult[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<StatusLocatorResult[]>>);
});

export default StatusLocatorReducer;
