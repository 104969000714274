import {IVkMethod} from "../../IVkMethod";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {VkApiError} from "../../VkApiError";
import {UserIdAnyIds} from "../../objects/UserIdAnyIds";
import {TaskProgress} from "../../../store/parsers/types";
import SendMethodsDefault from "../../helpers/SendMethodsDefault";

const ExecuteUserIdGroupId = function* (
    executes:IVkMethod[],
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
): Generator<unknown, UserIdAnyIds[], any> {

    interface IResponse {
        user_id: number,
        result: {
            count: number,
            items: number[]
        }
    }

    let result:UserIdAnyIds[] = [];

    const handler = (response: IResponse[]) => {
        response.forEach((response_item: IResponse) => {
            const item:UserIdAnyIds = {
                user_id: response_item.user_id,
                ids: response_item.result ? response_item.result.items : []
            };
            result.push(item);
        });
    };

    yield SendMethodsDefault<IResponse[]>({
        methods_params: {
            methods: executes,
            access_token: access_token,
            progress_action: progress_action,
            add_api_errors_action: add_api_errors_action,
        },
        response_handler: handler,
        progress_text: 'Получаем группы пользователей',
        chunk_size: 6,
    });

    return result;
};

export default ExecuteUserIdGroupId;
