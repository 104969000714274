import {IVkMethod} from "../../IVkMethod";
import _chunk from "lodash/chunk";
import {WallGetCommentsParams} from "../../methods/WallGetCommentsParams";
import * as methods from "../../methods/VkMethods";
import {ProcessMethodsParams} from "../../types";
import {Comment} from "../../objects/Comment";
import SendMethodsDefault from "../../helpers/SendMethodsDefault";
import {FirstComments} from "./GetFirstComments";
import {PostComments} from "../GetComments";
import {WithDefaultActions} from "../../../store/parsers/types";

export interface GetRemainingTopLevelCommentsParams extends WithDefaultActions {
    first_comments: FirstComments[],
    preview_length: number,
    access_token: string,
}

const GetRemainingTopLevelComments = function* (params: GetRemainingTopLevelCommentsParams): Generator<any, PostComments[], any> {
    const executes: Array<IVkMethod> = prepare(params.first_comments, params.preview_length);
    if (executes.length === 0) {
        return [];
    }
    const executes_result: PostComments[] = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(first_comments: FirstComments[], preview_length: number): IVkMethod[] {
    const methods_per_execute: number = 20;
    const count: number = 100;

    const requests: string[] = [];
    first_comments.forEach((item: FirstComments) => {
        if (item.current_level_count <= 100) {
            return;
        }
        for (let offset: number = count; offset < item.current_level_count; offset += count) {
            const params: WallGetCommentsParams = {
                owner_id: item.owner_id,
                post_id: item.post_id,
                offset: offset,
                count: count,
                thread_items_count: 10,
                need_likes: 1,
                preview_length: preview_length,
            };
            const method: IVkMethod = methods.wallGetComments(params);
            requests.push(`{owner_id: ${item.owner_id}, post_id: ${item.post_id}, result: API.${method.method_name}(${JSON.stringify(method.params)})}`);
        }
    });

    if (requests.length === 0) {
        return [];
    }

    const requests_chunks: string[][] = _chunk(requests, methods_per_execute);
    return requests_chunks.map((chunk: string[]) => {
        const code:string = `return [${chunk.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, PostComments[], any> {
    interface TResponse {
        owner_id: number,
        post_id: number,
        result: {
            count: number,
            items: Comment[],
        }|false
    }

    const map: Map<string, PostComments> = new Map<string, PostComments>();

    const handler = (response: TResponse[]) => {
        response.forEach((response_item: TResponse) => {
            if (!response_item.result) {
                return;
            }
            let comments: Comment[] = [];
            response_item.result.items.forEach((comment: Comment) => {
                if (comment.thread && comment.thread.count > 0) {
                    comments = comments.concat(comment.thread.items);
                    comment.thread.items = [];
                }
                comments.push(comment);
            });
            const unique_id: string = `${response_item.owner_id}_${response_item.post_id}`;
            let post_comments: PostComments = map.get(unique_id) ||
                {
                    owner_id: response_item.owner_id,
                    post_id: response_item.post_id,
                    comments: [],
                };
            post_comments.comments = post_comments.comments.concat(comments);
            map.set(unique_id, post_comments);
        });
    };

    yield SendMethodsDefault<TResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем комментарии',
        chunk_size: 6,
    });
    return Array.from(map.values());
};

export default GetRemainingTopLevelComments;
