const NAMESPACE = 'POPULAR_POSTS/';

export const SET_DATASOURCE = NAMESPACE + 'SET_DATASOURCE';
export const SET_SELECTED_USERS = NAMESPACE + 'SET_SELECTED_USERS';
export const SET_ACTIVITIES_FROM = NAMESPACE + 'SET_ACTIVITIES_FROM';
export const SET_POSTS_FILTER = NAMESPACE + 'SET_POSTS_FILTER';
export const SET_START_DATE = NAMESPACE + 'SET_START_DATE';
export const SET_END_DATE = NAMESPACE + 'SET_END_DATE';

export const START = NAMESPACE + 'START';
export const STOP = NAMESPACE + 'STOP';
export const SET_TASK_STATUS = NAMESPACE + 'SET_TASK_STATUS';
export const SET_TASK_ERROR = NAMESPACE + 'SET_TASK_ERROR';
export const SET_SUBPROGRESS = NAMESPACE + 'SET_SUBPROGRESS';
export const SET_PROGRESS = NAMESPACE + 'SET_PROGRESS';
export const SET_RESULT = NAMESPACE + 'SET_RESULT';

export const ADD_API_ERROR = NAMESPACE + 'ADD_API_ERROR';
export const RESET_API_ERRORS = NAMESPACE + 'RESET_API_ERRORS';

export const COMPLETED = NAMESPACE + 'COMPLETED';
