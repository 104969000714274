import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {Post} from "../../../../vkapi/objects/Post";
import {VkApiError} from "../../../../vkapi/VkApiError";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import * as types from "./types";
import * as global_types from "../../types";
import * as actions from './actions';
import {ActivitiesFrom} from "./types";
import {PostsFilter} from "./types";

const moment = require('moment');

const initialState: types.PopularPostsState = {
    settings: {
        data_source: '',
        selected_users: '',
        activities_from: ActivitiesFrom.ALL,
        posts_filter: PostsFilter.ALL,
        start_date: moment().subtract(14, "days").startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
    },
    sub_progress: {message: ''},
    ...DefaultStateProps<[]>([])
};

const PopularPostsReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setDataSource, (state: types.PopularPostsState, action: PayloadAction<string>) => {
        state.settings.data_source = action.payload;
    });
    builder.addCase(actions.setSelectedUsers, (state: types.PopularPostsState, action: PayloadAction<string>) => {
        state.settings.selected_users = action.payload;
    });
    builder.addCase(actions.setActivitiesFrom, (state: types.PopularPostsState, action: PayloadAction<ActivitiesFrom>) => {
        state.settings.activities_from = action.payload;
    });
    builder.addCase(actions.setPostsFilter, (state: types.PopularPostsState, action: PayloadAction<PostsFilter>) => {
        state.settings.posts_filter = action.payload;
    });
    builder.addCase(actions.setStartDate, (state: types.PopularPostsState, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state: types.PopularPostsState, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });
    builder.addCase(actions.setTaskStatus, (state: types.PopularPostsState, action: PayloadAction<global_types.TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setSubProgress, (state: types.PopularPostsState, action: PayloadAction<global_types.TaskProgress>) => {
        state.sub_progress = action.payload;
    });
    builder.addCase(actions.setProgress, (state: types.PopularPostsState, action: PayloadAction<global_types.TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: types.PopularPostsState, action: PayloadAction<Post[]>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: types.PopularPostsState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state: types.PopularPostsState) => {
        state.api_errors = [];
    });
});

export default PopularPostsReducer;
