import {createAction} from "@reduxjs/toolkit";
import {Post} from "../../../../vkapi/objects/Post";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {TaskProgress} from "../../types";
import * as types from "./action-types";
import {PostsSearchAttachType} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setGroupsSource = createAction(types.SET_GROUPS_SOURCE, withPayloadType<string>());
export const setQueriesSearch = createAction(types.SET_QUERIES_SOURCE, withPayloadType<string>());
export const setIsStrict = createAction(types.SET_IS_STRICT, withPayloadType<boolean>());
export const setWordsMinus = createAction(types.SET_WORDS_MINUS, withPayloadType<string>());
export const setStartDate = createAction(types.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.SET_END_DATE, withPayloadType<Date|null>());
export const setAttachType = createAction(types.SET_ATTACH_TYPE, withPayloadType<PostsSearchAttachType>());

export const setSubProgress = createAction(types.SET_SUB_PROGRESS, withPayloadType<TaskProgress>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<Post[]>(types.NAMESPACE);
