import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {TaskProgress} from "../../types";
import * as types from "./action-types";
import {PostsType, StatsPostsCommunityResult} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSource = createAction(types.SET_SOURCE, withPayloadType<string>());
export const setPostType = createAction(types.SET_POST_TYPE, withPayloadType<PostsType>());
export const setOnlyWithVkCc = createAction(types.SET_ONLY_WITH_VK_CC, withPayloadType<boolean>());
export const setStartDate = createAction(types.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.SET_END_DATE, withPayloadType<Date|null>());

export const setSubProgress = createAction(types.SET_SUBPROGRESS, withPayloadType<TaskProgress>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<StatsPostsCommunityResult[]>(types.NAMESPACE);
