import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Comment} from "../../vkapi/objects/Comment";
import {Topic} from "../../vkapi/objects/Topic";
import {UserObject} from "../../vkapi/objects/UserObject";

import {VkApiError} from "../../vkapi/VkApiError";

import {VkAppState} from "../app/types";
import {AudienceAnalysisState} from "./audience-search/audience-analysis/types";
import {SuperstarsLocatorState} from "./audience-search/superstars-locator/types";
import {TargetAudienceLocatorState} from "./audience-search/target-audience-locator/types";
import {ActivitiesCommunityState} from "./communities-audience/activities/types";
import {AdminContactsState} from "./communities-audience/admin-contacts/types";
import {GroupMembersState} from "./communities-audience/group-members/types";
import {PostsAudienceState} from "./communities-audience/posts-audience/types";
import {PromopostsState} from "./communities-audience/promoposts/types";
import {StatsPostsCommunityState} from "./communities-audience/stats-posts-community/types";
import {TopCommentatorsCommunityState} from "./communities-audience/top-commentators/types";
import {TopReadersState} from "./communities-audience/top-readers/types";
import {TopicsParserState} from "./communities-audience/topics-parser/types";
import {CommunitiesFilterState} from "./communities/communities-filter/types";
import {GroupStatusesState} from "./communities/group-statuses/types";
import {PostsSearchState} from "./communities/posts-search/types";
import {SearchCommunitiesState} from "./communities/search-communities/types";
import {TestingState} from "./debug/basic-debug/types";
import {AbTestingState} from "./filter-base/ab-testing/types";
import {BaseAnalysisState} from "./filter-base/base-analysis/types";
import {IdsConverterState} from "./filter-base/ids-converter/types";
import {IntersectionsState} from "./filter-base/intersections/types";
import {SearchBaseState} from "./filter-base/search-base/types";
import {ActivitiesContestState} from "./others/activities-contest/types";
import {BaseViewerState} from "./others/base-viewer/types";
import {InvolvingPostsState} from "./others/involving-posts/types";
import {NewsSearchState} from "./others/news-search/types";
import {PopularPostsState} from "./others/popular-posts/types";
import {ActivitiesInProfilesState} from "./user-profiles/activities-in-profiles/types";
import {FriendsAndFollowersState} from "./user-profiles/friends-and-followers/types";
import {InstagramState} from "./user-profiles/instagram/types";
import {StatusLocatorState} from "./user-profiles/status-locator/types";
import {UsersSearchState} from "./user-profiles/users-search/types";

export interface RootState {
    app: VkAppState,
    targetAudienceLocator: TargetAudienceLocatorState,
    superstarsLocator: SuperstarsLocatorState,
    audienceAnalysis: AudienceAnalysisState,
    groupMembers: GroupMembersState,
    activitiesCommunity: ActivitiesCommunityState,
    topCommentatorsCommunity: TopCommentatorsCommunityState,
    topicsParser: TopicsParserState,
    intersections: IntersectionsState,
    popularPosts: PopularPostsState,
    involvingPosts: InvolvingPostsState,
    promoposts: PromopostsState,
    searchCommunities: SearchCommunitiesState,
    idsConverter: IdsConverterState,
    activitiesContest: ActivitiesContestState,
    adminContacts: AdminContactsState,
    topReaders: TopReadersState,
    postsAudience: PostsAudienceState,
    postsSearch: PostsSearchState,
    searchBase: SearchBaseState,
    friendsAndFollowers: FriendsAndFollowersState,
    communitiesFilter: CommunitiesFilterState,
    usersSearch: UsersSearchState,
    statusLocator: StatusLocatorState,
    baseAnalysis: BaseAnalysisState,
    abTesting: AbTestingState,
    instagram: InstagramState,
    groupStatuses: GroupStatusesState,
    activitiesInProfiles: ActivitiesInProfilesState,
    newsSearch: NewsSearchState,
    baseViewer: BaseViewerState,
    statsPostsCommunity: StatsPostsCommunityState,

    testing: TestingState,
}

export interface Country {
    id: number,
    title: string,
}

export interface City {
    id: number,
    title: string,
}

export interface StateWithErrors {
    api_errors: VkApiError[],
}

export interface StateWithProgress {
    status: TaskStatus,
    progress: TaskProgress,
}

export interface DefaultProps<TResult> extends StateWithErrors, StateWithProgress {
    task_title: string,
    result: TResult,
}

export interface WithDefaultActions {
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
}

export enum DataSourceType {
    URL= "url",
    URL_LIST = "url_list",
    FILE = "file",
}

export interface TaskProgress {
    message: string,
    current?: number,
    total?: number,
}

export interface CommonError {
    message: string
}

export interface ValidationError {
    field?: string,
    error: string
}

export enum TaskStatus {
    READY = "ready",
    RUNNING = "running",
    COMPLETE = "complete",
}

export enum ActivityType {
    LIKES = "likes",
    REPOSTS = "reposts",
    COMMENTS = "comments",
    COMMENT_LIKES = "comment_likes"
}

export interface UserComments {
    user?: UserObject,
    comments?: Comment[],
}

export enum CommunityTypeFilter {
    ALL = "all",
    GROUPS = "groups",
    PUBLICS = "publics",
    EVENTS = "events",
}

export interface TopComment {
    user_id: number,
    user_comments: UserComments,
    total_comments: number,
    total_likes: number,
    smart: number,
    proportion: number,
}

export interface GroupTopics {
    group_id: number,
    topics: Topic[],
}

export enum SearchTypeListOrSearch {
    BY_GROUP_URLS = "by_group_urls",
    BY_GROUPS_SEARCH = "by_groups_search"
}

export enum Gender {
    ANY = 0,
    FEMALE = 1,
    MALE = 2,
}

export interface DropdownValue<T> {
    label: string,
    value: T,
}

