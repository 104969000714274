const NAMESPACE = 'INVOLVING_POSTS/';

export const SET_SEARCH_TYPE = NAMESPACE + 'SET_SEARCH_TYPE';
export const SET_GROUPS_SOURCE = NAMESPACE + 'SET_GROUPS_SOURCE';
export const SET_SEARCH_QUERIES_SOURCE = NAMESPACE + 'SET_SEARCH_QUERIES_SOURCE';
export const SET_START_DATE = NAMESPACE + 'SET_START_DATE';
export const SET_END_DATE = NAMESPACE + 'SET_END_DATE';

export const START = NAMESPACE + 'START';
export const STOP = NAMESPACE + 'STOP';
export const SET_TASK_STATUS = NAMESPACE + 'SET_TASK_STATUS';
export const SET_TASK_ERROR = NAMESPACE + 'SET_TASK_ERROR';
export const SET_SUBPROGRESS = NAMESPACE + 'SET_SUBPROGRESS';
export const SET_PROGRESS = NAMESPACE + 'SET_PROGRESS';
export const SET_RESULT = NAMESPACE + 'SET_RESULT';

export const ADD_API_ERROR = NAMESPACE + 'ADD_API_ERROR';
export const RESET_API_ERRORS = NAMESPACE + 'RESET_API_ERRORS';

export const COMPLETED = NAMESPACE + 'COMPLETED';
