import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import download_txt from "../../../../helpers/DownloadTxt";
import * as actions from "../../../../store/parsers/others/news-search/actions";
import selectFiltered from "../../../../store/parsers/others/news-search/selectors/NewsSearchSelector";
import {
    LikesCount,
    MessageType,
    NewsAttachType,
    NewsSearchResult,
    ShowType
} from "../../../../store/parsers/others/news-search/types";

import {DropdownValue, RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import useCitySingle from "../../../hooks/CitySingleHook";
import useCountry from "../../../hooks/CountryHook";
import useDateRange from "../../../hooks/DateRangeHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicNumbersInput from "../../../ui/inputs/BasicNumbersInput";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import DateRangeInput from "../../../ui/inputs/DateRangeInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import truncate from "lodash/truncate"

const NewsSearch = (props: AppPanelProps) => {
    const moment = require('moment');

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.newsSearch);
    const progress: TaskProgress = useSelector((state: RootState) => state.newsSearch.progress);

    const [parsingName, setParsingName] = useState<string>('');

    const result: NewsSearchResult[] = useSelector(selectFiltered);
    const [selection, setSelection] = useState<NewsSearchResult[]>([]);

    const saveRefUsers = useRef<HTMLAnchorElement>(null);
    const saveRefGroups = useRef<HTMLAnchorElement>(null);
    const saveRefPosts = useRef<HTMLAnchorElement>(null);

    const [downloadUrlUsers, setDownloadUrlUsers] = useState<string>('');
    const [downloadUrlGroups, setDownloadUrlGroups] = useState<string>('');
    const [downloadUrlPosts, setDownloadUrlPosts] = useState<string>('');

    useEffect(() => {
        setSelection(result);
    }, [result]);

    const CountryComponent = useCountry(state.settings.selected_country, actions.setSelectedCountry);
    const CityComponent = useCitySingle(state.settings.selected_country, state.settings.selected_city, actions.setSelectedCity);
    const [onMinDateChange, onMaxDateChange] = useDateRange(actions.setStartDate, actions.setEndDate);

    const message_types: DropdownValue<MessageType>[] = [
        {label: 'Не важно', value: MessageType.ANY},
        {label: 'Только комментарии', value: MessageType.COMMENTS},
        {label: 'Только копии', value: MessageType.REPOSTS},
    ];

    const attach_types: DropdownValue<NewsAttachType>[] = [
        {label: 'Не важно', value: NewsAttachType.ANY},
        {label: 'Фотография', value: NewsAttachType.PHOTO},
        {label: 'Видеозапись', value: NewsAttachType.VIDEO},
        {label: 'Аудиозапись', value: NewsAttachType.AUDIO},
        {label: 'Граффити', value: NewsAttachType.GRAFFITI},
        {label: 'Заметка', value: NewsAttachType.NOTE},
        {label: 'Опрос', value: NewsAttachType.POLL},
        {label: 'Ссылка', value: NewsAttachType.LINK},
        {label: 'Документ', value: NewsAttachType.DOC},
        {label: 'Альбом', value: NewsAttachType.ALBUM},
        {label: 'Без прикреплений', value: NewsAttachType.NONE},
    ];

    const likes_types: DropdownValue<LikesCount>[] = [
        {label: 'Не важно', value: LikesCount.ANY},
        {label: 'Не менее 10', value: LikesCount.MIN_10},
        {label: 'Не менее 100', value: LikesCount.MIN_100},
        {label: 'Не менее 1000', value: LikesCount.MIN_1000},
    ];

    const show_types: DropdownValue<ShowType>[] = [
        {label: 'Все посты', value: ShowType.ANY},
        {label: 'Посты от людей', value: ShowType.USERS},
        {label: 'Посты от сообществ', value: ShowType.COMMUNITIES},
    ];

    function newsBody(item: NewsSearchResult, row: any) {

        function isUser(item: UserObject|GroupObject, owner_id: number): item is UserObject {
            return owner_id > 0;
        }

        function getTitle(item: NewsSearchResult) {
            if (isUser(item.owner, item.owner_id)) {
                return <a href={'https://vk.com/id' + item.owner.id} target={'_blank'} rel="noopener noreferrer">{item.owner.first_name} {item.owner.last_name}</a>
            }
            return <a href={'https://vk.com/club' + item.owner.id} target={'_blank'} rel="noopener noreferrer">{item.owner.name}</a>
        }
        return (
            <div>
                <p className="p-text-bold">{getTitle(item)}</p>
                <p>{row.rowIndex + 1}. {truncate(item.text, {length: 512})}</p>
            </div>
        );
    }

    const saveUsers = () => {
        download_txt<NewsSearchResult[]>({
            data: selection,
            ref: saveRefUsers,
            setDownloadUrl: setDownloadUrlUsers,
            mapper: (data: NewsSearchResult[]) => data
                .filter(item => item.owner_id > 0)
                .map(item => {
                    if (item.owner_id > 0) {
                        return `https://vk.com/id${(item.owner as UserObject).id}`
                    }
                    return '';
                }),
        });
    };
    const saveGroups = () => {
        download_txt<NewsSearchResult[]>({
            data: selection,
            ref: saveRefGroups,
            setDownloadUrl: setDownloadUrlGroups,
            mapper: (data: NewsSearchResult[]) => data
                .filter(item => item.owner_id > 0)
                .map(item => {
                    if (item.owner_id < 0) {
                        return `https://vk.com/${(item.owner as GroupObject).screen_name}`
                    }
                    return '';
                }),
        });
    };

    const savePosts = () => {
        download_txt<NewsSearchResult[]>({
            data: selection,
            ref: saveRefPosts,
            setDownloadUrl: setDownloadUrlPosts,
            mapper: (data: NewsSearchResult[]) => data.map(item => `https://vk.com/wall${item.owner_id}_${item.post_id}`),
        });
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Найдите упоминания ключевых слов в новостях"
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/poisk_po_novostyam"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Введите фразы для поиска (по одной на строке)'}
                                    placeholder={'свадьба'}
                                    value={state.settings.queries}
                                    onChange={(e) => dispatch(actions.setQueries(e))}
                />

                <InputContainer label="Минус слова (через пробел)">
                    <BasicTextInput
                        value={state.settings.words_minus}
                        onChange={e => dispatch(actions.setWordsMinus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которых не должно быть в тексте'}
                    />
                </InputContainer>

                <InputContainer label="Тип сообщения">
                    <Dropdown options={message_types} value={state.settings.message_type}
                              onChange={(e) => dispatch(actions.setMessageType(e.value))}
                              className="p-field tw-w-full sm:tw-flex-1"
                    />
                </InputContainer>

                <InputContainer label="Прикрепления">
                    <Dropdown options={attach_types} value={state.settings.attach_type}
                              onChange={(e) => dispatch(actions.setAttachType(e.value))}
                              className="p-field tw-w-full sm:tw-flex-1"
                    />
                </InputContainer>

                <InputContainer label="Лайки">
                    <Dropdown options={likes_types} value={state.settings.likes_count}
                              onChange={(e) => dispatch(actions.setLikesCount(e.value))}
                              className="p-field tw-w-full sm:tw-flex-1"
                    />
                </InputContainer>

                <InputContainer label="Фильтр по длине поста">
                    <BasicNumbersInput
                                       value={state.settings.min_text_length}
                                       onNumberChange={(e) => dispatch(actions.setMinTextLength(e.value))}
                    />
                </InputContainer>

                {CountryComponent}

                {CityComponent}

                <InputContainer label="Дата (указывать не обязательно">
                    <DateRangeInput
                        minDateValue={state.settings.start_date}
                        onMinDateChange={onMinDateChange}
                        maxDateValue={state.settings.end_date}
                        onMaxDateChange={onMaxDateChange}
                        notice={'*Данные берутся начиная с 00:01 начальной даты и до 23:59 конечной даты.'}
                    />
                </InputContainer>

                <InputContainer label="Что показывать">
                    <Dropdown options={show_types} value={state.settings.show_type}
                              onChange={(e) => dispatch(actions.setShowType(e.value))}
                              className="p-field tw-w-full sm:tw-flex-1"
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())}  className="p-field" />
                    {result.length > 0 && (state.settings.show_type === ShowType.USERS || state.settings.show_type === ShowType.ANY) &&
                        <SaveButton label="Сохранить пользователей" onClick={() => saveUsers()} />
                    }
                    {result.length > 0 && (state.settings.show_type === ShowType.COMMUNITIES || state.settings.show_type === ShowType.ANY) &&
                    <SaveButton label="Сохранить группы" onClick={() => saveGroups()} />
                    }
                    {result.length > 0 &&
                    <SaveButton label="Сохранить ссылки" onClick={() => savePosts()} />
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'users.txt'} ref={saveRefUsers} href={downloadUrlUsers} style={{display: 'none'}}>Скачать пользователей</a>
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'groups.txt'} ref={saveRefGroups} href={downloadUrlGroups} style={{display: 'none'}}>Скачать пользователей</a>
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'posts.txt'} ref={saveRefPosts} href={downloadUrlPosts} style={{display: 'none'}}>Скачать пользователей</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            {result.length > 0 &&
            <div className="tw-mt-4">
                <DataTable value={result} autoLayout={true}
                           paginator={true} rows={100} rowHover={true}
                           selection={selection} onSelectionChange={(e) => setSelection(e.value)}
                >
                    <Column selectionMode="multiple" style={{width:'20px'}}/>
                    <Column field="post_id" header="Новость" body={(item: NewsSearchResult, row: any) => newsBody(item, row)} />
                    <Column field="date" header="Дата" body={(item: NewsSearchResult) => <a href={`https://vk.com/wall${item.owner_id}_${item.post_id}`} className={'underline'}>{moment(item.date * 1000).utcOffset(3).format('DD.MM.YYYY HH:mm')}</a>} sortable />
                </DataTable>
            </div>
            }

        </AppPanel>
    );
};

export default NewsSearch;
