import {createAction} from "@reduxjs/toolkit";
import * as types from ".//action-types";
import {DataSourceType, TaskProgress, TaskStatus} from "../../types";
import {PopularityType, SuperstarItem, SearchType} from "./types";
import {VkApiError} from "../../../../vkapi/VkApiError";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setDatasourceType = createAction(types.SET_DATASOURCE_TYPE, withPayloadType<DataSourceType.URL|DataSourceType.FILE>());
export const setDatasourceGroupUrl = createAction(types.SET_DATASOURCE_GROUP_URL, withPayloadType<string>());
export const setDatasourceUserIds = createAction(types.SET_DATASOURCE_USER_IDS, withPayloadType<string>());
export const setSearchType = createAction(types.SET_SEARCH_TYPE, withPayloadType<SearchType>());
export const setPopularityType = createAction(types.SET_POPULARITY_TYPE, withPayloadType<PopularityType>());
export const setMinFriends = createAction(types.SET_MIN_FRIENDS, withPayloadType<number>());
export const setMaxFriends = createAction(types.SET_MAX_FRIENDS, withPayloadType<number>());
export const setUsersToShowCount = createAction(types.SET_USERS_TO_SHOW_COUNT, withPayloadType<number>());

export const start = createAction(types.START);
export const stop = createAction(types.STOP);
export const addApiError = createAction(types.ADD_API_ERROR, withPayloadType<VkApiError>());
export const resetApiErrors = createAction(types.RESET_API_ERRORS);

export const setTaskStatus = createAction(types.SET_TASK_STATUS, withPayloadType<TaskStatus>());
export const setTaskError = createAction(types.SET_TASK_ERROR, withPayloadType<string>());
export const setProgress = createAction(types.SET_PROGRESS, withPayloadType<TaskProgress>());
export const setResult = createAction(types.SET_RESULT, withPayloadType<SuperstarItem[]>());
