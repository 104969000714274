import React, {FormEvent} from "react";
import InputContainer from "../containers/InputContainer";
import {InputTextarea} from "primereact/inputtextarea";
import BasicFileUpload from "../upload/BasicFileUpload";

export interface TextAreaWithButtonProps {
    label: string,
    value: string,
    onChange: (value: string) => void,
    buttonId?: string,
    buttonLabel?: string,
    placeholder?: string,
}

const TextAreaWithButton = (props: TextAreaWithButtonProps) => {

    const onUpload = (lines: string[]) => {
        props.onChange(lines.join('\n'));
    };

    const onChange = (e: FormEvent<HTMLTextAreaElement>) => {
        props.onChange(e.currentTarget.value);
    };

    return (
        <InputContainer label={props.label}>
            <InputTextarea className="p-field tw-w-full md:tw-flex-1" value={props.value} onChange={onChange} placeholder={props.placeholder} />
            <BasicFileUpload chooseLabel={props.buttonLabel ? props.buttonLabel : 'Загрузить из файла'} onUpload={onUpload} />
        </InputContainer>
    )
};

export default TextAreaWithButton;
