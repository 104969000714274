import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import {DefaultProps} from "../../types";
import * as types from "./types";
import * as actions from './actions';
import DefaultStateProps from "../../../helpers/DefaultStateProps";

const initialState: types.GroupStatusesState = {
    settings: {
        source: '',
        words_plus: '',
        words_minus: '',
    },
    ...DefaultStateProps<GroupObject[]>([])
};

const GroupStatusesReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
        state.settings.source = action.payload;
    });
    builder.addCase(actions.setWordsPlus, (state, action: PayloadAction<string>) => {
        state.settings.words_plus = action.payload;
    });
    builder.addCase(actions.setWordsMinus, (state, action: PayloadAction<string>) => {
        state.settings.words_minus = action.payload;
    });
    AddDefaultCasesToReducer<GroupObject[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<GroupObject[]>>);
});

export default GroupStatusesReducer;
