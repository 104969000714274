import UserSubscriptions from "../objects/UserSubscriptions";
import {IVkMethod} from "../IVkMethod";
import _chunk from "lodash/chunk";
import * as methods from "../methods/VkMethods";
import {UsersGetSubscriptionsParams} from "../methods/UsersGetSubscriptionsParams";
import {ProcessMethodsParams} from "../types";
import {UserIdAnyIds} from "../objects/UserIdAnyIds";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {WithDefaultActions} from "../../store/parsers/types";

export interface GetSubscriptionsUsersParams extends WithDefaultActions {
    user_ids: number[],
    access_token: string,
}

const GetSubscriptionsUsers = function* (params: GetSubscriptionsUsersParams): Generator<any, Map<number, UserSubscriptions>, any> {
    const access_token: string = params.access_token;
    const executes:Array<IVkMethod> = prepare(params.user_ids);
    const executes_result: UserIdAnyIds[] = yield send({
        methods: executes,
        access_token: access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    let result: Map<number, UserSubscriptions> = new Map<number, UserSubscriptions>();
    for (let item of executes_result) {
        result.set(item.user_id, {user_id: item.user_id, subscription_ids: item.ids});
    }
    return result;
};

function prepare(user_ids: number[]): Array<IVkMethod> {
    const max_requests_per_execute: number = 10;
    const user_ids_chunks = _chunk(user_ids, max_requests_per_execute);
    return user_ids_chunks.map((user_ids_chunk: number[]) => {
        const requests: string[] = user_ids_chunk.map((user_id: number) => {
            let params: UsersGetSubscriptionsParams = {
                user_id: user_id,
                count: 200,
            };
            const method: IVkMethod = methods.usersGetSubscriptions(params);
            return `{"user_id":${user_id},"result":API.${method.method_name}(${JSON.stringify(method.params)}).users}`;
        });
        const code:string = `return [${requests.join(",")}];`;
        return methods.execute({code: code});
    });
}

function* send(params: ProcessMethodsParams): Generator<any, UserIdAnyIds[], any> {

    interface IResponse {
        user_id: number,
        result: {
            count: number,
            items: number[]
        }
    }

    const result: UserIdAnyIds[] = [];

    const handler = (response: IResponse[]) => {
        response.forEach((response_item: IResponse) => {
            const item: UserIdAnyIds = {
                user_id: response_item.user_id,
                ids: response_item.result ? response_item.result.items : []
            };
            result.push(item);
        });
    };

    yield SendMethodsDefault<IResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем подписки',
        chunk_size: 6,
    });

    return result;
}


export default GetSubscriptionsUsers;
