import {createAction} from "@reduxjs/toolkit";
import {VkApiError} from "../../vkapi/VkApiError";
import {TaskProgress, TaskStatus} from "../parsers/types";

const START = 'START';
const STOP = 'STOP';
const SET_TASK_STATUS = 'SET_TASK_STATUS';
const SET_TASK_ERROR = 'SET_TASK_ERROR';
const SET_PROGRESS = 'SET_PROGRESS';
const SET_RESULT = 'SET_RESULT';
const ADD_API_ERROR = 'ADD_API_ERROR';
const RESET_API_ERRORS = 'RESET_API_ERRORS';
const SET_COMPLETED = 'SET_COMPLETED';

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export default function createDefaultActions<TResult>(namespace: string) {
    return {
        start: createAction(namespace + START),
        stop: createAction(namespace + STOP),
        addApiError: createAction(namespace + ADD_API_ERROR, withPayloadType<VkApiError>()),
        resetApiErrors: createAction(namespace + RESET_API_ERRORS),
        setTaskStatus: createAction(namespace + SET_TASK_STATUS, withPayloadType<TaskStatus>()),
        setTaskError: createAction(namespace + SET_TASK_ERROR, withPayloadType<string>()),
        setProgress: createAction(namespace + SET_PROGRESS, withPayloadType<TaskProgress>()),
        setResult: createAction(namespace + SET_RESULT, withPayloadType<TResult>()),
        setCompleted: createAction(namespace + SET_COMPLETED),
    }
}
