import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {CommunityTypeFilter, DefaultProps, SearchTypeListOrSearch} from "../../types";
import * as actions from './actions';
import * as types from "./types";

const initialState: types.AdminContactsState = {
    settings: {
        search_type: SearchTypeListOrSearch.BY_GROUP_URLS,
        groups_source: '',
        search_queries_source: '',
        search_filter_community: CommunityTypeFilter.ALL,
    },
    ...DefaultStateProps<number[]>([])
};

const AdminContactsReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSearchType, (state, action: PayloadAction<SearchTypeListOrSearch>) => {
        state.settings.search_type = action.payload;
    });
    builder.addCase(actions.setGroupsSource, (state, action: PayloadAction<string>) => {
        state.settings.groups_source = action.payload;
    });
    builder.addCase(actions.setSearchQueriesSource, (state, action: PayloadAction<string>) => {
        state.settings.search_queries_source = action.payload;
    });
    builder.addCase(actions.setSearchFilterCommunity, (state, action: PayloadAction<CommunityTypeFilter>) => {
        state.settings.search_filter_community = action.payload;
    });
    AddDefaultCasesToReducer<number[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<number[]>>);
});

export default AdminContactsReducer;
