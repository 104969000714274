import {createSelector} from "reselect";
import {RootState, TopComment} from "../../../types";

const select_items = (state: RootState) => state.topCommentatorsCommunity.result;
const select_comments_min = (state: RootState) => state.topCommentatorsCommunity.settings.comments_min;
const select_comments_max = (state: RootState) => state.topCommentatorsCommunity.settings.comments_max;
const select_likes_min = (state: RootState) => state.topCommentatorsCommunity.settings.likes_min;
const select_likes_max = (state: RootState) => state.topCommentatorsCommunity.settings.likes_max;

const selectByLikes = createSelector(
    [select_items, select_likes_min, select_likes_max],
    (items, min, max) => {
        if (min === 0 && max === 0) {
            return items;
        }
        return items.filter(item => {
            return (item.total_likes >= min || min === 0) && (item.total_likes <= max || max === 0)
        });
    }
);

const selectByComments = createSelector(
    [selectByLikes, select_comments_min, select_comments_max],
    (items, min, max) => {
        if (min === 0 && max === 0) {
            return items;
        }
        return items.filter(item => {
            return (item.total_comments >= min || min === 0) && (item.total_comments <= max || max === 0)
        });
    }
);

const selectFiltered = createSelector(
    [selectByComments],
    (items: TopComment[]) => items
);

export default selectFiltered;
