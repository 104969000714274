import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {Button} from "primereact/button";
import download_txt from "../../../../helpers/DownloadTxt";

import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {InstagramSearchType, SiteType} from "../../../../store/parsers/user-profiles/instagram/types";
import * as actions from "../../../../store/parsers/user-profiles/instagram/actions";
import BasicRadioButton from "../../../ui/inputs/BasicRadioButton";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingName from "../../../ui/inputs/ParsingName";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";

const Instagram = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.instagram);
    const progress: TaskProgress = useSelector((state: RootState) => state.instagram.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.instagram.sub_progress);

    const saveIdsRef = useRef<HTMLAnchorElement>(null);
    const saveSitesRef = useRef<HTMLAnchorElement>(null);
    const [downloadIdsUrl, setDownloadIdsUrl] = useState<string>('');
    const [downloadSitesUrl, setDownloadSitesUrl] = useState<string>('');

    const [parsingName, setParsingName] = useState<string>('');

    const saveIds = () => {
        download_txt<number[]>({
            data: state.result.user_ids,
            ref: saveIdsRef,
            setDownloadUrl: setDownloadIdsUrl,
            mapper: (data: number[]) => data.map(item => item.toString()),
        });
    };

    const saveSites = () => {
        download_txt<string[]>({
            data: state.result.site_urls,
            ref: saveSitesRef,
            setDownloadUrl: setDownloadSitesUrl,
            mapper: (data: string[]) => data.map(item => item),
        });
    };


    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Соберите людей и их аккаунты в других сетях"
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/instagram"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Как искать">
                    <BasicRadioButton name="instagramSearchType" label="По списку групп"
                                      value={InstagramSearchType.BY_GROUPS}
                                      onChange={(e) => dispatch(actions.setSearchType(e.value))}
                                      checked={state.settings.search_type === InstagramSearchType.BY_GROUPS}
                    />
                    <BasicRadioButton name="instagramSearchType" label="По пользователям из файла"
                                      value={InstagramSearchType.BY_USERS}
                                      onChange={(e) => dispatch(actions.setSearchType(e.value))}
                                      checked={state.settings.search_type === InstagramSearchType.BY_USERS}
                    />
                </InputContainer>

                {state.settings.search_type === InstagramSearchType.BY_GROUPS &&
                <TextAreaWithButton label={'Ссылки на группы (по одной на строке)'}
                                    placeholder={"https://vk.com/cerebro_vk"}
                                    value={state.settings.source_groups}
                                    onChange={(e) => dispatch(actions.setSourceGroups(e))}
                />
                }
                {state.settings.search_type === InstagramSearchType.BY_USERS &&
                <TextAreaWithButton label={'Ссылки на профили (по одной на строке)'}
                                    placeholder={"https://vk.com/id23818"}
                                    value={state.settings.source_users}
                                    onChange={(e) => dispatch(actions.setSourceUsers(e))}
                />
                }

                <InputContainer label="Что искать" notice={"Если Вы загружаете файл, то в нем должны быть id пользователей Вконтакте, а не ссылки на аккаунты в других сетях."}>
                    <BasicRadioButton name="instagramSiteType" label="Instagram"
                                      value={SiteType.INSTAGRAM}
                                      onChange={(e) => dispatch(actions.setSiteType(e.value))}
                                      checked={state.settings.site_type === SiteType.INSTAGRAM}
                    />
                    <BasicRadioButton name="instagramSiteType" label="Facebook"
                                      value={SiteType.FACEBOOK}
                                      onChange={(e) => dispatch(actions.setSiteType(e.value))}
                                      checked={state.settings.site_type === SiteType.FACEBOOK}
                    />
                    <BasicRadioButton name="instagramSiteType" label="Twitter"
                                      value={SiteType.TWITTER}
                                      onChange={(e) => dispatch(actions.setSiteType(e.value))}
                                      checked={state.settings.site_type === SiteType.TWITTER}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())}  className="p-field" />
                    {state.result.user_ids.length > 0 &&
                        <SaveButton label="Сохранить пользователей ВК" onClick={() => saveIds()} />
                    }
                    {state.result.site_urls.length > 0 &&
                        <SaveButton label="Сохранить ссылки других соц. сетей" onClick={() => saveSites()} />
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '-ids.txt' : 'ids.txt'} ref={saveIdsRef} href={downloadIdsUrl} style={{display: 'none'}}>Ids</a>
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '-sites.txt' : 'sites.txt'} ref={saveSitesRef} href={downloadSitesUrl} style={{display: 'none'}}>Sites</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>

        </AppPanel>
    );
};

export default Instagram;
