import React from "react";
import download_url from "./download_url";

export interface DownloadTxtProps<T> {
    data: T,
    mapper: (data: T) => string[],
    ref: React.RefObject<HTMLAnchorElement>,
    setDownloadUrl: (value: React.SetStateAction<string>) => void
}

function download_txt<T>(props: DownloadTxtProps<T>) {
    const lines: string[] = props.mapper(props.data);
    props.setDownloadUrl(download_url(lines.join('\r\n'), 'text/plain'));
    setTimeout(() => {
        if (props.ref && props.ref.current) {
            props.ref.current.click()
        }
    }, 1000);
}

export default download_txt;
