import {createSelector} from "reselect";
import {RootState} from "../../../types";
import {Promopost} from "../types";

const select_items = (state: RootState) => state.promoposts.result;

const selectFiltered = createSelector([select_items], (items: Promopost[]) => {
    return items;
});

export default selectFiltered;
