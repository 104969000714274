import {combineReducers, createReducer, PayloadAction} from "@reduxjs/toolkit";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {Gender} from "../../types";
import {setSearchType} from "./actions";
import ReducerActivity from "./reducers/reducer-activity";
import ReducerAudience from "./reducers/reducer-audience";
import ReducerBlocked from "./reducers/reducer-blocked";
import ReducerLastPost from "./reducers/reducer-last-post";
import {ActivityFilterResult, BlockedFilterResult, LastPostFilterResult} from "./types";
import * as types from "./types";
import {AudienceFilterResult, CommunitiesFilterSearchType, FilterSearchType} from "./types";

const moment = require('moment');

const initialState: types.CommunitiesFilterState = {
    settings: {
        search_type: CommunitiesFilterSearchType.AUDIENCE,
    },
    audience: {
        settings: {
            source: '',
            age_min: 0,
            age_max: 0,
            gender: Gender.ANY,
            selected_country: null,
            selected_city: null,
            search_type: FilterSearchType.QUICK,
        },
        ...DefaultStateProps<AudienceFilterResult[]>([])
    },
    lastPost: {
        settings: {
            source: '',
            start_date: null,
            end_date: null,
            only_from_community: false,
        },
        ...DefaultStateProps<LastPostFilterResult[]>([])
    },
    blocked: {
        settings: {
            source: '',
            members_count_min: 0,
            members_count_max: 0,
            members_count_blocked_min: 0,
            members_count_blocked_max: 0,
        },
        skipped: [],
        sub_progress: {message: ''},
        ...DefaultStateProps<BlockedFilterResult[]>([])
    },
    activity: {
        settings: {
            source: '',
            only_members: false,
            only_selected_users: false,
            selected_users_source: '',
            start_date: moment().subtract(14, "days").startOf("day").toDate(),
            end_date: null,
        },
        sub_progress: {message: ''},
        ...DefaultStateProps<ActivityFilterResult[]>([]),
    },
};

const settingsReducer = createReducer(initialState['settings'], builder => {
    builder.addCase(setSearchType, (state, action: PayloadAction<CommunitiesFilterSearchType>) => {
        state.search_type = action.payload;
    });
});

const audienceReducer = ReducerAudience(initialState.audience);
const lastPostReducer = ReducerLastPost(initialState.lastPost);
const blockedReducer = ReducerBlocked(initialState.blocked);
const activityReducer = ReducerActivity(initialState.activity);

const combined = combineReducers(
    {
        settings: settingsReducer,
        audience: audienceReducer,
        lastPost: lastPostReducer,
        blocked: blockedReducer,
        activity: activityReducer,
    }
);

export default combined;
