import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import React from "react";
import {Country} from "../../../store/parsers/types";
import GroupLabel from "../labels/GroupLabel";

export interface CountriesDialogProps {
    visible: boolean,
    onSelect: (item: Country) => void,
    onHide: () => void,
}

const items: Country[] = [
    {id: 1, title: "Россия"},
    {id: 2, title: "Украина"},
    {id: 3, title: "Беларусь"},
    {id: 4, title: "Казахстан"},
    {id: 5, title: "Азербайджан"},
    {id: 6, title: "Армения"},
    {id: 7, title: "Грузия"},
    {id: 11, title: "Кыргызстан"},
    {id: 18, title: "Узбекистан"},
    {id: 8, title: "Израиль"},
    {id: 9, title: "США"},
    {id: 191, title: "Тайланд"},
    {id: 15, title: "Молдова"},
    {id: 49, title: "Великобритания"},
    {id: 65, title: "Германия"},
    {id: 160, title: "Польша"},
    {id: 10, title: "Канада"},
    {id: 14, title: "Эстония"},
    {id: 13, title: "Литва"},
    {id: 12, title: "Латвия"},
    {id: 215, title: "Чехия"},
    {id: 207, title: "Финляндия"},
    {id: 218, title: "Швеция"},
    {id: 73, title: "Дания"},
    {id: 144, title: "Норвегия"},
    {id: 165, title: "Румыния"},
    {id: 145, title: "ОАЭ"},
    {id: 87, title: "Испания"},
    {id: 95, title: "Кипр"},
    {id: 71, title: "Греция"},
    {id: 97, title: "Китай"},
    {id: 39, title: "Болгария"},
];

const CountriesDialog = (props: CountriesDialogProps) => {

    return (
        <Dialog visible={props.visible} onHide={props.onHide}
                className="tw-w-4/5 md:tw-w-2/3"
                header={<GroupLabel label="Выберите страну" className="tw-font-medium tw-mb-1" />}
        >
            <DataTable value={items} autoLayout={true}>
                <Column className="tw-w-full" field="title" />
                <Column header={'Страна'} className="tw-w-auto" body={(row: Country) => <Button label="Выбрать" onClick={() => props.onSelect(row)}/> }/>
            </DataTable>
        </Dialog>
    )
};

export default CountriesDialog;
