import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {UserObject} from "../../../../../vkapi/objects/UserObject";
import {convert_urls_to_id} from "../../../../../vkapi/tasks/ConverUrlToId";
import GetUsers from "../../../../../vkapi/tasks/GetUsers";
import {users as actions} from "../actions";
import {RootState, TaskStatus} from "../../../types";
import {showAlertError, showAlertParsingCompleted} from "../../../../app/actions";
import {IdsConverterState} from "../types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: IdsConverterState['users'] = yield select((state: RootState) => state.idsConverter.users);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const user_ids: string[] = convert_urls_to_id(state.settings.source);

    const profiles: Map<number, UserObject> = yield GetUsers({
        user_ids: user_ids,
        access_token: access_token,
        chunk_size: 100,
        fields: 'screen_name',
    });

    yield put(actions.setProgress({message: `Завершено. Найдено: ${profiles.size}`, total: 100, current: 100}));
    yield put(actions.setResult(Array.from(profiles.values())));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${profiles.size}`));
    yield put(actions.setCompleted());
}

function* validate(state: IdsConverterState['users']) {
    if (state.settings.source.length === 0) {
        yield put(showAlertError('Вы не загрузили файл или он пустой'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
