import {TopicAddress} from "../ConverUrlToId";
import {IVkMethod} from "../../IVkMethod";
import * as methods from "../../methods/VkMethods";
import _chunk from "lodash/chunk";
import {ProcessMethodsParams} from "../../types";
import SendMethodsDefault from "../../helpers/SendMethodsDefault";
import {Topic} from "../../objects/Topic";
import {GroupTopics, WithDefaultActions} from "../../../store/parsers/types";
import {BoardGetTopicsParams} from "../../methods/BoardGetTopicsParams";

export interface GetFirstTopicsParams extends WithDefaultActions {
    topic_addresses: TopicAddress[],
    access_token: string,
}

export interface GroupTopicsWithCount {
    owner_id: number,
    count: number,
    topics: GroupTopics,
}

const GetFirstTopics = function* (params: GetFirstTopicsParams): Generator<any, GroupTopicsWithCount[], any> {
    const executes:Array<IVkMethod> = prepare(params.topic_addresses);
    const executes_result: GroupTopicsWithCount[] = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(topic_addresses: TopicAddress[]): IVkMethod[] {
    const methods_per_execute: number = 20;
    const count: number = 100;
    const requests: string[] = [];

    topic_addresses.forEach((item: TopicAddress) => {
        const params: BoardGetTopicsParams = {
            group_id: item.owner_id,
            count: count,
        };
        const method: IVkMethod = methods.boardGetTopics(params);
        requests.push(`{owner_id: ${item.owner_id}, result: API.${method.method_name}(${JSON.stringify(method.params)})}`);
    });

    const requests_chunks: string[][] = _chunk(requests, methods_per_execute);
    return requests_chunks.map((chunk: string[]) => {
        const code:string = `return [${chunk.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, GroupTopicsWithCount[], any> {
    interface TResponse {
        owner_id: number,
        result: {
            count: number,
            items: Topic[],
        }|false,
    }
    const map: Map<number, GroupTopicsWithCount> = new Map<number, GroupTopicsWithCount>();
    const handler = (response: TResponse[]) => {
        response.forEach((response_item: TResponse) => {
            const owner_id: number = response_item.owner_id;
            if (!response_item.result) {
                return;
            }
            const group_topics: GroupTopicsWithCount = map.get(owner_id) ||
                {
                    owner_id: owner_id,
                    count: response_item.result.count,
                    topics: {
                        group_id: owner_id,
                        topics: response_item.result.items,
                    },
                };
            map.set(owner_id, group_topics);
        });
    };
    yield SendMethodsDefault<TResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем темы',
        chunk_size: 6,
    });
    return Array.from(map.values());
};

export default GetFirstTopics;
