import {Post} from "../objects/Post";
import GetFirstComments, {FirstComments} from "./comments/GetFirstComments";
import {Comment} from "../objects/Comment";
import GetRemainingTopLevelComments from "./comments/GetRemainingTopLevelComments";
import GetRemainingChildrenComments from "./comments/GetRemainingChildrenComments";
import {WithDefaultActions} from "../../store/parsers/types";

export interface GetCommentsParams extends WithDefaultActions {
    posts: Post[],
    access_token: string,
    preview_length: number,
}

export interface PostComments {
    owner_id: number,
    post_id: number,
    comments: Comment[],
}

const GetComments = function* (params: GetCommentsParams): Generator<any, PostComments[], any> {
    let first_comments: FirstComments[] = yield GetFirstComments({
        posts: params.posts,
        preview_length: params.preview_length,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action,
    });

    let remaining_top_level_comments: PostComments[] = yield GetRemainingTopLevelComments({
        first_comments: first_comments,
        preview_length: params.preview_length,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action,
    });

    const all_comments: Map<string, PostComments> = merge_comments(first_comments, remaining_top_level_comments);
    first_comments = [];
    remaining_top_level_comments = [];

    let remaining_children_comments: PostComments[] = yield GetRemainingChildrenComments({
        comments: Array.from(all_comments.values()),
        preview_length: params.preview_length,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action,
    });

    remaining_children_comments.forEach((comments: PostComments) => {
        const unique_id: string = `${comments.owner_id}_${comments.post_id}`;
        let current: PostComments = all_comments.get(unique_id) || {
            owner_id: comments.owner_id,
            post_id: comments.post_id,
            comments: [],
        };
        current.comments = current.comments.concat(comments.comments);
        all_comments.set(unique_id, current);
    });
    remaining_children_comments = [];

    return Array.from(all_comments.values());
};

function merge_comments(first_comments: FirstComments[], remaining_comments: PostComments[]): Map<string, PostComments> {
    const result: Map<string, PostComments> = new Map<string, PostComments>();
    first_comments.forEach((comments: FirstComments) => {
        const unique_id: string = `${comments.owner_id}_${comments.post_id}`;
        result.set(unique_id, {
            owner_id: comments.owner_id,
            post_id: comments.post_id,
            comments: comments.comments,
        });
    });

    remaining_comments.forEach((comments: PostComments) => {
        const unique_id: string = `${comments.owner_id}_${comments.post_id}`;
        let current: PostComments = result.get(unique_id) || {
            owner_id: comments.owner_id,
            post_id: comments.post_id,
            comments: [],
        };
        current.comments = current.comments.concat(comments.comments);
        result.set(unique_id, current);
    });

    return result;
}

export default GetComments;
