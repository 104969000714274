import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import _chunk from "lodash/chunk";
import {TaskProgress} from "../../../types";
import {GroupsGetMembersParams} from "../../../../../vkapi/methods/GroupsGetMembersParams";
import * as methods from "../../../../../vkapi/methods/VkMethods";
import SendMethodsDefault from "../../../../../vkapi/helpers/SendMethodsDefault";
import {VkApiError} from "../../../../../vkapi/VkApiError";
import {IVkMethod} from "../../../../../vkapi/IVkMethod";

export interface GetUnsureEventMembersParams {
    event_ids: number[],
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
}

const GetUnsureEventMembers = function* (params: GetUnsureEventMembersParams): Generator<any, Map<number,number>, any> {
    const access_token = params.access_token;
    const executes: IVkMethod[] = prepare(params.event_ids);
    const executes_result: Map<number,number> = yield send(executes, access_token, params.progress_action, params.add_api_errors_action);
    return executes_result;
};

function prepare(event_ids: number[]): IVkMethod[] {
    const max_requests_per_execute: number = 25;
    const event_ids_chunks: Array<number>[] = _chunk(event_ids, max_requests_per_execute);
    const executes:Array<IVkMethod> = event_ids_chunks.map((chunk: number[]) => {
        const requests: string[] = chunk.map((event_id: number) => {
            const params: GroupsGetMembersParams = {
                group_id: event_id,
                count: 0,
                filter: "unsure",
            };
            const method: IVkMethod = methods.groupsGetMembers(params);
            const api_request: string = "API." + method.method_name + "(" + JSON.stringify(method.params) + ")";
            return `{"event_id":${event_id},"count":${api_request}.count}`;
        });
        const code: string = "return [" + requests.join(",") + "];";
        return methods.execute({code: code});
    });
    return executes;
}

function* send(
    executes:IVkMethod[],
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
): Generator<any, Map<number,number>, any> {

    interface UnsureCount {
        event_id: number,
        count: number|null
    }

    const result: Map<number,number> = new Map<number, number>();

    const handler = (response: UnsureCount[]) => {
        response.forEach((response_item: UnsureCount) => {
            if (response_item.count) {
                result.set(response_item.event_id, response_item.count);
            }
        });
    };

    yield SendMethodsDefault<UnsureCount[]>({
        methods_params: {
            methods: executes,
            access_token: access_token,
            progress_action: progress_action,
            add_api_errors_action: add_api_errors_action,
        },
        response_handler: handler,
        progress_text: 'Получаем информацию о встречах',
        chunk_size: 3,
    });

    return result;
}

export default GetUnsureEventMembers;
