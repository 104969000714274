import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import {DefaultProps} from "../../types";
import * as types from "./types";
import * as actions from './actions';
import DefaultStateProps from "../../../helpers/DefaultStateProps";

const initialState: types.AbTestingState = {
    settings: {
        source: '',
        parts_count: 2,
        shuffle: true,
    },
    ...DefaultStateProps<string[][]>([])
};

const AbTestingReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
        state.settings.source = action.payload;
    });
    builder.addCase(actions.setPartsCount, (state, action: PayloadAction<number>) => {
        state.settings.parts_count = action.payload;
    });
    builder.addCase(actions.setShuffle, (state, action: PayloadAction<boolean>) => {
        state.settings.shuffle = action.payload;
    });

    AddDefaultCasesToReducer<string[][]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<string[][]>>);
});

export default AbTestingReducer;
