import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import * as types from "./types";
import * as global_types from "../../types";
import * as actions from './actions';
import {VkApiError} from "../../../../vkapi/VkApiError";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {AudienceAnalysisResult} from "./types";
import {AudienceAnalysisItem} from "./types";
import {get_blank_audience_analysis} from "./helpers";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {DataSourceType} from "../../types";

const initialState: types.AudienceAnalysisState = {
    settings: {
        data_source_type: global_types.DataSourceType.URL,
        data_source_file: '',
        data_source_url: '',
    },
    profiles: new Map<number, UserObject>(),
    selected_category: 'interests',
    selected_results: get_blank_audience_analysis(),
    found_users: new Set<number>(),
    ...DefaultStateProps<AudienceAnalysisResult>(get_blank_audience_analysis())
};

const AudienceAnalysisReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setDatasourceType, (state: types.AudienceAnalysisState, action: PayloadAction<DataSourceType.URL|DataSourceType.FILE>) => {
        state.settings.data_source_type = action.payload;
    });
    builder.addCase(actions.setDatasourceGroupUrl, (state: types.AudienceAnalysisState, action: PayloadAction<string>) => {
        state.settings.data_source_url = action.payload;
    });
    builder.addCase(actions.setDatasourceUserIds, (state: types.AudienceAnalysisState, action: PayloadAction<string>) => {
        state.settings.data_source_file = action.payload;
    });
    builder.addCase(actions.setProfiles, (state: types.AudienceAnalysisState, action: PayloadAction<Map<number, UserObject>>) => {
        state.profiles = action.payload;
    });
    builder.addCase(actions.setSelectedCategory, (state: types.AudienceAnalysisState, action: PayloadAction<keyof AudienceAnalysisResult>) => {
        state.selected_category = action.payload;
    });
    builder.addCase(actions.addSelectedResults, (state: types.AudienceAnalysisState, action: PayloadAction<AudienceAnalysisItem[]>) => {
        state.selected_results[state.selected_category] = action.payload;
    });
    builder.addCase(actions.setFoundUsers, (state: types.AudienceAnalysisState, action: PayloadAction<Set<number>>) => {
        state.found_users = action.payload;
    });
    builder.addCase(actions.clearSelectedResults, (state: types.AudienceAnalysisState) => {
        state.selected_results = get_blank_audience_analysis();
    });

    builder.addCase(actions.setTaskStatus, (state: types.AudienceAnalysisState, action: PayloadAction<global_types.TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setTaskTitle, (state: types.AudienceAnalysisState, action: PayloadAction<string>) => {
        state.task_title = action.payload;
    });
    builder.addCase(actions.setProgress, (state: types.AudienceAnalysisState, action: PayloadAction<global_types.TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: types.AudienceAnalysisState, action: PayloadAction<types.AudienceAnalysisResult>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: types.AudienceAnalysisState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state: types.AudienceAnalysisState) => {
        state.api_errors = [];
    });
});

export default AudienceAnalysisReducer;
