import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {convert_urls_to_id, convert_urls_to_screen_names} from "../../../../vkapi/tasks/ConverUrlToId";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {date_in_range, is_post_before_start_date} from "../../../helpers/DateChecker";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import {ActivityType, RootState, TaskStatus} from "../../types";
import {OwnerPosts, Post} from "../../../../vkapi/objects/Post";
import {ResolveScreenNameResult} from "../../../../vkapi/objects/ResolveScreenNameResult";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import GetActivitiesIds, {PostActivitiesIds} from "../../../../vkapi/tasks/GetActivitiesIds";
import GetPosts from "../../../../vkapi/tasks/GetPosts";
import GetUsers from "../../../../vkapi/tasks/GetUsers";
import ResolveScreenName from "../../../../vkapi/tasks/ResolveScreenName";
import * as action_types from './action-types';
import * as actions from "./actions";
import {ActivitiesFrom, PopularPostsState} from "./types";

const main = function* () {
    while (yield take(action_types.START)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: PopularPostsState = yield select((state: RootState) => state.popularPosts);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    let selected_users: Map<number, UserObject> = new Map<number, UserObject>();

    if (state.settings.activities_from === ActivitiesFrom.SELECTED_USERS) {
        const selected_users_screen_names: string[] = convert_urls_to_id(state.settings.selected_users.split('\n'));
        selected_users = yield GetUsers({
            user_ids: selected_users_screen_names,
            chunk_size: 250,
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setProgress,
        });
    }

    let all_posts: Post[] = [];
    const screen_names: string[] = convert_urls_to_screen_names(state.settings.data_source.split('\n'));
    const progress = ProgressGenerator({text: 'Обрабатываем ссылки', total: screen_names.length, action: actions.setProgress});
    for (let screen_name_index in screen_names) {
        const screen_name: string = screen_names[screen_name_index];
        yield progress.next().value;
        const resolve_result: ResolveScreenNameResult|null = yield ResolveScreenName({
            screen_name: screen_name,
            access_token: access_token,
        });
        if (! resolve_result) {
            continue;
        }
        let owner_id: number = resolve_result.object_id;
        if (resolve_result.type === "group" || resolve_result.type === "page" || resolve_result.type === "event") {
            owner_id = owner_id * -1;
        }

        const is_date_in_range = date_in_range(state.settings.start_date, state.settings.end_date);
        const is_posts_start_date_reached = is_post_before_start_date(state.settings.start_date);

        yield put(actions.setSubProgress({message: 'Получаем посты'}));

        const posts: OwnerPosts = yield GetPosts({
            owner_id: owner_id,
            filter: state.settings.posts_filter,
            is_skip: (post: Post) => !is_date_in_range(post.date),
            is_stop: (post: Post) => is_posts_start_date_reached(post),
            access_token: access_token,
            add_api_errors_action: actions.addApiError,
        });
        yield put(actions.setSubProgress({message: ''}));

        if (state.settings.activities_from === ActivitiesFrom.ALL) {
            all_posts = all_posts.concat(posts.posts.filter(post => Math.max(post.likes.count, post.reposts.count, post.comments.count) > 0))
            continue;
        }

        if (state.settings.activities_from === ActivitiesFrom.SELECTED_USERS) {
            const activities: Map<number, PostActivitiesIds> = yield GetActivitiesIds({
                posts: posts.posts,
                activity_types: new Set<ActivityType>([ActivityType.LIKES, ActivityType.REPOSTS, ActivityType.COMMENTS]),
                access_token: access_token,
                progress_action: actions.setSubProgress,
                add_api_errors_action: actions.addApiError,
            });
            all_posts = all_posts.concat(
                Array.from(activities.values()).map((item: PostActivitiesIds) => {
                    item.post.likes.count = item.likes.filter(user_id => selected_users.has(user_id)).length;
                    item.post.comments.count = new Set<number>(item.comments.filter(user_id => selected_users.has(user_id))).size;
                    item.post.reposts.count = item.reposts.filter(user_id => selected_users.has(user_id)).length;
                    return item.post;
                }).filter((post: Post) => Math.max(post.likes.count + post.reposts.count + post.comments.count) > 0)
            );
        }
    }

    yield put(actions.setSubProgress({message: ''}));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${all_posts.length}`, total: undefined, current: undefined}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${all_posts.length}`));
    yield put(actions.setResult(all_posts));

    yield put({ type: action_types.COMPLETED});
}

function* validate(state: PopularPostsState) {
    if (state.settings.data_source.trim() === '') {
        yield put(showAlertError('Не указаны ссылки'));
        yield put(actions.stop());
        return;
    }
    if (state.settings.activities_from === ActivitiesFrom.SELECTED_USERS && state.settings.selected_users.trim() === '') {
        yield put(showAlertError('Не указаны пользователи чьи активности надо учитывать'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(action_types.STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
