const NAMESPACE = 'POPULAR_PEOPLE/';

export const SET_DATASOURCE_TYPE = NAMESPACE + 'SET_DATASOURCE_TYPE';
export const SET_DATASOURCE_GROUP_URL = NAMESPACE + 'SET_DATASOURCE_GROUP_URL';
export const SET_DATASOURCE_USER_IDS = NAMESPACE + 'SET_DATASOURCE_USER_IDS';
export const SET_SEARCH_TYPE = NAMESPACE + 'SET_SEARCH_TYPE';
export const SET_POPULARITY_TYPE = NAMESPACE + 'SET_POPULARITY_TYPE';
export const SET_MIN_FRIENDS = NAMESPACE + 'SET_MIN_FRIENDS';
export const SET_MAX_FRIENDS = NAMESPACE + 'SET_MAX_FRIENDS';
export const SET_USERS_TO_SHOW_COUNT = NAMESPACE + 'SET_USERS_TO_SHOW_COUNT';

export const START = NAMESPACE + 'START';
export const STOP = NAMESPACE + 'STOP';
export const SET_TASK_STATUS = NAMESPACE + 'SET_TASK_STATUS';
export const SET_TASK_ERROR = NAMESPACE + 'SET_TASK_ERROR';
export const SET_PROGRESS = NAMESPACE + 'SET_PROGRESS';
export const SET_RESULT = NAMESPACE + 'SET_RESULT';

export const ADD_API_ERROR = NAMESPACE + 'ADD_API_ERROR';
export const RESET_API_ERRORS = NAMESPACE + 'RESET_API_ERRORS';

export const COMPLETED = NAMESPACE + 'COMPLETED';
