import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from 'primereact/dropdown';
import React, {FormEvent, useCallback, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator, prepare_string} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import {showAlert} from "../../../../store/app/actions";
import * as actions from "../../../../store/parsers/audience-search/audience-analysis/actions";
import {id_to_category_title} from "../../../../store/parsers/audience-search/audience-analysis/helpers";
import selectFiltered, {
    hasItems,
    hasSelected,
    selectSelected
} from "../../../../store/parsers/audience-search/audience-analysis/selectors/AudienceAnalysisSelector";
import {
    AudienceAnalysisItem,
    AudienceAnalysisResult
} from "../../../../store/parsers/audience-search/audience-analysis/types";
import {DataSourceType, RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import DropdownChangeEvent from "../../../ui/ui-events/DropdownChangeEvent";
import BasicFileUpload from "../../../ui/upload/BasicFileUpload";
import uniqueId from "lodash/uniqueId";

const AudienceAnalysis = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.audienceAnalysis);
    const progress: TaskProgress = useSelector((state: RootState) => state.audienceAnalysis.progress);
    const result: AudienceAnalysisItem[] = useSelector(selectFiltered);
    const result_selected = useSelector(selectSelected);
    const has_results = useSelector(hasItems);
    const has_selected = useSelector(hasSelected);

    const saveCsvRef = useRef<HTMLAnchorElement>(null);
    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadCsvUrl, setDownloadCsvUrl] = useState<string>('');
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const [parsingName, setParsingName] = useState<string>('');
    const [groupUrlId] = useState(uniqueId('groupUrl-'));

    const onDatasourceTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setDatasourceType(e.value)), [dispatch]);
    const onUrlChange = useCallback((e: FormEvent<HTMLInputElement>) => dispatch(actions.setDatasourceGroupUrl(e.currentTarget.value)), [dispatch]);
    const onUpload = useCallback((lines: string[]) => dispatch(actions.setDatasourceUserIds(lines.join('\n'))), [dispatch]);
    const onCategoryChange = useCallback((e: DropdownChangeEvent<keyof AudienceAnalysisResult>) => dispatch(actions.setSelectedCategory(e.value)), [dispatch]);

    const save = () => {
        if (!has_results) {
            dispatch(showAlert({text: 'Нечего сохранять', header: 'Ошибка'}));
            return;
        }
        const lines: string[] = [];
        state.found_users.forEach((item) => {
            lines.push(item.toString());
        });
        setDownloadUrl(download_url(lines.join('\r\n'), 'text/plain'));
        setTimeout(() => {
            if (saveRef && saveRef.current) {
                saveRef.current.click()
            }
        }, 1000);
    };

    const saveCSV = () => {
        const headers = ['Категория', 'Название', 'Количество'];

        interface Category {
            category: string,
            data: AudienceAnalysisItem,
        }

        const lines: Category[] = [];
        for (const data of Object.entries(state.result)) {
            let category_id: string = data[0];
            let items: AudienceAnalysisItem[] = data[1];
            let category_title = id_to_category_title(category_id);
            for (const item of items) {
                lines.push({
                    category: category_title,
                    data: item,
                })
            }
        }

        const generator = (item: Category) => {
            const line: string[] = [
                prepare_string(item.category),
                prepare_string(item.data.title),
                item.data.count.toString(),
            ];
            return line.join(';');
        };
        const csv: string|null = csv_generator(lines, headers, generator);
        if (!csv) {
            return;
        }
        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);

    };


    type Category = {
        label: string,
        value: keyof AudienceAnalysisResult,
    }
    const categories: Category[] = [
        {label: 'интересы', value: 'interests'},
        {label: 'книги', value: 'books'},
        {label: 'кино', value: 'movies'},
        {label: 'музыка', value: 'music'},
        {label: 'игры', value: 'games'},
        {label: 'шоу', value: 'tv'},
        {label: 'работа', value: 'work'},
        {label: 'занятость', value: 'activities'},
        {label: 'ВУЗы', value: 'universities'},
        {label: 'факультеты', value: 'faculties'},
        {label: 'отношения', value: 'relations'},
        {label: 'политика', value: 'political'},
        {label: 'главное в людях', value: 'people_main'},
        {label: 'главное в жизни', value: 'life_main'},
    ];

    return (
        <AppPanel id={props.id} title={props.title}>

            <ParsingHelp title={props.title} url="http://blog.xn--90aha1bhc1b.xn--p1ai/analiz_ineteresov" description="Узнайте интересы Вашей аудитории" />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>
                <InputContainer label="Как искать">
                    <BasicRadioButton id="searchByUrl" name="searchByRadio" label="По ссылке на группу" value={DataSourceType.URL} onChange={onDatasourceTypeChange} checked={state.settings.data_source_type === DataSourceType.URL} />
                    <BasicRadioButton id="searchByFile" name="searchByRadio" label="По файлу со списком id пользователей" value={DataSourceType.FILE} onChange={onDatasourceTypeChange} checked={state.settings.data_source_type === DataSourceType.FILE} />
                </InputContainer>

                {state.settings.data_source_type === DataSourceType.URL &&
                <InputContainer label="Ссылка на сообщество">
                    <BasicTextInput id={groupUrlId} placeholder="https://vk.com/cerebro_vk" value={state.settings.data_source_url} onChange={onUrlChange}/>
                </InputContainer>
                }
                {state.settings.data_source_type === DataSourceType.FILE &&
                <InputContainer label="Загрузка из файла">
                    <BasicFileUpload id="upload" chooseLabel="Загрузить id из файла" onUpload={onUpload}/>
                </InputContainer>
                }
                <InputContainer label="Что показать">
                    <Dropdown className="p-field tw-w-full sm:tw-flex-1" value={state.selected_category} options={categories} onChange={onCategoryChange} placeholder={'Выберите раздел'} />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {has_selected &&
                    <Button label="Найти пользователей с отмеченными интересами" onClick={() => dispatch(actions.start_users_search())}/>
                    }
                    {state.found_users.size > 0 &&
                    <SaveButton label="Сохранить найденных пользователей" onClick={() => save()} />
                    }
                    {has_results &&
                    <SaveButton label="Сохранить CSV" onClick={() => saveCSV()} />
                    }
                    <a download={'result.csv'} ref={saveCsvRef} href={downloadCsvUrl} style={{display: 'none'}}>Сохранить в CSV</a>
                    <a download={'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Сохранить id</a>
                </ButtonsContainer>

            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            <div className="tw-mt-4">
                <DataTable value={result} rowHover={true} paginator={true} rows={100} selection={result_selected} onSelectionChange={(e) => dispatch(actions.addSelectedResults(e.value))}>
                    <Column selectionMode="multiple" style={{width:'20px'}}/>
                    <Column field="title" header="Название" body={(item: AudienceAnalysisItem, row: any) => <div>{row.rowIndex + 1}. {item.title}</div>} />
                    <Column field="count"  header="Всего" sortable />
                </DataTable>
            </div>

        </AppPanel>
    );
};

export default AudienceAnalysis;
