import {Post} from "../objects/Post";
import {ActivityType, WithDefaultActions} from "../../store/parsers/types";
import {PostLikes} from "./activities/GetLikes";
import {PostComments} from "./GetComments";
import {PostCommentsLikes} from "./activities/GetCommentsLikes";
import GetActivities from "./GetActivities";

export interface GetActivitiesIdsParams extends WithDefaultActions {
    posts: Post[],
    activity_types: Set<ActivityType>,
    comments_preview_length?: number,
    access_token: string,
}

export interface PostActivitiesIds {
    post: Post,
    likes: number[],
    reposts: number[],
    comments: number[],
    comments_likes: number[],
}

const GetActivitiesIds = function* (params: GetActivitiesIdsParams): Generator<any, Map<number, PostActivitiesIds>, any> {

    const activities_map: Map<number, PostActivitiesIds> = new Map<number, PostActivitiesIds>();
    params.posts.forEach((post: Post) => {
        activities_map.set(post.id, {
            post: post,
            likes: [],
            reposts: [],
            comments: [],
            comments_likes: [],
        });
    });

    const likes_handler = (likes: PostLikes[]) => {
        likes.forEach((item: PostLikes) => {
            let activities: PostActivitiesIds|undefined = activities_map.get(item.post_id);
            if (activities) {
                activities.likes = item.likes;
                activities_map.set(item.post_id, activities);
            }
        });
    };

    const reposts_handler = (likes: PostLikes[]) => {
        likes.forEach((item: PostLikes) => {
            let activities: PostActivitiesIds|undefined = activities_map.get(item.post_id);
            if (activities) {
                activities.reposts = item.likes;
                activities_map.set(item.post_id, activities);
            }
        });
    };

    const comments_handler = (comments: PostComments[]) => {
        comments.forEach((item: PostComments) => {
            let activities: PostActivitiesIds|undefined = activities_map.get(item.post_id);
            if (activities) {
                activities.comments = item.comments.map(comment => comment.from_id);
                activities_map.set(item.post_id, activities);
            }
        });
    };

    const comments_likes_handler = (comments_likes: PostCommentsLikes[]) => {
        comments_likes.forEach((item: PostCommentsLikes) => {
            let activities: PostActivitiesIds|undefined = activities_map.get(item.post_id);
            if (activities) {
                activities.comments_likes = activities.comments_likes.concat(item.likes);
                activities_map.set(item.post_id, activities);
            }
        });
    };

    yield GetActivities({
        posts: params.posts,
        activity_types: params.activity_types,
        comments_preview_length: 1,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action,

        likes_handler: likes_handler,
        reposts_handler: reposts_handler,
        comments_handler: comments_handler,
        comments_likes_handler: comments_likes_handler,
    });

    return activities_map;

};

export default GetActivitiesIds;
