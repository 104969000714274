import {UserIdAnyIds} from "../objects/UserIdAnyIds";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import _chunk from "lodash/chunk";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {VkApiError} from "../VkApiError";
import ExecuteUserIdGroupId from "./common/ExecuteUserIdGroupId";
import {TaskProgress} from "../../store/parsers/types";

export interface GetGroupsOfEveryUserIdParams {
    user_ids: number[],
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>,
}

const GetGroupsOfEveryUserId = function* (params: GetGroupsOfEveryUserIdParams): Generator<any, UserIdAnyIds[], any> {
    const access_token = params.access_token;
    const executes:Array<IVkMethod> = prepare(params.user_ids);
    return yield ExecuteUserIdGroupId(executes, access_token, params.progress_action, params.add_api_errors_action);
};

function prepare(user_ids: number[]): Array<IVkMethod> {
    const max_requests_per_execute: number = 25;
    const chunks:number[][] = _chunk(user_ids, max_requests_per_execute);
    return chunks.map((chunk) => {
        const requests:string[] = chunk.map((user_id) => {
            const method: IVkMethod = methods.groupsGet({user_id: user_id, count: 1000});
            return '{"user_id":' + user_id + ', "result":API.' + method.method_name + '(' + JSON.stringify(method.params) + ')}';
        });
        const code: string = "return [" + requests.join(",") + "];";
        return methods.execute({code: code});
    });
}

export default GetGroupsOfEveryUserId;
