import {createAction} from "@reduxjs/toolkit";
import * as types from "./action-types";
import * as users from "./actions/actions-users";
import * as groups from "./actions/actions-groups";
import {BaseViewerType} from "./types";

export {users, groups};

function withPayloadType<T>() {
    return (t: T) => ({payload: t})
}

export const setSearchType = createAction(types.SET_BASE_VIEWER_TYPE, withPayloadType<BaseViewerType>());
