export const NAMESPACE = 'ACTIVITIES_IN_PROFILES/';

export const SET_DATASOURCE = NAMESPACE + 'SET_DATASOURCE';
export const SET_START_DATE = NAMESPACE + 'SET_START_DATE';
export const SET_END_DATE = NAMESPACE + 'SET_END_DATE';
export const SET_ACTIVITIES_MIN = NAMESPACE + 'SET_ACTIVITIES_MIN';
export const SET_ACTIVITIES_MAX = NAMESPACE + 'SET_ACTIVITIES_MAX';
export const SET_USERS_MIN = NAMESPACE + 'SET_USERS_MIN';
export const SET_USERS_MAX = NAMESPACE + 'SET_USERS_MAX';
export const SET_POSTS_EVERY_USER = NAMESPACE + 'SET_POSTS_EVERY_USER';
export const SET_POSTS_MIN = NAMESPACE + 'SET_POSTS_MIN';
export const SET_POSTS_MAX = NAMESPACE + 'SET_POSTS_MAX';
export const ADD_ACTIVITY_TYPE = NAMESPACE + 'ADD_ACTIVITY_TYPE';
export const REMOVE_ACTIVITY_TYPE = NAMESPACE + 'REMOVE_ACTIVITY_TYPE';

export const SET_SUBPROGRESS = NAMESPACE + 'SET_SUBPROGRESS';
