import {Post} from "../../../../vkapi/objects/Post";
import {StateWithErrors, StateWithProgress, TaskProgress} from "../../types";

export enum ActivitiesFrom {
    ALL = "all",
    SELECTED_USERS = "selected_users",
}

export enum PostsFilter {
    OWNER = "owner",
    OTHERS = "others",
    ALL = "all",
}

interface State {
    settings: {
        data_source: string,
        selected_users: string,
        activities_from: ActivitiesFrom,
        posts_filter: PostsFilter,
        start_date: Date|null,
        end_date: Date|null,
    },
    sub_progress: TaskProgress,
    result: Post[],
}

export interface PopularPostsState extends State, StateWithErrors, StateWithProgress {}
