import ConvertUrlToId from "./ConverUrlToId";
import {GetGroupError, GetGroupParams} from "./GetGroup";
import GetGroup from "./GetGroup";
import {GroupObject} from "../objects/GroupObject";
import {Either, left} from "fp-ts/lib/Either";

export interface GetGroupByUrlParams {
    url: string,
    access_token: string,
    fields?: string,
}

const GetGroupByUrl = function* (params: GetGroupByUrlParams): Generator<any, Either<GetGroupError, GroupObject>, any> {
    let converted_url:string|undefined = ConvertUrlToId(params.url);
    if (!converted_url) {
        return left({message: 'Неправильная ссылка на группу: '+ params.url});
    }
    const get_group_params: GetGroupParams = {group_id: converted_url, access_token: params.access_token, fields: params.fields};
    const result: Either<GetGroupError, GroupObject> = yield GetGroup(get_group_params);
    return result;
};

export default GetGroupByUrl;
