import {TopicAddress} from "./ConverUrlToId";
import {GroupTopics, WithDefaultActions} from "../../store/parsers/types";
import {Topic} from "../objects/Topic";
import GetTopicsByIds from "./topics/GetTopicsByIds";
import GetFirstTopics, {GroupTopicsWithCount} from "./topics/GetFirstTopics";
import GetAllTopicsLeft from "./topics/GetAllTopicsLeft";

export interface GetBoardTopicsParams extends WithDefaultActions {
    topic_addresses: TopicAddress[],
    access_token: string,
}

const GetBoardTopics = function* (params: GetBoardTopicsParams): Generator<any, Topic[], any> {
    const result: Map<string, Topic> = new Map<string, Topic>();
    const topic_addresses_with_id: Required<TopicAddress>[] = params.topic_addresses.filter((item: TopicAddress) => {
        if (item.topic_id) {
            return true;
        }
        return false;
    }) as unknown as Required<TopicAddress>[];
    const topics_with_ids: GroupTopics[] = yield GetTopicsByIds({
        topic_addresses: topic_addresses_with_id,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action,
    });

    const fill_topics = (item: GroupTopics) => {
        item.topics.forEach((topic: Topic) => {
            topic.owner_id = item.group_id;
            const unique_id: string = `${item.group_id}_${topic.id}`;
            result.set(unique_id, topic);
        });
    };

    topics_with_ids.forEach(fill_topics);

    const topic_addresses_without_id: TopicAddress[] = params.topic_addresses.filter(item => !item.topic_id);

    const first_topics: GroupTopicsWithCount[] = yield GetFirstTopics({
        topic_addresses: topic_addresses_without_id,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action,
    });

    first_topics.forEach((item: GroupTopicsWithCount) => {
        fill_topics(item.topics);
    });

    const topics_left: GroupTopics[] = yield GetAllTopicsLeft({
        topics_with_count: first_topics,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action,
    });
    topics_left.forEach(fill_topics);

    return Array.from(result.values());
};

export default GetBoardTopics;
