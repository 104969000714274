import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {NumberChangeEvent} from "../ui/inputs/BasicNumbersInput";

function useMinMax(setMin: ActionCreatorWithPayload<number>, setMax: ActionCreatorWithPayload<number>) {
    const dispatch = useDispatch();
    const onMinChange = useCallback((e: NumberChangeEvent) => dispatch(setMin(parseInt(e.value))), [dispatch, setMin]);
    const onMaxChange = useCallback((e: NumberChangeEvent) => dispatch(setMax(parseInt(e.value))), [dispatch, setMax]);
    return [onMinChange, onMaxChange];
}

export default useMinMax;
