import {combineReducers, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import ReducerGroups from "./reducers/reducer-groups";
import ReducerUsers from "./reducers/reducer-users";
import * as actions from './actions';
import * as types from "./types";
import {BaseViewerType} from "./types";

const initialState: types.BaseViewerState = {
    settings: {
        viewer_type: BaseViewerType.USERS,
    },
    users: {
        settings: {
            source: '',
            show_avatar: false,
        },
        ...DefaultStateProps<UserObject[]>([])
    },
    groups: {
        settings: {
            source: '',
            show_avatar: false,
        },
        ...DefaultStateProps<GroupObject[]>([])
    }
};
const settingsReducer = createReducer(initialState['settings'], builder => {
    builder.addCase(actions.setSearchType, (state, action: PayloadAction<BaseViewerType>) => {
        state.viewer_type = action.payload;
    });
});
const usersReducer = ReducerUsers(initialState.users);
const groupsReducer = ReducerGroups(initialState.groups);

const combined = combineReducers(
    {
        settings: settingsReducer,
        users: usersReducer,
        groups: groupsReducer,
    }
);

export default combined;
