const NAMESPACE = 'ACTIVITIES_COMMUNITY/';

export const SET_DATASOURCE = NAMESPACE + 'SET_DATASOURCE';

export const SET_WHAT_TO_GET = NAMESPACE + 'SET_WHAT_TO_GET';
export const SET_START_DATE = NAMESPACE + 'SET_START_DATE';
export const SET_END_DATE = NAMESPACE + 'SET_END_DATE';
export const SET_ACTIVITIES_MIN = NAMESPACE + 'SET_ACTIVITIES_MIN';
export const SET_ACTIVITIES_MAX = NAMESPACE + 'SET_ACTIVITIES_MAX';
export const SET_GROUPS_MIN = NAMESPACE + 'SET_GROUPS_MIN';
export const SET_GROUPS_MAX = NAMESPACE + 'SET_GROUPS_MAX';
export const SET_POSTS_EVERY_COMMUNITY = NAMESPACE + 'SET_POSTS_EVERY_COMMUNITY';
export const SET_POSTS_MIN = NAMESPACE + 'SET_POSTS_MIN';
export const SET_POSTS_MAX = NAMESPACE + 'SET_POSTS_MAX';
export const ADD_ACTIVITY_TYPE = NAMESPACE + 'ADD_ACTIVITY_TYPE';
export const REMOVE_ACTIVITY_TYPE = NAMESPACE + 'REMOVE_ACTIVITY_TYPE';

export const START = NAMESPACE + 'START';
export const STOP = NAMESPACE + 'STOP';
export const SET_TASK_STATUS = NAMESPACE + 'SET_TASK_STATUS';
export const SET_TASK_ERROR = NAMESPACE + 'SET_TASK_ERROR';
export const SET_PROGRESS = NAMESPACE + 'SET_PROGRESS';
export const SET_SUBPROGRESS = NAMESPACE + 'SET_SUBPROGRESS';
export const SET_RESULT = NAMESPACE + 'SET_RESULT';

export const ADD_API_ERROR = NAMESPACE + 'ADD_API_ERROR';
export const RESET_API_ERRORS = NAMESPACE + 'RESET_API_ERRORS';

export const COMPLETED = NAMESPACE + 'COMPLETED';
