const NAMESPACE = 'USER_ANALYSIS/';

export const SET_DATASOURCE_TYPE = NAMESPACE + 'SET_DATASOURCE_TYPE';
export const SET_DATASOURCE_GROUP_URL = NAMESPACE + 'SET_DATASOURCE_GROUP_URL';
export const SET_DATASOURCE_USER_IDS = NAMESPACE + 'SET_DATASOURCE_USER_IDS';

export const SET_PROFILES = NAMESPACE + 'SET_PROFILES';
export const SET_FOUND_USERS = NAMESPACE + 'SET_FOUND_USERS';
export const SET_SELECTED_CATEGORY = NAMESPACE + 'SET_SELECTED_CATEGORY';
export const ADD_SELECTED_RESULTS = NAMESPACE + 'ADD_SELECTED_RESULTS';
export const CLEAR_SELECTED_RESULTS = NAMESPACE + 'CLEAR_SELECTED_RESULTS';

export const START_USERS_SEARCH = NAMESPACE + 'START_USERS_SEARCH';

export const START = NAMESPACE + 'START';
export const STOP = NAMESPACE + 'STOP';
export const SET_TASK_STATUS = NAMESPACE + 'SET_TASK_STATUS';
export const SET_TASK_ERROR = NAMESPACE + 'SET_TASK_ERROR';
export const SET_PROGRESS = NAMESPACE + 'SET_PROGRESS';
export const SET_RESULT = NAMESPACE + 'SET_RESULT';
export const SET_TASK_TITLE = NAMESPACE + 'SET_TASK_TITLE';

export const ADD_API_ERROR = NAMESPACE + 'ADD_API_ERROR';
export const RESET_API_ERRORS = NAMESPACE + 'RESET_API_ERRORS';

export const COMPLETED = NAMESPACE + 'COMPLETED';
