import {GroupsSearchSort} from "../../../../vkapi/methods/GroupsSearchParams";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {City, Country, StateWithErrors, StateWithProgress} from "../../types";

export enum GroupAccessType {
    OPEN = "open",
    CLOSED = "closed",
    ANY = "any",
}

interface State {
    settings: {
        search_query: string,
        is_strict: boolean,
        only_official: boolean,
        type_group: boolean,
        type_public: boolean,
        type_event: boolean,
        type_future_event: boolean,
        group_access_type: GroupAccessType,
        selected_country: Country|null,
        cities: City[],
        sort_type: GroupsSearchSort,
        members_min: number,
        members_max: number,
        title_length_min: number,
        title_length_max: number,
        min_date: Date|null,
        words_plus: string[],
        words_minus: string[],
        is_last_post_dates_found: boolean,
    },
    result: CommunitySearchItem[],
}

export interface CommunitySearchItem {
    group: GroupObject,
    last_post: number,
}

export interface SearchCommunitiesState extends State, StateWithErrors, StateWithProgress {}
