import {combineReducers} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import ReducerGroups from "./reducers/reducer-groups";
import ReducerUsers from "./reducers/reducer-users";
import * as types from "./types";
import DefaultStateProps from "../../../helpers/DefaultStateProps";

const initialState: types.IdsConverterState = {
    users: {
        settings: {
            source: [],
        },
        ...DefaultStateProps<UserObject[]>([])
    },
    groups: {
        settings: {
            source: [],
        },
        ...DefaultStateProps<GroupObject[]>([])
    }
};

const usersReducer = ReducerUsers(initialState.users);
const groupsReducer = ReducerGroups(initialState.groups);

const combined = combineReducers(
    {
        users: usersReducer,
        groups: groupsReducer,
    }
);

export default combined;
