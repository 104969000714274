import {createAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {TaskProgress} from "../../types";
import * as types from "./action-types";
import {InstagramResult, InstagramSearchType, SiteType} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSearchType = createAction(types.SET_SEARCH_TYPE, withPayloadType<InstagramSearchType>());
export const setSourceGroups = createAction(types.SET_SOURCE_GROUPS, withPayloadType<string>());
export const setSourceUsers = createAction(types.SET_SOURCE_USERS, withPayloadType<string>());
export const setSiteType = createAction(types.SET_SITE_TYPE, withPayloadType<SiteType>());
export const setSkipped = createAction(types.SET_SKIPPED, withPayloadType<GroupObject[]>());
export const setSubProgress = createAction(types.SET_SUBPROGRESS, withPayloadType<TaskProgress>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<InstagramResult>(types.NAMESPACE);
