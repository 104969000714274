export const NAMESPACE = 'POSTS_SEARCH/';

export const SET_GROUPS_SOURCE = NAMESPACE + 'SET_GROUPS_SOURCE';
export const SET_QUERIES_SOURCE = NAMESPACE + 'SET_QUERIES_SOURCE';
export const SET_IS_STRICT = NAMESPACE + 'SET_IS_STRICT';
export const SET_WORDS_MINUS = NAMESPACE + 'SET_WORDS_MINUS';
export const SET_START_DATE = NAMESPACE + 'SET_START_DATE';
export const SET_END_DATE = NAMESPACE + 'SET_END_DATE';
export const SET_ATTACH_TYPE = NAMESPACE + 'SET_ATTACH_TYPE';

export const SET_SUB_PROGRESS = NAMESPACE + 'SET_SUB_PROGRESS';
