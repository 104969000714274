import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {Relation, UserObject} from "../../../../vkapi/objects/UserObject";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {City, Country, DefaultProps, Gender} from "../../types";
import * as actions from './actions';
import * as types from "./types";
import {ZodiacSign} from "./types";

const initialState: types.SearchBaseState = {
    settings: {
        source_users: [],
        source_names: '',
        age_from: 0,
        age_to: 0,
        only_hidden_age: false,
        zodiac_sign: ZodiacSign.ANY,
        remove_blocked: true,
        keep_blocked: false,
        keep_verified: false,
        gender: Gender.ANY,
        selected_country: null,
        cities: [],
        family: [],
        has_children: false,
        last_activity: null,
        source_status: '',
        status_has_all_words: false,
    },
    users: [],
    ...DefaultStateProps<UserObject[]>([])
};

const SearchBaseReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSourceUsers, (state, action: PayloadAction<string[]>) => {
        state.settings.source_users = action.payload;
        state.users = [];
    });
    builder.addCase(actions.setSourceNames, (state, action: PayloadAction<string>) => {
        state.settings.source_names = action.payload;
    });
    builder.addCase(actions.setAgeFrom, (state, action: PayloadAction<number>) => {
        state.settings.age_from = action.payload;
    });
    builder.addCase(actions.setAgeTo, (state, action: PayloadAction<number>) => {
        state.settings.age_to = action.payload;
    });
    builder.addCase(actions.setOnlyHiddenAge, (state, action: PayloadAction<boolean>) => {
        state.settings.only_hidden_age = action.payload;
    });
    builder.addCase(actions.setZodiacSign, (state, action: PayloadAction<ZodiacSign>) => {
        state.settings.zodiac_sign = action.payload;
    });
    builder.addCase(actions.setRemoveBlocked, (state, action: PayloadAction<boolean>) => {
        state.settings.remove_blocked = action.payload;
        if (action.payload) {
            state.settings.keep_blocked = false;
        }
    });
    builder.addCase(actions.setKeepBlocked, (state, action: PayloadAction<boolean>) => {
        state.settings.keep_blocked = action.payload;
        if (action.payload) {
            state.settings.remove_blocked = false;
        }
    });
    builder.addCase(actions.setKeepVerified, (state, action: PayloadAction<boolean>) => {
        state.settings.keep_verified = action.payload;
    });
    builder.addCase(actions.setGender, (state, action: PayloadAction<Gender>) => {
        state.settings.gender = action.payload;
    });
    builder.addCase(actions.setSelectedCountry, (state, action: PayloadAction<Country|null>) => {
        state.settings.selected_country = action.payload;
    });
    builder.addCase(actions.setCities, (state, action: PayloadAction<City[]>) => {
        state.settings.cities = action.payload;
    });
    builder.addCase(actions.addFamily, (state, action: PayloadAction<Relation>) => {
        state.settings.family.push(action.payload);
    });
    builder.addCase(actions.removeFamily, (state, action: PayloadAction<Relation>) => {
        const index: number = state.settings.family.findIndex(item => item === action.payload);
        if (index > -1) {
            state.settings.family.splice(index, 1);
        }
    });
    builder.addCase(actions.setHasChildren, (state, action: PayloadAction<boolean>) => {
        state.settings.has_children = action.payload;
    });
    builder.addCase(actions.setLastActivity, (state, action: PayloadAction<Date|null>) => {
        state.settings.last_activity = action.payload;
    });
    builder.addCase(actions.setSourceStatus, (state, action: PayloadAction<string>) => {
        state.settings.source_status = action.payload;
    });
    builder.addCase(actions.setStatusHasAllWords, (state, action: PayloadAction<boolean>) => {
        state.settings.status_has_all_words = action.payload;
    });
    builder.addCase(actions.setUsers, (state, action: PayloadAction<UserObject[]>) => {
        state.users = action.payload;
    });

    AddDefaultCasesToReducer<UserObject[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<UserObject[]>>);
});

export default SearchBaseReducer;
