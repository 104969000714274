import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {DataSourceType, TaskProgress} from "../parsers/types";
import {Either, isLeft} from "fp-ts/Either";
import {GetGroupError} from "../../vkapi/tasks/GetGroup";
import {GroupObject} from "../../vkapi/objects/GroupObject";
import GetGroupByUrl from "../../vkapi/tasks/GetGroupByUrl";
import GetAllGroupMemberIds, {GetAllGroupMemberIdsError} from "../../vkapi/tasks/GetAllGroupMemberIds";

function* getUserIdsFromGroupOrFile(data_source_type: string, url: string, file: string, access_token: string, progress_action: ActionCreatorWithPayload<TaskProgress>): Generator<any, number[], any> {
    if (data_source_type === DataSourceType.URL) {
        const get_group_by_url_result: Either<GetGroupError, GroupObject> = yield GetGroupByUrl({access_token: access_token, url: url});
        if (isLeft(get_group_by_url_result)) {
            throw new Error(get_group_by_url_result.left.message);
        }
        const group: GroupObject = get_group_by_url_result.right;
        const user_ids: Either<GetAllGroupMemberIdsError, number[]> = yield GetAllGroupMemberIds({
            group_id: group.id,
            access_token: access_token,
            progressAction: progress_action,
        });
        if (isLeft(user_ids)) {
            throw new Error(user_ids.left.message);
        }
        return user_ids.right;
    }

    return file.split('\n').map(line => parseInt(line.trim()));
}

export default getUserIdsFromGroupOrFile;
