import React from "react";

export interface NoticeProps {
    text: string
}

const Notice = (props: NoticeProps) => {
    return (
        <span className="text-red-800">{props.text}</span>
    )
};

export default Notice;
