import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {VkApiError} from "../../../../vkapi/VkApiError";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {SearchTypeListOrSearch} from "../../types";
import * as types from "./types";
import {InvolvingPost} from "./types";
import * as global_types from "../../types";
import * as actions from './actions';

const moment = require('moment');

const initialState: types.InvolvingPostsState = {
    settings: {
        search_type: SearchTypeListOrSearch.BY_GROUP_URLS,
        groups_source: '',
        search_queries_source: '',
        start_date: moment().subtract(14, "days").startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
    },
    sub_progress: {message: ''},
    ...DefaultStateProps<InvolvingPost[]>([])
};

const InvolvingPostsReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSearchType, (state: types.InvolvingPostsState, action: PayloadAction<SearchTypeListOrSearch>) => {
        state.settings.search_type = action.payload;
    });
    builder.addCase(actions.setGroupsSource, (state: types.InvolvingPostsState, action: PayloadAction<string>) => {
        state.settings.groups_source = action.payload;
    });
    builder.addCase(actions.setSearchQueriesSource, (state: types.InvolvingPostsState, action: PayloadAction<string>) => {
        state.settings.search_queries_source = action.payload;
    });
    builder.addCase(actions.setStartDate, (state: types.InvolvingPostsState, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state: types.InvolvingPostsState, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });

    builder.addCase(actions.setTaskStatus, (state: types.InvolvingPostsState, action: PayloadAction<global_types.TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setSubProgress, (state: types.InvolvingPostsState, action: PayloadAction<global_types.TaskProgress>) => {
        state.sub_progress = action.payload;
    });
    builder.addCase(actions.setProgress, (state: types.InvolvingPostsState, action: PayloadAction<global_types.TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: types.InvolvingPostsState, action: PayloadAction<InvolvingPost[]>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: types.InvolvingPostsState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state: types.InvolvingPostsState) => {
        state.api_errors = [];
    });
});

export default InvolvingPostsReducer;
