import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../../helpers/DefaultActionsCreator";
import {TaskProgress} from "../../../types";
import * as types from "../action-types";
import {ActivityFilterResult} from "../types";

function withPayloadType<T>() {
    return (t: T) => ({payload: t})
}

export const setSource = createAction(types.activity.SET_SOURCE, withPayloadType<string>());
export const setOnlyMembers = createAction(types.activity.SET_ONLY_MEMBERS, withPayloadType<boolean>());
export const setOnlySelectedUsers = createAction(types.activity.SET_ONLY_SELECTED_USERS, withPayloadType<boolean>());
export const setSelectedUsersSource = createAction(types.activity.SET_SELECTED_USERS_SOURCE, withPayloadType<string>());
export const setStartDate = createAction(types.activity.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.activity.SET_END_DATE, withPayloadType<Date|null>());

export const setSubProgress = createAction(types.activity.SET_SUBPROGRESS, withPayloadType<TaskProgress>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<ActivityFilterResult[]>(types.activity.NAMESPACE);
