import {WithDefaultActions} from "../../store/parsers/types";
import SendMethodsWithAutoRetry from "../helpers/SendMethodsWithAutoRetry";
import {IVkMethod} from "../IVkMethod";
import {GroupsGetMembersParams} from "../methods/GroupsGetMembersParams";
import * as methods from "../methods/VkMethods";
import {ERROR_CODE_HIDDEN_MEMBERS} from "../VkApiError";

export interface CheckHiddenMembersParams extends WithDefaultActions {
    group_id: number,
    access_token: string,
}

const CheckHiddenMembers = function* (params: CheckHiddenMembersParams): Generator<any, boolean, any> {

    const method_params: GroupsGetMembersParams = {
        group_id: params.group_id,
        count: 1,
        offset: 0,
    };
    const method: IVkMethod = methods.groupsGetMembers(method_params);

    let is_hidden: boolean = false;

    yield SendMethodsWithAutoRetry<{count: number}>({
        methods: [method],
        access_token: params.access_token,
        onResponse: response => {},
        onError: (error) => {
            if (error.error_data.error_code === ERROR_CODE_HIDDEN_MEMBERS) {
                is_hidden = true;
            }
        },
    });

    return is_hidden;
};

export default CheckHiddenMembers;
