import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {VkApiError} from "../../../../vkapi/VkApiError";
import * as global_types from "../../types";
import * as actions from './actions';
import * as types from "./types";
import {FilterLikes} from "./types";

const moment = require('moment');

const initialState: types.TopCommentatorsCommunityState = {
    settings: {
        data_source: '',
        filter_likes: FilterLikes.ALL_USERS,
        filter_likes_users: '',
        start_date: moment().subtract(14, "days").startOf("day").toDate(),
        end_date: null,
        is_skip_first_post: false,
        likes_min: 0,
        likes_max: 0,
        comments_min: 0,
        comments_max: 0,
        comments_length_min: 0,
        comments_length_max: 0,
        words_plus: '',
        words_minus: '',
    },
    sub_progress: {message: ''},
    ...DefaultStateProps<global_types.TopComment[]>([])
};

const TopCommentatorsCommunityReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setDataSource, (state: types.TopCommentatorsCommunityState, action: PayloadAction<string>) => {
        state.settings.data_source = action.payload;
    });
    builder.addCase(actions.setFilterLikes, (state: types.TopCommentatorsCommunityState, action: PayloadAction<FilterLikes>) => {
        state.settings.filter_likes = action.payload;
    });
    builder.addCase(actions.setFilterLikesUsers, (state: types.TopCommentatorsCommunityState, action: PayloadAction<string>) => {
        state.settings.filter_likes_users = action.payload;
    });
    builder.addCase(actions.setStartDate, (state: types.TopCommentatorsCommunityState, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state: types.TopCommentatorsCommunityState, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });
    builder.addCase(actions.setIsSkipFirstPost, (state: types.TopCommentatorsCommunityState, action: PayloadAction<boolean>) => {
        state.settings.is_skip_first_post = action.payload;
    });
    builder.addCase(actions.setLikesMin, (state: types.TopCommentatorsCommunityState, action: PayloadAction<number>) => {
        state.settings.likes_min = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setLikesMax, (state: types.TopCommentatorsCommunityState, action: PayloadAction<number>) => {
        state.settings.likes_max = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setCommentsMin, (state: types.TopCommentatorsCommunityState, action: PayloadAction<number>) => {
        state.settings.comments_min = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setCommentsMax, (state: types.TopCommentatorsCommunityState, action: PayloadAction<number>) => {
        state.settings.comments_max = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setCommentsLengthMin, (state: types.TopCommentatorsCommunityState, action: PayloadAction<number>) => {
        state.settings.comments_length_min = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setCommentsLengthMax, (state: types.TopCommentatorsCommunityState, action: PayloadAction<number>) => {
        state.settings.comments_length_max = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setWordsPlus, (state: types.TopCommentatorsCommunityState, action: PayloadAction<string>) => {
        state.settings.words_plus = action.payload;
    });
    builder.addCase(actions.setWordsMinus, (state: types.TopCommentatorsCommunityState, action: PayloadAction<string>) => {
        state.settings.words_minus = action.payload;
    });
    builder.addCase(actions.setTaskStatus, (state: types.TopCommentatorsCommunityState, action: PayloadAction<global_types.TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setProgress, (state: types.TopCommentatorsCommunityState, action: PayloadAction<global_types.TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setSubProgress, (state: types.TopCommentatorsCommunityState, action: PayloadAction<global_types.TaskProgress>) => {
        state.sub_progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: types.TopCommentatorsCommunityState, action: PayloadAction<global_types.TopComment[]>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: types.TopCommentatorsCommunityState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state: types.TopCommentatorsCommunityState) => {
        state.api_errors = [];
    });
});

export default TopCommentatorsCommunityReducer;
