import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {City, Country} from "../../store/parsers/types";
import CitySingleInput from "../ui/inputs/CitySingleInput";

function useCitySingle(country: Country|null, city: City|null, setCity: ActionCreatorWithPayload<City|null>) {
    const dispatch = useDispatch();

    const onCityClear = useCallback(() => {
        dispatch(setCity(null));
    }, [setCity, dispatch]);

    const onCitySelect = useCallback((item: Country) => {
        dispatch(setCity(item))
    }, [setCity, dispatch]);

    const component = (
        <CitySingleInput
            selectedCountry={country ?? {id: 1, title: 'Россия'}}
            selectedCity={city}
            onSelect={onCitySelect}
            onClear={onCityClear}
        />
    );

    return component;
}

export default useCitySingle;
