import React, {FormEvent} from "react";
import {InputText} from "primereact/inputtext";
import { v4 as uuidv4 } from 'uuid';

export interface BasicTextInputProps extends React.HTMLAttributes<HTMLDivElement> {
    id?: string,
    label?: string,
    value?: string,
    placeholder?: string,
    onChange?: (e: FormEvent<HTMLInputElement>) => void
    disabled?: boolean
}

const BasicTextInput = (props: BasicTextInputProps) => {

    const classes = props.className || "p-field tw-w-full sm:tw-flex-1";
    const uniqueId = props.id ? props.id : uuidv4();

    return (
        <div className={classes}>
            {props.label &&
                <label htmlFor={uniqueId}>{props.label}</label>
            }
            <InputText className="tw-w-full" id={uniqueId}
                       value={props.value} onChange={props.onChange}
                       placeholder={props.placeholder} type="text"
                       disabled={props.disabled}
            />
        </div>
    )
};

export default BasicTextInput;
