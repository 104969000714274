import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../../helpers/DefaultActionsCreator";
import * as types from "../action-types";
import {LastPostFilterResult} from "../types";

function withPayloadType<T>() {
    return (t: T) => ({payload: t})
}

export const setSource = createAction(types.lastPost.SET_SOURCE, withPayloadType<string>());
export const setOnlyFromCommunities = createAction(types.lastPost.SET_ONLY_FROM_COMMUNITY, withPayloadType<boolean>());
export const setStartDate = createAction(types.lastPost.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.lastPost.SET_END_DATE, withPayloadType<Date|null>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<LastPostFilterResult[]>(types.lastPost.NAMESPACE);
