import {ERROR_CODE_RATE_LIMIT, VkApiError} from "../VkApiError";
import _find from "lodash/find";

const RateLimitHandler = function (error: VkApiError) {
    if (error.error_data.error_reason.error_code === ERROR_CODE_RATE_LIMIT) {
        let method_name: string = 'неизвестный метод';
        const method_param: {key: string, value: string}|undefined = _find(error.error_data.error_reason.request_params, {'key': 'method'});
        if (method_param) {
            method_name = method_param.value;
        }
        throw new Error('Достигнут лимит запросов для метода: ' + method_name);
    }
};

export default RateLimitHandler;
