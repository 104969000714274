export enum BoardCommentsSort {
    ASC = "asc",
    DESC = "desc",
}

export interface BoardGetCommentsParams {
    group_id: number,
    topic_id: number,
    need_likes: 0|1,
    offset: number,
    count: number,
    sort?: BoardCommentsSort,
}
