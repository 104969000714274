import GetGroup, {GetGroupError} from "./GetGroup";
import {put} from 'redux-saga/effects';
import {GroupObject} from "../objects/GroupObject";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import {GroupsGetMembersParams} from "../methods/GroupsGetMembersParams";
import _chunk from "lodash/chunk";
import {IdsAndCountObject} from "../objects/IdsAndCountObject";
import {Either, isLeft, left, right} from "fp-ts/lib/Either";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {TaskProgress} from "../../store/parsers/types";

export interface GetAllGroupMembersParams {
    group_id: number,
    access_token: string,
    progressAction?: ActionCreatorWithPayload<TaskProgress>,
}

export interface GetAllGroupMemberIdsError {
    message: string,
    group_id: number,
}

const GetAllGroupMemberIds = function* (params:GetAllGroupMembersParams): Generator<any, Either<GetAllGroupMemberIdsError, number[]>, any> {
    const group_id = params.group_id;
    const access_token = params.access_token;
    if (params.progressAction) {
        yield put(params.progressAction({message: "Получаем информацию о группе"}));
    }
    const get_group_result: Either<GetGroupError, GroupObject> = yield GetGroup({group_id: group_id, fields: 'members_count', access_token: access_token});
    if (isLeft(get_group_result)) {
        return left({message: get_group_result.left.message, group_id: params.group_id});
    }
    const group: GroupObject = get_group_result.right;
    if(!group.members_count) {
        return left({message: `Отсутствует информация о количестве пользователей в группе: https://vk.com/club${params.group_id}`, group_id: params.group_id});
    }

    const executes:Array<IVkMethod> = prepare(group_id, group.members_count);
    try {
        const group_member_ids: number[] = yield send(executes, access_token, params.progressAction);
        return right(group_member_ids)
    }
    catch (e) {
        return left({message: e.message, group_id: params.group_id});
    }
};

function prepare(group_id: number, members_count: number): Array<IVkMethod> {
    const max_requests_per_execute: number = 20;
    const groupsGetMembersMethods: IVkMethod[] = makeGroupsGetMembersMethods(group_id, members_count);
    const chunks:IVkMethod[][] = _chunk(groupsGetMembersMethods, max_requests_per_execute);
    return chunks.map((chunk) => {
        const requests:string[] = chunk.map((method) => {
            return "API." + method.method_name + "(" + JSON.stringify(method.params) + ")";
        });
        const code:string = "return [" + requests.join(",") + "];";
        return methods.execute({code: code});
    });
}

function makeGroupsGetMembersMethods(group_id: number, members_count: number): Array<IVkMethod> {
    const members_per_request: number = 250;
    let result:Array<IVkMethod> = [];
    for (let offset = 0; offset < members_count; offset += members_per_request) {
        let groupsGetMembersParams:GroupsGetMembersParams = {
            group_id: group_id,
            offset: offset,
            count: members_per_request
        };
        let method = methods.groupsGetMembers(groupsGetMembersParams);
        result.push(method);
    }
    return result;
}

function* send(executes:IVkMethod[], access_token: string, progress_action?: ActionCreatorWithPayload<TaskProgress>): Generator<any, number[], any> {

    const member_ids:Set<number> = new Set<number>();

    const handler = (response: Array<IdsAndCountObject|false>) => {
        response.forEach((response_item: IdsAndCountObject|false) => {
            if (!response_item) {
                return;
            }
            response_item.items.forEach((id: number) => member_ids.add(id));
        });
    };

    yield SendMethodsDefault<Array<IdsAndCountObject|false>>({
        methods_params: {
            methods: executes,
            access_token: access_token,
            progress_action: progress_action
        },
        response_handler: handler,
        error_handler: error => {
            if (error.error_data.error_code === 15) {
                throw new Error('В сообществе скрыт список участников');
            }
        },
        progress_text: 'Получаем подписчиков сообщества',
        chunk_size: 6,
    });

    return Array.from(member_ids);
}

export default GetAllGroupMemberIds;
