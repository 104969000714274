export const NAMESPACE = 'ACTIVITIES_CONTEST/';

export const SET_SOURCE = NAMESPACE + 'SET_SOURCE';
export const SET_START_DATE = NAMESPACE + 'SET_START_DATE';
export const SET_END_DATE = NAMESPACE + 'SET_END_DATE';
export const SET_SCORE_LIKE_ENABLED = NAMESPACE + 'SET_SCORE_LIKE_ENABLED';
export const SET_SCORE_LIKE = NAMESPACE + 'SET_SCORE_LIKE';
export const SET_SCORE_LIKE_ALL_ENABLED = NAMESPACE + 'SET_SCORE_LIKE_ALL_ENABLED';
export const SET_SCORE_LIKE_ALL = NAMESPACE + 'SET_SCORE_LIKE_ALL';
export const SET_SCORE_LIKE_COMMENT_ENABLED = NAMESPACE + 'SET_SCORE_LIKE_COMMENT_ENABLED';
export const SET_SCORE_LIKE_COMMENT = NAMESPACE + 'SET_SCORE_LIKE_COMMENT';
export const SET_SCORE_LIKE_FROM_AUTHOR_ENABLED = NAMESPACE + 'SET_SCORE_LIKE_FROM_AUTHOR_ENABLED';
export const SET_SCORE_LIKE_FROM_AUTHOR = NAMESPACE + 'SET_SCORE_LIKE_FROM_AUTHOR';
export const SET_SCORE_COMMENT_ENABLED = NAMESPACE + 'SET_SCORE_COMMENT_ENABLED';
export const SET_SCORE_COMMENT = NAMESPACE + 'SET_SCORE_COMMENT';
export const SET_SCORE_BEST_COMMENT_ENABLED = NAMESPACE + 'SET_SCORE_BEST_COMMENT_ENABLED';
export const SET_SCORE_BEST_COMMENT = NAMESPACE + 'SET_SCORE_BEST_COMMENT';
export const SET_COMMENT_MIN_LENGTH = NAMESPACE + 'SET_COMMENT_MIN_LENGTH';
export const SET_SCORE_LIKE_OWNER_ENABLED = NAMESPACE + 'SET_SCORE_LIKE_OWNER_ENABLED';
export const SET_OWNER_URL = NAMESPACE + 'SET_OWNER_URL';
export const SET_SCORE_LIKE_OWNER = NAMESPACE + 'SET_SCORE_LIKE_OWNER';
