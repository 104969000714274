import React, {useRef} from "react";
import {FileUpload} from "primereact/fileupload";
import get_lines_from_file from "../../../helpers/get_lines_from_file";
import { v4 as uuidv4 } from 'uuid';

export interface BasicFileUploadProps extends React.HTMLAttributes<HTMLDivElement> {
    id?: string,
    chooseLabel: string,
    onUpload: (lines: string[]) => void
}

const BasicFileUpload = (props: BasicFileUploadProps) => {

    const onFileUpload = (event: {files: any}) => {
        get_lines_from_file(event.files as FileList, (unique) => {
            props.onUpload(unique);
            if (ref && ref.current) {
                ref.current.clear();
            }
        });
    };

    const ref = useRef<FileUpload>(null);

    const classes = props.className || "p-field tw-w-full sm:tw-w-auto";
    return (
        <div className={classes}>
            <FileUpload ref={ref} id={props.id ? props.id : uuidv4()} mode="basic" accept="*.txt" auto chooseLabel={props.chooseLabel} customUpload={true} uploadHandler={onFileUpload}/>
        </div>
    );
};

export default BasicFileUpload;
