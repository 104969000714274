import {
    ActionCreatorWithoutPayload,
    ActionCreatorWithPayload, ActionReducerMapBuilder,
    PayloadAction
} from "@reduxjs/toolkit";
import {VkApiError} from "../../vkapi/VkApiError";
import {DefaultProps, TaskProgress, TaskStatus} from "../parsers/types";

export interface AddDefaultCasesToReducerProps<TResult> {
    actions: {
        setTaskStatus: ActionCreatorWithPayload<TaskStatus>,
        setProgress: ActionCreatorWithPayload<TaskProgress>,
        setResult: ActionCreatorWithPayload<TResult>,
        addApiError: ActionCreatorWithPayload<VkApiError>,
        resetApiErrors: ActionCreatorWithoutPayload,
    }
}

export default function addDefaultCasesToReducer<TResult>(
    props: AddDefaultCasesToReducerProps<TResult>,
    builder: ActionReducerMapBuilder<DefaultProps<TResult>>) {

    const actions = props.actions;
    builder.addCase(actions.setTaskStatus, (state, action: PayloadAction<TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setProgress, (state, action: PayloadAction<TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setResult, (state, action: PayloadAction<TResult>) => {
        (state.result as unknown as TResult) = action.payload;
    });
    builder.addCase(actions.addApiError, (state, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state) => {
        state.api_errors = [];
    });
}
