import _chunk from "lodash/chunk";
import cartesian from "../../helpers/Cartesian";
import {WithDefaultActions} from "../../store/parsers/types";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {IVkMethod} from "../IVkMethod";
import {GroupsSearchParams as MethodParams, GroupsSearchSort, GroupsSearchType} from "../methods/GroupsSearchParams";
import * as methods from "../methods/VkMethods";
import {City} from "../objects/City";
import {Country} from "../objects/Country";
import {GroupObject} from "../objects/GroupObject";
import {ProcessMethodsParams} from "../types";

export enum TaskSearchType {
    GROUP = "group",
    PAGE = "page",
    EVENT = "event",
    FUTURE_EVENT = "future_event",
}

export interface GroupsSearchParams extends WithDefaultActions {
    query: string,
    types: TaskSearchType[],
    countries: Array<Country|null>,
    cities: City[],
    sort: GroupsSearchSort,
    access_token: string,
}

const GroupsSearch = function* (params: GroupsSearchParams): Generator<any, Map<number, GroupObject>, any> {
    const executes:Array<IVkMethod> = prepare(params.query, params.types, params.countries, params.cities, params.sort);
    const executes_result: Map<number, GroupObject> = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(query: string, types: TaskSearchType[], countries: Array<Country|null>, cities: City[], sort: GroupsSearchSort): IVkMethod[] {

    let countries_ids: Array<number|undefined> = [undefined];
    if (countries.length > 0) {
        countries_ids = countries.map(item => item?.id);
    }
    let cities_ids: Array<number|undefined> = [undefined];
    if (cities.length > 0) {
        cities_ids = cities.map(item => item.id);
    }

    const combinations: Array<[TaskSearchType, number|undefined, number|undefined]> = cartesian(types, countries_ids, cities_ids);

    const requests: string[] = combinations.map(cmb => {
        const [type, country_id, city_id] = cmb;
        let search_type: GroupsSearchType = convertSearchType(type);
        const params: MethodParams = {
            q: query,
            type: search_type,
            count: 1000,
            sort: sort,
            offset: 0
        };
        if (city_id) {
            params.city_id = city_id;
        }
        if (country_id) {
            params.country_id = country_id;
        }
        if (type === TaskSearchType.FUTURE_EVENT) {
            params.future = 1;
        }

        const method: IVkMethod = methods.groupsSearch(params);
        return `API.${method.method_name}(${JSON.stringify(method.params)}).items`;
    });

    const chunks = _chunk(requests, 2);

    return chunks.map((requests: string[]) => {
        const code:string = `return [${requests.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, Map<number, GroupObject>, any> {
    const result:Map<number, GroupObject> = new Map<number, GroupObject>();

    const handler = (response: Array<GroupObject[]|false>) => {
        response.forEach((response_item: GroupObject[]|false) => {
            if (!response_item) {
                return;
            }
            response_item.forEach(group => result.set(group.id, group));
        });
    };

    yield SendMethodsDefault<Array<GroupObject[]|false>>({
        methods_params: {
            methods: params.methods,
            access_token: params.access_token,
            progress_action: params.progress_action,
            add_api_errors_action: params.add_api_errors_action,
        },
        response_handler: handler,
        progress_text: 'Ищем группы',
        chunk_size: 6,
    });

    return result;
};

function convertSearchType(type: TaskSearchType): GroupsSearchType {
    if (type === TaskSearchType.GROUP) {
        return GroupsSearchType.GROUP;
    }
    if (type === TaskSearchType.PAGE) {
        return GroupsSearchType.PAGE;
    }
    if (type === TaskSearchType.EVENT || type === TaskSearchType.FUTURE_EVENT) {
        return GroupsSearchType.EVENT;
    }
    return GroupsSearchType.GROUP;
}

export default GroupsSearch;
