import bridge from "@vkontakte/vk-bridge";
import Bottleneck from "bottleneck";
import {IVkMethod} from "./IVkMethod";
import {VkApiResponse} from "./VkApiResponse";
import {Either, left, right} from "fp-ts/lib/Either";
import {VkApiError} from "./VkApiError";

const limiter = new Bottleneck({
    maxConcurrent: 6,
    minTime: 350,
});

const api = async (method:IVkMethod, access_token:string): Promise<Either<VkApiError,VkApiResponse>> => {
    return limiter.schedule(() => callApi(method, access_token));
};

const callApi = async (method:IVkMethod, access_token:string): Promise<Either<VkApiError,VkApiResponse>> => {
    return new Promise(async (resolve) => {
        const params = {
            method: method.method_name,
            params: {...method.params, access_token: access_token, v: "5.103", lang: "ru"}
        };
        try {
            const result = await bridge.send("VKWebAppCallAPIMethod", params);
            resolve(right(result));
        }
        catch (e) {
            resolve(left(e));
        }
    });
};

export default api;
