import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {Either, isRight} from "fp-ts/lib/Either";
import {COMPLETED, SET_TASK_ERROR, START, STOP} from "./action-types";
import {EMPTY_ID_FILE, EMPTY_URL_GROUP} from "../../validation-errors";
import {DataSourceType, RootState, TaskStatus} from "../../types";
import * as actions from "./actions";

import GetGroupCounts from "./tasks/GetGroupCounts";
import GetTargetAudienceGroups from "./tasks/GetTargetAudienceGroups";
import GetSubscriptionCounts from "./tasks/GetSubscriptionCounts";

import {AnalysisType, GroupCount, TargetAudienceGroup, TargetAudienceLocatorState} from "./types";
import {showAlert} from "../../../app/actions";

import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {GetGroupError} from "../../../../vkapi/tasks/GetGroup";
import GetGroupByUrl from "../../../../vkapi/tasks/GetGroupByUrl";
import GetUserIdsFromGroupOrFile from "../../../helpers/GetUserIdsFromGroupOrFile";

const main = function* () {
    while ( yield take(START) ) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}


function* execute() {

    yield put(actions.resetApiErrors());

    const state: TargetAudienceLocatorState = yield select((state: RootState) => state.targetAudienceLocator);
    yield validate(state);

    const access_token = yield select((state: RootState) => state.app.access_token);

    let target_group_id: number|undefined;

    if (state.settings.data_source_type === DataSourceType.URL) {
        target_group_id = yield getTargetGroupId(state.settings.data_source_url, access_token);
    }

    const user_ids: number[] = yield GetUserIdsFromGroupOrFile(state.settings.data_source_type, state.settings.data_source_url, state.settings.data_source_file, access_token, actions.setProgress);

    let group_counts: Map<number, GroupCount> = new Map<number, GroupCount>();
    if (state.settings.analysis_type === AnalysisType.ANALISE_ALL) {
        group_counts = yield GetGroupCounts(user_ids, access_token, actions.setProgress, actions.addApiError);
    }

    if (state.settings.analysis_type === AnalysisType.ANALISE_TOP) {
        const top_min: number = state.settings.top_min;
        let top_max: number = state.settings.top_max;
        if (top_max === 0 || top_max > 200) {
            top_max = 200;
        }
        group_counts = yield GetSubscriptionCounts({
            user_ids: user_ids,
            top_min: top_min,
            top_max: top_max,
            access_token: access_token,
            progress_action: actions.setProgress,
            add_api_errors_action: actions.addApiError
        });
    }

    yield put(actions.setProgress({message: `Популярные группы собраны`, total: undefined, current: undefined}));

    const target_audience_groups: TargetAudienceGroup[] = yield GetTargetAudienceGroups({
        group_counts: group_counts,
        target_group_id: target_group_id,
        truncate_to_count: state.settings.truncate_to_count,
        filter: (group: GroupObject) => {
            if (group.deactivated) {
                return false;
            }
            if (!group.members_count) {
                return false;
            }
            if (state.settings.members_min && group.members_count < state.settings.members_min) {
                return false;
            }
            if (state.settings.members_max && group.members_count > state.settings.members_max) {
                return false;
            }
            return true;
        },
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });

    yield put(actions.setProgress({message: `Завершено.`, total: undefined, current: undefined}));
    yield put(showAlert({text: `Готово.`, header: 'Парсинг завершен'}));
    yield put(actions.setResult(target_audience_groups));

    yield put({ type: COMPLETED});
}

function* getTargetGroupId(url: string, access_token: string): Generator<any, number|undefined, any> {
    const api_result: Either<GetGroupError, GroupObject> = yield GetGroupByUrl({url: url, fields: 'members_count', access_token: access_token});
    if (isRight(api_result)) {
        return api_result.right.id;
    }
    return undefined;
}

function* validate(state: TargetAudienceLocatorState) {
    if (state.settings.data_source_type === DataSourceType.URL) {
        if (state.settings.data_source_url.trim() === '') {
            yield put(showAlert({text: EMPTY_URL_GROUP, header: 'Ошибка'}));
            yield put(actions.stop());
            return;
        }
    }
    if (state.settings.data_source_type === DataSourceType.FILE) {
        if (state.settings.data_source_file.trim() === '') {
            yield put(showAlert({text: EMPTY_ID_FILE, header: 'Ошибка'}));
            yield put(actions.stop());
            return;
        }
    }
}

function* onStop(task: any) {
    yield take(STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(COMPLETED);
}

function* onError(task: any) {
    const error: PayloadAction<Error> = yield take(SET_TASK_ERROR);
    console.log(error);
    yield put(actions.setProgress({message: `Ошибка: ${error.payload.message}`}));
    yield put(showAlert({text: error.payload.message, header: 'Ошибка'}));
    yield cancel(task);
}

export default main;
