import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Button} from "primereact/button";
import React, {useCallback, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import download_txt from "../../../../../helpers/DownloadTxt";
import {showAlert} from "../../../../../store/app/actions";
import {TaskProgress, TaskStatus} from "../../../../../store/parsers/types";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../../vkapi/objects/UserObject";
import SaveButton from "../../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../../ui/info/AppProgressBar";
import ParsingName from "../../../../ui/inputs/ParsingName";
import BasicFileUpload from "../../../../ui/upload/BasicFileUpload";

export interface IdsConverterInputProps {
    type: "user"|"group",
    source: string[],
    label: string,
    uploadLabel: string,
    status: TaskStatus,
    progress: TaskProgress,
    uploadAction: ActionCreatorWithPayload<string[]>,
    startAction: ActionCreatorWithoutPayload,
    result: UserObject[]|GroupObject[],
}

const IdsConverterInput = function (props: IdsConverterInputProps) {

    const downloadRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const dispatch = useDispatch();
    const [parsingName, setParsingName] = useState<string>('');
    const [downloadFilename, setDownloadFilename] = useState<string>('result.txt');

    const {startAction, uploadAction} = props;
    const onUpload = useCallback((lines: string[]) => {
        dispatch(showAlert({header: 'Файл загружен', text: `Количество строк: ${lines.length}`}));
        dispatch(uploadAction(lines));
    }, [dispatch, uploadAction]);

    const onStartClick = useCallback(() => dispatch(startAction()), [dispatch, startAction]);

    function isUserObject(item: UserObject[]|GroupObject[]): item is UserObject[] {
        return props.type === "user";
    }

    const onSaveIds = () => {
        const filename: string = parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'ids.txt';
        if (isUserObject(props.result)) {
            saveNumbers(props.result.map(item => item.id), filename);
            return;
        }
        saveNumbers(props.result.map(item => item.id), filename);
    };

    const onSaveScreenNames = () => {
        const filename: string = parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'screen_names.txt';
        if (isUserObject(props.result)) {
            saveStrings(props.result.map(item => item.screen_name || `id${item.id.toString()}`).filter(item => item !== ''), filename);
            return;
        }
        saveStrings(props.result.map(item => item.screen_name || `club${item.id.toString()}`).filter(item => item !== ''), filename);
    };

    const onSaveLinks = () => {
        const filename: string = parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'links.txt';
        if (isUserObject(props.result)) {
            saveStrings(props.result.map(item => `https://vk.com/id${item.id}`), filename);
            return;
        }
        saveStrings(props.result.map(item => {
            if (item.type) {
                if (item.type === "group") {
                    return `https://vk.com/club${item.id}`;
                }
                if (item.type === "page") {
                    return `https://vk.com/public${item.id}`;
                }
                if (item.type === "event") {
                    return `https://vk.com/event${item.id}`;
                }
            }
            return `https://vk.com/club${item.id}`;
        }), filename);
    };

    function saveNumbers(items: number[], filename: string) {
        setDownloadFilename(filename !== '' ? filename : 'result.txt');
        download_txt<number[]>({
            data: items,
            ref: downloadRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: number[]) => data.map(item => item.toString()),
        });
    }

    function saveStrings(items: string[], filename: string) {
        setDownloadFilename(filename !== '' ? filename : 'result.txt');
        download_txt<string[]>({
            data: items,
            ref: downloadRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: string[]) => data,
        });
    }

    return (
        <div>
            <ParsingSettingsContainer showOverlay={props.status === TaskStatus.RUNNING}>
                <InputContainer label={props.label}>
                    <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>
                    <ButtonsContainer>
                        <BasicFileUpload chooseLabel={props.uploadLabel} onUpload={onUpload} />
                        {props.source.length > 0 &&
                            <Button label="Запустить" className="p-field" onClick={onStartClick} />
                        }
                        {props.result.length > 0 &&
                            <>
                                <SaveButton label="Сохранить цифровой id" onClick={onSaveIds} />
                                <SaveButton label="Сохранить буквенный id" onClick={onSaveScreenNames} />
                                <SaveButton label="Сохранить ссылки" onClick={onSaveLinks} />
                            </>
                        }
                        <a download={downloadFilename} ref={downloadRef} href={downloadUrl} style={{display: 'none'}}>{downloadFilename}</a>
                    </ButtonsContainer>
                </InputContainer>
                <p className="tw-text-sm">Загружено: {props.source.length}</p>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={props.progress.current} total={props.progress.total}
                            message={props.progress.message}/>
        </div>
    )
};

export default IdsConverterInput;
