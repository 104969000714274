import {Either, isLeft} from "fp-ts/Either";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import {incrementMap} from "../../../../helpers/IncrementMap";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {getUserAge, UserObject} from "../../../../vkapi/objects/UserObject";
import {convert_urls_to_id} from "../../../../vkapi/tasks/ConverUrlToId";
import {GetGroupError} from "../../../../vkapi/tasks/GetGroup";
import GetGroupByUrl from "../../../../vkapi/tasks/GetGroupByUrl";
import GetUsers from "../../../../vkapi/tasks/GetUsers";
import GroupsGetMembers from "../../../../vkapi/tasks/GroupsGetMembers";
import {EMPTY_ID_FILE, EMPTY_URL_GROUP} from "../../validation-errors";
import * as actions from "./actions";
import {DataSourceType, Gender, RootState, TaskStatus} from "../../types";
import {showAlert, showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {BaseAnalysisResult, BaseAnalysisState} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: BaseAnalysisState = yield select((state: RootState) => state.baseAnalysis);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const result: BaseAnalysisResult = {
        genders: [],
        ages: [],
        cities: [],
        countries: [],
    };

    const genders: Map<Gender, number> = new Map<Gender, number>();
    const ages: Map<number, number> = new Map<number, number>();
    const countries: Map<string, number> = new Map<string, number>();
    const cities: Map<string, number> = new Map<string, number>();

    let total_count: number = 0;

    const users_handler = (user: UserObject) => {

        if (user.deactivated) {
            //return;
        }

        const sex: Gender = user.sex || 0;
        incrementMap<Gender>(genders, sex);

        const age: number|null = getUserAge(user);
        incrementMap<number>(ages, age || 0);

        const country: string = user.country?.title || 'Не указано';
        incrementMap<string>(countries, country);

        const city: string = user.city?.title || 'Не указано';
        incrementMap<string>(cities, city);
    };

    if (state.settings.data_source_file) {
        const user_ids: string[] = convert_urls_to_id(state.settings.data_source_file.split("\n"));
        const users: Map<number, UserObject> = yield GetUsers({
            user_ids: user_ids,
            fields: "sex,bdate,city,country,last_seen",
            access_token: access_token,
            progress_action: actions.setProgress,
            add_api_errors_action: actions.addApiError,
        });
        users.forEach(users_handler);
        total_count = users.size;
    }

    if (state.settings.data_source_url) {
        const get_group_result: Either<GetGroupError, GroupObject> = yield GetGroupByUrl({
            url: state.settings.data_source_url,
            access_token: access_token,
            fields: "members_count",
        });
        if (isLeft(get_group_result)) {
            throw new Error(get_group_result.left.message);
        }
        const group: GroupObject = get_group_result.right;
        if (!group.members_count || group.is_closed) {
            throw new Error("Не удалось получить подписчиков группы");
        }
        const users: UserObject[] = yield GroupsGetMembers<UserObject[]> ({
            group_id: group.id,
            members_count: group.members_count,
            fields: "sex,bdate,city,country,last_seen",
            access_token: access_token,
            progress_action: actions.setProgress,
        });
        users.forEach(users_handler);
        total_count = users.length;
    }

    const getRatio = (count: number): number => {
        if (total_count === 0) {
            return 0;
        }
        return (count / total_count) * 100;
    };

    genders.forEach((count, key) => {
        let label: string = "Не указано";
        if (key === Gender.MALE) {
            label = "Мужской";
        }
        if (key === Gender.FEMALE) {
            label = "Женский";
        }
        result.genders.push({
            label: label,
            count: count,
            ratio: getRatio(count),
        })
    });

    ages.forEach((count, age) => {
        let label: string = age.toString();
        if (label === "0") {
            label = "Не указано";
        }
        result.ages.push({
            label: label,
            count: count,
            ratio: getRatio(count),
        });
    });

    countries.forEach((count, country) => {
        result.countries.push({
            label: country,
            count: count,
            ratio: getRatio(count),
        })
    });

    cities.forEach((count, city) => {
        result.cities.push({
            label: city,
            count: count,
            ratio: getRatio(count),
        })
    });


    let k: keyof typeof result;
    for (k in result) {
        result[k] = result[k]
            .sort((a, b) => b.count - a.count)
            .slice(0, 10);
    }

    yield put(actions.setResult(result));
    yield put(actions.setHasResult(true));
    yield put(actions.setProgress({message: `Завершено`, total: 1, current: 1}));
    yield put(showAlertParsingCompleted(`Готово.`));
    yield put(actions.setCompleted());
}

function* validate(state: BaseAnalysisState) {
    if (state.settings.data_source_type === DataSourceType.URL) {
        if (state.settings.data_source_url.trim() === '') {
            yield put(showAlert({text: EMPTY_URL_GROUP, header: 'Ошибка'}));
            yield put(actions.stop());
            return;
        }
    }
    if (state.settings.data_source_type === DataSourceType.FILE) {
        if (state.settings.data_source_file.trim() === '') {
            yield put(showAlert({text: EMPTY_ID_FILE, header: 'Ошибка'}));
            yield put(actions.stop());
            return;
        }
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
