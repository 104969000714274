import {UserFriendsCount} from "../saga";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {TaskProgress} from "../../../types";
import {VkApiError} from "../../../../../vkapi/VkApiError";
import {UserFriends} from "../../../../../vkapi/objects/UserFriends";
import GetFriends from "../../../../../vkapi/tasks/GetFriends";
import _chunk from "lodash/chunk";
import ProgressGenerator from "../../../../helpers/ProgressGenerator";

export interface GetFriendsUserCountParams {
    user_ids: number[],
    counts: Map<number, UserFriendsCount>,
    filter_func: (user_id: number) => boolean,
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>,
}

const GetFriendsUserCounts = function* (params: GetFriendsUserCountParams): Generator<any, Map<number, UserFriendsCount>, any> {
    const result = params.counts;
    const user_chunks = _chunk(params.user_ids, 150);

    const progress = ProgressGenerator({text: 'Получаем друзей', total: user_chunks.length, action: params.progress_action});

    for (let chunk of user_chunks) {

        yield progress.next().value;

        const friends: Map<number, UserFriends> = yield GetFriends({
            user_ids: chunk,
            access_token: params.access_token,
        });

        for (let data of friends.values()) {
            data.friend_ids.forEach((friend_id: number) => {
                const item: UserFriendsCount|undefined = result.get(friend_id);
                if (!params.filter_func(friend_id)) {
                    return;
                }
                if (result.size >= 1500000) {
                    return;
                }
                if (item) {
                    item.friends++;
                    item.total++;
                    result.set(friend_id, item);
                }
                else {
                    result.set(friend_id, {user_id: friend_id, friends: 1, followers: 0, total: 1});
                }
            });
        }
    }
    return result;
};

export default GetFriendsUserCounts;
