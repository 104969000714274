import {ProcessMethodsParams} from "../types";
import _chunk from "lodash/chunk";
import {put} from "redux-saga/effects";
import {SendMethodsParams} from "./SendMethods";
import {VkApiError} from "../VkApiError";
import RateLimitHandler from "../handlers/RateLimitHandler";
import SendMethodsWithAutoRetry from "./SendMethodsWithAutoRetry";
import ProgressGenerator from "../../store/helpers/ProgressGenerator";

export interface SendMethodsDefaultParams<TResponse> {
    methods_params: ProcessMethodsParams,
    response_handler: (response: TResponse) => void,
    chunk_size?: number,
    progress_text?: string,
    error_handler?: (error: VkApiError) => void,
}

const SendMethodsDefault = function* <TResponse>(params: SendMethodsDefaultParams<TResponse>): Generator<any, any, any> {

    const chunks = _chunk(params.methods_params.methods, params.chunk_size ?? 6);
    const progress = ProgressGenerator({text: params.progress_text ?? '', total: chunks.length, action: params.methods_params.progress_action});

    for (const chunk of chunks) {
        yield progress.next().value;

        const api_errors: VkApiError[] = [];

        let send_methods_params: SendMethodsParams<TResponse> = {
            methods: chunk,
            access_token: params.methods_params.access_token,
            onResponse: (response => params.response_handler(response)),
            onError: (error: VkApiError) => {
                if (params.error_handler) {
                    params.error_handler(error);
                    return;
                }
                RateLimitHandler(error);
                api_errors.push(error);
            },
        };

        yield SendMethodsWithAutoRetry(send_methods_params);

        if (params.methods_params.add_api_errors_action) {
            for (const api_error of api_errors) {
                yield put(params.methods_params.add_api_errors_action(api_error))
            }
        }
    }
};

export default SendMethodsDefault;
