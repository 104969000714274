import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {csv_generator} from "../../../../helpers/csv_generator";
import download_url from "../../../../helpers/download_url";
import download_txt from "../../../../helpers/DownloadTxt";
import strip_punctuation from "../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../store/app/actions";

import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import * as actions from "../../../../store/parsers/user-profiles/status-locator/actions";
import {
    StatusLocatorResult,
    StatusLocatorSearchType
} from "../../../../store/parsers/user-profiles/status-locator/types";
import useSaveWithCsv from "../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicCheckbox from "../../../ui/inputs/BasicCheckbox";
import BasicRadioButton from "../../../ui/inputs/BasicRadioButton";
import BasicTextInput from "../../../ui/inputs/BasicTextInput";
import ParsingName from "../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";

const StatusLocator = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.statusLocator);
    const progress: TaskProgress = useSelector((state: RootState) => state.statusLocator.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.statusLocator.sub_progress);
    const result: StatusLocatorResult[] = useSelector((state: RootState) => state.statusLocator.result);

    const [parsingName, setParsingName] = useState<string>('');

    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);

    const [selection, setSelection] = useState<StatusLocatorResult[]>([]);
    useEffect(() => {
        setSelection(result);
    }, [result]);

    const save = () => {
        download_txt<StatusLocatorResult[]>({
            data: selection,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: StatusLocatorResult[]) => data.map(item => item.user.id.toString()),
        });
    };

    const saveCSV = () => {

        if (result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = [
            '"Id"',
            '"Пользователь"',
            '"Ссылка"',
            '"Статус"',
        ];

        const generator = (item: StatusLocatorResult) => {
            const line: string[] = [
                item.user.id.toString(),
                '"' + strip_punctuation(item.user.first_name + ' ' + item.user.last_name) + '"',
                `"https://vk.com/id${item.user.id}"`,
                '"' + strip_punctuation(item.status) + '"',
            ];
            return line.join(';');
        };

        const csv: string|null = csv_generator(result, headers, generator);
        if (!csv) {
            return;
        }

        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };


    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Найдите людей с определенными словами в статусе."
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/poisk_po_statusam"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Как искать">
                    <BasicRadioButton name="statusLocatorSearchType" label="По списку групп"
                                      value={StatusLocatorSearchType.BY_GROUPS}
                                      onChange={(e) => dispatch(actions.setSearchType(e.value))}
                                      checked={state.settings.search_type === StatusLocatorSearchType.BY_GROUPS}
                    />
                    <BasicRadioButton name="statusLocatorSearchType" label="По пользователям из файла"
                                      value={StatusLocatorSearchType.BY_USERS}
                                      onChange={(e) => dispatch(actions.setSearchType(e.value))}
                                      checked={state.settings.search_type === StatusLocatorSearchType.BY_USERS}
                    />
                </InputContainer>

                {state.settings.search_type === StatusLocatorSearchType.BY_GROUPS &&
                    <TextAreaWithButton label={'Ссылки на группы (по одной на строке)'}
                                        placeholder={"https://vk.com/cerebro_vk"}
                                        value={state.settings.source_groups}
                                        onChange={(e) => dispatch(actions.setSourceGroups(e))}
                    />
                }
                {state.settings.search_type === StatusLocatorSearchType.BY_USERS &&
                    <TextAreaWithButton label={'Ссылки на профили (по одной на строке)'}
                                        placeholder={"https://vk.com/id23818"}
                                        value={state.settings.source_users}
                                        onChange={(e) => dispatch(actions.setSourceUsers(e))}
                    />
                }

                <InputContainer label="Слова в статусе (через пробел)" notice={'Оставьте поле пустым, если хотите найти все статусы'}>
                    <BasicTextInput
                        value={state.settings.words_plus}
                        onChange={e => dispatch(actions.setWordsPlus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которые должны быть в статусе'}
                    />
                </InputContainer>

                <BasicCheckbox
                    label={'в стутусе должны быть все слова'}
                    checked={state.settings.strict_mode}
                    onChange={e => dispatch(actions.setStrictMode(e.checked))}
                />

                <InputContainer label="Минус слова (через пробел)">
                    <BasicTextInput
                        value={state.settings.words_minus}
                        onChange={e => dispatch(actions.setWordsMinus(e.currentTarget.value))}
                        placeholder={'введите через пробел слова, которых не должно быть в статусе'}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить id" onClick={() => save()} />
                        <SaveButton label="Сохранить в CSV" onClick={() => saveCSV()} />
                    </>
                    }
                    {anchors}
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>

            {state.status !== TaskStatus.RUNNING && result.length > 0 &&
                <div className="tw-mt-4">
                    <DataTable value={state.result}
                               autoLayout={true} rowHover={true} paginator={true} rows={100}
                               selection={selection}
                               onSelectionChange={(e) => setSelection(e.value)}>
                        <Column selectionMode="multiple" style={{width:'20px'}}/>
                        <Column header="Пользователь" field="user.last_name" body={(item: StatusLocatorResult, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/id${item.user.id}`} target="_blank" rel="noopener noreferrer">{item.user.first_name} {item.user.last_name}</a></div>} sortable />
                        <Column header="Статус"  field="status" sortable/>
                    </DataTable>
                </div>
            }

        </AppPanel>
    );
};

export default StatusLocator;
