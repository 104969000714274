import {IntersectionsResult} from "../types";

export default function getBlankResult(): IntersectionsResult {
    return {
        subtract: [],
        intersect: [],
        unique: [],
        union: [],
    }
}
