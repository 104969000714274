import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {OwnerPosts, Post} from "../../../../../vkapi/objects/Post";
import GetActivitiesIds, {PostActivitiesIds} from "../../../../../vkapi/tasks/GetActivitiesIds";
import GetPosts from "../../../../../vkapi/tasks/GetPosts";
import GroupsGetMembers from "../../../../../vkapi/tasks/GroupsGetMembers";
import {VkApiError} from "../../../../../vkapi/VkApiError";
import {date_in_range, is_post_before_start_date} from "../../../../helpers/DateChecker";
import {ActivityType, TaskProgress, WithDefaultActions} from "../../../types";
import {ActivityFilterResult} from "../types";

export interface GetActivityStatsSlowParams extends WithDefaultActions {
    group: GroupObject,
    only_selected_users: boolean,
    selected_users: Set<number>,
    only_members: boolean,
    start_date: Date|null,
    end_date: Date|null,
    access_token: string,
}

const GetActivityStatsSlow = function* (params: GetActivityStatsSlowParams): Generator<any, ActivityFilterResult, any> {
    let members: Set<number> = new Set<number>();
    const group: GroupObject = params.group;
    const access_token: string = params.access_token;

    if (params.only_members) {
        members = yield getGroupMembers(group, access_token, params.progress_action, params.add_api_errors_action);
    }
    const activities: Map<number, PostActivitiesIds> = yield getActivities(
        group, params.start_date, params.end_date, access_token, params.progress_action, params.add_api_errors_action
    );

    const result: ActivityFilterResult = {
        group: group,
        posts_count: activities.size,
        likes: 0,
        reposts: 0,
        comments: 0,
        activity: 0,
        engagement_rate: 0,
    };

    const usersFilter = (user_id: number): boolean => {
        if (params.only_selected_users && !params.selected_users.has(user_id)) {
            return false;
        }
        if (params.only_members && !members.has(user_id)) {
            return false;
        }
        return true;
    };

    activities.forEach((value: PostActivitiesIds) => {
        result.likes += value.likes.filter(usersFilter).length;
        result.reposts += value.reposts.filter(usersFilter).length;
        result.comments += value.comments.filter(usersFilter).length;
    });
    const total_activities_count: number = result.likes + result.reposts + result.comments;
    if (activities.size > 0) {
        result.activity = total_activities_count / activities.size;
    }
    if (group.members_count) {
        result.engagement_rate = (total_activities_count / group.members_count) * 100;
    }
    return result;
};

function* getGroupMembers(
    group: GroupObject,
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
): Generator<any, Set<number>, any> {
    if (!group.members_count) {
        throw new Error("Не удалось получить подписчиков группы: " + group.id);
    }
    const members_ids: number[] = yield GroupsGetMembers<number[]> ({
        group_id: group.id,
        members_count: group.members_count,
        access_token: access_token,
        progress_action: progress_action,
        add_api_errors_action: add_api_errors_action,
    });
    return new Set<number>(members_ids);
}

function* getActivities(
    group: GroupObject,
    start_date: Date|null,
    end_date: Date|null,
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
): Generator<any, Map<number, PostActivitiesIds>, any> {

    const posts: OwnerPosts = yield getPosts(group, access_token, start_date, end_date, progress_action, add_api_errors_action);
    const activities: Map<number, PostActivitiesIds> = yield GetActivitiesIds({
        posts: posts.posts,
        comments_preview_length: 1,
        activity_types: new Set<ActivityType>([ActivityType.LIKES, ActivityType.REPOSTS, ActivityType.COMMENTS]),
        access_token: access_token,
        progress_action: progress_action,
        add_api_errors_action: add_api_errors_action,
    });
    return activities;
}

function* getPosts(
    group: GroupObject,
    access_token: string,
    start_date: Date|null,
    end_date: Date|null,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>

): Generator<any, OwnerPosts, any> {
    const is_date_in_range = date_in_range(start_date, end_date);
    const is_posts_start_date_reached = is_post_before_start_date(start_date);

    const posts: OwnerPosts = yield GetPosts({
        owner_id: group.id * -1,
        is_skip: (post: Post) => !is_date_in_range(post.date),
        is_stop: (post: Post) => is_posts_start_date_reached(post),
        access_token: access_token,
        progress_action: progress_action,
        add_api_errors_action: add_api_errors_action,
    });
    return posts;
}

export default GetActivityStatsSlow;
