import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {VkApiError} from "../../../../vkapi/VkApiError";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import * as types from "./types";
import {TopicsSearchResult, TopicsSearchType} from "./types";
import * as global_types from "../../types";
import * as actions from './actions';

const moment = require('moment');

const initialState: types.TopicsParserState = {
    settings: {
        search_type: TopicsSearchType.BY_LINKS,
        links_groups: '',
        links_topics: '',
        start_date: moment().subtract(14, "days").startOf("day").toDate(),
        end_date: null,
        words_minus: '',
        words_plus: '',
        is_skip_messages_with_urls: false,
        min_messages: 0
    },
    groups: [],
    ...DefaultStateProps<TopicsSearchResult>({
        comments: [],
        topics: [],
        user_ids: []
    })
};

const TopicsParserReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSearchType, (state: types.TopicsParserState, action: PayloadAction<TopicsSearchType>) => {
        state.settings.search_type = action.payload;
    });
    builder.addCase(actions.setLinksGroups, (state: types.TopicsParserState, action: PayloadAction<string>) => {
        state.settings.links_groups = action.payload;
    });
    builder.addCase(actions.setLinksTopics, (state: types.TopicsParserState, action: PayloadAction<string>) => {
        state.settings.links_topics = action.payload;
    });
    builder.addCase(actions.setWordsPlus, (state: types.TopicsParserState, action: PayloadAction<string>) => {
        state.settings.words_plus = action.payload;
    });
    builder.addCase(actions.setWordsMinus, (state: types.TopicsParserState, action: PayloadAction<string>) => {
        state.settings.words_minus = action.payload;
    });
    builder.addCase(actions.setStartDate, (state: types.TopicsParserState, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state: types.TopicsParserState, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });
    builder.addCase(actions.setIsSkipMessagesWithUrls, (state: types.TopicsParserState, action: PayloadAction<boolean>) => {
        state.settings.is_skip_messages_with_urls = action.payload;
    });
    builder.addCase(actions.setMinMessages, (state: types.TopicsParserState, action: PayloadAction<number>) => {
        state.settings.min_messages = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setGroups, (state: types.TopicsParserState, action: PayloadAction<GroupObject[]>) => {
        state.groups = action.payload;
    });

    builder.addCase(actions.setTaskStatus, (state: types.TopicsParserState, action: PayloadAction<global_types.TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setProgress, (state: types.TopicsParserState, action: PayloadAction<global_types.TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: types.TopicsParserState, action: PayloadAction<TopicsSearchResult>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: types.TopicsParserState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state: types.TopicsParserState) => {
        state.api_errors = [];
    });
});

export default TopicsParserReducer;
