import {Button} from "primereact/button";
import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator} from "../../../../../helpers/csv_generator";
import download_url from "../../../../../helpers/download_url";
import strip_punctuation from "../../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../../store/app/actions";
import {RootState, TaskProgress, TaskStatus} from "../../../../../store/parsers/types";
import {groups as actions} from "../../../../../store/parsers/others/base-viewer/actions"
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import SaveButton from "../../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../../ui/containers/ButtonsContainer";
import ParsingSettingsContainer from "../../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../../ui/info/AppProgressBar";
import BasicCheckbox from "../../../../ui/inputs/BasicCheckbox";
import ParsingName from "../../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../../ui/inputs/TextAreaWithButton";

const BaseViewerGroups = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.baseViewer.groups);
    const progress: TaskProgress = useSelector((state: RootState) => state.baseViewer.groups.progress);

    const [parsingName, setParsingName] = useState<string>('');
    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const saveCSV = () => {

        if (state.result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = [
            '"id"',
            '"Ссылка"',
            '"Название"',
        ];

        const generator = (item: GroupObject) => {
            const line: string[] = [
                item.id.toString(),
                `"https://vk.com/${item.screen_name}"`,
                '"' + strip_punctuation(item.name || '') + '"',
            ];
            return line.join(';');
        };

        const csv: string|null = csv_generator(state.result, headers, generator);
        if (!csv) {
            return;
        }

        setDownloadUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveRef && saveRef.current) {
                saveRef.current.click()
            }
        }, 1000);
    };

    return (
        <div>
            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>
                <TextAreaWithButton label="Список групп (по одному на строке)"
                                    value={state.settings.source}
                                    onChange={(value) => dispatch(actions.setSource(value))}
                />
                <BasicCheckbox label="Показывать аватар"
                               checked={state.settings.show_avatar}
                               onChange={(e) => dispatch(actions.setShowAvatar(e.checked))}
                />
                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)} />
                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {state.result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить CSV" onClick={() => saveCSV()} />
                    </>
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.csv' : 'result.csv'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Скачать CSV</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            {state.status !== TaskStatus.RUNNING && state.result.length > 0 &&
            <div className="tw-mt-4 tw-flex tw-flex-wrap">
                {state.result.map((item) => {
                    return (
                        <div className="tw-w-full md:tw-w-1/2 tw-flex tw-items-center tw-space-x-4 tw-my-2">
                            {state.settings.show_avatar &&
                            <div>
                                <img src={item.photo_50} alt={item.name} />
                            </div>
                            }
                            <div>
                                <div><a href={'https://vk.com/club' + item.id} target={'_blank'} rel="noopener noreferrer">{item.name}</a></div>
                            </div>
                        </div>
                    );
                })}
            </div>
            }

        </div>
    )
};

export default BaseViewerGroups;
