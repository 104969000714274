import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Dropdown} from "primereact/dropdown";
import React from "react";
import {useDispatch} from "react-redux";
import {DropdownValue, Gender} from "../../store/parsers/types";
import InputContainer from "../ui/containers/InputContainer";

function useGender(selected_gender: Gender, set_gender: ActionCreatorWithPayload<Gender>) {
    const dispatch = useDispatch();

    const genderOptions: DropdownValue<Gender>[] = [
        {label: "любой", value: Gender.ANY},
        {label: "мужской", value: Gender.MALE},
        {label: "женский", value: Gender.FEMALE},
    ];

    return (
        <InputContainer label="Пол">
            <Dropdown options={genderOptions} value={selected_gender}
                      onChange={(e) => dispatch(set_gender(e.value))}
                      className="p-field tw-w-full sm:tw-flex-1"
            />
        </InputContainer>
    );
}

export default useGender;
