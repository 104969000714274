import _chunk from "lodash/chunk";
import {WithDefaultActions} from "../../store/parsers/types";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {IVkMethod} from "../IVkMethod";
import {UtilsGetLinkStatsParams} from "../methods/UtilsGetLinkStatsParams";
import {ProcessMethodsParams} from "../types";
import * as methods from "../methods/VkMethods";

export interface GetShortLinksStatsParams extends WithDefaultActions {
    codes: string[],
    access_token: string,
}

export interface LinkStats {
    key: string,
    views: number,
}

const GetShortLinksStats = function* (params: GetShortLinksStatsParams): Generator<any, Map<string, LinkStats>, any> {
    const executes:Array<IVkMethod> = prepare(params.codes);
    const executes_result: Map<string, LinkStats> = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(codes: string[]): IVkMethod[] {
    const max_requests_per_execute: number = 20;
    const requests: string[] = codes.map(key => {
        const params: UtilsGetLinkStatsParams = {
            key: key,
            interval: "day",
            intervals_count: 100,
        }
        const method: IVkMethod = methods.utilsGetLinkStats(params);
        return `API.${method.method_name}(${JSON.stringify(method.params)})`
    });
    const requests_chunks: string[][] = _chunk(requests, max_requests_per_execute);
    return requests_chunks.map((requests_chunk: string[]) => {
        const code:string = `return [${requests_chunk.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, Map<string, LinkStats>, any> {
    interface Stats {
        timestamp: number,
        views: number,
    }
    interface IResponse {
        key: string,
        stats: Array<Stats>|false,
    }
    const result: Map<string, LinkStats> = new Map<string, LinkStats>();

    const handler = (response: IResponse[]) => {
        response.forEach((response_item: IResponse) => {
            if (!response_item.stats) {
                return;
            }
            const views: number = response_item.stats.reduce((val, item) => val + item.views, 0)
            result.set(response_item.key, {key: response_item.key, views: views})
        });
    };

    yield SendMethodsDefault<IResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем статистику ссылок',
        chunk_size: 6
    });

    return result;
};

export default GetShortLinksStats;
