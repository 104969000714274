import {createSelector} from "reselect";
import tokenize from "../../../../../helpers/tokenizer";
import {RootState} from "../../../types";
import {TargetAudienceGroup} from "../types";
import {isTextContainsAnyWord} from "../../../../../helpers/words-checker";
import {EventType, ShowType} from "../types";

const select_items = (state: RootState) => state.targetAudienceLocator.result;
const select_members_min = (state: RootState) => state.targetAudienceLocator.settings.selector_filter_members_min;
const select_members_max = (state: RootState) => state.targetAudienceLocator.settings.selector_filter_members_max;

const select_words_plus = (state: RootState) => state.targetAudienceLocator.settings.selector_filter_words_plus;
const select_words_minus = (state: RootState) => state.targetAudienceLocator.settings.selector_filter_words_minus;

const select_community_type_public = (state: RootState) => state.targetAudienceLocator.settings.selector_filter_community_type_public;
const select_community_type_club = (state: RootState) => state.targetAudienceLocator.settings.selector_filter_community_type_club;
const select_community_type_event = (state: RootState) => state.targetAudienceLocator.settings.selector_filter_community_type_event;

const select_event_type = (state: RootState) => state.targetAudienceLocator.settings.selector_filter_event_type;

const select_show_type = (state: RootState) => state.targetAudienceLocator.settings.selector_filter_show_type;

const selectByMembers = createSelector(
    [select_items, select_members_min, select_members_max],
    (items, min, max) => {
        if (min === 0 && max === 0) {
            return items;
        }
        return items.filter((item) => {
            if (!item.group.members_count) {
                return false;
            }
            return (item.group.members_count >= min || min === 0) && (item.group.members_count <= max || max === 0)
        })
    }
);

const selectByWords = createSelector(
    [selectByMembers, select_words_plus, select_words_minus],
    (items: TargetAudienceGroup[], words_plus: string, words_minus: string) => {

        const tokens_plus: string[] = tokenize(words_plus.replace(",", " "));
        const tokens_minus: string[] = tokenize(words_minus.replace(",", " "));
        return items.filter((item: TargetAudienceGroup) => {
            if (!item.group.name) {
                return false;
            }
            if (tokens_minus.length > 0 && isTextContainsAnyWord(item.group.name, tokens_minus)) {
                return false;
            }
            if (tokens_plus.length > 0 && !isTextContainsAnyWord(item.group.name, tokens_plus)) {
                return false;
            }
            return true;
        });
    }
);

const selectByCommunityType = createSelector(
    [selectByWords, select_community_type_public, select_community_type_club, select_community_type_event],
    (items: TargetAudienceGroup[], public_selected, club_selected, event_selected) => {
        if (public_selected && club_selected && event_selected) {
            return items;
        }
        return items.filter((item: TargetAudienceGroup) => {
            if (!item.group.type) {
                return false;
            }
            const type = item.group.type;
            if (!public_selected && type === "page") {
                return false;
            }
            if (!club_selected && type === "group") {
                return false;
            }
            if (!event_selected && type === "event") {
                return false;
            }
            return true;
        });
    }
);

const selectByEventType = createSelector(
    [selectByCommunityType, select_community_type_event, select_event_type],
    (items: TargetAudienceGroup[], is_events_selected, event_type) => {
        if (!is_events_selected) {
            return items;
        }
        if (event_type === EventType.ALL) {
            return items;
        }
        return items.filter((item: TargetAudienceGroup) => {
            if (!item.group.type) {
                return false;
            }
            const type = item.group.type;
            if (type !== "event") {
                return true;
            }
            if (!item.group.start_date) {
                return false;
            }
            const start_date = item.group.start_date * 1000;
            if (event_type !== EventType.FUTURE && start_date > Date.now()) {
                return false;
            }
            if (event_type !== EventType.PAST && start_date < Date.now()) {
                return false;
            }
            return true;
        });
    }
);

const selectByOpenOrClosed = createSelector(
    [selectByEventType, select_show_type],
    (items: TargetAudienceGroup[], show_type: ShowType) => {
        if (show_type === ShowType.ALL) {
            return items;
        }
        return items.filter((item: TargetAudienceGroup) => {
            if (item.group.type !== "group") {
                return true;
            }
            if (item.group.is_closed === undefined) {
                return false;
            }
            if (show_type === ShowType.CLOSED && item.group.is_closed === 0) {
                return false;
            }
            if (show_type === ShowType.OPEN && item.group.is_closed > 0) {
                return false;
            }
            return true;
        });
    }
);


const selectFiltered = createSelector(
    [selectByOpenOrClosed],
    (items: TargetAudienceGroup[]) => items
);

export default selectFiltered;
