import {createAction} from "@reduxjs/toolkit";
import * as types from "./action-types"

import * as audience from "./actions/actions-audience";
import * as lastPost from "./actions/actions-last-post";
import * as blocked from "./actions/actions-blocked";
import * as activity from "./actions/actions-activity";
import {CommunitiesFilterSearchType} from "./types";

export {audience, lastPost, blocked, activity};

function withPayloadType<T>() {
    return (t: T) => ({payload: t})
}

export const setSearchType = createAction(types.SET_SEARCH_TYPE, withPayloadType<CommunitiesFilterSearchType>());
