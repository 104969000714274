import {spawn} from 'redux-saga/effects'

import AudienceSaga from "./sagas/saga-audience";
import LastPostSaga from "./sagas/saga-last-post";
import BlockedSaga from "./sagas/saga-blocked";
import ActivitySaga from "./sagas/saga-activity";

const main = function* () {
    yield spawn(AudienceSaga);
    yield spawn(LastPostSaga);
    yield spawn(BlockedSaga);
    yield spawn(ActivitySaga);
};

export default main;
