import {DefaultProps, TaskStatus} from "../parsers/types";

export default function DefaultStateProps<TResult>(result: TResult): DefaultProps<TResult> {
    return {
        task_title: '',
        status: TaskStatus.READY,
        progress: {message: ''},
        result: result,
        api_errors: [],
    }
}
