import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import {ActivityType, DefaultProps, TaskProgress} from "../../types";
import {InProfileActivities} from "./types";
import * as types from "./types";
import * as actions from './actions';
import DefaultStateProps from "../../../helpers/DefaultStateProps";

const moment = require('moment');

const initialState: types.ActivitiesInProfilesState = {
    settings: {
        data_source: '',
        start_date: moment().subtract(14, "days").startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
        activities_min: 0,
        activities_max: 0,
        users_min: 0,
        users_max: 0,
        is_posts_every_user: false,
        posts_min: 0,
        posts_max: 0,
        activity_types: [
            ActivityType.LIKES,
            ActivityType.REPOSTS,
            ActivityType.COMMENTS,
            ActivityType.COMMENT_LIKES
        ],
    },
    sub_progress: {message: ''},
    ...DefaultStateProps<InProfileActivities[]>([])
};

const ActivitiesInProfilesReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setDataSource, (state, action: PayloadAction<string>) => {
        state.settings.data_source = action.payload;
    });
    builder.addCase(actions.setStartDate, (state, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });
    builder.addCase(actions.setActivitiesMin, (state, action: PayloadAction<number>) => {
        state.settings.activities_min = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setActivitiesMax, (state, action: PayloadAction<number>) => {
        state.settings.activities_max = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setUsersMin, (state, action: PayloadAction<number>) => {
        state.settings.users_min = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setUsersMax, (state, action: PayloadAction<number>) => {
        state.settings.users_max = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setPostsEveryUser, (state, action: PayloadAction<boolean>) => {
        state.settings.is_posts_every_user = action.payload;
    });
    builder.addCase(actions.setPostsMin, (state, action: PayloadAction<number>) => {
        state.settings.posts_min = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setPostsMax, (state, action: PayloadAction<number>) => {
        state.settings.posts_max = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.addActivityType, (state, action: PayloadAction<ActivityType>) => {
        if (!state.settings.activity_types.includes(action.payload)) {
            state.settings.activity_types.push(action.payload);
        }
    });
    builder.addCase(actions.removeActivityType, (state, action: PayloadAction<ActivityType>) => {
        state.settings.activity_types = state.settings.activity_types.filter(item => item !== action.payload);
    });
    builder.addCase(actions.setSubProgress, (state, action: PayloadAction<TaskProgress>) => {
        state.sub_progress = action.payload;
    });

    AddDefaultCasesToReducer<InProfileActivities[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<InProfileActivities[]>>);
});

export default ActivitiesInProfilesReducer;
