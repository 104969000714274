import {Dropdown} from "primereact/dropdown";
import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {Button} from "primereact/button";
import download_txt from "../../../../helpers/DownloadTxt";
import {showAlert} from "../../../../store/app/actions";

import {DropdownValue, RootState, TaskStatus} from "../../../../store/parsers/types";
import * as actions from "../../../../store/parsers/filter-base/ab-testing/actions";
import useSave from "../../../hooks/UseSave";
import BasicCheckbox from "../../../ui/inputs/BasicCheckbox";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicFileUpload from "../../../ui/upload/BasicFileUpload";
import shuffle from "lodash/shuffle"

const AbTesting = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.abTesting);

    const [parsingName, setParsingName] = useState<string>('');
    const {saveRef, anchors, setDownloadUrl} = useSave(parsingName);

    const parts: DropdownValue<number>[] = [
        {label: "2", value: 2},
        {label: "3", value: 3},
        {label: "4", value: 4},
        {label: "5", value: 5},
        {label: "6", value: 6},
        {label: "7", value: 7},
        {label: "8", value: 8},
        {label: "9", value: 9},
        {label: "10", value: 10},
    ];

    const onUpload = (lines: string[]) => {
        let source: string;
        let alert_text: string;
        if (state.settings.shuffle) {
            const shuffled = shuffle(lines);
            source = shuffled.join('\n');
            alert_text = 'База загружена и перемешана: ' + shuffled.length;
        }
        else {
            source = lines.join('\n');
            alert_text = 'База загружена: ' + lines.length;
        }
        dispatch(actions.setSource(source));
        dispatch(showAlert({text: alert_text, header: 'Загружено'}));
    };

    const save = (i: number) => {
        setParsingName(`part-${i + 1}.txt`);
        download_txt<string[]>({
            data: state.result[i],
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: string[]) => data.map(item => item),
        });
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Вы можете разделить базу на равные части, чтобы проводить на ней сплит тесты"
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/a/btesty"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Загрузка из файла">
                    <BasicFileUpload id="upload" chooseLabel="Загрузить строки из файла"
                                     onUpload={onUpload}
                    />
                </InputContainer>

                <InputContainer label="На сколько частей разделить">
                    <Dropdown options={parts}
                              value={state.settings.parts_count}
                              onChange={(e) => dispatch(actions.setPartsCount(e.value))}
                              className="p-field tw-w-full sm:tw-flex-1"
                    />
                </InputContainer>

                <BasicCheckbox label="Перемешать базу" checked={state.settings.shuffle}
                               onChange={e => dispatch(actions.setShuffle(e.value))}
                />

                <ButtonsContainer>
                    <Button label="Разделить" onClick={() => dispatch(actions.start())}  className="p-field" />
                    {anchors}
                </ButtonsContainer>
                {state.result.map((chunk, i) => {
                    return (<SaveButton label={`Cохранить часть: ${i + 1}`} onClick={() => save(i)} />)
                })}
            </ParsingSettingsContainer>

        </AppPanel>
    );
};

export default AbTesting;
