import {Post} from "../../objects/Post";
import {IVkMethod} from "../../IVkMethod";
import _chunk from "lodash/chunk";
import * as methods from "../../methods/VkMethods";
import {WallGetCommentsParams} from "../../methods/WallGetCommentsParams";
import {ProcessMethodsParams} from "../../types";
import SendMethodsDefault from "../../helpers/SendMethodsDefault";
import {Comment} from "../../objects/Comment";
import {WithDefaultActions} from "../../../store/parsers/types";

export interface GetFirstCommentsParams extends WithDefaultActions {
    posts: Post[],
    preview_length: number,
    access_token: string,
}

export interface FirstComments {
    owner_id: number,
    post_id: number,
    comments: Comment[],
    current_level_count: number,
}

const GetFirstComments = function* (params: GetFirstCommentsParams): Generator<any, FirstComments[], any> {
    const executes: Array<IVkMethod> = prepare(params.posts, params.preview_length);
    const executes_result: FirstComments[] = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(posts: Post[], preview_length: number): IVkMethod[] {
    const methods_per_execute: number = 20;
    const count: number = 100;

    const posts_chunks: Post[][] = _chunk(posts, methods_per_execute);
    return posts_chunks.map((chunk: Post[]) => {
        const requests: string[] = chunk.map((post: Post) => {
            const params: WallGetCommentsParams = {
                owner_id: post.owner_id,
                post_id: post.id,
                offset: 0,
                count: count,
                thread_items_count: 10,
                need_likes: 1,
                preview_length: preview_length,
            };
            const method: IVkMethod = methods.wallGetComments(params);
            return `{owner_id: ${post.owner_id}, post_id: ${post.id}, result: API.${method.method_name}(${JSON.stringify(method.params)})}`;
        });
        const code:string = `return [${requests.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, FirstComments[], any> {
    interface TResponse {
        owner_id: number,
        post_id: number,
        result: {
            count: number,
            items: Comment[],
            current_level_count: number,
        }|false
    }
    const result: FirstComments[] = [];

    const handler = (response: TResponse[]) => {
        response.forEach((response_item: TResponse) => {
            let comments: Comment[] = [];
            if (!response_item.result) {
                return;
            }
            response_item.result.items.forEach((comment: Comment) => {
                if (comment.thread && comment.thread.count > 0) {
                    comments = comments.concat(comment.thread.items);
                    comment.thread.items = [];
                }
                comments.push(comment);
            });
            result.push({
                owner_id: response_item.owner_id,
                post_id: response_item.post_id,
                current_level_count: response_item.result.current_level_count,
                comments: comments,
            });
        });
    };

    yield SendMethodsDefault<TResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем комментарии',
        chunk_size: 6,
    });
    return result;
};

export default GetFirstComments;
