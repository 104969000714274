import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {VkApiError} from "../../../../vkapi/VkApiError";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import * as global_types from "../../types";
import * as actions from './actions';
import * as types from "./types";
import {AllActivities, WhatToGet} from "./types";

const moment = require('moment');

const initialState: types.ActivitiesCommunityState = {
    settings: {
        data_source: '',
        what_to_get: WhatToGet.USERS,
        start_date: moment().subtract(14, "days").startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
        activities_min: 0,
        activities_max: 0,
        groups_min: 0,
        groups_max: 0,
        is_posts_every_community: false,
        posts_min: 0,
        posts_max: 0,
        activity_types: [
            global_types.ActivityType.LIKES,
            global_types.ActivityType.REPOSTS,
            global_types.ActivityType.COMMENTS,
            global_types.ActivityType.COMMENT_LIKES
        ],
    },
    sub_progress: {message: ''},
    ...DefaultStateProps<AllActivities[]>([])
};

const ActivitiesCommunityReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setDataSource, (state: types.ActivitiesCommunityState, action: PayloadAction<string>) => {
        state.settings.data_source = action.payload;
    });
    builder.addCase(actions.setWhatToGet, (state: types.ActivitiesCommunityState, action: PayloadAction<WhatToGet>) => {
        state.settings.what_to_get = action.payload;
    });
    builder.addCase(actions.setStartDate, (state: types.ActivitiesCommunityState, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state: types.ActivitiesCommunityState, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });
    builder.addCase(actions.setActivitiesMin, (state: types.ActivitiesCommunityState, action: PayloadAction<number>) => {
        state.settings.activities_min = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setActivitiesMax, (state: types.ActivitiesCommunityState, action: PayloadAction<number>) => {
        state.settings.activities_max = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setGroupsMin, (state: types.ActivitiesCommunityState, action: PayloadAction<number>) => {
        state.settings.groups_min = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setGroupsMax, (state: types.ActivitiesCommunityState, action: PayloadAction<number>) => {
        state.settings.groups_max = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setPostsEveryCommunity, (state: types.ActivitiesCommunityState, action: PayloadAction<boolean>) => {
        state.settings.is_posts_every_community = action.payload;
    });
    builder.addCase(actions.setPostsMin, (state: types.ActivitiesCommunityState, action: PayloadAction<number>) => {
        state.settings.posts_min = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setPostsMax, (state: types.ActivitiesCommunityState, action: PayloadAction<number>) => {
        state.settings.posts_max = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.addActivityType, (state: types.ActivitiesCommunityState, action: PayloadAction<global_types.ActivityType>) => {
        if (!state.settings.activity_types.includes(action.payload)) {
            state.settings.activity_types.push(action.payload);
        }
    });
    builder.addCase(actions.removeActivityType, (state: types.ActivitiesCommunityState, action: PayloadAction<global_types.ActivityType>) => {
        state.settings.activity_types = state.settings.activity_types.filter(item => item !== action.payload);
    });
    builder.addCase(actions.setTaskStatus, (state: types.ActivitiesCommunityState, action: PayloadAction<global_types.TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setProgress, (state: types.ActivitiesCommunityState, action: PayloadAction<global_types.TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setSubProgress, (state: types.ActivitiesCommunityState, action: PayloadAction<global_types.TaskProgress>) => {
        state.sub_progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: types.ActivitiesCommunityState, action: PayloadAction<AllActivities[]>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: types.ActivitiesCommunityState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state: types.ActivitiesCommunityState) => {
        state.api_errors = [];
    });
});

export default ActivitiesCommunityReducer;
