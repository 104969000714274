import React, {useRef, useState} from "react";

function useSave(parsingName: string) {
    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const anchors = (
        <div>
            <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Сохранить id</a>
        </div>
    );

    return {
        saveRef,
        setDownloadUrl,
        anchors,
    }
}

export default useSave;
