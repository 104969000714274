import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {useEffect,  useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {csv_generator} from "../../../../../helpers/csv_generator";
import download_url from "../../../../../helpers/download_url";
import download_txt from "../../../../../helpers/DownloadTxt";
import strip_punctuation from "../../../../../helpers/strip-punctuation";
import {showAlertError} from "../../../../../store/app/actions";
import {blocked as actions} from "../../../../../store/parsers/communities/communities-filter/actions";
import {
    BlockedFilterResult,
} from "../../../../../store/parsers/communities/communities-filter/types";
import {
    RootState,
    TaskProgress,
    TaskStatus,
} from "../../../../../store/parsers/types";
import useMinMax from "../../../../hooks/MinMaxHook";
import useSaveWithCsv from "../../../../hooks/SaveWithCsvHook";
import SaveButton from "../../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../../ui/containers/ButtonsContainer";
import ParsingSettingsContainer from "../../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../../ui/info/AppProgressBar";
import MinMaxInput from "../../../../ui/inputs/MinMaxInput";
import ParsingName from "../../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../../ui/inputs/TextAreaWithButton";
import selectFiltered from "../../../../../store/parsers/communities/communities-filter/selectors/BlockedSelector";

const FilterByBlocked = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.communitiesFilter.blocked);
    const progress: TaskProgress = useSelector((state: RootState) => state.communitiesFilter.blocked.progress);
    const sub_progress: TaskProgress = useSelector((state: RootState) => state.communitiesFilter.blocked.sub_progress);
    const result: BlockedFilterResult[] = useSelector(selectFiltered);

    const [parsingName, setParsingName] = useState<string>('');

    const {
        saveRef,
        saveCsvRef,
        setDownloadUrl,
        setDownloadCsvUrl,
        anchors
    } = useSaveWithCsv(parsingName);

    const [selection, setSelection] = useState<BlockedFilterResult[]>([]);
    useEffect(() => {
        setSelection(result);
    }, [result]);

    const [onMinMembersChange, onMaxMembersChange] = useMinMax(actions.setMembersCountMin, actions.setMembersCountMax);
    const [onMinMembersBlockedChange, onMaxMembersBlockedChange] = useMinMax(actions.setMembersCountBlockedMin, actions.setMembersCountBlockedMax);

    const save = () => {
        download_txt<BlockedFilterResult[]>({
            data: selection,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: BlockedFilterResult[]) => data.map(item => `https://vk.com/${item.group.screen_name}`),
        });
    };

    const saveCSV = () => {

        if (result.length === 0) {
            dispatch(showAlertError('Нечего сохранять'));
            return;
        }
        const headers: string[] = ['"Название"', '"Ссылка"', '"Подписчики"', '"Заблокировано"', '"Соотношение"'];

        const generator = (item: BlockedFilterResult) => {
            const line: string[] = [
                '"' + strip_punctuation(item.group.name || '') + '"',
                `"https://vk.com/${item.group.screen_name}"`,
                item.group.members_count ? item.group.members_count.toString() : "0",
                item.blocked_members_count.toString(),
                item.ratio.toFixed(2).replace(".", ","),
            ];
            return line.join(';');
        };

        const csv: string|null = csv_generator(result, headers, generator);
        if (!csv) {
            return;
        }

        setDownloadCsvUrl(download_url(csv, "text/csv"));
        setTimeout(() => {
            if (saveCsvRef && saveCsvRef.current) {
                saveCsvRef.current.click()
            }
        }, 1000);
    };

    return (
        <div>
            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>
                <TextAreaWithButton label="Список групп (по одной ссылке на строке)"
                                    value={state.settings.source}
                                    onChange={(value) => dispatch(actions.setSource(value))}
                />
                <MinMaxInput label="Фильтр по количеству подписчиков"
                             min={state.settings.members_count_min} onMinChange={onMinMembersChange}
                             max={state.settings.members_count_max} onMaxChange={onMaxMembersChange}
                />
                <MinMaxInput label="Фильтр по проценту заблокированых"
                             min={state.settings.members_count_blocked_min} onMinChange={onMinMembersBlockedChange}
                             max={state.settings.members_count_blocked_max} onMaxChange={onMaxMembersBlockedChange}
                />
                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)} />
                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {result.length > 0 &&
                        <>
                            <SaveButton label="Сохранить ссылки" onClick={() => save()} />
                            <SaveButton label="Сохранить CSV" onClick={() => saveCSV()} />
                        </>
                    }
                    {anchors}
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>
            <AppProgressBar className="tw-mt-4" current={sub_progress.current} total={sub_progress.total} message={sub_progress.message}/>

            {state.status !== TaskStatus.RUNNING && result.length > 0 &&
                <div className="tw-mt-4">
                    <DataTable value={result} autoLayout={true} rowHover={true}
                               paginator={true} rows={100} selection={selection}
                               onSelectionChange={(e) => setSelection(e.value)}
                               sortField={"blocked_members_count"}
                    >
                        <Column selectionMode="multiple" style={{width:'20px'}}/>
                        <Column header="Группа" field="group.id" body={(item: BlockedFilterResult, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/${item.group.screen_name}`} target="_blank" rel="noopener noreferrer">{item.group.name}</a></div>} sortable />
                        <Column header="Подписчики"  field="group.members_count" sortable/>
                        <Column header="Заблокировано"  field="blocked_members_count" sortable/>
                        <Column header="Соотношение"  field="ratio" body={(item: BlockedFilterResult) => item.ratio.toFixed(2) + '%'} sortable/>
                    </DataTable>
                </div>
            }
        </div>

    );
};

export default FilterByBlocked;
