import {createAction} from "@reduxjs/toolkit";
import * as types from "./action-types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const subtract_set_data_1 = createAction(types.SUBTRACT_SET_DATA_1, withPayloadType<string[]>());
export const subtract_set_data_2 = createAction(types.SUBTRACT_SET_DATA_2, withPayloadType<string[]>());
export const subtract_start = createAction(types.SUBTRACT_START);
export const subtract_set_result = createAction(types.SUBTRACT_SET_RESULT, withPayloadType<string[]>());

export const intersect_set_data_1 = createAction(types.INTERSECT_SET_DATA_1, withPayloadType<string[]>());
export const intersect_set_data_2 = createAction(types.INTERSECT_SET_DATA_2, withPayloadType<string[]>());
export const intersect_start = createAction(types.INTERSECT_START);
export const intersect_set_result = createAction(types.INTERSECT_SET_RESULT, withPayloadType<string[]>());

export const unique_set_data_1 = createAction(types.UNIQUE_SET_DATA_1, withPayloadType<string[]>());
export const unique_set_data_2 = createAction(types.UNIQUE_SET_DATA_2, withPayloadType<string[]>());
export const unique_start = createAction(types.UNIQUE_START);
export const unique_set_result = createAction(types.UNIQUE_SET_RESULT, withPayloadType<string[]>());

export const union_set_result = createAction(types.UNION_SET_RESULT, withPayloadType<string[]>());
