import {IVkMethod} from "../IVkMethod";
import {Post} from "../objects/Post";
import _chunk from "lodash/chunk";
import * as methods from "../methods/VkMethods";
import {ProcessMethodsParams} from "../types";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {WallGetByIdParams} from "../methods/WallGetByIdParams";
import {WithDefaultActions} from "../../store/parsers/types";

export interface GetPostsByIdParams extends WithDefaultActions {
    post_ids: string[],
    access_token: string,
}

const GetPostsById = function* (params: GetPostsByIdParams): Generator<any, Post[], any> {
    const executes: Array<IVkMethod> = prepare(params.post_ids);
    const executes_result: Post[] = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(post_ids: string[]): IVkMethod[] {
    const methods_per_execute: number = 20;
    const ids_per_request: number = 5;

    const ids_chunks: string[][] = _chunk(post_ids, ids_per_request);
    const requests_chunks: string[][][] = _chunk(ids_chunks, methods_per_execute);

    return requests_chunks.map((request_chunk: string[][]) => {
        const requests: string[] = request_chunk.map((chunk: string[]) => {
            const params: WallGetByIdParams = {
                posts: chunk.join(','),
            };
            const method: IVkMethod = methods.wallGetById(params);
            return `{result: API.${method.method_name}(${JSON.stringify(method.params)})}`;
        });
        const code:string = `return [${requests.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, Post[], any> {
    interface TResponse {
        result: Post[],
    }
    const result: Map<string, Post> = new Map<string, Post>();

    const handler = (response: TResponse[]) => {
        response.forEach((response_item: TResponse) => {
            response_item.result.forEach(post => {
                const unique_id: string = `${post.owner_id}_${post.id}`;
                result.set(unique_id, post);
            });
        });
    };

    yield SendMethodsDefault<TResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем посты',
        chunk_size: 6,
    });
    return Array.from(result.values());
};

export default GetPostsById;
