import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {ActivityType, DefaultProps} from "../../types";
import * as actions from './actions';
import * as types from "./types";
import {PostAudienceSearchType, PostsAudienceResult} from "./types";

const initialState: types.PostsAudienceState = {
    settings: {
        source: '',
        search_type: PostAudienceSearchType.USERS,
        activity_types: [ActivityType.LIKES, ActivityType.REPOSTS, ActivityType.COMMENTS, ActivityType.COMMENT_LIKES],
        posts_min: 0,
        posts_max: 0,
    },
    ...DefaultStateProps<PostsAudienceResult[]>([])
};

const PostsAudienceReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setSource, (state, action: PayloadAction<string>) => {
        state.settings.source = action.payload;
    });
    builder.addCase(actions.setSearchType, (state, action: PayloadAction<PostAudienceSearchType>) => {
        state.settings.search_type = action.payload;
    });
    builder.addCase(actions.addActivityType, (state, action: PayloadAction<ActivityType>) => {
        if (!state.settings.activity_types.includes(action.payload)) {
            state.settings.activity_types.push(action.payload);
        }
    });
    builder.addCase(actions.removeActivityType, (state, action: PayloadAction<ActivityType>) => {
        state.settings.activity_types = state.settings.activity_types.filter(item => item !== action.payload);
    });
    builder.addCase(actions.setPostsMin, (state, action: PayloadAction<number>) => {
        state.settings.posts_min = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setPostsMax, (state, action: PayloadAction<number>) => {
        state.settings.posts_max = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    AddDefaultCasesToReducer<PostsAudienceResult[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<PostsAudienceResult[]>>);
});

export default PostsAudienceReducer;
