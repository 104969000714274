import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {CommunityTypeFilter, SearchTypeListOrSearch} from "../../types";
import * as types from "./action-types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSearchType = createAction(types.SET_SEARCH_TYPE, withPayloadType<SearchTypeListOrSearch>());
export const setGroupsSource = createAction(types.SET_GROUPS_SOURCE, withPayloadType<string>());
export const setSearchQueriesSource = createAction(types.SET_SEARCH_QUERIES_SOURCE, withPayloadType<string>());
export const setSearchFilterCommunity = createAction(types.SET_SEARCH_FILTER_COMMUNITY, withPayloadType<CommunityTypeFilter>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<number[]>(types.NAMESPACE);
