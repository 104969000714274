import {spawn} from 'redux-saga/effects'
import MainSaga from './sagas/main';
import SearchUsersSaga from './sagas/search-users';


const main = function* () {
    yield spawn(MainSaga);
    yield spawn(SearchUsersSaga);
};

export default main;

