const NAMESPACE = 'INTERSECTIONS/';

export const SUBTRACT_SET_DATA_1 = NAMESPACE + 'SUBTRACT_SET_DATA_1';
export const SUBTRACT_SET_DATA_2 = NAMESPACE + 'SUBTRACT_SET_DATA_2';
export const SUBTRACT_START = NAMESPACE + 'SUBTRACT_START';
export const SUBTRACT_SET_RESULT = NAMESPACE + 'SUBTRACT_SET_RESULT';

export const INTERSECT_SET_DATA_1 = NAMESPACE + 'INTERSECT_SET_DATA_1';
export const INTERSECT_SET_DATA_2 = NAMESPACE + 'INTERSECT_SET_DATA_2';
export const INTERSECT_START = NAMESPACE + 'INTERSECT_START';
export const INTERSECT_SET_RESULT = NAMESPACE + 'INTERSECT_SET_RESULT';

export const UNIQUE_SET_DATA_1 = NAMESPACE + 'UNIQUE_SET_DATA_1';
export const UNIQUE_SET_DATA_2 = NAMESPACE + 'UNIQUE_SET_DATA_2';
export const UNIQUE_START = NAMESPACE + 'UNIQUE_START';
export const UNIQUE_SET_RESULT = NAMESPACE + 'UNIQUE_SET_RESULT';

export const UNION_SET_RESULT = NAMESPACE + 'UNION_SET_RESULT';
