import {put, select} from "redux-saga/effects";
import {Topic} from "../../../../../vkapi/objects/Topic";
import {TopicComment} from "../../../../../vkapi/objects/TopicComment";
import {TopicAddress} from "../../../../../vkapi/tasks/ConverUrlToId";
import GetBoardTopics from "../../../../../vkapi/tasks/GetBoardTopics";
import GetTopicComments from "../../../../../vkapi/tasks/GetTopicComments";
import date_to_timestamp from "../../../../helpers/DateToTimestamp";
import ProgressGenerator from "../../../../helpers/ProgressGenerator";
import {RootState} from "../../../types";
import * as actions from "../actions";
import selectFiltered from "../selectors/TopicsSelector";
import {TopicsParserState} from "../types";

const main = function* (topic_addresses: TopicAddress[]) {

    const state: TopicsParserState = yield select((state: RootState) => state.topicsParser);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const topics: Topic[] = yield GetBoardTopics({
        topic_addresses: topic_addresses,
        access_token: access_token,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    yield put(actions.setResult({
        user_ids: [],
        topics: topics,
        comments: [],
    }));
    const root_state: RootState = yield select((state: RootState) => state);
    const filtered_topics: Topic[] = selectFiltered(root_state);

    const start_timestamp: number|null = date_to_timestamp(state.settings.start_date);
    const end_timestamp: number|null = date_to_timestamp(state.settings.end_date);

    const users_set: Set<number> = new Set<number>();
    const comments_map: Map<string, TopicComment> = new Map<string, TopicComment>();

    const progress = ProgressGenerator({text: 'Обрабатываем обсуждения', total: filtered_topics.length, action: actions.setProgress});
    for (let topic of filtered_topics) {
        yield progress.next().value;
        if (topic.owner_id) {
            const comments: TopicComment[] = yield GetTopicComments({
                topic_id: topic.id,
                group_id: topic.owner_id,
                access_token: access_token,
                add_api_errors_action: actions.addApiError,
                is_skip: (comment: TopicComment) => {
                    if (end_timestamp && end_timestamp < comment.date) {
                        return true;
                    }
                    if (start_timestamp && start_timestamp > comment.date) {
                        return true;
                    }
                    if (comment.from_id < 0) {
                        return true;
                    }
                    if (state.settings.is_skip_messages_with_urls && comment.text.includes('http')) {
                        return true;
                    }
                    return false;
                },
                is_stop: (comment: TopicComment) => {
                    if (start_timestamp && comment.date < start_timestamp) {
                        return true;
                    }
                    return false;
                },
            });
            comments.forEach((comment: TopicComment) => {
                if (comment.from_id > 0) {
                    users_set.add(comment.from_id);
                }
                comment.topic_id = topic.id;
                comment.owner_id = topic.owner_id;
                const unique_id: string = `${topic.owner_id}_${topic.id}_${comment.id}`;
                comments_map.set(unique_id, comment);
            });
        }
    }

    yield put(actions.setResult({
        user_ids: Array.from(users_set.values()),
        topics: topics,
        comments: Array.from(comments_map.values()),
    }));
};

export default main;
