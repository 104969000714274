import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {StateWithErrors, StateWithProgress} from "../../types";

export enum BaseViewerType {
    USERS = "users",
    GROUPS = "groups",
}

interface StateSettings<TResult> extends StateWithErrors, StateWithProgress {
    settings: {
        source: string,
        show_avatar: boolean,
    }
    result: TResult,
}

interface State {
    settings: {
        viewer_type: BaseViewerType,
    },
    users: StateSettings<UserObject[]>,
    groups: StateSettings<GroupObject[]>,
}

export interface BaseViewerState extends State {}
