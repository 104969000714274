
import {Post} from "../../objects/Post";
import {IVkMethod} from "../../IVkMethod";
import {LikesGetListParams} from "../../methods/LikesGetListParams";
import * as methods from "../../methods/VkMethods";
import _chunk from "lodash/chunk";
import {ProcessMethodsParams} from "../../types";
import SendMethodsDefault from "../../helpers/SendMethodsDefault";
import {WithDefaultActions} from "../../../store/parsers/types";

export interface GetLikesParams extends WithDefaultActions {
    posts: Post[],
    filter?: "likes"|"copies"
    access_token: string,
}

export interface PostLikes {
    owner_id: number,
    post_id: number,
    likes: number[],
}

const GetLikes = function* (params: GetLikesParams): Generator<any, PostLikes[], any> {
    const executes:Array<IVkMethod> = prepare(params.posts, params.filter);
    const executes_result: PostLikes[] = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(posts: Post[], filter?: "likes"|"copies"): IVkMethod[] {
    const methods_per_execute: number = 20;
    const count: number = 1000;
    const requests: string[] = [];
    for (const post of posts) {
        for (let offset: number = 0; offset < post.likes.count; offset += count) {
            const params: LikesGetListParams = {
                type: "post",
                owner_id: post.owner_id,
                item_id: post.id,
                offset: offset,
                count: count,
            };
            if (filter) {
                params.filter = filter;
            }
            const method: IVkMethod = methods.likesGetList(params);
            requests.push(`{owner_id: ${post.owner_id}, post_id: ${post.id}, items: API.${method.method_name}(${JSON.stringify(method.params)}).items}`);
        }
    }
    const requests_chunks: string[][] = _chunk(requests, methods_per_execute);
    return requests_chunks.map((chunk: string[]) => {
        const code:string = `return [${chunk.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, PostLikes[], any> {
    interface TResponse {
        owner_id: number,
        post_id: number,
        items: number[],
    }
    const map: Map<string, PostLikes> = new Map<string, PostLikes>();
    const handler = (response: TResponse[]) => {
        response.forEach((response_item: TResponse) => {
            const unique_id: string = `${response_item.owner_id}_${response_item.post_id}`;
            const post_likes: PostLikes = map.get(unique_id) ||
                {
                    owner_id: response_item.owner_id,
                    post_id: response_item.post_id,
                    likes: []
                };
            post_likes.likes = post_likes.likes.concat(response_item.items);
            map.set(unique_id, post_likes);
        });
    };
    yield SendMethodsDefault<TResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем лайки',
        chunk_size: 6,
    });
    return Array.from(map.values());
};

export default GetLikes;
