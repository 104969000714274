import {UserFriendsCount} from "../saga";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {TaskProgress} from "../../../types";
import {VkApiError} from "../../../../../vkapi/VkApiError";
import _chunk from "lodash/chunk";
import UserSubscriptions from "../../../../../vkapi/objects/UserSubscriptions";
import GetSubscriptionsUsers from "../../../../../vkapi/tasks/GetSubscriptionsUsers";
import ProgressGenerator from "../../../../helpers/ProgressGenerator";


export interface GetSubscriptionsUserCountParams {
    user_ids: number[],
    counts: Map<number, UserFriendsCount>,
    filter_func: (user_id: number) => boolean,
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>,
}

const GetSubscriptionsUserCount = function* (params: GetSubscriptionsUserCountParams): Generator<any, Map<number, UserFriendsCount>, any> {
    const result = params.counts;
    const user_chunks = _chunk(params.user_ids, 150);

    const progress = ProgressGenerator({text: 'Получаем подписчиков', total: user_chunks.length, action: params.progress_action});

    for (let chunk of user_chunks) {
        yield progress.next().value;

        const subscriptions: Map<number, UserSubscriptions> = yield GetSubscriptionsUsers({
            user_ids: chunk,
            access_token: params.access_token,
        });
        for (let data of subscriptions.values()) {
            data.subscription_ids.forEach((subscription_id: number) => {
                if (!params.filter_func(subscription_id)) {
                    return;
                }
                if (result.size >= 1500000) {
                    return;
                }
                const item: UserFriendsCount|undefined = result.get(subscription_id);
                if (item) {
                    item.followers++;
                    item.total++;
                    result.set(subscription_id, item);
                }
                else {
                    result.set(subscription_id, {user_id: subscription_id, friends: 0, followers: 1, total: 1});
                }
            })
        }
    }
    return result;
};

export default GetSubscriptionsUserCount;
