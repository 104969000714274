import {createSelector} from "reselect";
import {UserObject} from "../../../../../vkapi/objects/UserObject";
import {RootState} from "../../../types";

const select_items = (state: RootState) => state.searchBase.result;

const selectFiltered = createSelector(
    [select_items],
    (items: UserObject[]) => items
);

export default selectFiltered;
