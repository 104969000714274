import {IVkMethod} from "../../IVkMethod";
import * as methods from "../../methods/VkMethods";
import _chunk from "lodash/chunk";
import {ProcessMethodsParams} from "../../types";
import SendMethodsDefault from "../../helpers/SendMethodsDefault";
import {Topic} from "../../objects/Topic";
import {GroupTopics, WithDefaultActions} from "../../../store/parsers/types";
import {BoardGetTopicsParams} from "../../methods/BoardGetTopicsParams";
import {GroupTopicsWithCount} from "./GetFirstTopics";

export interface GetAllTopicsLeftParams extends WithDefaultActions {
    topics_with_count: GroupTopicsWithCount[],
    access_token: string,
}

const GetAllTopicsLeft = function* (params: GetAllTopicsLeftParams): Generator<any, GroupTopics[], any> {
    const executes:Array<IVkMethod> = prepare(params.topics_with_count);
    const executes_result: GroupTopics[] = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(topics_with_count: GroupTopicsWithCount[]): IVkMethod[] {
    const methods_per_execute: number = 20;
    const count: number = 100;
    const requests: string[] = [];

    topics_with_count.forEach((item: GroupTopicsWithCount) => {
        if (item.count <= count) {
            return;
        }
        for (let offset = count; offset < item.count; offset += count) {
            const params: BoardGetTopicsParams = {
                group_id: item.owner_id,
                count: count,
                offset: offset,
            };
            const method: IVkMethod = methods.boardGetTopics(params);
            requests.push(`{owner_id: ${item.owner_id}, items: API.${method.method_name}(${JSON.stringify(method.params)}).items}`);
        }
    });

    const requests_chunks: string[][] = _chunk(requests, methods_per_execute);
    return requests_chunks.map((chunk: string[]) => {
        const code:string = `return [${chunk.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams): Generator<any, GroupTopics[], any> {
    interface TResponse {
        owner_id: number,
        items: Topic[],
    }
    const map: Map<number, GroupTopics> = new Map<number, GroupTopics>();
    const handler = (response: TResponse[]) => {
        response.forEach((response_item: TResponse) => {
            const owner_id: number = response_item.owner_id;
            const group_topics: GroupTopics = map.get(owner_id) ||
                {
                    group_id: owner_id,
                    topics: []
                };
            group_topics.topics = group_topics.topics.concat(response_item.items);
            map.set(owner_id, group_topics);
        });
    };
    yield SendMethodsDefault<TResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем темы',
        chunk_size: 6,
    });
    return Array.from(map.values());
};

export default GetAllTopicsLeft;
