import {ActionReducerMapBuilder, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {Post} from "../../../../vkapi/objects/Post";
import AddDefaultCasesToReducer from "../../../helpers/AddDefaultCasesToReducer";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import {DefaultProps, TaskProgress} from "../../types";
import * as actions from './actions';
import * as types from "./types";
import {PostsSearchAttachType} from "./types";

const moment = require('moment');

const initialState: types.PostsSearchState = {
    settings: {
        groups_source: '',
        queries_source: '',
        is_strict: true,
        words_minus: '',
        start_date: moment().subtract(14, "days").startOf("day").toDate(),
        end_date: null,
        attach_type: PostsSearchAttachType.ANY,
    },
    sub_progress: {message: ''},
    ...DefaultStateProps<Post[]>([])
};

const PostsSearchReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setGroupsSource, (state, action: PayloadAction<string>) => {
        state.settings.groups_source = action.payload;
    });
    builder.addCase(actions.setQueriesSearch, (state, action: PayloadAction<string>) => {
        state.settings.queries_source = action.payload;
    });
    builder.addCase(actions.setIsStrict, (state, action: PayloadAction<boolean>) => {
        state.settings.is_strict = action.payload;
    });
    builder.addCase(actions.setWordsMinus, (state, action: PayloadAction<string>) => {
        state.settings.words_minus = action.payload;
    });
    builder.addCase(actions.setStartDate, (state, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });
    builder.addCase(actions.setAttachType, (state, action: PayloadAction<PostsSearchAttachType>) => {
        state.settings.attach_type = action.payload;
    });
    builder.addCase(actions.setSubProgress, (state, action: PayloadAction<TaskProgress>) => {
        state.sub_progress = action.payload;
    });
    AddDefaultCasesToReducer<Post[]>({
        actions: {
            setTaskStatus: actions.setTaskStatus,
            setProgress: actions.setProgress,
            setResult: actions.setResult,
            addApiError: actions.addApiError,
            resetApiErrors: actions.resetApiErrors
        }
    }, builder as unknown as ActionReducerMapBuilder<DefaultProps<Post[]>>);
});

export default PostsSearchReducer;
