import {GroupObject} from "../objects/GroupObject";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import {Either, right, left} from "fp-ts/lib/Either";
import {VkApiError} from "../VkApiError";
import SendMethodsDefault from "../helpers/SendMethodsDefault";

export interface GetGroupParams {
    group_id: number|string,
    access_token: string,
    fields?: string,
}

export interface GetGroupError {
    message: string
}

const GetGroup = function* (params:GetGroupParams): Generator<any, Either<GetGroupError, GroupObject>, any>  {

    const method:IVkMethod = methods.groupsGetById({group_id: params.group_id, fields: params.fields});

    let group: GroupObject|undefined;
    let api_error: VkApiError|undefined;



    yield SendMethodsDefault<GroupObject[]>({
        methods_params: {
            methods: [method],
            access_token: params.access_token,
        },
        response_handler: (response: GroupObject[]) => {
            if (response.length > 0) {
                group = response[0];
            }
        },
        error_handler: (error: VkApiError) => {
            api_error = error;
        }
    });

    if (group) {
        return right(group);
    }
    if (api_error) {
        return left({message: 'Не удалось получить информацию о группе. Ошибка: ' + api_error.error_data.error_reason.error_msg})
    }
    return left({message: 'Не удалось получить информацию о группе. Ошибка: неизвестная ошибка'});
};

export default GetGroup;
