import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from "redux-saga/effects";
import GetLastPostDates from "../../../../../vkapi/tasks/GetLastPostDates";
import {showAlertError, showAlertParsingCompleted} from "../../../../app/actions";
import {RootState, TaskStatus} from "../../../types";
import * as action_types from "../action-types";
import * as actions from "../actions";
import selectFiltered from "../selectors/FilteredSelector";
import {CommunitySearchItem, SearchCommunitiesState} from "../types";

const main = function* () {
    while (yield take(action_types.FIND_LAST_POST_DATES)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: SearchCommunitiesState = yield select((state: RootState) => state.searchCommunities);
    const access_token = yield select((state: RootState) => state.app.access_token);
    let result: CommunitySearchItem[] = state.result;

    const group_ids: number[] = result.map(item => item.group.id);

    const last_post_dates: Map<number, number> = yield GetLastPostDates({
        group_ids: group_ids,
        access_token: access_token,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    result = result.map((item) => {
        const last_post_date: number|undefined = last_post_dates.get(item.group.id);
        if (!last_post_date) {
            return item;
        }
        let new_item:CommunitySearchItem = JSON.parse(JSON.stringify(item));
        new_item.last_post = last_post_date;
        return new_item;
    });


    yield put(actions.setIsLastPostDatesFound(true));
    yield put(actions.setResult(result));

    const filtered: CommunitySearchItem[] = yield select(selectFiltered);

    yield put(actions.setProgress({message: `Завершено.`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${filtered.length}`));
    yield put({ type: action_types.COMPLETED});

}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    yield put(actions.setProgress({message: `Ошибка: ${error.payload.message}`, current: 100, total: 100}));
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
