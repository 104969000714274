import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import * as action_types from '../action-types';
import * as actions from "../actions";
import {DataSourceType, RootState, TaskStatus} from "../../../types";
import {showAlert} from "../../../../app/actions";
import {AudienceAnalysisItem, AudienceAnalysisResult, AudienceAnalysisState} from "../types";
import {EMPTY_ID_FILE, EMPTY_URL_GROUP} from "../../../validation-errors";
import GetUsers from "../../../../../vkapi/tasks/GetUsers";
import {id_to_life_main, id_to_people_main, id_to_political, id_to_relation} from "../helpers";
import {UserObject} from "../../../../../vkapi/objects/UserObject";
import GetUserIdsFromGroupOrFile from "../../../../helpers/GetUserIdsFromGroupOrFile";

const main = function* () {
    while (yield take(action_types.START)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {

    yield put(actions.resetApiErrors());

    const state: AudienceAnalysisState = yield select((state: RootState) => state.audienceAnalysis);
    yield validate(state);

    const access_token = yield select((state: RootState) => state.app.access_token);

    const users_array: number[] = yield GetUserIdsFromGroupOrFile(state.settings.data_source_type, state.settings.data_source_url, state.settings.data_source_file, access_token, actions.setProgress);

    yield put(actions.clearSelectedResults());
    yield put(actions.setFoundUsers(new Set<number>()));


    const profiles: Map<number, UserObject> = yield GetUsers({
        user_ids: users_array,
        chunk_size: 250,
        access_token: access_token,
        fields: ["interests","education","universities","schools","relation","occupation","activities","interests","music","movies","tv","books","games","personal"].join(','),
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    yield put(actions.setProfiles(profiles));

    const result: AudienceAnalysisResult = getResult(profiles);

    yield put(actions.setProgress({message: `Завершено`, total: undefined, current: undefined}));
    yield put(actions.setResult(result));
    yield put(showAlert({text: `Готово.`, header: 'Парсинг завершен'}));
    yield put({ type: action_types.COMPLETED});
}

function* validate(state: AudienceAnalysisState) {
    if (state.settings.data_source_type === DataSourceType.URL) {
        if (state.settings.data_source_url.trim() === '') {
            yield put(showAlert({text: EMPTY_URL_GROUP, header: 'Ошибка'}));
            yield put(actions.stop());
            return;
        }
    }
    if (state.settings.data_source_type === DataSourceType.FILE) {
        if (state.settings.data_source_file.trim() === '') {
            yield put(showAlert({text: EMPTY_ID_FILE, header: 'Ошибка'}));
            yield put(actions.stop());
            return;
        }
    }
}

function getResult(profiles: Map<number, UserObject>): AudienceAnalysisResult {

    let interests: Map<string, number> = new Map<string, number>();
    let books: Map<string, number> = new Map<string, number>();
    let music: Map<string, number> = new Map<string, number>();
    let movies: Map<string, number> = new Map<string, number>();
    let activities: Map<string, number> = new Map<string, number>();
    let games: Map<string, number> = new Map<string, number>();
    let tv: Map<string, number> = new Map<string, number>();
    let occupation: Map<string, number> = new Map<string, number>();
    let universities: Map<string, number> = new Map<string, number>();
    let faculties: Map<string, number> = new Map<string, number>();
    let relations: Map<string, number> = new Map<string, number>();
    let political: Map<string, number> = new Map<string, number>();
    let people_main: Map<string, number> = new Map<string, number>();
    let life_main: Map<string, number> = new Map<string, number>();

    profiles.forEach(profile => {

        if (profile.interests) {
            interests = addItems(interests, split(profile.interests));
        }
        if (profile.books) {
            books = addItems(books, split(profile.books.replace('"', '')));
        }
        if (profile.music) {
            music = addItems(music, split(profile.music.replace('"', '')));
        }
        if (profile.movies) {
            movies = addItems(movies, split(profile.movies.replace('"', '')));
        }
        if (profile.activities) {
            activities = addItems(activities, split(profile.activities));
        }
        if (profile.games) {
            games = addItems(games, split(profile.games));
        }
        if (profile.tv) {
            tv = addItems(tv, split(profile.tv));
        }
        if (profile.occupation && profile.occupation.name && profile.occupation.type === "work") {
            occupation = addItems(occupation, [profile.occupation.name.trim().toLowerCase()]);
        }
        if (profile.universities) {
            universities = addItems(universities, profile.universities.map(item => {
                if (item.name) {
                    return item.name.trim().toLowerCase();
                }
                return '';
            }));
            faculties = addItems(faculties, profile.universities.map(item => {
                if (item.faculty_name) {
                    return item.faculty_name.trim().toLowerCase();
                }
                return '';
            }));
        }
        if (profile.relation) {
            relations = addItems(relations, [id_to_relation(profile.relation)]);
        }
        if (profile.personal) {
            const personal = profile.personal;
            if (personal.political) {
                political = addItems(political, [id_to_political(personal.political)]);
            }
            if (personal.people_main) {
                people_main = addItems(people_main, [id_to_people_main(personal.people_main)])
            }
            if (personal.life_main) {
                life_main = addItems(life_main, [id_to_life_main(personal.life_main)])
            }
        }
    });

    return  {
        activities: map_to_analysis_items(activities),
        books: map_to_analysis_items(books),
        faculties: map_to_analysis_items(faculties),
        games: map_to_analysis_items(games),
        interests: map_to_analysis_items(interests),
        life_main: map_to_analysis_items(life_main),
        people_main: map_to_analysis_items(people_main),
        movies: map_to_analysis_items(movies),
        music: map_to_analysis_items(music),
        occupation: map_to_analysis_items(occupation),
        political: map_to_analysis_items(political),
        relations: map_to_analysis_items(relations),
        tv: map_to_analysis_items(tv),
        universities: map_to_analysis_items(universities),
        work: map_to_analysis_items(occupation),
    };
}

function addItems(collection: Map<string, number>, items: string[]): Map<string, number> {
    items.forEach(item => {
        let count: number = collection.get(item) || 0;
        count++;
        collection.set(item, count);
    });
    return collection;
}

function split(text: string, separator: RegExp = /[,.]/): string[] {
    return text.toLowerCase().trim().split(separator).map(item => item.trim()).filter(item => item.length > 1 && item !== '-');
}

function map_to_analysis_items(map: Map<string, number>): AudienceAnalysisItem[] {
    const result: AudienceAnalysisItem[] = [];
    for (let [key, value] of map) {
        result.push({title: key, count: value});
    }
    return result.sort((a, b) => b.count - a.count);

}

function* onStop(task) {
    yield take(action_types.STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    yield put(actions.setProgress({message: `Ошибка: ${error.payload.message}`}));
    yield put(showAlert({text: error.payload.message, header: 'Ошибка'}));
    yield cancel(task);
}

export default main;
