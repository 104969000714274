import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {VkApiError} from "../../../../vkapi/VkApiError";
import DefaultStateProps from "../../../helpers/DefaultStateProps";
import * as types from "./types";
import * as global_types from "../../types";
import * as actions from './actions';
import {Promopost} from "./types";

const moment = require('moment');

const initialState: types.PromopostsState = {
    settings: {
        groups_source: '',
        start_date: moment().subtract(14, "days").startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
        posts_min: 0,
        posts_max: 0,
    },
    sub_progress: {message: ''},
    ...DefaultStateProps<Promopost[]>([])
};

const PromopostsReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setGroupsSource, (state: types.PromopostsState, action: PayloadAction<string>) => {
        state.settings.groups_source = action.payload;
    });
    builder.addCase(actions.setStartDate, (state: types.PromopostsState, action: PayloadAction<Date|null>) => {
        state.settings.start_date = action.payload;
    });
    builder.addCase(actions.setEndDate, (state: types.PromopostsState, action: PayloadAction<Date|null>) => {
        state.settings.end_date = action.payload;
    });
    builder.addCase(actions.setPostsMin, (state: types.PromopostsState, action: PayloadAction<number>) => {
        state.settings.posts_min = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setPostsMax, (state: types.PromopostsState, action: PayloadAction<number>) => {
        state.settings.posts_max = isNaN(action.payload) || action.payload < 0 ? 0 : action.payload;
    });
    builder.addCase(actions.setTaskStatus, (state: types.PromopostsState, action: PayloadAction<global_types.TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setSubProgress, (state: types.PromopostsState, action: PayloadAction<global_types.TaskProgress>) => {
        state.sub_progress = action.payload;
    });
    builder.addCase(actions.setProgress, (state: types.PromopostsState, action: PayloadAction<global_types.TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: types.PromopostsState, action: PayloadAction<Promopost[]>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: types.PromopostsState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state: types.PromopostsState) => {
        state.api_errors = [];
    });
});

export default PromopostsReducer;
