import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {City, Country, StateWithErrors, StateWithProgress} from "../../types";

export enum MessageType {
    ANY = "any",
    COMMENTS = "type:reply",
    REPOSTS = "type:copy",
}
export enum NewsAttachType {
    ANY = "any",
    PHOTO = "has:photo",
    VIDEO = "has:video",
    AUDIO = "has:audio",
    GRAFFITI = "has:graffiti",
    NOTE = "has:note",
    POLL = "has:poll",
    LINK = "has:link",
    DOC = "has:doc",
    ALBUM = "has:album",
    NONE = "has:none",
}

export enum LikesCount {
    ANY = "any",
    MIN_10 = "likes:10",
    MIN_100 = "likes:100",
    MIN_1000 = "likes:1000",
}

export enum ShowType {
    ANY = "any",
    USERS = "users",
    COMMUNITIES = "communities",
}

export interface NewsSearchResult {
    post_id: number,
    owner_id: number,
    owner: UserObject | GroupObject,
    text: string,
    date: number,
}

interface State {
    settings: {
        queries: string,
        words_minus: string,
        message_type: MessageType,
        attach_type: NewsAttachType,
        likes_count: LikesCount,
        min_text_length: number,
        selected_country: Country|null,
        selected_city: City|null,
        start_date: Date|null,
        end_date: Date|null,
        show_type: ShowType,
    },
    result: NewsSearchResult[],
}

export interface NewsSearchState extends State, StateWithErrors, StateWithProgress {}
