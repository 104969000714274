import React from "react";
import {ProgressSpinner} from "primereact/progressspinner";
import {Message} from "primereact/message";

export interface RunningParsingOverlayProps {
}

const RunningParsingOverlay = (props: RunningParsingOverlayProps) => {
    return (
        <div className='tw-absolute tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center' style={{'zIndex': 10}}>
            <div className="tw-flex tw-flex-col tw-items-center" >
                <div>
                    <ProgressSpinner/>
                </div>
                <div>
                    <Message severity="info" text="Идет парсинг" />
                </div>
            </div>
        </div>
    )
};

export default RunningParsingOverlay;
