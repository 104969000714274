import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {OwnerPosts, Post} from "../../../../vkapi/objects/Post";
import {convert_urls_to_id} from "../../../../vkapi/tasks/ConverUrlToId";
import GetActivitiesIds, {PostActivitiesIds} from "../../../../vkapi/tasks/GetActivitiesIds";
import GetGroups from "../../../../vkapi/tasks/GetGroups";
import GetPosts from "../../../../vkapi/tasks/GetPosts";
import {showAlert, showAlertError} from "../../../app/actions";
import {date_in_range, is_post_before_start_date} from "../../../helpers/DateChecker";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import {ActivityType, RootState, TaskStatus} from "../../types";
import {EMPTY_URL_GROUP_LIST} from "../../validation-errors";
import * as action_types from './action-types';
import * as actions from "./actions";
import {ActivitiesCommunityState, AllActivities, WhatToGet} from "./types";

interface GroupActivities {
    from_id,
    posts: number,
    activities: number,
}


const main = function* () {
    while (yield take(action_types.START)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: ActivitiesCommunityState = yield select((state: RootState) => state.activitiesCommunity);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const group_urls: string[] = convert_urls_to_id(state.settings.data_source.split('\n'));
    const groups: GroupObject[] = yield GetGroups({
        access_token: access_token,
        group_ids: group_urls,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    const is_date_in_range = date_in_range(state.settings.start_date, state.settings.end_date);
    const is_posts_start_date_reached = is_post_before_start_date(state.settings.start_date);

    const all_activities: Map<number, AllActivities> = new Map<number, AllActivities>();

    const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});
    for (const group of groups) {
        yield progress.next().value;
        const posts: OwnerPosts = yield GetPosts({
            owner_id: group.id * -1,
            is_skip: (post: Post) => !is_date_in_range(post.date),
            is_stop: (post: Post) => is_posts_start_date_reached(post),
            access_token: access_token,
        });

        const activities: Map<number, PostActivitiesIds> = yield GetActivitiesIds({
            posts: posts.posts,
            activity_types: new Set<ActivityType>(state.settings.activity_types),
            access_token: access_token,
            progress_action: actions.setSubProgress,
            add_api_errors_action: actions.addApiError,
        });

        const group_activities: Map<number, GroupActivities> = summarize_group_activities(Array.from(activities.values()));
        group_activities.forEach((value: GroupActivities, from_id: number) => {
            if (state.settings.is_posts_every_community) {
                if (state.settings.posts_min > 0 && value.posts < state.settings.posts_min) {
                    return;
                }
                if (state.settings.posts_max > 0 && value.posts > state.settings.posts_max) {
                    return;
                }
            }
            const item: AllActivities = all_activities.get(from_id) || {
                from_id: from_id,
                activities: 0,
                groups: 0,
                posts: 0,
            };
            item.activities += value.activities;
            item.posts += value.posts;
            item.groups++;
            all_activities.set(from_id, item);
        });
    }

    const result: AllActivities[] = Array.from(all_activities.values()).filter((item: AllActivities) => {
        if (state.settings.what_to_get === WhatToGet.USERS && item.from_id < 0) {
            return false;
        }
        if (state.settings.what_to_get === WhatToGet.COMMUNITIES && item.from_id > 0) {
            return false;
        }
        if (state.settings.groups_min > 0 && item.groups < state.settings.groups_min) {
            return false;
        }
        if (state.settings.groups_max > 0 && item.groups > state.settings.groups_max) {
            return false;
        }
        if (state.settings.posts_min > 0 && item.posts < state.settings.posts_min) {
            return false;
        }
        if (state.settings.posts_max > 0 && item.posts > state.settings.posts_max) {
            return false;
        }
        if (state.settings.activities_min > 0 && item.activities < state.settings.activities_min) {
            return false;
        }
        if (state.settings.activities_max > 0 && item.activities > state.settings.activities_max) {
            return false;
        }
        return true;
    });

    yield put(actions.setSubProgress({message: '', total: undefined, current: undefined}));
    yield put(actions.setProgress({message: `Завершено`, total: undefined, current: undefined}));
    yield put(showAlert({text: `Готово. Найдено: ${result.length}`, header: 'Парсинг завершен'}));
    yield put(actions.setResult(result));

    yield put({ type: action_types.COMPLETED});
}

function summarize_group_activities(post_activities: PostActivitiesIds[]): Map<number, GroupActivities> {
    const result: Map<number, GroupActivities> = new Map<number, GroupActivities>();
    post_activities.forEach((item: PostActivitiesIds) => {
        const post_activities: Set<number> = new Set<number>();
        item.likes.concat(item.reposts, item.comments, item.comments_likes).forEach((from_id: number) => {
            const result_item: GroupActivities = result.get(from_id) ||
                {
                    from_id: from_id,
                    activities: 0,
                    posts: 0,
                };
            result_item.activities++;
            if (!post_activities.has(from_id)) {
                result_item.posts++;
                post_activities.add(from_id);
            }
            result.set(from_id, result_item);
        });
    });
    return result;
}

function* validate(state: ActivitiesCommunityState) {
    if (state.settings.data_source.trim() === '') {
        yield put(showAlertError(EMPTY_URL_GROUP_LIST));
        yield put(actions.stop());
        return;
    }
    if (state.settings.activities_min > state.settings.activities_max && state.settings.activities_max > 0) {
        yield put(showAlert({text: 'Минимальное количество активностей не может быть больше, чем максимальное', header: 'Ошибка'}));
        yield put(actions.stop());
        return;
    }
    if (state.settings.groups_min > state.settings.groups_max && state.settings.groups_max > 0) {
        yield put(showAlert({text: 'Минимальное количество групп не может быть больше, чем максимальное', header: 'Ошибка'}));
        yield put(actions.stop());
        return;
    }
    if (state.settings.posts_min > state.settings.posts_max && state.settings.posts_max > 0) {
        yield put(showAlert({text: 'Минимальное количество постов не может быть больше, чем максимальное', header: 'Ошибка'}));
        return;
    }
}

function* onStop(task) {
    yield take(action_types.STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    console.log(error);
    yield put(actions.setProgress({message: `Ошибка: ${error.payload.message}`}));
    yield put(showAlert({text: error.payload.message, header: 'Ошибка'}));
    yield cancel(task);
}

export default main;
