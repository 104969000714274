import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {Topic} from "../../../../vkapi/objects/Topic";
import {TopicComment} from "../../../../vkapi/objects/TopicComment";
import {StateWithErrors, StateWithProgress} from "../../types";

export enum TopicsSearchType {
    BY_LINKS = "by_links",
    BY_GROUPS = "by_groups",
    TOPICS_ONLY = "topics_only"
}

export interface TopicsSearchResult {
    user_ids: number[],
    topics: Topic[],
    comments: TopicComment[],
}

interface State {
    settings: {
        search_type: TopicsSearchType,
        links_topics: string,
        links_groups: string,
        words_plus: string,
        words_minus: string,
        start_date: Date|null,
        end_date: Date|null,
        min_messages: number,
        is_skip_messages_with_urls: boolean,
    },
    groups: GroupObject[],
    result: TopicsSearchResult,
}

export interface TopicsParserState extends State, StateWithErrors, StateWithProgress {}
