export enum GroupsSearchSort {
    DEFAULT = 0,
    BY_GROWTH_SPEED = 1,
    BY_DAILY_VISITORS = 2,
    BY_LIKES = 3,
    BY_COMMENTS = 4,
    BY_TOPICS = 5,
}

export enum GroupsSearchType {
    GROUP = "group",
    PAGE = "page",
    EVENT = "event",
}

export interface GroupsSearchParams {
    q: string,
    type?: GroupsSearchType,
    country_id?: number,
    city_id?: number,
    future?: 1|0,
    market?: 1|0,
    sort?: GroupsSearchSort,
    offset: number,
    count: number,
}
