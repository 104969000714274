import {Post} from "../../vkapi/objects/Post";
import date_to_timestamp from "./DateToTimestamp";

export function date_in_range(start_date: Date|null, end_date: Date|null) {
    const start_timestamp: number|null = date_to_timestamp(start_date);
    let end_timestamp: number|null = date_to_timestamp(end_date);
    if (end_timestamp) {
        end_timestamp = end_timestamp + (60 * 60 * 24);
    }
    return (date: number): boolean => {
        if (end_timestamp && end_timestamp < date) {
            return false;
        }
        if (start_timestamp && start_timestamp > date) {
            return false;
        }
        return true;
    }
}

export function is_post_before_start_date(start_date: Date|null) {
    const start_timestamp: number|null = date_to_timestamp(start_date);
    return (post: Post) => {
        if (start_timestamp && post.date < start_timestamp && !post.is_pinned) {
            return true;
        }
        return false;
    }
}
