export const NAMESPACE = 'NEWS_SEARCH/';

export const SET_QUERIES = NAMESPACE + 'SET_QUERIES';
export const SET_WORDS_MINUS = NAMESPACE + 'SET_WORDS_MINUS';
export const SET_MESSAGE_TYPE = NAMESPACE + 'SET_MESSAGE_TYPE';
export const SET_ATTACH_TYPE = NAMESPACE + 'SET_ATTACH_TYPE';
export const SET_LIKES_COUNT = NAMESPACE + 'SET_LIKES_COUNT';
export const SET_MIN_TEXT_LENGTH = NAMESPACE + 'SET_MIN_TEXT_LENGTH';
export const SET_SELECTED_COUNTRY = NAMESPACE + 'SET_SELECTED_COUNTRY';
export const SET_SELECTED_CITY = NAMESPACE + 'SET_SELECTED_CITY';
export const SET_START_DATE = NAMESPACE + 'SET_START_DATE';
export const SET_END_DATE = NAMESPACE + 'SET_END_DATE';
export const SET_SHOW_TYPE = NAMESPACE + 'SET_SHOW_TYPE';
