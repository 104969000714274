import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import * as action_types from './action-types';
import * as actions from "./actions";
import {RootState, TaskStatus} from "../../types";
import {showAlert} from "../../../app/actions";
import {GetUsersCountsResult, GroupMembersState} from "./types";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import GetGroups from "../../../../vkapi/tasks/GetGroups";
import {Either, isLeft} from "fp-ts/lib/Either";
import GetAllGroupMemberIds, {GetAllGroupMemberIdsError} from "../../../../vkapi/tasks/GetAllGroupMemberIds";
import {convert_urls_to_id} from "../../../../vkapi/tasks/ConverUrlToId";
import ProgressGenerator from "../../../helpers/ProgressGenerator";

const main = function* () {
    while (yield take(action_types.START)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: GroupMembersState = yield select((state: RootState) => state.groupMembers);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const groups_min: number = state.settings.groups_min;
    const groups_max: number = state.settings.groups_max;

    yield put(actions.setUserCounts(new Map<number, number>()));
    yield put(actions.setClosedGroups([]));

    const group_urls: string[] = convert_urls_to_id(state.settings.groups_source.split("\n"));
    const groups: GroupObject[] = yield GetGroups({
        access_token: access_token,
        group_ids: group_urls,
        add_api_errors_action: actions.addApiError,
        progress_action: actions.setProgress,
    });

    const user_counts_result: GetUsersCountsResult = yield get_users_counts(groups, access_token);

    yield put(actions.setUserCounts(user_counts_result.user_counts));
    yield put(actions.setClosedGroups(user_counts_result.closed_groups));

    const result: number[] = [];
    user_counts_result.user_counts.forEach((count: number, user_id: number) => {
        if (groups_min > 0 && count < groups_min) {
            return;
        }
        if (groups_max > 0 && count > groups_max) {
            return;
        }
        result.push(user_id);
    });

    yield put(actions.setProgress({message: `Завершено`, total: undefined, current: undefined}));
    yield put(showAlert({text: `Готово. Найдено: ${result.length}`, header: 'Парсинг завершен'}));
    yield put(actions.setResult(result));

    yield put({ type: action_types.COMPLETED});
}

function* get_users_counts(groups: GroupObject[], access_token: string): Generator<any, GetUsersCountsResult, any> {

    const result: GetUsersCountsResult = {
        user_counts: new Map<number, number>(),
        closed_groups: [],
    };

    const progress = ProgressGenerator({text: 'Обабатываем группы' ?? '', total: groups.length, action: actions.setProgress});
    for (let group of groups) {

        yield progress.next().value;

        try {
            let user_ids: Either<GetAllGroupMemberIdsError, number[]> = yield GetAllGroupMemberIds({
                group_id: group.id,
                access_token: access_token,
            });
            if (isLeft(user_ids)) {
                continue;
            }
            user_ids.right.forEach(user_id => {
                let count: number = result.user_counts.get(user_id) || 0;
                count++;
                result.user_counts.set(user_id, count);
            });
        }
        catch (e) {
            result.closed_groups.push(group.id);
        }
    }

    return result;
}

function* validate(state: GroupMembersState) {
    if (state.settings.groups_source.trim() === '') {
        yield put(showAlert({text: 'Не указаны ссылки', header: 'Ошибка'}));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(action_types.STOP);
    yield cancel(task);
}

function* onCompleted() {
    yield take(action_types.COMPLETED);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(action_types.SET_TASK_ERROR);
    yield put(showAlert({text: error.payload.message, header: 'Ошибка'}));
    yield cancel(task);
}

export default main;
