import {createAction} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {TaskProgress} from "../../types";
import * as types from "./action-types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSource = createAction(types.SET_SOURCE, withPayloadType<string>());
export const setTopPlaces = createAction(types.SET_TOP_PLACES_COUNT, withPayloadType<number>());
export const setSubProgress = createAction(types.SET_SUBPROGRESS, withPayloadType<TaskProgress>());
export const setGroupsWithHiddenMembers = createAction(types.SET_GROUPS_WITH_HIDDEN_MEMBERS, withPayloadType<GroupObject[]>());
export const setNotPublics = createAction(types.SET_NOT_PUBLICS, withPayloadType<GroupObject[]>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<number[]>(types.NAMESPACE);
