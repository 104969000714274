import {Button} from "primereact/button";
import React, {useState} from "react";
import {City, Country} from "../../../store/parsers/types";
import InputContainer from "../containers/InputContainer";
import CitiesDialog from "../dialogs/CitiesDialog";

export interface CityInputProps {
    selectedCountry: Country|null,
    selectedCities: City[],
    setSelection: (items: City[]) => void,
    onRemoveCity: (item: City) => void,
    onClear: () => void,
}

const CityInput = (props: CityInputProps) => {

    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const footer = () => {
        return (
            <div className="p-field">
                {props.selectedCities.length > 0 &&
                props.selectedCities.map((item: City) => {
                    return (
                        <Button key={item.id} label={item.title}
                                onClick={() => props.onRemoveCity(item)}
                                className="p-button-rounded p-button-sm p-button-secondary tw-mr-2"
                                icon="pi pi-times-circle"
                        />
                    );
                })
                }
                {props.selectedCities.length === 0 &&
                <span>Города не выбранны</span>
                }
            </div>
        );
    };

    return (
        <InputContainer label="Выбор города" bottom={footer()}>
            <Button label="Выбрать город" className="p-field" onClick={() => setDialogVisible(true)}/>
            <Button label="Сбросить"
                    className="p-field"
                    onClick={props.onClear}
            />
            <CitiesDialog
                country_id={props.selectedCountry?.id}
                selection={props.selectedCities}
                setSelection={props.setSelection}
                visible={dialogVisible}
                onHide={() => setDialogVisible(false)}
            />
        </InputContainer>
    )
};

export default CityInput;
