import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {City, Country, Gender} from "../../types";
import * as types from "./action-types";
import {UsersSearchType} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSearchType = createAction(types.SET_SEARCH_TYPE, withPayloadType<UsersSearchType>());
export const setQuery = createAction(types.SET_SEARCH_QUERY, withPayloadType<string>());
export const setAgeMin = createAction(types.SET_AGE_MIN, withPayloadType<number>());
export const setAgeMax = createAction(types.SET_AGE_MAX, withPayloadType<number>());
export const setSelectedCountry = createAction(types.SET_SELECTED_COUNTRY, withPayloadType<Country|null>());
export const setSelectedCity = createAction(types.SET_SELECTED_CITY, withPayloadType<City|null>());
export const setGender = createAction(types.SET_GENDER, withPayloadType<Gender>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<number[]>(types.NAMESPACE);
