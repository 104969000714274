import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {getUserAge, UserObject} from "../../../../../vkapi/objects/UserObject";
import GroupsGetMembers from "../../../../../vkapi/tasks/GroupsGetMembers";
import {Gender, WithDefaultActions} from "../../../types";
import {AudienceFilterResult} from "../types";

export interface GetStatsSlowParams extends WithDefaultActions {
    group: GroupObject,
    search: {
        age_min?: number,
        age_max?: number,
        gender?: Gender,
        country_id?: number,
        city_id?: number,
    },
    access_token: string,
}

const GetStatsSlow = function* (params: GetStatsSlowParams): Generator<any, AudienceFilterResult, any> {
    if (!params.group.members_count) {
        throw new Error('Не удалось получить подписчиков группы: ' + params.group.name);
    }
    const users_to_get: number = Math.min(params.group.members_count, 10000);
    const users: UserObject[] = yield GroupsGetMembers<UserObject[]> ({
        group_id: params.group.id,
        members_count: users_to_get,
        fields: 'sex,bdate,city,country',
        access_token: params.access_token,
    });

    let users_valid_count: number = 0;
    let users_all_count: number = 0;

    const search_params = params.search;

    users.forEach(user => {
        if (user.deactivated) {
            return;
        }
        if (search_params.gender) {
            if (!user.sex) {
                return;
            }
        }
        const user_age = getUserAge(user);
        if (search_params.age_min || search_params.age_max) {
            if (!user_age) {
                return;
            }
        }

        if (search_params.country_id) {
            if (!user.country) {
                return;
            }
        }

        if (search_params.city_id) {
            if (!user.city) {
                return;
            }
        }

        users_all_count++;

        if (search_params.gender && search_params.gender !== user.sex) {
            return;
        }
        if (search_params.age_min && user_age && user_age < search_params.age_min) {
            return;
        }
        if (search_params.age_max && user_age && user_age > search_params.age_max) {
            return;
        }
        if (search_params.country_id && search_params.country_id !== user.country?.id) {
            return;
        }
        if (search_params.city_id && search_params.city_id !== user.city?.id) {
            return;
        }

        users_valid_count++;
    });

    let percent: number = 0;
    if (users_all_count > 0) {
        percent = (users_valid_count / users_all_count) * 100
    }
    const found: number = Math.round(params.group.members_count * (percent / 100));

    const result: AudienceFilterResult = {
        group: params.group,
        found: found,
        ratio: (found / params.group.members_count) * 100,
    };
    return result;
};

export default GetStatsSlow;
