import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Button} from "primereact/button";
import {FileUpload} from "primereact/fileupload";
import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import download_url from "../../../../helpers/download_url";
import {get_lines_from_multiple_files} from "../../../../helpers/get_lines_from_file";
import {showAlert} from "../../../../store/app/actions";
import {TaskStatus} from "../../../../store/parsers/types";
import SaveButton from "../../../ui/buttons/SaveButton";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import * as global_types from "../../../../store/parsers/types";
import * as actions from "../../../../store/parsers/filter-base/intersections/actions";
import BasicFileUpload from "../../../ui/upload/BasicFileUpload";


const Intersections = (props: AppPanelProps) => {

    const dispatch = useDispatch();
    const state = useSelector((state: global_types.RootState) => state.intersections);

    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const ref = useRef<FileUpload>(null);

    function onUploadFile(lines: string[], action: ActionCreatorWithPayload<string[]>) {
        dispatch(action(lines));
        dispatch(showAlert({text: 'Загружено: ' + lines.length, header: 'Загружено'}));
    }

    function onUploadUnion(event: {files: any}, action: ActionCreatorWithPayload<string[]>) {
        let file_list: FileList|null = null;
        if (event.files) {
            file_list = event.files as unknown as FileList;
        }
        if (file_list) {
            get_lines_from_multiple_files(file_list, (unique: string[]) => {
                dispatch(action(unique));
                dispatch(showAlert({text: 'Готово. Результат: ' + unique.length, header: 'Загружено'}));
                if (ref && ref.current) {
                    ref.current.clear();
                }
            })
        }
    }

    const save = (data: string[]) => {
        setDownloadUrl(download_url(data.join('\r\n'), 'text/plain'));
        setTimeout(() => {
            if (saveRef && saveRef.current) {
                saveRef.current.click()
            }
        }, 1000);
    };

    return (
        <AppPanel id={props.id} title={props.title}>

            <ParsingHelp
                description="Вы можете по разному комбинировать собранные базы."
                url="http://blog.xn--90aha1bhc1b.xn--p1ai/peresecheniye_auditorii"
                title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <InputContainer label="Убрать пользователей-2 из пользователей-1">
                    <BasicFileUpload id="sub1" chooseLabel={'Загрузить базу 1'} onUpload={(e) => onUploadFile(e, actions.subtract_set_data_1)} />
                    <BasicFileUpload id="sub2" chooseLabel={'Загрузить базу 2'} onUpload={(e) => onUploadFile(e, actions.subtract_set_data_2)} />
                    <Button label="Старт" className="p-field"
                            disabled={state.settings.subtract.data1.length === 0 || state.settings.subtract.data2.length === 0}
                            onClick={() => dispatch(actions.subtract_start())}
                    />
                    <SaveButton label="Сохранить" className="p-field"
                                disabled={state.result.subtract.length === 0}
                                onClick={() => save(state.result.subtract)}/>
                </InputContainer>

                <InputContainer label="Оставить пересекающихся">
                    <BasicFileUpload id="sub1" chooseLabel={'Загрузить базу 1'} onUpload={(e) => onUploadFile(e, actions.intersect_set_data_1)} />
                    <BasicFileUpload id="sub2" chooseLabel={'Загрузить базу 2'} onUpload={(e) => onUploadFile(e, actions.intersect_set_data_2)} />
                    <Button label="Старт" className="p-field"
                            disabled={state.settings.intersect.data1.length === 0 || state.settings.intersect.data2.length === 0}
                            onClick={() => dispatch(actions.intersect_start())}
                    />
                    <SaveButton label="Сохранить" className="p-field"
                                disabled={state.result.intersect.length === 0}
                                onClick={() => save(state.result.intersect)}/>
                </InputContainer>

                <InputContainer label="Оставить не пересекающихся">
                    <BasicFileUpload id="sub1" chooseLabel={'Загрузить базу 1'} onUpload={(e) => onUploadFile(e, actions.unique_set_data_1)} />
                    <BasicFileUpload id="sub2" chooseLabel={'Загрузить базу 2'} onUpload={(e) => onUploadFile(e, actions.unique_set_data_2)} />
                    <Button label="Старт" className="p-field"
                            disabled={state.settings.unique.data1.length === 0 || state.settings.unique.data2.length === 0}
                            onClick={() => dispatch(actions.unique_start())}
                    />
                    <SaveButton label="Сохранить" className="p-field"
                                disabled={state.result.unique.length === 0}
                                onClick={() => save(state.result.unique)}
                    />
                </InputContainer>

                <InputContainer label="Объединить файлы">
                    <div className="p-field tw-w-full sm:tw-w-auto">
                        <FileUpload ref={ref} chooseLabel="Загрузить файлы" multiple mode="basic" accept="*.txt" auto
                                    customUpload={true}
                                    uploadHandler={(e) => onUploadUnion(e, actions.union_set_result)}
                        />
                    </div>
                    <SaveButton label="Сохранить" className="p-field"
                                disabled={state.result.union.length === 0}
                                onClick={() => save(state.result.union)}
                    />
                    <a download={'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Скачать</a>
                </InputContainer>

            </ParsingSettingsContainer>
        </AppPanel>
    );
};

export default Intersections;
