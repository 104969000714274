import {WithDefaultActions} from "../../store/parsers/types";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import SendMethodsWithAutoRetry from "../helpers/SendMethodsWithAutoRetry";
import {IVkMethod} from "../IVkMethod";
import {FriendsGetParams} from "../methods/FriendsGetParams";
import * as methods from "../methods/VkMethods";
import {UserObject} from "../objects/UserObject";
import {ProcessMethodsParams} from "../types";
import _chunk from "lodash/chunk";

export interface GetFriendsAllParams extends WithDefaultActions {
    user_id: number,
    fields?: string,
    access_token: string,
}

const GetFriendsAll = function*<TResponse extends number[]|UserObject[]> (params: GetFriendsAllParams): Generator<any, TResponse, any> {
    const count: number = yield getCount(params.user_id, params.access_token);
    const executes:Array<IVkMethod> = prepare(params.user_id, count, params.fields);
    const executes_result: TResponse = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    }, params.fields !== undefined);
    return executes_result;
};

function* getCount(user_id: number, access_token: string): Generator<any, number, any> {

    let params: FriendsGetParams = {
        user_id: user_id,
        count: 1,
        offset: 0,
    };
    const method: IVkMethod = methods.friendsGet(params);

    let count: number = 0;

    yield SendMethodsWithAutoRetry<{count: number}>({
        methods: [method],
        access_token: access_token,
        onResponse: response => count = response.count,
        onError: () => {},
    });

    return count;
}

function prepare(user_id: number, count: number, fields?: string): IVkMethod[] {
    const step: number = 2500;
    const max_requests_per_execute: number = 10;
    const requests: string[] = [];
    for (let offset: number = 0; offset < count; offset += step) {
        let params: FriendsGetParams = {
            user_id: user_id,
            count: step,
            offset: offset,
        };
        if (fields) {
            params.fields = fields;
        }
        const method: IVkMethod = methods.friendsGet(params);
        requests.push(`API.${method.method_name}(${JSON.stringify(method.params)})`);
    }

    const requests_chunks: string[][] = _chunk(requests, max_requests_per_execute);
    return requests_chunks.map((requests_chunk: string[]) => {
        const code:string = `return [${requests_chunk.join(",")}];`;
        return methods.execute({code: code});
    });
}

const send = function* (params: ProcessMethodsParams, has_fields: boolean): Generator<any, number[]|UserObject[], any> {
    interface IResponse {
        count: number,
        items: number[]|UserObject[],
    }

    const isUserObjects = (items: number[]|UserObject[]): items is UserObject[] => {
        return has_fields;
    };

    const result_ids: Set<number> = new Set<number>();
    const result_objects: Map<number, UserObject> = new Map<number, UserObject>();

    const handler = (response: IResponse[]) => {
        response.forEach((response_item: IResponse) => {
            if (isUserObjects(response_item.items)) {
                response_item.items.forEach(item => {
                    result_objects.set(item.id, item);
                })
            }
            else {
                response_item.items.forEach((user_id) => {
                    result_ids.add(user_id);
                })
            }
        });
    };

    yield SendMethodsDefault<IResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Получаем друзей',
        chunk_size: 6
    });

    if (has_fields) {
        return Array.from(result_objects.values());
    }
    return Array.from(result_ids.values());
};

export default GetFriendsAll;
