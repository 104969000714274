import {AttachPoll, PostAttach} from "./PostAttach";

export interface Post {
    id: number,
    owner_id: number,
    from_id: number,
    created_by?: number,
    date: number,
    text: string,
    reply_owner_id?: number,
    reply_post_id?: number,
    comments: {
        count: number,
    },
    likes: {
        count: number,
    },
    reposts: {
        count: number,
    },
    views?: {
        count: number,
    },
    post_type: "post"|"copy"|"reply"|"postpone"|"suggest"|"post_ads",
    attachments?: Array<PostAttach>,
    signer_id?: number,
    copy_history?: Post[],
    is_pinned?: 1,
    marked_as_ads: 0|1,
}

export function post_has_button(post: Post): boolean {
    if (!post.attachments) {
        return false;
    }
    for (let attach of post.attachments) {
        if ((attach as any).button) {
            return true;
        }
        if ((attach as any).link) {
            return true;
        }
    }
    return false;
}

export function post_has_pretty_card(post: Post) {
    if (!post.attachments) {
        return false;
    }
    for (let attach of post.attachments) {
        if ((attach as any).pretty_cards) {
            return true;
        }
    }
    return false;
}

export function getPoll(post: Post): AttachPoll|undefined {
    if (!post.attachments || post.attachments.length === 0) {
        return undefined;
    }
    for (const attach of post.attachments) {
        if (attach.type === "poll") {
            return attach;
        }
    }
    return undefined;
}

export interface OwnerPosts {
    owner_id: number,
    posts: Post[],
}
