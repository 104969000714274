import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {Post} from "../../../../vkapi/objects/Post";
import {StateWithErrors, StateWithProgress, TaskProgress} from "../../types";

export enum PostsType {
    ANY = "any",
    REGULAR = "regular",
    PROMO = "promo",
}

export interface StatsPostsCommunityResult {
    post: Post,
    group: GroupObject,
    is_promo: boolean,
    short_link_code?: string,
    short_link_views: number,
}

interface State {
    settings: {
        source: string,
        post_type: PostsType,
        only_with_vk_cc: boolean,
        start_date: Date|null,
        end_date: Date|null,
    },
    sub_progress: TaskProgress,
    result: StatsPostsCommunityResult[],
}

export interface StatsPostsCommunityState extends State, StateWithErrors, StateWithProgress {}
