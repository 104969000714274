import {createSelector} from "reselect";
import {RootState} from "../../../types";
import {LastPostFilterResult} from "../types";

const select_items = (state: RootState) => state.communitiesFilter.lastPost.result;
const select_start_date = (state: RootState) => state.communitiesFilter.lastPost.settings.start_date;
const select_end_date = (state: RootState) => state.communitiesFilter.lastPost.settings.end_date;

const selectFiltered = createSelector(
    [select_items, select_start_date, select_end_date],
    (items: LastPostFilterResult[], date_start: Date|null, date_end: Date|null) => {
        if (!date_start && !date_end) {
            return items;
        }

        return items.filter((item: LastPostFilterResult) => {
            if (date_start && (item.last_post_date ?? 0) * 1000 < date_start.getTime()) {
                return false;
            }
            if (date_end && (item.last_post_date ?? 0) * 1000 > date_end.getTime()) {
                return false;
            }
            return true;
        });
    }
);

export default selectFiltered;
