import React, {useCallback, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {Button} from "primereact/button";
import download_txt from "../../../../helpers/DownloadTxt";

import {RootState, TaskProgress, TaskStatus} from "../../../../store/parsers/types";
import {FriendsAndFollowersSearchType} from "../../../../store/parsers/user-profiles/friends-and-followers/types";
import * as actions from "../../../../store/parsers/user-profiles/friends-and-followers/actions";
import BasicNumbersInput from "../../../ui/inputs/BasicNumbersInput";
import BasicRadioButton, {RadioButtonChangeEvent} from "../../../ui/inputs/BasicRadioButton";
import TextAreaWithButton from "../../../ui/inputs/TextAreaWithButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import ParsingName from "../../../ui/inputs/ParsingName";
import SaveButton from "../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../ui/info/AppProgressBar";
import ParsingHelp from "../../../ui/info/ParsingHelp";

const FriendsAndFollowers = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.friendsAndFollowers);
    const progress: TaskProgress = useSelector((state: RootState) => state.friendsAndFollowers.progress);

    const [parsingName, setParsingName] = useState<string>('');

    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const onTypeChange = useCallback((e: RadioButtonChangeEvent) => dispatch(actions.setType(e.value)), [dispatch]);

    const save = () => {
        download_txt<number[]>({
            data: state.result,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: number[]) => data.map(item => item.toString()),
        });
    };

    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Соберите людей, которые состоят в друзьях или подписаны на выбранных людей."
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/druzia_i_podpischiki"
                    title={props.title}
            />

            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>

                <TextAreaWithButton label={'Ссылки на профили (по одной на строке)'}
                                    placeholder={"https://vk.com/id23818"}
                                    value={state.settings.source}
                                    onChange={(e) => dispatch(actions.setSource(e))}
                />

                <InputContainer label="Кого собирать">
                    <BasicRadioButton name="friendsAndFollowersSearchType" label="всех"
                                      value={FriendsAndFollowersSearchType.ANY}
                                      onChange={onTypeChange}
                                      checked={state.settings.type === FriendsAndFollowersSearchType.ANY}
                    />
                    <BasicRadioButton name="friendsAndFollowersSearchType" label="только друзей"
                                      value={FriendsAndFollowersSearchType.FRIENDS}
                                      onChange={onTypeChange}
                                      checked={state.settings.type === FriendsAndFollowersSearchType.FRIENDS}
                    />
                    <BasicRadioButton name="friendsAndFollowersSearchType" label="только подписчиков"
                                      value={FriendsAndFollowersSearchType.FOLLOWERS}
                                      onChange={onTypeChange}
                                      checked={state.settings.type === FriendsAndFollowersSearchType.FOLLOWERS}
                    />
                </InputContainer>

                <InputContainer label="У какого количества людей из списка должен быть в друзьях или подписчиках найденный пользователь?">
                    <BasicNumbersInput value={state.settings.min_count}
                                       onNumberChange={(e) => dispatch(actions.setMinCount(e.value))}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)}/>

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())}  className="p-field" />
                    {state.result.length > 0 &&
                        <SaveButton label={'Сохранить'} onClick={save} />
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Скачать ссылки</a>
                </ButtonsContainer>
            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

        </AppPanel>
    );
};

export default FriendsAndFollowers;
