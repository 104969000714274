import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {StateWithErrors, StateWithProgress, TaskProgress} from "../../types";

export enum InstagramSearchType {
    BY_GROUPS = "BY_GROUPS",
    BY_USERS = "BY_USERS",
}

export enum SiteType {
    INSTAGRAM = "INSTAGRAM",
    FACEBOOK = "FACEBOOK",
    TWITTER = "TWITTER",
}

export interface InstagramResult {
    user_ids: number[],
    site_urls: string[],
}

interface State {
    settings: {
        search_type: InstagramSearchType,
        source_groups: string,
        source_users: string,
        site_type: SiteType,
    },
    skipped: GroupObject[],
    sub_progress: TaskProgress,
    result: InstagramResult,
}

export interface InstagramState extends State, StateWithErrors, StateWithProgress {}
