import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import download_txt from "../../../../../helpers/DownloadTxt";
import {audience as actions} from "../../../../../store/parsers/communities/communities-filter/actions";
import {
    AudienceFilterResult,
    FilterSearchType
} from "../../../../../store/parsers/communities/communities-filter/types";
import {DropdownValue, Gender, RootState, TaskProgress, TaskStatus} from "../../../../../store/parsers/types";
import useCitySingle from "../../../../hooks/CitySingleHook";
import useCountry from "../../../../hooks/CountryHook";
import useMinMax from "../../../../hooks/MinMaxHook";
import SaveButton from "../../../../ui/buttons/SaveButton";
import ButtonsContainer from "../../../../ui/containers/ButtonsContainer";
import InputContainer from "../../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../../ui/containers/ParsingSettingsContainer";
import AppProgressBar from "../../../../ui/info/AppProgressBar";
import BasicRadioButton from "../../../../ui/inputs/BasicRadioButton";
import MinMaxInput from "../../../../ui/inputs/MinMaxInput";
import ParsingName from "../../../../ui/inputs/ParsingName";
import TextAreaWithButton from "../../../../ui/inputs/TextAreaWithButton";
import Notice from "../../../../ui/messages/Notice";

const FilterByAudience = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.communitiesFilter.audience);
    const progress: TaskProgress = useSelector((state: RootState) => state.communitiesFilter.audience.progress);

    const [parsingName, setParsingName] = useState<string>('');
    const saveRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState<string>('');

    const [selection, setSelection] = useState<AudienceFilterResult[]>([]);
    const result = useSelector((state: RootState) => state.communitiesFilter.audience.result);
    useEffect(() => {
        setSelection(result);
    }, [result]);

    const [onAgeMinChange, onAgeMaxChange] = useMinMax(actions.setAgeMin, actions.setAgeMax);
    const CountryComponent = useCountry(state.settings.selected_country, actions.setSelectedCountry);
    const CityComponent = useCitySingle(state.settings.selected_country, state.settings.selected_city, actions.setSelectedCity);

    const genderOptions: DropdownValue<Gender>[] = [
        {label: "любой", value: Gender.ANY},
        {label: "мужской", value: Gender.MALE},
        {label: "женский", value: Gender.FEMALE},
    ];

    const save = () => {
        download_txt<AudienceFilterResult[]>({
            data: selection,
            ref: saveRef,
            setDownloadUrl: setDownloadUrl,
            mapper: (data: AudienceFilterResult[]) => data.map(item => `https://vk.com/${item.group.screen_name}`),
        });
    };

    return (
        <div>
            <ParsingSettingsContainer showOverlay={state.status === TaskStatus.RUNNING}>
                <TextAreaWithButton label="Список групп (по одной ссылке на строке)"
                                    value={state.settings.source}
                                    onChange={(value) => dispatch(actions.setSource(value))}
                />
                <MinMaxInput label="Возраст"
                             min={state.settings.age_min} onMinChange={onAgeMinChange}
                             max={state.settings.age_max} onMaxChange={onAgeMaxChange}
                />
                <InputContainer label="Пол">
                    <Dropdown options={genderOptions} value={state.settings.gender}
                              onChange={(e) => dispatch(actions.setGender(e.value))}
                              className="p-field tw-w-full sm:tw-flex-1"
                    />
                </InputContainer>

                {CountryComponent}

                {CityComponent}

                <InputContainer label="Поиск" bottom={<Notice text={'Быстрый поиск имеет ограничение на количество запросов в течениие часа. Он хорошо подходит, когда нужно быстро проанализировать небольшое количество групп (до 100).'}/>}>
                    <BasicRadioButton name="communitiesFilterAudienceSearchType" label="быстрый"
                                      value={FilterSearchType.QUICK}
                                      onChange={(e) => dispatch(actions.setSearchType(e.value))}
                                      checked={state.settings.search_type === FilterSearchType.QUICK}
                    />
                    <BasicRadioButton name="communitiesFilterAudienceSearchType" label="обычный"
                                      value={FilterSearchType.SLOW}
                                      onChange={(e) => dispatch(actions.setSearchType(e.value))}
                                      checked={state.settings.search_type === FilterSearchType.SLOW}
                    />
                </InputContainer>

                <ParsingName value={parsingName} onChange={e => setParsingName(e.currentTarget.value)} />

                <ButtonsContainer>
                    <Button label="Запустить" onClick={() => dispatch(actions.start())} className="p-field"/>
                    {state.result.length > 0 &&
                    <>
                        <SaveButton label="Сохранить" onClick={() => save()} />
                    </>
                    }
                    <a download={parsingName.trim() !== '' ? parsingName.trim() + '.txt' : 'result.txt'} ref={saveRef} href={downloadUrl} style={{display: 'none'}}>Скачать ссылки</a>
                </ButtonsContainer>

            </ParsingSettingsContainer>

            <AppProgressBar className="tw-mt-4" current={progress.current} total={progress.total} message={progress.message}/>

            {state.status !== TaskStatus.RUNNING && result.length > 0 &&
                <div className="tw-mt-4">
                    <DataTable value={state.result} autoLayout={true} rowHover={true} paginator={true} rows={100} selection={selection} onSelectionChange={(e) => setSelection(e.value)}>
                        <Column selectionMode="multiple" style={{width:'20px'}}/>
                        <Column header="Группа" field="group.id" body={(item: AudienceFilterResult, row: any) => <div>{row.rowIndex + 1}. <a href={`https://vk.com/${item.group.screen_name}`} target="_blank" rel="noopener noreferrer">{item.group.name}</a></div>} sortable />
                        <Column header="Всего"  field="group.members_count" sortable/>
                        <Column header="Целевые" field="found" sortable />
                        <Column header="Соотношение" field="ratio" body={(item: AudienceFilterResult) => item.ratio.toFixed(2) + '%'} sortable />
                    </DataTable>
                </div>
            }
        </div>

    );
};

export default FilterByAudience;
