import React, {ReactNode} from "react";
import { v4 as uuidv4 } from 'uuid';

import InputContainer from "../containers/InputContainer";
import BasicNumbersInput, {NumberChangeEvent} from "./BasicNumbersInput";

export interface MinMaxInputProps extends React.HTMLAttributes<HTMLDivElement> {
    id?: string,
    label: string,
    min: number,
    onMinChange: (e: NumberChangeEvent) => void,
    max: number,
    onMaxChange: (e: NumberChangeEvent) => void,
    notice?: string,
    top?: ReactNode,
    bottom?: ReactNode,
    disabled?: boolean,
}

const MinMaxInput = (props: MinMaxInputProps) => {
    return (
        <InputContainer className={props.className} label={props.label} notice={props.notice} top={props.top} bottom={props.bottom}>
            <div className="p-field tw-w-full md:tw-flex-1">
                <BasicNumbersInput disabled={props.disabled} value={props.min > 0 ? props.min : undefined} id={(props.id ? props.id : uuidv4()) + '-min-input'} onNumberChange={props.onMinChange} placeholder="минимум" className="tw-w-full" />
            </div>
            <div className="p-field tw-w-full md:tw-flex-1">
                <BasicNumbersInput disabled={props.disabled} value={props.max > 0 ? props.max : undefined} id={(props.id ? props.id : uuidv4()) + '-max-input'} onNumberChange={props.onMaxChange} placeholder="максимум" className="tw-w-full" />
            </div>
        </InputContainer>
    );
};

export default MinMaxInput;
