import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import {DataSourceType} from "../../types";
import * as types from "./action-types";
import {BaseAnalysisResult} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setDatasourceType = createAction(types.SET_DATASOURCE_TYPE, withPayloadType<DataSourceType.URL|DataSourceType.FILE>());
export const setDatasourceGroupUrl = createAction(types.SET_DATASOURCE_URL, withPayloadType<string>());
export const setDatasourceUserIds = createAction(types.SET_DATASOURCE_FILE, withPayloadType<string>());
export const setHasResult = createAction(types.SET_HAS_RESULT, withPayloadType<boolean>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<BaseAnalysisResult>(types.NAMESPACE);
