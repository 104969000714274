export const NAMESPACE = 'STATUS_LOCATOR/';

export const SET_SEARCH_TYPE = NAMESPACE + 'SET_SEARCH_TYPE';
export const SET_SOURCE_GROUPS = NAMESPACE + 'SET_SOURCE_GROUPS';
export const SET_SOURCE_USERS = NAMESPACE + 'SET_SOURCE_USERS';
export const SET_WORDS_PLUS = NAMESPACE + 'SET_WORDS_PLUS';
export const SET_WORDS_MINUS = NAMESPACE + 'SET_WORDS_MINUS';
export const SET_STRICT_MODE = NAMESPACE + 'SET_STRICT_MODE';

export const SET_SKIPPED = NAMESPACE + 'SET_SKIPPED';
export const SET_SUBPROGRESS = NAMESPACE + 'SET_SUBPROGRESS';
