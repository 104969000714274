import {isLeft} from "fp-ts/es6/Either";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {City, RootState} from "../../../store/parsers/types";
import {City as VkCity} from "../../../vkapi/objects/City";
import {IVkMethod} from "../../../vkapi/IVkMethod";
import {databaseGetCities} from "../../../vkapi/methods/VkMethods";
import api from "../../../vkapi/VkApi";
import BasicTextInput from "../inputs/BasicTextInput";
import GroupLabel from "../labels/GroupLabel";
import { v4 as uuidv4 } from 'uuid';

export interface CitiesDialogProps {
    country_id?: number,
    selection: City[],
    setSelection: (cities: City[]) => void,
    visible: boolean,
    onHide: () => void,
}

const CitiesDialog = (props: CitiesDialogProps) => {
    
    const [items, setItems] = useState<City[]>([]);
    const [query, setQuery] = useState<string>('');

    const access_token: string|undefined = useSelector((state: RootState) => state.app.access_token);

    useEffect(() => {
        const getCities = async () => {
            if (!access_token || !props.country_id) {
                return;
            }
            const method: IVkMethod = databaseGetCities({
                country_id: props.country_id,
                need_all: 0,
                offset: 0,
                count: 100,
            });
            const result = await api(method, access_token);
            if (isLeft(result)) {
                return;
            }
            type TResponse = {
                count: number,
                items: VkCity[],
            }
            const response: TResponse = result.right.response as unknown as TResponse;
            setItems(response.items);
        };
        if (props.visible && props.country_id) {
            getCities();
        }
    },[access_token, props.visible, props.country_id]);

    const search = async () => {
        if (!access_token || !props.country_id) {
            return;
        }
        const method: IVkMethod = databaseGetCities({
            country_id: props.country_id,
            q: query,
            need_all: 1,
            offset: 0,
            count: 100,
        });
        const result = await api(method, access_token);
        if (isLeft(result)) {
            return;
        }
        type TResponse = {
            count: number,
            items: VkCity[],
        }
        const response: TResponse = result.right.response as unknown as TResponse;
        setItems(response.items);
    };

    const onSelectionChange = (e: {originalEvent: Event, value: any}) => {
        props.setSelection(e.value);
    };

    
    return (
        <Dialog visible={props.visible} onHide={props.onHide}
                className="tw-w-4/5 md:tw-w-1/3"
                header={<GroupLabel label="Выберите город" className="tw-font-medium tw-mb-1" />}
        >
            <div className="tw-flex tw-gap-x-4">
                <BasicTextInput id={uuidv4()} value={query} onChange={(e) => setQuery(e.currentTarget.value)} placeholder={'Поиск города'}/>
                <Button className="p-field" label="Найти" onClick={search}/>
            </div>
            <DataTable
                value={items}
                autoLayout={true}
                selection={props.selection}
                selectionMode={'multiple'}
                onSelectionChange={onSelectionChange}
            >
                <Column selectionMode="multiple" style={{width:'20px'}}/>
                <Column header={'Город'} className="tw-w-full" field="title" />
            </DataTable>
        </Dialog>
    )
};

export default CitiesDialog;
