import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {GroupObject} from "../../../../../vkapi/objects/GroupObject";
import {OwnerPosts, Post} from "../../../../../vkapi/objects/Post";
import GetPosts from "../../../../../vkapi/tasks/GetPosts";
import {VkApiError} from "../../../../../vkapi/VkApiError";
import {date_in_range, is_post_before_start_date} from "../../../../helpers/DateChecker";
import {TaskProgress, WithDefaultActions} from "../../../types";
import {ActivityFilterResult} from "../types";

export interface GetActivityStatsQuickParams extends WithDefaultActions {
    group: GroupObject,
    start_date: Date|null,
    end_date: Date|null,
    access_token: string,
}

const GetActivityStatsQuick = function* (params: GetActivityStatsQuickParams):  Generator<any, ActivityFilterResult, any> {
    const group: GroupObject = params.group;
    const access_token: string = params.access_token;
    const posts: OwnerPosts = yield getPosts(
        group, access_token, params.start_date, params.end_date, params.progress_action, params.add_api_errors_action
    );
    const result: ActivityFilterResult = {
        group: group,
        posts_count: posts.posts.length,
        likes: 0,
        reposts: 0,
        comments: 0,
        activity: 0,
        engagement_rate: 0,
    };
    posts.posts.forEach(post => {
        result.likes += post.likes.count;
        result.reposts += post.reposts.count;
        result.comments += post.comments.count;
    });

    const total_activities_count: number = result.likes + result.reposts + result.comments;
    if (posts.posts.length > 0) {
        result.activity = total_activities_count / posts.posts.length;
    }
    if (group.members_count) {
        result.engagement_rate = (total_activities_count / group.members_count) * 100;
    }
    return result;
};

function* getPosts(
    group: GroupObject,
    access_token: string,
    start_date: Date|null,
    end_date: Date|null,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>

): Generator<any, OwnerPosts, any> {
    const is_date_in_range = date_in_range(start_date, end_date);
    const is_posts_start_date_reached = is_post_before_start_date(start_date);

    const posts: OwnerPosts = yield GetPosts({
        owner_id: group.id * -1,
        is_skip: (post: Post) => !is_date_in_range(post.date),
        is_stop: (post: Post) => is_posts_start_date_reached(post),
        access_token: access_token,
        progress_action: progress_action,
        add_api_errors_action: add_api_errors_action,
    });
    return posts;
}

export default GetActivityStatsQuick;
