let production: boolean = false;
if (process.env.NODE_ENV) {
    if (process.env.NODE_ENV === 'production') {
        production = true;
    }
}

export default function isProd(): boolean
{
    return production;
}
