import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {GroupObject} from "../../../../vkapi/objects/GroupObject";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {convert_urls_to_id, convert_urls_to_screen_names} from "../../../../vkapi/tasks/ConverUrlToId";
import GetGroups from "../../../../vkapi/tasks/GetGroups";
import GetUsers from "../../../../vkapi/tasks/GetUsers";
import GroupsGetMembers from "../../../../vkapi/tasks/GroupsGetMembers";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import {RootState, TaskStatus} from "../../types";
import * as actions from "./actions";
import {InstagramSearchType, InstagramState, SiteType} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: InstagramState = yield select((state: RootState) => state.instagram);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const user_ids: Set<number> = new Set<number>();
    const site_urls: string[] = [];

    const skipped: GroupObject[] = [];

    const user_handler = (user: UserObject) => {
        if (state.settings.site_type === SiteType.INSTAGRAM && user.instagram) {
            site_urls.push(`https://instagram.com/${user.instagram}`);
            user_ids.add(user.id);
        }
        if (state.settings.site_type === SiteType.FACEBOOK && user.facebook) {
            site_urls.push(`http://facebook.com/profile.php?id=${user.facebook}`);
            user_ids.add(user.id);
        }
        if (state.settings.site_type === SiteType.TWITTER && user.twitter) {
            site_urls.push(`https://twitter.com/${user.twitter}`);
            user_ids.add(user.id);
        }
    };

    if (state.settings.search_type === InstagramSearchType.BY_GROUPS) {
        const group_urls: string[] = convert_urls_to_id(state.settings.source_groups.split('\n'));
        const groups: GroupObject[] = yield GetGroups({
            access_token: access_token,
            fields: "members_count",
            group_ids: group_urls,
            add_api_errors_action: actions.addApiError,
            progress_action: actions.setProgress,
        });

        const progress = ProgressGenerator({text: 'Обрабатываем группы', total: groups.length, action: actions.setProgress});

        for (const group of groups) {
            yield progress.next().value;
            if (group.deactivated || !group.members_count || group.is_closed) {
                skipped.push(group);
                continue;
            }
            let users: UserObject[] = [];
            try {
                users = yield GroupsGetMembers<UserObject[]> ({
                    group_id: group.id,
                    members_count: group.members_count,
                    fields: 'connections',
                    access_token: access_token,
                    progress_action: actions.setSubProgress
                });
            }
            catch (e) {
                skipped.push(group);
                continue;
            }
            if (users.length === 0) {
                skipped.push(group);
                continue;
            }
            users.forEach(user_handler);
        }
    }

    if (state.settings.search_type === InstagramSearchType.BY_USERS) {
        const users: Map<number, UserObject> = yield GetUsers({
            user_ids: convert_urls_to_screen_names(state.settings.source_users.split("\n")),
            fields: "connections",
            access_token: access_token,
            progress_action: actions.setProgress,
            add_api_errors_action: actions.addApiError,
        });
        users.forEach(user_handler);
    }

    yield put(actions.setResult({
        user_ids: Array.from(user_ids.values()),
        site_urls: site_urls,
    }));
    yield put(actions.setProgress({message: `Завершено. Найдено ссылок: ${site_urls.length}. Найдено пользователей: ${user_ids.size}. Пропущенно групп: ${skipped.length}.`, total: 100, current: 100}));
    yield put(actions.setSubProgress({message: ``}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${site_urls.length}. Найдено пользователей: ${user_ids.size}. Пропущенно групп: ${skipped.length}.`));
    yield put(actions.setCompleted());
}

function* validate(state: InstagramState) {
    if (state.settings.search_type === InstagramSearchType.BY_GROUPS && state.settings.source_groups.trim() === '') {
        yield put(showAlertError('Вы не указали список групп'));
        yield put(actions.stop());
        return;
    }
    if (state.settings.search_type === InstagramSearchType.BY_USERS && state.settings.source_users.trim() === '') {
        yield put(showAlertError('Вы не указали список пользователей'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
