import * as actions from './actions';
import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {AnalysisType, EventType, ShowType, TargetAudienceLocatorState} from "./types";
import {TargetAudienceGroup} from "./types";
import {DataSourceType, TaskProgress, TaskStatus} from "../../types";
import {VkApiError} from "../../../../vkapi/VkApiError";
import DefaultStateProps from "../../../helpers/DefaultStateProps";

const initialState: TargetAudienceLocatorState = {
    settings: {
        data_source_type: DataSourceType.URL,
        data_source_file: '',
        data_source_url: '',
        truncate_to_count: 1000,
        analysis_type: AnalysisType.ANALISE_ALL,
        members_min: 0,
        members_max: 0,
        top_min: 0,
        top_max: 0,
        selector_filter_members_min: 0,
        selector_filter_members_max: 0,
        selector_filter_words_plus: '',
        selector_filter_words_minus: '',
        selector_filter_community_type_club: true,
        selector_filter_community_type_public: true,
        selector_filter_community_type_event: true,
        selector_filter_event_type: EventType.ALL,
        selector_filter_show_type: ShowType.ALL,
    },
    ...DefaultStateProps<TargetAudienceGroup[]>([])
};

const TargetAudienceLocatorReducer = createReducer(initialState, builder => {
    builder.addCase(actions.setDatasourceType, (state: TargetAudienceLocatorState, action: PayloadAction<DataSourceType.URL|DataSourceType.FILE>) => {
        state.settings.data_source_type = action.payload;
    });
    builder.addCase(actions.setDatasourceGroupUrl, (state: TargetAudienceLocatorState, action: PayloadAction<string>) => {
        state.settings.data_source_url = action.payload;
    });
    builder.addCase(actions.setDatasourceUserIds, (state:TargetAudienceLocatorState, action: PayloadAction<string>) => {
        state.settings.data_source_file = action.payload;
    });
    builder.addCase(actions.setMembersMin, (state: TargetAudienceLocatorState, action: PayloadAction<number>) => {
        state.settings.members_min = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setMembersMax, (state: TargetAudienceLocatorState, action: PayloadAction<number>) => {
        state.settings.members_max = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setTruncateToCount, (state: TargetAudienceLocatorState, action: PayloadAction<number>) => {
        state.settings.truncate_to_count = action.payload;
    });
    builder.addCase(actions.setAnalysisType, (state: TargetAudienceLocatorState, action: PayloadAction<AnalysisType>) => {
        state.settings.analysis_type = action.payload;
    });
    builder.addCase(actions.setTopMin, (state: TargetAudienceLocatorState, action: PayloadAction<number>) => {
        state.settings.top_min = action.payload;
    });
    builder.addCase(actions.setTopMax, (state: TargetAudienceLocatorState, action: PayloadAction<number>) => {
        state.settings.top_max = action.payload;
    });
    builder.addCase(actions.setSelectorFilterMembersMin, (state: TargetAudienceLocatorState, action: PayloadAction<number>) => {
        state.settings.selector_filter_members_min = isNaN(action.payload) ? 0 : action.payload;
    });
    builder.addCase(actions.setSelectorFilterMembersMax, (state: TargetAudienceLocatorState, action: PayloadAction<number>) => {
        state.settings.selector_filter_members_max = isNaN(action.payload) ? 0 :action.payload;
    });
    builder.addCase(actions.setSelectorFilterWordsPlus, (state: TargetAudienceLocatorState, action: PayloadAction<string>) => {
        state.settings.selector_filter_words_plus = action.payload;
    });
    builder.addCase(actions.setSelectorFilterWordsMinus, (state: TargetAudienceLocatorState, action: PayloadAction<string>) => {
        state.settings.selector_filter_words_minus = action.payload;
    });
    builder.addCase(actions.setSelectorFilterCommunityTypePublic, (state: TargetAudienceLocatorState, action: PayloadAction<boolean>) => {
        state.settings.selector_filter_community_type_public = action.payload;
    });
    builder.addCase(actions.setSelectorFilterCommunityTypeClub, (state: TargetAudienceLocatorState, action: PayloadAction<boolean>) => {
        state.settings.selector_filter_community_type_club = action.payload;
    });
    builder.addCase(actions.setSelectorFilterCommunityTypeEvent, (state: TargetAudienceLocatorState, action: PayloadAction<boolean>) => {
        state.settings.selector_filter_community_type_event = action.payload;
    });
    builder.addCase(actions.setSelectorFilterEventType, (state: TargetAudienceLocatorState, action: PayloadAction<EventType>) => {
        state.settings.selector_filter_event_type = action.payload;
    });
    builder.addCase(actions.setSelectorFilterShowType, (state: TargetAudienceLocatorState, action: PayloadAction<ShowType>) => {
        state.settings.selector_filter_show_type = action.payload;
    });
    builder.addCase(actions.setTaskStatus, (state: TargetAudienceLocatorState, action: PayloadAction<TaskStatus>) => {
        state.status = action.payload;
    });
    builder.addCase(actions.setProgress, (state: TargetAudienceLocatorState, action: PayloadAction<TaskProgress>) => {
        state.progress = action.payload;
    });
    builder.addCase(actions.setResult, (state: TargetAudienceLocatorState, action: PayloadAction<TargetAudienceGroup[]>) => {
        state.result = action.payload;
    });
    builder.addCase(actions.addApiError, (state: TargetAudienceLocatorState, action: PayloadAction<VkApiError>) => {
        state.api_errors.push(action.payload);
    });
    builder.addCase(actions.resetApiErrors, (state:TargetAudienceLocatorState) => {
        state.api_errors = [];
    });
});

export default TargetAudienceLocatorReducer;
