import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import React from "react";

export interface ParsingHelpParams {
    title: string,
    description: string,
    url?: string,
}

const ParsingHelp = (props: ParsingHelpParams) => {

    const handleClick = () => {
        window.open(props.url, '_blank');
    };

    return (
        <Panel header={props.title} className="tw-mt-4 tw-shadow-xl">
            <div className="tw-flex tw-flex-wrap tw-gap-x-2">
                <div className="tw-w-full md:tw-flex-1">
                    {props.description}
                </div>
                {props.url &&
                    <div className="tw-mt-4 md:tw-mt-0">
                        <Button label={'Помощь'} icon={'pi pi-question-circle'} onClick={handleClick} className="p-button-secondary"/>
                    </div>
                }
            </div>
        </Panel>
    );
};

export default ParsingHelp;
