import {createSelector} from "reselect";
import {RootState} from "../../../types";
import {PostsAudienceResult} from "../types";

const select_items = (state: RootState) => state.postsAudience.result;
const posts_min = (state: RootState) => state.postsAudience.settings.posts_min;
const posts_max = (state: RootState) => state.postsAudience.settings.posts_max;

const selectFiltered = createSelector(
    [select_items, posts_min, posts_max],
    (items: PostsAudienceResult[], min: number, max: number) => {
        if (min === 0 && max === 0) {
            return items;
        }
        return items.filter(item => {
            return (item.posts >= min || min === 0) && (item.posts <= max || max === 0);
        })
    }
);

export default selectFiltered;
