import {createSelector} from "@reduxjs/toolkit";
import {AudienceAnalysisItem, AudienceAnalysisResult, SelectedResults} from "../types";
import {RootState} from "../../../types";

const select_items = (state: RootState) => state.audienceAnalysis.result;
const selected_category = (state: RootState) => state.audienceAnalysis.selected_category;

const select_selected = (state: RootState) => state.audienceAnalysis.selected_results;

const select_by_category = createSelector(
    [select_items, selected_category],
    (items: AudienceAnalysisResult, category: string): AudienceAnalysisItem[] => {
        if (!items.hasOwnProperty(category)) {
            return [];
        }
        return items[category];
    }
);

const selectFiltered = createSelector(
    [select_by_category],
    items => items
);

export const selectSelected = createSelector(
    [select_selected, selected_category],
    (items: SelectedResults, category) => items[category]
);

export const hasItems = createSelector(
    [select_items],
    (items): boolean => {
        return Object.values(items).filter(item => item.length > 0).length > 0;
    }
);

export const hasSelected = createSelector(
    [select_selected],
    (items): boolean => {
        return Object.values(items).filter(item => item.length > 0).length > 0;
    }
);

export default selectFiltered;
