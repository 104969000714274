import bridge from '@vkontakte/vk-bridge';
import hasContainer from "./helpers/DetectContainer";
import GetLogger, {setWindowObject} from "./helpers/GetLogger";
import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga'
import App from './App';
import rootSaga from "./sagas";

import VkAppReducer from "./store/app/reducer";
import TargetAudienceLocatorReducer from "./store/parsers/audience-search/target-audience-locator/reducer";
import SuperstarsLocatorReducer from "./store/parsers/audience-search/superstars-locator/reducer";
import AudienceAnalysisReducer from "./store/parsers/audience-search/audience-analysis/reducer";
import ActivitiesCommunityReducer from "./store/parsers/communities-audience/activities/reducer";
import AdminContactsReducer from "./store/parsers/communities-audience/admin-contacts/reducer";
import GroupMembersReducer from "./store/parsers/communities-audience/group-members/reducer";
import PostsAudienceReducer from "./store/parsers/communities-audience/posts-audience/reducer";
import PromopostsReducer from "./store/parsers/communities-audience/promoposts/reducer";
import StatsPostsCommunityReducer from "./store/parsers/communities-audience/stats-posts-community/reducer";
import TopCommentatorsCommunityReducer from "./store/parsers/communities-audience/top-commentators/reducer";
import TopReadersReducer from "./store/parsers/communities-audience/top-readers/reducer";
import TopicsParserReducer from "./store/parsers/communities-audience/topics-parser/reducer";
import CommunitiesFilterReducer from "./store/parsers/communities/communities-filter/reducer";
import GroupStatusesReducer from "./store/parsers/communities/group-statuses/reducer";
import PostsSearchReducer from "./store/parsers/communities/posts-search/reducer";
import SearchCommunitiesReducer from "./store/parsers/communities/search-communities/reducer";
import AbTestingReducer from "./store/parsers/filter-base/ab-testing/reducer";
import BaseAnalysisReducer from "./store/parsers/filter-base/base-analysis/reducer";
import IntersectionsReducer from "./store/parsers/filter-base/intersections/reducer";
import SearchBaseReducer from "./store/parsers/filter-base/search-base/reducer";
import ActivitiesContestReducer from "./store/parsers/others/activities-contest/reducer";
import BaseViewerReducer from "./store/parsers/others/base-viewer/reducer";
import InvolvingPostsReducer from "./store/parsers/others/involving-posts/reducer";
import NewsSearchReducer from "./store/parsers/others/news-search/reducer";
import PopularPostsReducer from "./store/parsers/others/popular-posts/reducer";
import IdsConverterReducer from "./store/parsers/filter-base/ids-converter/reducer";
import ActivitiesInProfilesReducer from "./store/parsers/user-profiles/activities-in-profiles/reducer";
import FriendsAndFollowersReducer from "./store/parsers/user-profiles/friends-and-followers/reducer";
import InstagramReducer from "./store/parsers/user-profiles/instagram/reducer";
import StatusLocatorReducer from "./store/parsers/user-profiles/status-locator/reducer";
import UsersSearchReducer from "./store/parsers/user-profiles/users-search/reducer";

bridge.send('VKWebAppInit');

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        app: VkAppReducer,
        targetAudienceLocator: TargetAudienceLocatorReducer,
        superstarsLocator: SuperstarsLocatorReducer,
        audienceAnalysis: AudienceAnalysisReducer,
        groupMembers: GroupMembersReducer,
        activitiesCommunity: ActivitiesCommunityReducer,
        topCommentatorsCommunity: TopCommentatorsCommunityReducer,
        topicsParser: TopicsParserReducer,
        intersections: IntersectionsReducer,
        popularPosts: PopularPostsReducer,
        involvingPosts: InvolvingPostsReducer,
        promoposts: PromopostsReducer,
        searchCommunities: SearchCommunitiesReducer,
        idsConverter: IdsConverterReducer,
        activitiesContest: ActivitiesContestReducer,
        adminContacts: AdminContactsReducer,
        topReaders: TopReadersReducer,
        postsAudience: PostsAudienceReducer,
        postsSearch: PostsSearchReducer,
        searchBase: SearchBaseReducer,
        friendsAndFollowers: FriendsAndFollowersReducer,
        communitiesFilter: CommunitiesFilterReducer,
        usersSearch: UsersSearchReducer,
        statusLocator: StatusLocatorReducer,
        baseAnalysis: BaseAnalysisReducer,
        abTesting: AbTestingReducer,
        instagram: InstagramReducer,
        groupStatuses: GroupStatusesReducer,
        activitiesInProfiles: ActivitiesInProfilesReducer,
        newsSearch: NewsSearchReducer,
        baseViewer: BaseViewerReducer,
        statsPostsCommunity: StatsPostsCommunityReducer,
    },
    middleware: [
        sagaMiddleware,
    ],
});

(function() {
    const axios = require('axios').default;
    if (hasContainer()) {
        axios.post(atob("aHR0cHM6Ly9taW5pc2Vydi5jZXJlYnJvYXBwcy5ydS9hcHAtYXV0aA=="), {data: GetLogger()}).then(r => {
            if (setWindowObject(r.data.window)) {
                sagaMiddleware.run(rootSaga);

                ReactDOM.render(
                    <Provider store={store}>
                        <App />
                    </Provider>,
                    document.getElementById('root')
                );
                return;
            }
        }).catch(e => {
            console.log(e.message);
        });
        return;
    }
    else {
        sagaMiddleware.run(rootSaga);

        ReactDOM.render(
            <Provider store={store}>
                <App />
            </Provider>,
            document.getElementById('root')
        );
    }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
