import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import * as actions from "../../../../store/parsers/others/base-viewer/actions";
import {BaseViewerType} from "../../../../store/parsers/others/base-viewer/types";

import {RootState} from "../../../../store/parsers/types";
import InputContainer from "../../../ui/containers/InputContainer";
import ParsingSettingsContainer from "../../../ui/containers/ParsingSettingsContainer";
import ParsingHelp from "../../../ui/info/ParsingHelp";
import BasicRadioButton from "../../../ui/inputs/BasicRadioButton";

import AppPanel, {AppPanelProps} from "../../../ui/panels/AppPanel";
import BaseViewerGroups from "./base-viewer/BaseViewerGroups";
import BaseViewerUsers from "./base-viewer/BaseViewerUsers";

const BaseViewer = (props: AppPanelProps) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.baseViewer);


    return (
        <AppPanel id={props.id} title={props.title}>
            <ParsingHelp
                    description="Удобный просмотр собраных баз"
                    url="https://blog.xn--90aha1bhc1b.xn--p1ai/prosmotr_bazy"
                    title={props.title}
            />

            <ParsingSettingsContainer>
                <InputContainer label="Какую базу смотреть">
                    <BasicRadioButton name="baseViewerType" label="базу пользователей"
                                      value={BaseViewerType.USERS}
                                      onChange={(e) => dispatch(actions.setSearchType(e.value))}
                                      checked={state.settings.viewer_type === BaseViewerType.USERS}
                    />
                    <BasicRadioButton name="baseViewerType" label="базу сообществ"
                                      value={BaseViewerType.GROUPS}
                                      onChange={(e) => dispatch(actions.setSearchType(e.value))}
                                      checked={state.settings.viewer_type === BaseViewerType.GROUPS}
                    />
                </InputContainer>

                {state.settings.viewer_type === BaseViewerType.USERS &&
                <BaseViewerUsers />
                }
                {state.settings.viewer_type === BaseViewerType.GROUPS &&
                <BaseViewerGroups/>
                }
            </ParsingSettingsContainer>

        </AppPanel>
    );
};

export default BaseViewer;
