import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../types";
import {SuperstarItem} from "../types";

const select_items = (state: RootState) => state.superstarsLocator.result;
const select_min = (state: RootState) => state.superstarsLocator.settings.min_friends;
const select_max = (state: RootState) => state.superstarsLocator.settings.max_friends;

const selectFilteredByMinMax = createSelector(
    [select_items, select_min, select_max],
    (items, min, max) => {
        if (min === 0 && max === 0) {
            return items;
        }
        return items.filter((item) => {
            return (item.counts.total >= min || min === 0) && (item.counts.total <= max || max === 0)
        })
    }
);

const selectFiltered = createSelector(
    [selectFilteredByMinMax],
    (items: SuperstarItem[]) => items
);


export default selectFiltered;
