import {GroupsSearchParams, GroupsSearchType} from "../methods/GroupsSearchParams";
import {IVkMethod} from "../IVkMethod";
import _chunk from "lodash/chunk";
import * as methods from "../methods/VkMethods";
import {ProcessMethodsParams} from "../types";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {GroupObject} from "../objects/GroupObject";
import {WithDefaultActions} from "../../store/parsers/types";

export interface GroupsSearchQuickParams extends WithDefaultActions {
    queries: string[],
    types: GroupsSearchType[],
    access_token: string,
}

const GroupsSearchQuick = function* (params: GroupsSearchQuickParams): Generator<any, GroupObject[], any> {
    const executes: Array<IVkMethod> = prepare(params.queries, params.types);
    const executes_result: GroupObject[] = yield send({
        methods: executes,
        access_token: params.access_token,
        progress_action: params.progress_action,
        add_api_errors_action: params.add_api_errors_action
    });
    return executes_result;
};

function prepare(queries: string[], types: GroupsSearchType[]): IVkMethod[] {
    const methods_per_execute: number = 2;
    const count: number = 1000;

    const executes: IVkMethod[] = [];

    const queries_chunks: string[][] = _chunk(queries, methods_per_execute);
    queries_chunks.forEach((chunk: string[]) => {
        const requests: string[] = [];
        chunk.forEach((query: string) => {
            types.forEach((type: GroupsSearchType) => {
                const params: GroupsSearchParams = {
                    q: query,
                    type: type,
                    count: count,
                    offset: 0,
                };
                const method: IVkMethod = methods.groupsSearch(params);
                requests.push(`{result: API.${method.method_name}(${JSON.stringify(method.params)})}`);
            });
        });
        const code: string = `return [${requests.join(",")}];`;
        executes.push(methods.execute({code: code}));
    });

    return executes;
}

const send = function* (params: ProcessMethodsParams): Generator<any, GroupObject[], any> {
    interface TResponse {
        result: {
            count?: number,
            items: GroupObject[],
        }
    }
    const result: Map<number, GroupObject> = new Map<number, GroupObject>();

    const handler = (response: TResponse[]) => {
        response.forEach((response_item: TResponse) => {
            if (! response_item.result.count) {
                return;
            }
            response_item.result.items.forEach((group: GroupObject) => {
                result.set(group.id, group);
            });
        });
    };


    yield SendMethodsDefault<TResponse[]>({
        methods_params: params,
        response_handler: handler,
        progress_text: 'Ищем группы',
        chunk_size: 6,
    });

    return Array.from(result.values());
};

export default GroupsSearchQuick;
