import {createAction} from "@reduxjs/toolkit";
import * as types from "./action-types";
import {TaskProgress, TaskStatus, TopComment} from "../../types";
import {VkApiError} from "../../../../vkapi/VkApiError";
import {FilterLikes} from "./types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setDataSource = createAction(types.SET_DATASOURCE, withPayloadType<string>());
export const setFilterLikes = createAction(types.SET_FILTER_LIKES, withPayloadType<FilterLikes>());
export const setFilterLikesUsers = createAction(types.SET_FILTER_LIKES_USERS, withPayloadType<string>());
export const setStartDate = createAction(types.SET_START_DATE, withPayloadType<Date|null>());
export const setEndDate = createAction(types.SET_END_DATE, withPayloadType<Date|null>());
export const setIsSkipFirstPost = createAction(types.SET_IS_SKIP_FIRST_POST, withPayloadType<boolean>());
export const setLikesMin = createAction(types.SET_LIKES_MIN, withPayloadType<number>());
export const setLikesMax = createAction(types.SET_LIKES_MAX, withPayloadType<number>());
export const setCommentsMin = createAction(types.SET_COMMENTS_MIN, withPayloadType<number>());
export const setCommentsMax = createAction(types.SET_COMMENTS_MAX, withPayloadType<number>());
export const setCommentsLengthMin = createAction(types.SET_COMMENTS_LENGTH_MIN, withPayloadType<number>());
export const setCommentsLengthMax = createAction(types.SET_COMMENTS_LENGTH_MAX, withPayloadType<number>());
export const setWordsPlus = createAction(types.SET_WORDS_PLUS, withPayloadType<string>());
export const setWordsMinus = createAction(types.SET_WORDS_MINUS, withPayloadType<string>());

export const start = createAction(types.START);
export const stop = createAction(types.STOP);
export const addApiError = createAction(types.ADD_API_ERROR, withPayloadType<VkApiError>());
export const resetApiErrors = createAction(types.RESET_API_ERRORS);

export const setTaskStatus = createAction(types.SET_TASK_STATUS, withPayloadType<TaskStatus>());
export const setTaskError = createAction(types.SET_TASK_ERROR, withPayloadType<string>());
export const setProgress = createAction(types.SET_PROGRESS, withPayloadType<TaskProgress>());
export const setSubProgress = createAction(types.SET_SUBPROGRESS, withPayloadType<TaskProgress>());
export const setResult = createAction(types.SET_RESULT, withPayloadType<TopComment[]>());
