import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import {Post} from "../../../../vkapi/objects/Post";
import {convert_urls_to_posts, PostAddress} from "../../../../vkapi/tasks/ConverUrlToId";
import GetActivitiesIds, {PostActivitiesIds} from "../../../../vkapi/tasks/GetActivitiesIds";
import GetPostsById from "../../../../vkapi/tasks/GetPostsById";
import {showAlert, showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import {ActivityType, RootState, TaskStatus} from "../../types";
import * as actions from "./actions";
import selectFiltered from "./selectors/PostAudienceSelector";
import {PostAudienceSearchType, PostsAudienceResult, PostsAudienceState} from "./types";

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: PostsAudienceState = yield select((state: RootState) => state.postsAudience);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const post_addresses: PostAddress[] = convert_urls_to_posts(state.settings.source.split('\n'));
    const post_ids: string[] = post_addresses.map(item => `${item.owner_id}_${item.post_id}`);

    const posts: Post[] = yield GetPostsById({
        post_ids: post_ids,
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });

    const activities: Map<number, PostActivitiesIds> = yield GetActivitiesIds({
        posts: posts,
        activity_types: new Set<ActivityType>(state.settings.activity_types),
        access_token: access_token,
        progress_action: actions.setProgress,
        add_api_errors_action: actions.addApiError,
    });

    const result_map: Map<number, PostsAudienceResult> = new Map<number, PostsAudienceResult>();

    function getPostAudienceResult(from_id: number): PostsAudienceResult {
        let item: PostsAudienceResult|undefined = result_map.get(from_id);
        if (item) {
            return item;
        }
        item = {
            from_id: from_id,
            posts: 0,
            likes: 0,
            reposts: 0,
            comments: 0,
            comment_likes: 0,
        };
        result_map.set(from_id, item);
        return item;
    }
    activities.forEach((value: PostActivitiesIds) => {
        const post_activity_from_ids: Set<number> = new Set<number>();
        value.likes.forEach(from_id => {
            post_activity_from_ids.add(from_id);
            const post_audience_result: PostsAudienceResult = getPostAudienceResult(from_id);
            post_audience_result.likes++;
        });
        value.reposts.forEach(from_id => {
            post_activity_from_ids.add(from_id);
            const post_audience_result: PostsAudienceResult = getPostAudienceResult(from_id);
            post_audience_result.reposts++;
        });
        value.comments.forEach(from_id => {
            post_activity_from_ids.add(from_id);
            const post_audience_result: PostsAudienceResult = getPostAudienceResult(from_id);
            post_audience_result.comments++;
        });
        value.comments_likes.forEach(from_id => {
            post_activity_from_ids.add(from_id);
            const post_audience_result: PostsAudienceResult = getPostAudienceResult(from_id);
            post_audience_result.comment_likes++;
        });
        post_activity_from_ids.forEach(from_id => {
            const post_audience_result: PostsAudienceResult = getPostAudienceResult(from_id);
            post_audience_result.posts++;
        })
    });

    let result: PostsAudienceResult[] = Array.from(result_map.values());
    if (state.settings.search_type === PostAudienceSearchType.USERS) {
        result = result.filter(item => item.from_id > 0);
    }
    if (state.settings.search_type === PostAudienceSearchType.GROUPS) {
        result = result.filter(item => item.from_id < 0);
    }

    const filtered: PostsAudienceResult[] = yield select(selectFiltered);

    yield put(actions.setResult(result));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result.length}. Отфильтровано ${filtered.length}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${result.length}. Отфильтровано ${filtered.length}`));
    yield put(actions.setCompleted());
}

function* validate(state: PostsAudienceState) {
    if (state.settings.source.trim() === '') {
        yield put(showAlert({text: 'Не указаны ссылки на посты', header: 'Ошибка'}));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
