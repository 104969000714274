import {GroupObject} from "../objects/GroupObject";
import {IVkMethod} from "../IVkMethod";
import * as methods from "../methods/VkMethods";
import _chunk from "lodash/chunk";
import {GroupsGetByIdParams} from "../methods/GroupsGetByIdParams";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {VkApiError} from "../VkApiError";
import SendMethodsDefault from "../helpers/SendMethodsDefault";
import {TaskProgress} from "../../store/parsers/types";

export interface GetGroupsParams {
    group_ids: Array<number|string>,
    fields?: string,
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
}

const GetGroups = function* (params:GetGroupsParams): Generator<any, GroupObject[], any> {
    const access_token = params.access_token;
    const executes:Array<IVkMethod> = prepare(params.group_ids, params.fields);
    const executes_result: GroupObject[] = yield send(executes, access_token, params.progress_action, params.add_api_errors_action);
    return executes_result;
};

function prepare(group_ids: Array<number|string>, fields?: string): Array<IVkMethod> {

    const max_requests_per_execute: number = 25;
    const max_group_ids_per_request: number = 200;

    const group_ids_chunks:Array<number|string>[] = _chunk(group_ids, max_group_ids_per_request);
    const execute_chunks:Array<number|string>[][] = _chunk(group_ids_chunks, max_requests_per_execute);
    const executes:Array<IVkMethod> = execute_chunks.map(chunk => {
        const requests:string[] = chunk.map(group_ids => {
            const params:GroupsGetByIdParams = {
                group_ids: group_ids,
            };
            if (fields) {
                params.fields = fields;
            }
            const method:IVkMethod = methods.groupsGetById(params);
            return "API." + method.method_name + "(" + JSON.stringify(method.params) + ")";
        });
        const code:string = "return [" + requests.join(",") + "];";
        return methods.execute({code: code});
    });
    return executes;
}

function* send(
    executes:IVkMethod[],
    access_token: string,
    progress_action?: ActionCreatorWithPayload<TaskProgress>,
    add_api_errors_action?: ActionCreatorWithPayload<VkApiError>
): Generator<any, GroupObject[], any> {

    const result:Map<number, GroupObject> = new Map<number, GroupObject>();

    const handler = (response: Array<GroupObject[]|false>) => {
        response.forEach((response_item: GroupObject[]|false) => {
            if (!response_item) {
                return;
            }
            response_item.forEach(group => result.set(group.id, group));
        });
    };

    yield SendMethodsDefault<Array<GroupObject[]|false>>({
        methods_params: {
            methods: executes,
            access_token: access_token,
            progress_action: progress_action,
            add_api_errors_action: add_api_errors_action,
        },
        response_handler: handler,
        progress_text: 'Получаем информацию о группах',
        chunk_size: 6,
    });

    return Array.from(result.values());
}

export default GetGroups;
