import {PayloadAction} from "@reduxjs/toolkit";
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'
import sleep from "sleep-promise";
import cartesian from "../../../../helpers/Cartesian";
import SendMethodsWithAutoRetry from "../../../../vkapi/helpers/SendMethodsWithAutoRetry";
import {IVkMethod} from "../../../../vkapi/IVkMethod";
import {UsersSearchParams} from "../../../../vkapi/methods/UsersSearchParams";
import * as methods from "../../../../vkapi/methods/VkMethods";
import {UserObject} from "../../../../vkapi/objects/UserObject";
import {showAlertError, showAlertParsingCompleted} from "../../../app/actions";
import ProgressGenerator from "../../../helpers/ProgressGenerator";
import {Gender, RootState, TaskStatus} from "../../types";
import * as actions from "./actions";
import {UsersSearchState, UsersSearchType} from "./types";

interface UsersSearchResponse {
    count: number,
    items: UserObject[],
}

const main = function* () {
    while (yield take(actions.start)) {
        yield put(actions.setTaskStatus(TaskStatus.RUNNING));
        const task = yield fork(start);
        yield race({
            stop: call(onStop, task),
            completed: call(onCompleted),
            onError: call(onError, task),
        });
        yield put(actions.setTaskStatus(TaskStatus.READY));
    }
};

function* start() {
    try {
        yield execute();
    }
    catch (e) {
        yield put(actions.setTaskError(e))
    }
}

function* execute() {
    const state: UsersSearchState = yield select((state: RootState) => state.usersSearch);
    yield validate(state);
    const access_token = yield select((state: RootState) => state.app.access_token);

    const count_response: UsersSearchResponse|null = yield getCount(state.settings, access_token);
    if (!count_response || !count_response.count) {
        throw new Error("Не удалось получить результаты поиска. Возможно вы достигли лимита поисковых запросов или по вашим критериям ничего не найдно");
    }
    if (count_response.count < 1000) {
        yield complete(count_response.items.map(user => user.id));
        return;
    }

    const result: Set<number> = new Set<number>();

    const vk_methods: IVkMethod[] = makeMethods(state.settings);
    const progress = ProgressGenerator({text: 'Идет поиск', total: vk_methods.length, action: actions.setProgress});
    for (const method of vk_methods) {
        yield progress.next().value;
        if (result.size === count_response.count) {
            continue;
        }
        const response: UsersSearchResponse|null = yield send(method, access_token);
        if (!response || !response.count) {
            continue;
        }
        response.items.forEach(item => result.add(item.id));
        yield sleep(3000);
    }

    yield complete(Array.from(result.values()));
}

function* complete(result: number[]) {
    yield put(actions.setResult(result));
    yield put(actions.setProgress({message: `Завершено. Найдено: ${result.length}`, total: 100, current: 100}));
    yield put(showAlertParsingCompleted(`Готово. Найдено: ${result.length}`));
    yield put(actions.setCompleted());
}

function* getCount(settings: UsersSearchState['settings'], access_token: string): Generator<any, UsersSearchResponse|null, any> {
    const method_params: UsersSearchParams = {
        offset: 0,
        count: 1000,
        sort: 0,
        has_photo: 1,
    };
    if (settings.search_type === UsersSearchType.DEFAULT) {
        method_params.q = settings.query;
    }
    if (settings.search_type === UsersSearchType.JOB) {
        method_params.position = settings.query;
    }
    if (settings.search_type === UsersSearchType.RELIGION) {
        method_params.religion = settings.query;
    }
    if (settings.search_type === UsersSearchType.WORK) {
        method_params.company = settings.query;
    }
    if (settings.gender !== Gender.ANY) {
        method_params.sex = settings.gender;
    }
    const age_from: number = settings.age_min > 0 ? settings.age_min : Math.min(17, settings.age_max || 17);
    method_params.age_from = age_from;
    const age_to: number = settings.age_max > 0 ? settings.age_max : Math.max(50, settings.age_min || 50);
    method_params.age_to = age_to;
    if (settings.selected_country) {
        method_params.country = settings.selected_country.id;
    }
    if (settings.selected_city) {
        method_params.city = settings.selected_city.id;
    }

    const method: IVkMethod = methods.usersSearch(method_params);

    return yield send(method, access_token);
}

function* send(method: IVkMethod, access_token: string): Generator<any, UsersSearchResponse|null, any> {

    let result: UsersSearchResponse|null = null;
    yield SendMethodsWithAutoRetry<UsersSearchResponse>({
        methods: [method],
        access_token: access_token,
        onResponse: response => result = response,
        onError: () => {},
    });

    return result;
}

function makeMethods(settings: UsersSearchState['settings']) {
    const age_from: number = settings.age_min > 0 ? settings.age_min : Math.min(17, settings.age_max || 17);
    const age_to: number = settings.age_max > 0 ? settings.age_max : Math.max(50, settings.age_min || 50);
    const ages: number[] = [];
    for (let current_age = age_from; current_age < age_to; current_age++) {
        ages.push(current_age);
    }
    const genders: Gender[] = [];
    if (settings.gender === Gender.ANY) {
        genders.push(Gender.MALE, Gender.FEMALE);
    }
    if (settings.gender === Gender.MALE) {
        genders.push(Gender.MALE);
    }
    if (settings.gender === Gender.FEMALE) {
        genders.push(Gender.FEMALE);
    }
    const sorts: Array<0|1> = [0, 1];

    const combinations: Array<[number, Gender, 0|1]> = cartesian(ages, genders, sorts);

    const vk_methods: IVkMethod[] = combinations.map(combination => {
        const [age, gender, sort] = combination;
        const method_params: UsersSearchParams = {
            offset: 0,
            count: 1000,
            sex: gender,
            age_from: age,
            age_to: age,
            sort: sort,
            has_photo: 1,
        };
        if (settings.search_type === UsersSearchType.DEFAULT) {
            method_params.q = settings.query;
        }
        if (settings.search_type === UsersSearchType.JOB) {
            method_params.position = settings.query;
        }
        if (settings.search_type === UsersSearchType.RELIGION) {
            method_params.religion = settings.query;
        }
        if (settings.search_type === UsersSearchType.WORK) {
            method_params.company = settings.query;
        }
        if (settings.selected_country) {
            method_params.country = settings.selected_country.id;
        }
        if (settings.selected_city) {
            method_params.city = settings.selected_city.id;
        }
        return methods.usersSearch(method_params);
    });
    return vk_methods;
}

function* validate(state: UsersSearchState) {
    if (state.settings.query.trim() === '') {
        yield put(showAlertError('Вы не указали поисковый запрос'));
        yield put(actions.stop());
        return;
    }
    if (state.settings.age_min > 0 && state.settings.age_max > 0 && state.settings.age_min > state.settings.age_max) {
        yield put(showAlertError('Минимальный возраст не может быть больше максимального'));
        yield put(actions.stop());
        return;
    }
}

function* onStop(task) {
    yield take(actions.stop);
    yield cancel(task);
}

function* onCompleted() {
    yield take(actions.setCompleted);
}

function* onError(task) {
    const error: PayloadAction<Error> = yield take(actions.setTaskError);
    yield put(showAlertError(error.payload.message));
    yield cancel(task);
}

export default main;
