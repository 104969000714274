import {IVkMethod} from "../IVkMethod";
import _find from "lodash/find";
import {UsersGetParams} from "../methods/UsersGetParams";

interface FailedRequestParam {
    key: string,
    value: string,
}

const RestoreMethod = function (request_params: FailedRequestParam[]): IVkMethod|undefined {
    const method_param: FailedRequestParam|undefined = _find(request_params, {'key': 'method'});
    if (!method_param) {
        return undefined;
    }
    const method_name: string = method_param.value;
    return getMethod(method_name, request_params);
};

function getMethod(method_name: string, request_params: FailedRequestParam[]): IVkMethod|undefined {
    switch (method_name) {
        case 'users.get':
            let keys: (keyof UsersGetParams)[] = ["user_ids", "fields"];
            return {method_name: method_name, params: getMethodParams(keys, request_params)};
        default:
            return undefined;
    }
}

function getMethodParams(method_keys: string[], request_params: FailedRequestParam[]): object {
    let result: object = {};
    method_keys.forEach(key => {
        request_params.forEach(param => {
            if (param.key === key) {
                result[key] = param.value;
            }
        })
    });
    return result;
}

export default RestoreMethod;
