import {createAction} from "@reduxjs/toolkit";
import DefaultActionsCreator from "../../../helpers/DefaultActionsCreator";
import * as types from "./action-types";

function withPayloadType<T>() {
    return (t: T) => ({ payload: t })
}

export const setSource = createAction(types.SET_SOURCE, withPayloadType<string>());
export const setPartsCount = createAction(types.SET_PARTS_COUNT, withPayloadType<number>());
export const setShuffle = createAction(types.SET_SHUFFLE, withPayloadType<boolean>());

export const {
    start, stop, setProgress, setTaskError, setTaskStatus, addApiError, resetApiErrors, setResult, setCompleted
} = DefaultActionsCreator<string[][]>(types.NAMESPACE);
