import {IVkMethod} from "../IVkMethod";
import {convert_execute_error_to_api_error, VkApiError} from "../VkApiError";
import VkApiSendBatch, {VkApiBatchParams} from "../VkApiSendBatch";
import {Either, isLeft} from "fp-ts/lib/Either";
import {VkApiResponse} from "../VkApiResponse";

export interface SendMethodsParams<TResponse> {
    methods: IVkMethod[],
    access_token: string,
    onResponse: (response: TResponse) => void,
    onError: (error: VkApiError) => void,
    onRejected?: (reason: any) => void
}

const SendMethods = async <TResponse>(params: SendMethodsParams<TResponse>) => {
    const batch_params: VkApiBatchParams = {methods: params.methods, access_token: params.access_token};
    const promise_results: PromiseSettledResult<Either<VkApiError,VkApiResponse>>[] = await VkApiSendBatch(batch_params);
    for (let promise_result of promise_results) {
        if (promise_result.status === "rejected") {
            if (params.onRejected) {
                params.onRejected(promise_result.reason);
                continue;
            }
            throw new Error(promise_result.reason.toString());
        }
        if (isLeft(promise_result.value)) {
            params.onError(promise_result.value.left);
            continue;
        }

        const execute_errors = promise_result.value.right.execute_errors;
        if (execute_errors) {
            for (let execute_error of execute_errors) {
                const converted_error: VkApiError = convert_execute_error_to_api_error(execute_error);
                params.onError(converted_error);
            }
        }

        params.onResponse(promise_result.value.right.response as unknown as TResponse)
    }
};

export default SendMethods;
